import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, ButtonToolbar, Button, Row, Col, ButtonGroupInput } from 'reactstrap';
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';

import { getRestaurants, deleteRestaurant } from "../../../redux/actions/restaurantsActions";
import { getKitchenTypes } from "../../../redux/actions/kitchenTypesActions";

import { getUsers } from "../../../redux/actions/authActions"
import { NavLink } from 'react-router-dom';
import { api } from '../../../const';
import ConfirmMdl from '../confirm-mdl';
import { notification, Popconfirm, Table, Input, Space, Tag, Tooltip,AutoComplete } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Select from 'react-select';

import axios from 'axios'
import { FormattedMessage } from 'react-intl';

const ListStatus = []

ListStatus.push({ label: <FormattedMessage id="app.profile.active" />, value: "true" })
ListStatus.push({ label: <FormattedMessage id="app.profile.inactive" />, value: "false" })


const ListCreationStatus = []

ListCreationStatus.push({ label: <FormattedMessage id="app.creationInProgress" />, value: 0 })
ListCreationStatus.push({ label: <FormattedMessage id="app.profile.verifier" />, value: 1 })
ListCreationStatus.push({ label: <FormattedMessage id="app.Partenaire" />, value: 2 })

const filterGlobal = {
	isActive: "",
	creationStatus: "",
	postalCode: "",
	RestoName: '',
	TypeCuisine:"",
}

const { Search } = Input;
class ListRestaurantsCmp extends PureComponent {

	constructor() {
		super();
		this.state = {
			filter_by_driver: false,
			filter_by_restaurant: false,
			filter_by_tech_support: false,

			filter_roles: [],
			users: [],
			filter_all: true,
			restaurants: [],
			filter_status: '',
			filterZipCode: '',


			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,
			page_sizes: [5, 10, 20, 30, 50],


			show_add_restaurant_mdl: false,
			show_delete_msg: false,
			req_delete_id: null,
			selected_restaurant: null,
			selected_restaurants: [],
			searchText: '',
			searchedColumn: '',
			isClearable: true,
			postalcode: '',
			Clear: true,
			allresto: '',
			Resto:"",




			storageLanguage: localStorage.getItem('language')
		};

	}

	toggleClearable = () =>
		this.setState(state => ({ isClearable: !state.isClearable }))

	handleChange = (pagination, filters) => {

		this.setState({ filteredInfo: filters });
	};
	getColumnSearchCity = () => ({

	})

	getColumnSearchProps = dataIndex => (

		{

			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (

				<div style={{ padding: 8 }}>

					<Input
						ref={node => {
							this.searchInput = node;
						}}
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: 'block' }}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size="small"
							style={{ width: 90 }}
						>

							<FormattedMessage id="app.btn.chercher" />
						</Button>
						<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
							<FormattedMessage id="app.btn.cancel" />
						</Button>

					</Space>
				</div>
			),
			filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : "" }} />,






			onFilterDropdownVisibleChange: visible => {
				if (visible) {
					setTimeout(() => this.searchInput.select(), 100);
				}
			},
			render: text =>
				this.state.searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
						searchWords={[this.state.searchText]}
						autoEscape
						textToHighlight={text ? text.toString() : ''}
					/>
				) : (
					text
				),
		});


	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.getPage()
	};

	changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
	}

	componentDidMount(page=1) {
		const { selected_page_size, filter_status, filter_roles, postalcode, filterType
		} = this.state;

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		if (filter_roles !== "") {
			this.props.getUsers({ obj: filter_roles, offset, limit: selected_page_size })

		}
		else {
			this.props.getUsers({ offset, limit: selected_page_size })
		}

		this.props.getRestaurants({ offset, limit: selected_page_size })
		this.props.getKitchenTypes({})
	}


	componentDidUpdate(prev) {
		const { loading_delete_restaurant, error_delete_restaurant } = this.props
		if (prev.loading_delete_restaurant !== loading_delete_restaurant && !error_delete_restaurant) {
			this.onChangePage(1)
		}
	}

	getPage(page = 1) {


		const { selected_page_size, filter_status, filter_roles, postalcode, filterType
		} = this.state;

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })






	}
	ClearInput = (e) => {
		this.setState({ postalcode: "" })
		this.getPage({})
	}
	changePostalCode = (e, page = 1, allowClear = true) => {
		const { selected_page_size, filter_status, filter_roles, Clear
		} = this.state;

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		const { postalcode } = this.state



		// if (e.target.value !== "") {
		// 	this.props.getRestaurants({ filterZipCode: e.target.value, offset, limit: selected_page_size })

		// }
		// else {
		// 	this.props.getRestaurants({filterZipCode:"", offset, limit: selected_page_size })
		// }





	}

	onChangePage = page => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};


	ChangeFilterCreationStatus = (value, page = 1) => {


		const { selected_page_size, allresto,
		} = this.state;


		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		if (value !== null)  {
	
			if(value.value===0){
				filterGlobal.creationStatus = 0
			}else
			filterGlobal.creationStatus = value.value || ""
		}
		else {
			filterGlobal.creationStatus = ""
		}

		this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })




	}
	getResto=(e, page = 1 )=>{
		
				  const { selected_page_size,restaurant} = this.state;
				  const {restaurants}=this.props;
			
				  const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

				  
				  
				  if(e.target.value!=="")
			   {  				  this.setState({Resto:e.target.value}) 
			
				

			filterGlobal.RestoName=e.target.value
			this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })
		}
		else {
			filterGlobal.RestoName = ""
			this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })
		}
	
				}
	
	ChangeFilterName = (value,page=1) => {

		const { selected_page_size, allresto,
		} = this.state;
let filterName=""

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		if (value !== null) {
			filterGlobal.RestoName=value.label
			filterName= value.label || ""
		//  this.props.getRestaurants({filterName:filterName,offset, limit: selected_page_size})
		this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })
		}
		else {
			filterGlobal.RestoName = ""
			this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })
		}
		
		
	}

	ChangeFilterEtat = (value, page = 1) => {


		const { selected_page_size
		} = this.state;


		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

		if (value !== null) {
			filterGlobal.isActive = value.value || ""

		}
		else { filterGlobal.isActive = "" }

		this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })





	}

	changeManager = (value) => {
		const { users } = this.props
		if (value !== null) {
			users && users.filter(user => user.roles.includes('MANAGER')).map(opt => ({ label: opt, value: opt }));
			let IdManager = value.value

			this.props.getRestaurants({ IdManager })
		} else {
			this.getPage({})
		}
	}
	changeCuisine=(value,page=1)=>{
		const { selected_page_size
		} = this.state;


		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
	
		
		if(value!=null){
			let SelectedCuisine = [];
			value.forEach(element => {
			  SelectedCuisine.push(element.value)
			});
	
				filterGlobal.TypeCuisine=SelectedCuisine
		}else{
			filterGlobal.TypeCuisine=""
			
		}
		this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })
	}
	handleSearch = (value, page = 1) => {


		const { selected_page_size, allresto
		} = this.state;


		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		if (value != null) { filterGlobal.postalCode = value.target.value || "" }
		else { filterGlobal.postalCode = filterGlobal.postalCode || "" }
		let listcode = []
		{
			allresto !== "" && allresto.map((restaurant, i) =>
				listcode.push({
					label: restaurant?.address.postalCode,

				})
			)

		}

		this.props.getRestaurants({ filterGlobal, offset, limit: selected_page_size })
	}

	toggleAddRestaurantMdl = () => this.setState((prev) => { return { show_add_restaurant_mdl: !prev.show_add_restaurant_mdl } })

	changeSelectedUser = (item, checked = true) => {
		if (item) {
			if (this.props.uni) this.props.changeSelectedUser(item)
		}
	}


	deleteReq = () => {
		this.props.deleteRestaurant({ _id: this.state.req_delete_id })

		this.setState({ show_delete_msg: false })

	}
	openNotificationWithIconSuppression = type => {
		const { storageLanguage } = this.state
		notification[type]

			({
				duration: 3,
				message: storageLanguage === "en" ? 'Success' : 'Succès',
				description: storageLanguage === "en" ? 'Restaurant successfully deleted' : 'Supperssion du réstaurant avec Succès',

			});
	};
	openNotificationWithIconDésactivation = type => {
		const { storageLanguage } = this.state
		notification[type]

			({
				duration: 3,
				message: storageLanguage === "en" ? 'Success' : 'Succès',
				description: storageLanguage === "en" ? 'Restaurant successfully deactivate' : 'Désactivation du réstaurant avec Succès',


			});
	};
	openNotificationWithIcon = type => {
		const { storageLanguage } = this.state
		notification[type]

			({
				duration: 3,
				message: storageLanguage === "en" ? 'Success' : 'Succès',
				description: storageLanguage === "en" ? 'Restaurant successfully activated' : 'Activation du réstaurant avec Succès',

			});
	};
	openNotificationWithIconErreur = type => {
		const { storageLanguage } = this.state
		notification[type]

			({
				duration: 3,
				message: storageLanguage === "en" ? 'Error' : 'Erreur',
				description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
			});
	};
	activation = (isactive, id) => {

		{
			axios.patch(api.restaurants + '/activate/' + id, { isActive: isactive, message: 'ok' }).then(response => {
				if (response.status = 200) {
					this.openNotificationWithIcon('success')

					this.getPage()
				}
				else {

					this.openNotificationWithIconErreur('error')

					this.getPage({})

				}

			})

		}
	}

	désactivation = (isactive, id) => {

		{
			axios.patch(api.restaurants + '/activate/' + id, { isActive: !isactive, message: 'ok' }).then(response => {
				if (response.status = 200) {
					this.openNotificationWithIconDésactivation('success')
					this.getPage({})
				}
				else {

					this.openNotificationWithIconErreur('error')

					this.getPage({})

				}

			})

		}
	}
	suppression = (id) => {
		axios.delete(api.restaurants + "/" + id).then(response => {
			if (response.status = 200) {
				this.openNotificationWithIconSuppression('success')
				this.getPage({})
			}
			else {

				this.openNotificationWithIconErreur('error')

				this.getPage({})

			}

		})

	}

	cancel = (e) => {
		console.log(e);

	}



	Downloadfile = () => {



		axios.post(api.restaurants + '/excel', { "postalCode": this.state.postalcode }).then(response => {

			let path = api.root + '/' + response.data?.path

			window.open(path)

		});
	}

	render() {
		const { current_page, selected_page_size, show_delete_msg, filter_status, filter_all, selected_restaurant, selected_restaurants, storageLanguage, isClearable, Clear } = this.state;

		const {kitchen_types, user, users, restaurants, uni, show_footer, onSuccess, onCancel, card_style, body_style, count_all_restaurants } = this.props

		let CreationStatusList = []
		ListCreationStatus.forEach(element => {
			CreationStatusList.push({
				value: element.value,
				label: element.label
			})
		})
		let EtatRestaurant = []
		ListStatus.forEach(element => {
			EtatRestaurant.push({
				value: element.value,
				label: element.label
			})
		})


		let listmanager = []

		{
			users && users.filter(user => user.roles.includes('MANAGER')).map((usr, i) =>


				listmanager.push({
					value: usr._id,
					label: usr.profile.firstName + " " + usr.profile.lastName
				})


			)
		}
		let listcuisine=[]

		kitchen_types.forEach(element => {
			listcuisine.push({
			  value: element._id,
			  label: element.name,
			})
		  });

		
		let RestaurantList = []
		{
			restaurants && restaurants.map((restaurant, i) =>


				RestaurantList.push({

					label: restaurant.name
				})

			)
		
			
		}
		const columns = [

			{

				title: <FormattedMessage id="app.restaurant" />,
				dataIndex: 'name',
				key: 'name',

				sortDirections: ['descend', 'ascend'],

				...this.getColumnSearchProps('name'),
				render: (value, item, index) => {
					return (<div>
						{item.logo ? <img alt={item.name} src={`${api.root}/${item.logo.path}`} style={{ width: 40 }} />
							: <img alt={item.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />}
						<span> {item.name}</span>
					</div>)

				},
				sorter: (a, b) => a.name.localeCompare(b.name),


			},

			{
				title: <FormattedMessage id="app.profile.address" />,
				dataIndex: 'city',
				key: 'Adresse',

				width: '22%',
				sortDirections: ['descend', 'ascend'],

				render: (value, item, index) => {
					return (<div>



						<Tooltip placement="topLeft" title={item.address!== null  ? item.address.street + " " + item.address.country + " " + item.address.city : " "}>

							{item.address!== null ? item.address.street + "...":""}
						</Tooltip>


					</div>)
				},
			

			},
			{
				title: <FormattedMessage id="app.restaurant.contact" />,
				dataIndex: 'email',
				key: 'email',
				render: (value, item, index) => {
					return (<div>
						{item.email !== null || item.phone !== "" ?
							<div>
								<Tag color="green">{item.email} </Tag><br />

								<Tag color="cyan" style={{ marginTop: 10 }}>{item.phone}</Tag>
							</div>
							: <div></div>}
					</div>)
				}

			},
			{
				title: <FormattedMessage id="app.etat" />,
				dataIndex: 'statu',
				key: 'statu',

				render: (value, item, index) => {
					if (item.isActive)
						return (<div>  <Tag color="green"><FormattedMessage id="app.profile.active" /> </Tag><br /></div>)

					else return (<div>  <Tag color="volcano"><FormattedMessage id="app.profile.inactive" /> </Tag><br /></div>)


				}

			},
			{
				title: <FormattedMessage id="app.profile.status" />,
				dataIndex: 'statu',
				key: 'statu',

				render: (value, item, index) => {
					if (item.creationStatus?.code === 0)
						return (<div>  <Tag color="volcano"><FormattedMessage id="app.creationInProgress" /> </Tag><br /></div>)

					if (item.creationStatus?.code === 1) {
						return (<div>  <Tag color="green"><FormattedMessage id="app.profile.verifier" /> </Tag><br /></div>)
					}
					if (item.creationStatus?.code === 2) { return (<div>  <Tag color="cyan"><FormattedMessage id="app.Partenaire" /> </Tag><br /></div>) }


				}

			},
			{
				title: <FormattedMessage id="app.profile.manager" />,
				dataIndex: 'firstName',
				key: 'firstName',

				render: (value, item, index) => {
					return (<div>{item.owner ? item.owner?.profile?.firstName + " " + item.owner?.profile?.lastName : " "}
					</div>)
				}


			},
			{
				title: <FormattedMessage id="app.manager.contact" />,

				key: 'owner?.email',


				render: (value, item, index) => {
					return (<div>{item.owner?.profile?.phone}
					</div>)
				}

			},



			{
				title: <FormattedMessage id="app.profile.action" />,
				width: '15%',



				render: (value, item, index) => {
					return (<span><Popconfirm title={storageLanguage === "en" ? "Do you want to deactivate " + item.name + ' ?' : "Voulez-vous désactiver " + item.name + ' ?'} onConfirm={() => this.désactivation(item.isActive, item._id)}
						onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
						{item.isActive && <span className="lnr lnr-checkmark-circle" style={{ fontSize: 15, fontWeight: 'bolder', color: '#28a745', marginLeft: 25 }}></span>}
					</Popconfirm>


						<Popconfirm title={storageLanguage === "en" ? "Do you want to activate " + item.name + ' ?' : "Voulez-vous activer " + item.name + ' ?'} onConfirm={() => this.activation(!item.isActive, item._id)}
							onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
							{!item.isActive && <span className="lnr lnr-cross-circle" style={{ cursor: 'pointer', fontSize: 15, fontWeight: 'bolder', color: '#dc3545', marginLeft: 25 }}></span>}
						</Popconfirm>


						<NavLink to={"/app/restaurants/edit/" + item._id}><span className="lnr lnr-pencil" style={{ cursor: 'pointer', color: "#70bbfd", fontSize: 18, cursor: "pointer", marginLeft: 25 }}></span></NavLink>


						<Popconfirm title={storageLanguage === "en" ? "Do you want to delete" + item.name + ' ?' : "Voulez-vous supprimer " + item.name + ' ?'} onConfirm={() => this.suppression(item._id)}
							onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
							<span className="lnr lnr-trash" style={{ cursor: 'pointer', color: "#dc3545", fontSize: 18, marginLeft: 25 }}></span>
						</Popconfirm>
					</span>)
				}
			},
		];


		return (<Card style={card_style ? card_style : {}}>
			<CardBody style={body_style ? body_style : {}}>
				<div className="card__title">

					<Row style={{ marginBottom: 10 }}>
						<Col md="8">
						</Col>
						{(user && user.roles.includes('ADMIN')) && <Col md="4" style={{ textAlign: 'right' }}>
							<Button color="success" className="rounded">
								<NavLink style={{ color: "white" }} to={"/app/restaurants/add"}><FormattedMessage id="app.btn.addnewrestaurant" /></NavLink></Button>
						</Col>}

					</Row>
					<Row>
						<Col md="4" style={{ marginBottom: 10 }}>



							<Select isClearable={isClearable} onChange={(e) => this.ChangeFilterEtat(e)} options={EtatRestaurant}
								placeholder='Etat' styles={{ background: '#e7e2e2', width: '150px', border: 'none', borderBottom: '1px solid #ddd' }} />


						</Col>
						<Col md="4" style={{ marginBottom: 10 }}>

							<Select isClearable={isClearable} onChange={(e) => this.ChangeFilterCreationStatus(e)} options={CreationStatusList}
								placeholder='Status' styles={{ background: '#e7e2e2', width: '150px', border: 'none', borderBottom: '1px solid #ddd' }} />

						</Col>
						<Col md="4" style={{ marginBottom: 10 }} onChange={(e) => this.getResto(e)}>

							<Select isClearable={isClearable}   onChange={(e) => this.ChangeFilterName(e)} options={RestaurantList}
								  placeholder={<FormattedMessage id="app.restaurant"/>} styles={{ background: '#e7e2e2', width: '150px', border: 'none', borderBottom: '1px solid #ddd' }} />
							

						</Col>
						<Col md="4" style={{ marginBottom: 10 }}>
							<Select isClearable={isClearable} onChange={(e) => this.changeManager(e)} options={listmanager}
								placeholder='Manager' styles={{ background: '#e7e2e2', width: '150px', border: 'none', borderBottom: '1px solid #ddd' }} />
						</Col>
						


						<Col md="4" style={{ marginBottom: 10 }}>


							<Input placeholder="Code Postal" onChange={this.handleSearch}
								onKeyUp={(e) => this.changePostalCode(e)}
								styles={{ background: '#e7e2e2', width: '150px', border: 'none', borderBottom: '1px solid #ddd' }}/>
							<span className="lnr lnr-cross-circle" style={{ position: 'absolute', marginLeft: -25, padding: 7, opacity: 0.5 }} onClick={(e) => this.ClearInput(e)}></span>

						</Col>
						<Col md="4" style={{ marginBottom: 10 }}>
							<Select isClearable={isClearable} onChange={(e) => this.changeCuisine(e)} isMulti options={listcuisine}
								placeholder='Cuisine' styles={{ background: '#e7e2e2', width: '150px', border: 'none', borderBottom: '1px solid #ddd' }} />
						</Col>
						<Col md="2">

							<Button className="rounded">
								<span onClick={() => this.Downloadfile()}> <h5 style={{ color: 'black' }}> <span className="lnr lnr-download"></span>  Export excel</h5></span>

							</Button>


						</Col>

					</Row>
				</div>



				<ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
					is_open={show_delete_msg} accept={() => {
						this.deleteReq()
					}}
				/>



				<div>

					<Table rowKey={(item, index) => index} scroll={{ x: 1000 }}
						dataSource={restaurants} columns={columns} bordered pagination={false}
						style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} />


				</div>

				<Pagination currentPage={current_page}
					totalPage={getCountPages(count_all_restaurants, selected_page_size)}
					onChangePage={i => this.onChangePage(i)} />

			</CardBody>

			{show_footer && <div style={{ padding: 5 }}>
				<ButtonToolbar className="modal__footer form__button-toolbar">
					<Button color="primary" onClick={() => onSuccess(uni ? selected_restaurant : selected_restaurants)}><FormattedMessage id="app.btn.select" /></Button>
					<Button type="button" onClick={() => onCancel()}><FormattedMessage id="app.btn.cancel" /></Button>
				</ButtonToolbar>
			</div>}
		</Card>);
	}
}

const mapStateToProps = ({ auth, restaurant,ktype }) => {
	const { loading_get_restaurants, user, users } = auth
	const { restaurants, count_all_restaurants, loading_delete_restaurant, error_delete_restaurant } = restaurant
	return {
		loading_get_restaurants, user, users, restaurants, count_all_restaurants,
		loading_delete_restaurant, error_delete_restaurant,
		kitchen_types: ktype.kitchen_types,
	}
}
export default connect(mapStateToProps, {
	getUsers,
	getRestaurants, deleteRestaurant,getKitchenTypes
})(ListRestaurantsCmp);

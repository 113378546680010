import React,{ PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import {getUserConfig } from "../../../redux/actions/UserConfigActions";

import { FormattedMessage } from 'react-intl';
class TotalCustomers extends PureComponent {

  componentDidMount() {
    	

    }
    render(){
    
      const {config}=this.props

let allcommande=config!==undefined?config?.all:0
let commandedelivery=config!==undefined?config?.delivery:0
let commandepickup=config!==undefined?config?.pickup:0

let  delivery=((commandedelivery*100)/allcommande)
let pickup=((commandepickup*100)/allcommande)
      return (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card">
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title dashboard__booking-total-title --blue">
            {allcommande}
          </h5>
          {/* <TrendingUpIcon className="dashboard__trend-icon" /> */}
        </div>
        <h5 className="dashboard__booking-total-description"> <FormattedMessage id="app.order" /></h5>
       
        <div className="progress-wrap progress-wrap--small progress-wrap--blue-gradient progress-wrap--rounded">

          <p className="dashboard__booking-card-progress-label progress__label"> 
          {pickup.toFixed(2)} %  <FormattedMessage id="app.récupere"/>
          </p>
      
          <Progress value={pickup} />
          
          <p className="dashboard__booking-card-progress-label progress__label"> 
          {delivery.toFixed(2)} % <FormattedMessage id="app.livraison"/>
          </p>
      
          <Progress value={delivery} />
          
        </div>
       
        
        
     
      </CardBody>
    </Card>
  </Col>
   )
  }
}

const mapStateToProps = ({UserConfig}) => {
  const{config}=UserConfig


  return {
    config:UserConfig?.UserConfig.orders,
  }
}
export default connect(mapStateToProps, { getUserConfig})(TotalCustomers);




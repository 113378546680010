import {
    GET_FEATURES, GET_FEATURES_SUCCESS, GET_FEATURES_ERROR,
    ADD_FEATURE, ADD_FEATURE_SUCCESS, ADD_FEATURE_ERROR,
 
    EDIT_FEATURE, EDIT_FEATURE_SUCCESS, EDIT_FEATURE_ERROR
  } from '../actions/feautreActions';
  
  const initialState = {
    loading_get_features: false,
    error_get_features: null,
    features: [],
    // count_all_feature: 0,
  
    new_feature: null, loading_add_feature: false, error_add_feature: null,
  };
  
  const FeaturesReducer = (state = initialState, action) => {

    switch (action.type) {
  
      case GET_FEATURES: return {...state, loading_get_features: true, error_get_features: null };
      case GET_FEATURES_SUCCESS:
        return {...state, features: action.payload.features, loading_get_features: false, error_get_features: null };
      case GET_FEATURES_ERROR:

        return {...state, error_get_features: action.error, loading_get_features: false, features: [] };
  
  
  
      case ADD_FEATURE: return {...state, loading_add_feature: true, error_add_feature: null };
      case ADD_FEATURE_SUCCESS:
        return {...state, new_feature: action.payload.feature, loading_add_feature: false, error_add_feature: null };
      case ADD_FEATURE_ERROR:
        return {...state, error_add_feature: action.error, loading_add_feature: false, new_feature: null };
  
  
    
  
      case EDIT_FEATURE: return {...state, loading_edit_feature: true, error_edit_feature: null };
      case EDIT_FEATURE_SUCCESS:
        return {...state, loading_edit_feature: false, error_edit_feature: null };
      case EDIT_FEATURE_ERROR:
        return {...state, error_edit_feature: action.error, loading_edit_feature: false};
  
  
      default: return state;
    }
  };
  
  export default FeaturesReducer;
  
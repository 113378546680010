import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DemandeMarqueBlanche from './demande';
import ListeMArques from'./list';
import EditMarqueBlanche  from './edit';

const MarquesBlanchesRoutingPages = ({ match }) => (


<Suspense fallback={<div className="loading" />}>
<Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

    <Route path={`${match.url}/list`}
      render={props => <ListeMArques {...props} />}
    />

    <Route path={`${match.url}/demande`}
      render={props => <DemandeMarqueBlanche {...props} />}
    />
      <Route path={`${match.url}/edit/:marqueblanch_id`}
      render={props => <EditMarqueBlanche {...props} />}
    />
  

 
    <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    <Redirect to="/error" />
  </Switch>

</Suspense>
);
export default MarquesBlanchesRoutingPages;


import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_RESTAURANTS, getRestaurantsSuccess, getRestaurantsError, 
  ADD_RESTAURANT, addRestaurantError, addRestaurantSuccess, 
  GET_ONE_RESTAURANT, getOneRestaurantSuccess, getOneRestaurantError, 
  EDIT_RESTAURANT, editRestaurantError, editRestaurantSuccess,
  DELETE_RESTAURANT, deleteRestaurantError, deleteRestaurantSuccess
} from '../actions/restaurantsActions';
import { api } from '../../const';



// *** GET RESTAURANTS
export function* watchGetRestaurants() { yield takeEvery(GET_RESTAURANTS, getRestaurants) }
function* getRestaurants({ payload }) {

  try {
    const ret = yield call(getRestaurantsRequest, payload);

    if (ret && ret.status === 200) yield put(getRestaurantsSuccess({ restaurants: ret.data?.restaurants || [],count: ret.data?.counts || 0}));
    else yield put(getRestaurantsError({ message: ret.data ? ret.data.msg : "Error" }))
  

  } catch (error) {
    yield put(getRestaurantsError({ error, message: (error) ? error.message : '' }));
  }
}
const getRestaurantsRequest = async (payload) => {  

 
 
 
  if(payload.IdManager!==undefined){
    return await axios.get(api.restaurants+'?owner='+payload.IdManager) 
   
  }
  if(payload.filterName!==undefined)
  {  
    return await axios.get(api.restaurants+'/get-all/'+payload.offset+'/'+payload.limit+'?name='+payload.filterName)
  }
  if(payload.filterZipCode!==undefined)
  {  
    return await axios.get(api.restaurants+'/get-all/'+payload.offset+'/'+payload.limit+'?postalCode='+payload.filterZipCode)
  }
  

  if(payload.filterGlobal)
  {
    return await axios.get(api.restaurants+'/get-all/'+payload.offset+'/'+payload.limit+'?isActive='+payload.filterGlobal.isActive
    +'&creationStatus='+payload.filterGlobal.creationStatus+'&postalCode='+payload.filterGlobal.postalCode+'&name='+payload.filterGlobal.RestoName+'&cuisines='+payload.filterGlobal.TypeCuisine)
  }
  // if(payload.filterGlobal.RestoName!==undefined)
  // {
  //   return await axios.get(api.restaurants+'/get-all?isActive='+payload.filterGlobal.isActive
  //   +'&creationStatus='+payload.filterGlobal.creationStatus+'&postalCode='+payload.filterGlobal.postalCode+'&name='+payload.filterGlobal.RestoName)
  // }

  else 
  return await axios.get(api.restaurants+'/get-all/'+payload.offset+'/'+payload.limit)

  

  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** GET ONE RESTAURANT
export function* watchGetOneRestaurant() { yield takeEvery(GET_ONE_RESTAURANT, getOneRestaurant) }
function* getOneRestaurant({ payload }) {
  try {
    const ret = yield call(getOneRestaurantRequest, payload);

    
    if (ret && ret.status === 200) yield put(getOneRestaurantSuccess({ restaurant: ret.data?.restaurant || null }));
    else yield put(getOneRestaurantError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneRestaurantError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneRestaurantRequest = async (payload) => {  
  //const endpoint = (payload.track) ? '/track-one/' : '/get-one/'
  return await axios.get(api.restaurants+'/'+payload.restaurant_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD RESTAURANTS
export function* watchAddRestaurants() { yield takeEvery(ADD_RESTAURANT, addRestaurant) }
function* addRestaurant({ payload }) {
  try {
 
   
    const ret = yield call(addRestaurantsRequest, payload.data);

    if (ret && ret.status === 200){
      yield put(addRestaurantSuccess({ restaurant: ret.data }));
    
    } 
    else yield put(addRestaurantError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addRestaurantError({ error, message: (error) ? error.message : '' }));
  }
}
const addRestaurantsRequest = async (payload) => {  

  return await axios.post(api.restaurants, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT RESTAURANTS
export function* watchEditRestaurant() { yield takeEvery(EDIT_RESTAURANT, editRestaurant) }
function* editRestaurant({ payload }) {
  const {history } = payload;
  try {
    const ret = yield call(editRestaurantsRequest, payload);

    
    if (ret && ret.status === 200) {
      yield put(editRestaurantSuccess({ restaurant: ret.data.restaurant }));
      if(history){
        history.push('/app/restaurants');
      }
      
    }
    else yield put(editRestaurantError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editRestaurantError({ error, message: (error) ? error.message : '' }));
  }
}
const editRestaurantsRequest = async (payload) => {  
  return await axios.patch(api.restaurants+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE RESTAURANTS
export function* watchDeleteRestaurant() { yield takeEvery(DELETE_RESTAURANT, deleteRestaurant) }
function* deleteRestaurant({ payload }) {
  try {
    const ret = yield call(deleteRestaurantsRequest, payload);

    if (ret && ret.status === 204) yield put(deleteRestaurantSuccess({ vehicle_type: ret.data.vehicle_type }));
    else yield put(deleteRestaurantError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteRestaurantSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteRestaurantsRequest = async (payload) => {  
  return await axios.delete(api.restaurants+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetRestaurants),
    fork(watchGetOneRestaurant),
    fork(watchAddRestaurants),
    fork(watchDeleteRestaurant),
    fork(watchEditRestaurant)
  ]);
}

import React,{ PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';



import { FormattedMessage } from 'react-intl';

class TotalCancels extends PureComponent {


    componentDidMount() {

      }
    render(){
 
      const {config}=this.props
   
      let allreservation=config!==undefined?config?.all:0
      let reservationconfirmed=config!==undefined?config?.confirmed:0
     
      
      let  confirmed=((reservationconfirmed*100)/allreservation)


      return (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card">
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title">{allreservation}</h5>
  
        </div>
        <h5 className="dashboard__booking-total-description"> <FormattedMessage id="app.reservation" /></h5>
        <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--rounded">
          <p className="dashboard__booking-card-progress-label progress__label">{confirmed.toFixed(2)}% <FormattedMessage id="app.confirmer" /></p>
          <Progress value={parseInt(confirmed)} />
        </div>
      </CardBody>
    </Card>
  </Col>
 )
}
}
const mapStateToProps = ({UserConfig}) => {
  const{config}=UserConfig


  return {
    config:UserConfig?.UserConfig.reservations,
  }
}
export default connect(mapStateToProps, )(TotalCancels);



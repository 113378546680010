import React, { PureComponent } from 'react';
import { Col, Row} from 'reactstrap';
import {notification} from 'antd'

import { connect } from 'react-redux';
import {getVEGAN} from "../../../redux/actions/VEGANActions"

import { api } from '../../../const';
import axios from "axios"
import { FormattedMessage } from 'react-intl';
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../helper/utils";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
class EditVEGANCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
        title: "",
        description:'',
        _id: "",
        storageLanguage : localStorage.getItem('language')

	};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }
  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Diet type modified successfully  ':'Type Régime modifieé avec sucées',
   
		})
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  checkFrm = (data) => {

    const { title } = data
    if (title=== "") return "Type VEGAN est require";

    return ""
  }
  editVEGAN = () =>{
    const { title, description,_id } = this.state
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
    if(error_frm===''){
      const data={title,description}

      axios.patch(api.vegan+'/'+_id,data).then(response=>{
        
      if (response.status=200){ 
        this.openNotificationWithIcon('success') 
        this.props.success()
        this.props.getVEGAN()
      }
      else{

        this.openNotificationWithIconErreur('error')
        this.props.getVEGAN()
      
      }
        
    })
  
  }}
  
  
  componentDidMount(){
    if(this.props.VEGANType)
     this.setState({
      title: this.props.VEGANType.title,
      description: this.props.VEGANType.description,
      _id: this.props.VEGANType._id,
    })

  }
  handleChange = (value) => {
    const obj = {};
    obj["description"] = value;
    this.setState(obj);
  };

  render() {
 
    
    const { title,description} = this.state

    return (<Row>

      <Col md={10}>
        <div className="form">
          <div className="form__form-group">
            <span className="form__form-group-label"><FormattedMessage id="app.setting.titre" /></span>

            <div className="form__form-group-field">
              <input value={title} placeholder="Titre" onChange={(e)=>this.changeVal('title', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
                 </span>
            <div className="form__form-group-field">
            <ReactQuill style={{ width: "100%" }}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={this.handleChange}
        
                />
            </div>


            
          </div>


          <div className="form__form-group-field">
            <div onClick={this.editVEGAN} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-pencil"></span>
              </div>


              <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
              </div>
        </div>



      </Col>
    </Row>)
  }
}

const mapStateToProps = ({ VEGAN_types }) => {
  
    return {
     
      veganReq:VEGAN_types.VEGAN_types,
    }
  }
  export default connect(mapStateToProps, {
  getVEGAN
  })(EditVEGANCmp);
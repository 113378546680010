import React from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ListCart from '../../shared/components/Carts/list-carts';
import { FormattedMessage } from 'react-intl';
const ListCartsCmp = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title"><FormattedMessage id="app.titre.listpanier" /> </h3>
      </Col>
      <Col md={12}>
        <ListCart/>
      </Col>
    </Row>
  
  </Container>
);

export default ListCartsCmp;
// ***** GET RESTAURANTS 
export const GET_RESTAURANTS            = 'GET_RESTAURANTS';
export const GET_RESTAURANTS_SUCCESS    = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_ERROR      = 'GET_RESTAURANTS_ERROR';

export function getRestaurants(payload) {
  return {type: GET_RESTAURANTS, payload};
}
export function getRestaurantsSuccess(payload) {
  return { type: GET_RESTAURANTS_SUCCESS, payload };
}
export function getRestaurantsError(error) {
  return { type: GET_RESTAURANTS_ERROR, error };
}






// ***** ADD RESTAURANT
export const ADD_RESTAURANT            = 'ADD_RESTAURANT';
export const ADD_RESTAURANT_SUCCESS    = 'ADD_RESTAURANT_SUCCESS';
export const ADD_RESTAURANT_ERROR      = 'ADD_RESTAURANT_ERROR';

export function addRestaurant(payload) {
  return {type: ADD_RESTAURANT, payload};
}
export function addRestaurantSuccess(payload) {
  return { type: ADD_RESTAURANT_SUCCESS, payload };
}
export function addRestaurantError(error) {
  return { type: ADD_RESTAURANT_ERROR, error };
}






// ***** GET ONE RESTAURANT
export const GET_ONE_RESTAURANT            = 'GET_ONE_RESTAURANT';
export const GET_ONE_RESTAURANT_SUCCESS    = 'GET_ONE_RESTAURANT_SUCCESS';
export const GET_ONE_RESTAURANT_ERROR      = 'GET_ONE_RESTAURANT_ERROR';

export function getOneRestaurant(payload) {
  return {type: GET_ONE_RESTAURANT, payload};
}
export function getOneRestaurantSuccess(payload) {
  return { type: GET_ONE_RESTAURANT_SUCCESS, payload };
}
export function getOneRestaurantError(error) {
  return { type: GET_ONE_RESTAURANT_ERROR, error };
}



// ***** EDIT RESTAURANT
export const EDIT_RESTAURANT            = 'EDIT_RESTAURANT';
export const EDIT_RESTAURANT_SUCCESS    = 'EDIT_RESTAURANT_SUCCESS';
export const EDIT_RESTAURANT_ERROR      = 'EDIT_RESTAURANT_ERROR';

export function editRestaurant(payload) {
  return {type: EDIT_RESTAURANT, payload};
}
export function editRestaurantSuccess(payload) {
  return { type: EDIT_RESTAURANT_SUCCESS, payload };
}
export function editRestaurantError(error) {
  return { type: EDIT_RESTAURANT_ERROR, error };
}



// ***** EDIT RESTAURANT
export const DELETE_RESTAURANT            = 'DELETE_RESTAURANT';
export const DELETE_RESTAURANT_SUCCESS    = 'DELETE_RESTAURANT_SUCCESS';
export const DELETE_RESTAURANT_ERROR      = 'DELETE_RESTAURANT_ERROR';

export function deleteRestaurant(payload) {
  return {type: DELETE_RESTAURANT, payload};
}
export function deleteRestaurantSuccess(payload) {
  return { type: DELETE_RESTAURANT_SUCCESS, payload };
}
export function deleteRestaurantError(error) {
  return { type: DELETE_RESTAURANT_ERROR, error };
}
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { FormattedMessage } from 'react-intl';
const ExamplePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title"><FormattedMessage id="app.profile" /></h3>
      </Col>
    </Row>
    <Row>
      {/* <ExampleCard /> */}
    </Row>
  </Container>
);

export default ExamplePage;

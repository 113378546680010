
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_COMMANDE_PERDU, getCommande_PerduSuccess, getCommande_PerduError, 
  DELETE_COMMANDE_PERDU,deleteCommande_PerduSuccess,deleteCommande_PerduError
} from '../actions/commandeperduActions';
import { api } from '../../const';

// *** GET DEMANA
export function* watchGetCommandePerdu() { yield takeEvery(GET_COMMANDE_PERDU, getCommande_Perdu) }
function* getCommande_Perdu({ payload }) {
  try {
    const ret = yield call(getCommande_PerduRequest, payload);
    if (ret && ret.status === 200) yield put(getCommande_PerduSuccess({ commande_perdu: ret.data?.recommendation|| [], count: ret.data?.counts || 0}));
    else yield put(getCommande_PerduError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getCommande_PerduError({ error, message: (error) ? error.message : '' }));
  }
}
const getCommande_PerduRequest = async (payload) => {  

    if(payload.IdResto){
        return await axios.get(api.commande_perdu+'/'+payload.offset+'/'+payload.limit+'?restaurant='+payload.IdResto) 
    }
    return await axios.get(api.commande_perdu+'/'+payload.offset+'/'+payload.limit)
 
  
  
 
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** DELETE DIETS
export function* watchDeletCommandePerdu() { yield takeEvery(DELETE_COMMANDE_PERDU,deleteCommande_Perdu)}
function* deleteCommande_Perdu({ payload }) {
 try {
   const ret = yield call(deleteCommande_PerduRequest, payload);

   if (ret && ret.status === 204) yield put(deleteCommande_PerduSuccess({ DIET: ret.data }));
    else yield put(deleteCommande_PerduError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteCommande_PerduSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteCommande_PerduRequest = async (payload) => {  
  return await axios.delete(api.commande_perdu+'/'+payload._id)
 .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};

export default function* rootSaga() {
    yield all([
      fork(watchGetCommandePerdu),
      // fork(watchGetCommandePerduByanager),
    //  fork(watchCommandePerdu),
  fork(watchDeletCommandePerdu)
    ]);
  }
import React, { PureComponent } from 'react';
import { Col, Row , Button } from 'reactstrap';
 
import { connect } from 'react-redux';

import {
	addMenu , getOneMenu , editMenu , deleteMenu
} from "../../../redux/actions/menuActions";
import {editRestaurant} from "../../../redux/actions/restaurantsActions";


import EditDishCmp from "./edit-dish";
import AddDishCmp from "./add-dish";
import CollapseCmp from "../Collapse";
import { api } from '../../../const';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select'
class EditMenuCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
            show_add_dish:false,
            selected_dish:null,
            show_edit_dish:false,
            name: "",
            description:"",
            type :"",
            price:"",
            picture: null,
            picture_url: '',
            plates : [],
            ListPlats:[]
		};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }

  editMenu = () =>{
    const { selected_menu} = this.props
    const { name, description , type, price, plates} = this.state

    if(name!==''){
      const frm = new FormData()
      if(plates.length > 0){
        let i =0;
        for (i=0; i< plates.length; i++){
            frm.append('plates[]', plates[i]);
          }
      }
    
      frm.append('name', name)
      frm.append('description', description)
      frm.append('type', type)
      frm.append('price', price)
       this.props.editMenu({data:frm,_id:selected_menu._id})
  
      this.props.success()
    
    }
  
  }

  componentWillMount(){

    const { selected_menu} = this.props
    if(selected_menu){
      
        this.props.getOneMenu({_id:selected_menu._id})
    }

  }

  changePlat=(value)=>{

    let SelectedPlat= [];
    if (value!==null){
      value.forEach(element => {
        SelectedPlat.push(element.value)
      });
  
      this.setState({ 
        plates: SelectedPlat
      },()=>{
        
      })
    }else{
      SelectedPlat=''
    }
 
  }
  // addPlat = (plat) =>{
  //   const {plates } = this.state
  //   const { selected_restaurant} = this.props
  //   let id=selected_restaurant._id
  //   console.log('plates',plates,id)
  //   plates.push(plat)
  //   this.setState({plates},()=>{
  //     const frm = new FormData();
  //     let i =0;
  //     for (i=0; i< plates.length; i++){
  //       frm.append('plates[]', plates[i]._id);
  //     }
   
  //     this.forceUpdate()
  //   })
  // }


  deletePlat = (idx) =>{
    const { selected_menu} = this.props
    const {plates } = this.state
    plates.splice(idx, 1);
    this.setState({plates,selected_dish:null},()=>{
        if(plates.length>0){
            
            const frm = new FormData();
            let j = 0
            for (j=0; j< plates.length; j++){
                frm.append('plates[]', plates[j]._id);
            }
            this.props.editMenu({data:frm,_id:selected_menu._id})
        }else{
            const data = {plates : []}
            this.props.editMenu({data,_id:selected_menu._id})
        }
        
    })
  }
  

  componentDidUpdate(prev){
    const {new_menu } = this.props
  


    if(prev.new_menu !== new_menu ){
        
        const {name , description , type , price , picture , plates } = new_menu
       
        this.setState({
            name , description , type , price:price.price , picture , plates , picture_url : picture ? api.root+'/'+picture.path : ''
        })

    }

  }

  changeImg = (e) => {
    const { selected_menu} = this.props
    try {
      const picture = e.target.files[0]
      this.setState({ picture, picture_url: URL.createObjectURL(picture) },()=>{
        const frm = new FormData()
        frm.append('picture', picture)
        this.props.editMenu({data:frm,_id:selected_menu._id})
      })
    } catch (error) { this.setState({ picture: null, picture_url: '' }) }
  }

  render() {
    const { name, picture_url,description, type , price  , show_add_dish , selected_dish ,  show_edit_dish , plates} = this.state
    const {selected_restaurant}=this.props
    let ListPlats=[]
  
    selected_restaurant.plates.forEach(element => {
      ListPlats.push({
        value:element._id,
        label: element.name,
      })
    });

  
    return (<Row>
      <Col md="2">
        <div className="add_img_wrap">
          <img alt={name} src={picture_url === '' ? ` ${process.env.PUBLIC_URL}/img/no.png` : picture_url } />

          <div className="caption">
            <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
            <input onChange={(e)=>this.changeImg(e)} id="add_avatar" type="file" accept="image/*" hidden />
          </div>
        </div>
      </Col>

      <Col md={9}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
              <Row>
              <Col md={6}> 
                <span className="form__form-group-label"><FormattedMessage id="app.setting.titre"/></span>

                <div className="form__form-group-field">
                    <input value={name} placeholder="Name" onChange={(e)=>this.changeVal('name', e.target.value)}  style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}/>
                </div>
                <span className="form__form-group-label"><FormattedMessage id="app.type"/></span>

                <div className="form__form-group-field">
                    <input value={type} placeholder="type" onChange={(e)=>this.changeVal('type', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                </div>
                <span className="form__form-group-label"><FormattedMessage id="app.order.prix"/></span>

                <div className="form__form-group-field">
                    <input value={price}  type="number" min="0" placeholder="0" onChange={(e)=>this.changeVal('price', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                </div>

                
              </Col>
              <Col md={6}> 
              <span className="form__form-group-label"><FormattedMessage id="app.setting.description"/></span>
              <div className="form__form-group-field">
                    <textarea rows={3} value={description} placeholder="Description" onChange={(e)=>this.changeVal('description', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}/>
                </div>
              </Col>
              </Row>
              
            

   
              <Row>
                    <Col md="6">
                    <div className="form__form-group-field" style={{marginTop:'10px'}}>
                          <div className="form__form-group">
                    <span className="form__form-group-label" style={{marginTop:'8px',fontWeight:'bolder'}}><FormattedMessage id="app.plats"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => this.changePlat(e)} isMulti options={ListPlats} 
                                  value={ListPlats.filter(option => plates!==undefined && plates.includes(option.name))}
                                  styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                          </div>
                    </Col>
                  
      </Row>
            



          </div>
        </div>
      </Col>
      <Col md={1}>
        <div className="form__form-group-field" style={{marginTop:'10px'}}>
            <div onClick={this.editMenu} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-checkmark-circle"></span>
            </div>

            <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : ()=>{}} className="form__form-group-icon" style={{background: "#F44336",marginLeft:'10px', color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
            </div>
        </div>
      </Col>
    </Row>)
  }
}


const mapStateToProps = ({restaurant, menu }) => {
  const { loading_add_menu, error_add_menu  } = menu
  const new_menu = menu.menu
  const {selected_restaurant} = restaurant
  return {
    selected_restaurant, loading_add_menu, error_add_menu , new_menu
  }
}
export default connect(mapStateToProps, {
    addMenu , getOneMenu , editMenu , deleteMenu,editRestaurant
})(EditMenuCmp);

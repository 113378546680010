import React, { useState } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonToolbar, ButtonGroup,
} from 'reactstrap';
import { api } from '../../../../const';
import Select from 'react-select'
import { FormattedMessage } from 'react-intl';
const Role=[
  {label: 'CLIENT', value: 0},
  {label: 'MANAGER', value: 1},
  {label: 'DELIVERYMAN', value: 2},
] 
const ProfileMain = ({profile, editProfile, changeAvatar,renderImage, count_trips, count_finished, count_canceled, drive_request, editreservationsReq}) =>{

  const [editing, setEditing] = useState(false);
  const [firstName, setFirstName] = useState(profile && profile.profile ? profile.profile.firstName : '' );
  const [lastName, setLastName] = useState(profile && profile.profile ? profile.profile.lastName : '');
  const[password,setpassword]=useState(profile && profile.profile?profile.profile.password:'');
  const[confirmPassword,setconfirmPassword]=useState(profile && profile.profile?profile.profile.confirmPassword:'');
  const [email, setEmail] = useState(profile?.email);
  const [phone, setMobile] = useState(profile && profile.profile?profile.profile.phone:'');
  const [gender, setGender] = useState(profile && profile.profile ?  profile.profile.gender : '' );
  const [address, setAddress] = useState(profile && profile.profile.address ? profile.profile.address?.fullAddress : '' );
  const [zipcode, setZipcode] = useState(profile && profile.profile.address ? profile.profile.address?.postalCode : '');
  const [logo,setLogo]=useState(profile&&profile.profile.picture?profile.profile.picture?.path:"  ")
  const[role,setRole]=useState(profile && profile.profile?profile.roles:'')
  const [selectedItem, setSelectedItem] = useState(profile && profile.profile?profile.roles:'');
 const  ChangeRole=(value)=>{ 
   


  
  value.forEach(element => {

    selectedItem == element.label ? setSelectedItem(null) : setSelectedItem(element.label);
 
  });

}


  return (

    <Col md={12} lg={12} xl={12}>
      {profile && <Card>
        <CardBody className="profile__card">
          <span onClick={()=>setEditing(!editing)} className="lnr lnr-pencil" style={{
            color: "#50b179", cursor: "pointer",
            position: "absolute", right: 5, top: 5,
            fontSize: 16
          }}></span>
        
          <div className="profile__information">
    
            {!editing && <div className="profile__avatar profile_img_wrap">
              {
                (profile.profile?.picture) ? <img alt={firstName +' '+ lastName} src={`${api.root}/${profile.profile?.picture?.path}`} />
                : <img  alt={firstName +' '+ lastName}src={`${process.env.PUBLIC_URL}/img/no-user.png`} /> 
              }
              {renderImage}

              <div className="caption">
                <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
                <input onChange={changeAvatar} id="add_avatar" type="file" accept="image/*" hidden />
              </div>
            </div>}
  
            {editing ? <Row>
              <Col md="6">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profile.firstname" /></span>

                    <div className="form__form-group-field">
                      <input value={firstName} placeholder="First name" onChange={(e)=>setFirstName(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profile.lastname" /></span>

                    <div className="form__form-group-field">
                      <input value={lastName} placeholder="Last name" onChange={(e)=>setLastName(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>
             

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profile.password" /></span>

                    <div className="form__form-group-field">
                      <input value={password} placeholder="********" onChange={(e)=>setpassword(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profil.cnfrmpassword"/> </span>

                    <div className="form__form-group-field">
                      <input value={confirmPassword} placeholder="********" onChange={(e)=>setconfirmPassword(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profile.email" /></span>

                    <div className="form__form-group-field">
                      <input value={email} placeholder="Email" onChange={(e)=>setEmail(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profile.phone" /></span>

                    <div className="form__form-group-field">
                      <input type="number" value={phone} placeholder="téléphone" onChange={(e)=>setMobile(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="12">
                <ButtonToolbar>
                  <ButtonGroup dir="ltr" className="btn-group--icons mb-1">
                    <Button onClick={() => setGender("MALE")} outline style={gender==='Homme' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.profile.gender.homme" /> </Button>
                    <Button onClick={() => setGender("FEMALE")} outline style={gender==='Femme' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.profile.gender.femme" /></Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
              <Col md="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.cuisine"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => ChangeRole(e)} 
                                  value={Role.filter(option => selectedItem!==undefined &&selectedItem.includes(option.label))}
                                  isMulti 
                                  options={Role} styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                      </Col>

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profile.address" /></span>

                    <div className="form__form-group-field">
                      <input value={address} placeholder="Address" onChange={(e)=>setAddress(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label"><FormattedMessage id="app.profile.zipcode" /></span>

                    <div className="form__form-group-field">
                      <input value={zipcode} placeholder="Zip Code" onChange={(e)=>setZipcode(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>



              <Col md="12">
                <ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
                  <Button color="primary" onClick={()=>editProfile({
                    firstName, lastName,password,confirmPassword, email, phone, gender, address, zipcode,
                    roles:selectedItem!==role?selectedItem:null
                  },setEditing(false))}><FormattedMessage id="app.btn.edit" /></Button>
                  <Button type="button" onClick={()=>setEditing(false)}>
                  <FormattedMessage id="app.btn.cancel" />
                    </Button>
                </ButtonToolbar>
              </Col>
            </Row>


            : <div className="profile__data">
              <p className="profile__name">{firstName + " " + lastName}</p>
              <p className="profile__work">{email}</p>
              
              {profile.profile!==""&&profile.profile.phone!==undefined && profile.profile.phone!==null?
               <p className="profile__contact"dir="ltr">+251-{profile.profile.phone}</p>
              :<p className="profile__contact" onClick={()=>setEditing(!editing)} className="lnr lnr-pencil" style={{
            color: "#f31d1d", cursor: "pointer", fontSize: 16 }}>  <FormattedMessage id="app.profile.msgenewtel" /></p> }

              <p className="profile__contact" >{gender==="MALE"?"Homme":"Femme"}</p>
              {profile.profile!==""&&profile.profile.address!==undefined &&  profile.profile.address!==null?
               <p className="profile__contact" >{profile.profile.address?.fullAddress+" "+profile.profile.address?.postalCode}</p>
              :
              
              <p className="profile__contact" onClick={()=>setEditing(!editing)} className="lnr lnr-pencil" style={{color: "#f31d1d", cursor: "pointer", fontSize: 16
          }}><FormattedMessage id="app.profile.msgenewaddress"/></p> }
    
           
            </div>}
            
          </div>
        </CardBody>
      </Card>}
    </Col>
  )
}

export default ProfileMain;

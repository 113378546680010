import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListeMarquesBlanche from '../../shared/components/MarqueBlanche/listemarqueBlanche'
import { FormattedMessage } from 'react-intl';

const ListeMArques = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title"><FormattedMessage id="app.liste.marqueblanche" /> </h3>
      </Col>

      <Col md={12}>
<ListeMarquesBlanche/>
      </Col>
    </Row>
  </Container>
);

export default ListeMArques;
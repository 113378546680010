// ***** GET KITCHEN_TYPES
export const GET_KITCHEN_TYPES            = 'GET_KITCHEN_TYPES';
export const GET_KITCHEN_TYPES_SUCCESS    = 'GET_KITCHEN_TYPES_SUCCESS';
export const GET_KITCHEN_TYPES_ERROR      = 'GET_KITCHEN_TYPES_ERROR';

export function getKitchenTypes(payload) {

  return {type: GET_KITCHEN_TYPES, payload};
}
export function getKitchenTypesSuccess(payload) {
  
  return { type: GET_KITCHEN_TYPES_SUCCESS, payload };
}
export function getKitchenTypesError(error) {
  return { type: GET_KITCHEN_TYPES_ERROR, error };
}





// ***** ADD KITCHEN_TYPE
export const ADD_KITCHEN_TYPE            = 'ADD_KITCHEN_TYPE';
export const ADD_KITCHEN_TYPE_SUCCESS    = 'ADD_KITCHEN_TYPE_SUCCESS';
export const ADD_KITCHEN_TYPE_ERROR      = 'ADD_KITCHEN_TYPE_ERROR';

export function addKitchenType(payload) {
  return {type: ADD_KITCHEN_TYPE, payload};
}
export function addKitchenTypeSuccess(payload) {
  return { type: ADD_KITCHEN_TYPE_SUCCESS, payload };
}
export function addKitchenTypeError(error) {
  return { type: ADD_KITCHEN_TYPE_ERROR, error };
}






// ***** GET ONE KITCHEN_TYPE
export const GET_ONE_KITCHEN_TYPE            = 'GET_ONE_KITCHEN_TYPE';
export const GET_ONE_KITCHEN_TYPE_SUCCESS    = 'GET_ONE_KITCHEN_TYPE_SUCCESS';
export const GET_ONE_KITCHEN_TYPE_ERROR      = 'GET_ONE_KITCHEN_TYPE_ERROR';

export function getOneKitchenType(payload) {
  return {type: GET_ONE_KITCHEN_TYPE, payload};
}
export function getOneKitchenTypeSuccess(payload) {
  return { type: GET_ONE_KITCHEN_TYPE_SUCCESS, payload };
}
export function getOneKitchenTypeError(error) {
  return { type: GET_ONE_KITCHEN_TYPE_ERROR, error };
}



// ***** EDIT KITCHEN_TYPE
export const EDIT_KITCHEN_TYPE            = 'EDIT_KITCHEN_TYPE';
export const EDIT_KITCHEN_TYPE_SUCCESS    = 'EDIT_KITCHEN_TYPE_SUCCESS';
export const EDIT_KITCHEN_TYPE_ERROR      = 'EDIT_KITCHEN_TYPE_ERROR';

export function editKitchenType(payload) {
  return {type: EDIT_KITCHEN_TYPE, payload};
}
export function editKitchenTypeSuccess(payload) {
  return { type: EDIT_KITCHEN_TYPE_SUCCESS, payload };
}
export function editKitchenTypeError(error) {
  return { type: EDIT_KITCHEN_TYPE_ERROR, error };
}



// ***** EDIT KITCHEN_TYPE
export const DELETE_KITCHEN_TYPE            = 'DELETE_KITCHEN_TYPE';
export const DELETE_KITCHEN_TYPE_SUCCESS    = 'DELETE_KITCHEN_TYPE_SUCCESS';
export const DELETE_KITCHEN_TYPE_ERROR      = 'DELETE_KITCHEN_TYPE_ERROR';

export function deleteKitchenType(payload) {
  return {type: DELETE_KITCHEN_TYPE, payload};
}
export function deleteKitchenTypeSuccess(payload) {
  return { type: DELETE_KITCHEN_TYPE_SUCCESS, payload };
}
export function deleteKitchenTypeError(error) {
  return { type: DELETE_KITCHEN_TYPE_ERROR, error };
}
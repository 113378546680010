import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {Card, CardBody,Container, Button, Row, Col} from 'reactstrap';
import Select from 'react-select'


import Alert from "../../helper/Alert";
import { NavLink } from 'react-router-dom';

import {addRestaurant} from "../../../redux/actions/restaurantsActions";


import {getKitchenTypes} from "../../../redux/actions/kitchenTypesActions";
import {getDressCodes} from "../../../redux/actions/dressCodeActions";
import { getUsers } from "../../../redux/actions/authActions";
import {getDiet} from "../../../redux/actions/dietActions"
import {getRestauranTypes} from '../../../redux/actions/RestauranTypesActions'

import ResizeImg from '../ResizeImgMdl';
import { api } from '../../../const';
import {notification} from 'antd';
import axios from "axios";
import { FormattedMessage } from 'react-intl';
class AddRestaurantCmp extends PureComponent {


  constructor() {
    super();
    this.state = {
      name: '',description:'',email:'', mobile:'',capacity: '', city: '', state: '', country: '', postalCode: '',managers:'' ,
      cuisine:[],dressCodes:[],type:[],logo:null,logo_url:'',cover:null,cover_url:'',gallery:'', error_frm: "",diets:[],
      show_resize_img: false,
      current_page: 1,
			totalPage: 1,
			selected_page_size: 10,
			page_sizes: [5, 10, 20, 30, 50],

      storageLanguage : localStorage.getItem('language')

    };
  }

  componentDidMount(page=1){
    const { selected_page_size, 
		} = this.state;
 

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    this.props.getUsers({obj:'MANAGER' ,offset, limit: selected_page_size})
    
    this.props.getKitchenTypes()
    this.props.getDressCodes()

    this.props.getRestauranTypes({})
    this.props.getDiet()
  
  }

  changeVal = (key, value) => {

    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  changeTypeRestoVal = (elem) => {
    this.setState({ 
      type : elem.value
    })
 
  }
  
  changeTypeRegimeVal=(value)=>{

    let SelectedDiets = [];
    value.forEach(element => {
      SelectedDiets.push(element.value)
    });
    this.setState({ 
      dites : SelectedDiets
    })
  }

  changeCuisineVal = (value) => {

    let SelectedCuisine = [];
    value.forEach(element => {
      SelectedCuisine.push(element.value)
    });
    this.setState({ 
      cuisine : SelectedCuisine
    })
  }

  changeDressCodeVal = (value) => {

    let SelectedDressCode = [];
    value.forEach(element => {
      SelectedDressCode.push(element.value)
    });
    this.setState({ 
      dressCodes : SelectedDressCode
    })
  }

  changeManagersVal = (elem) => {


    this.setState({ 
      managers : elem.value
    })


  }

  changeCover = (e) => {
    try {
      const cover = e.target.files[0]
      this.setState({ cover, cover_url: URL.createObjectURL(cover) }, ()=>{
        
      })
    } catch (error) { this.setState({ cover: null, cover_url: '' }) }
  }
  changeLogo = (e) => {
    try {
      const logo = e.target.files[0]
      //this.setState({ logo, logo_url: URL.createObjectURL(logo) ,show_resize_img:true}, ()=>{
      this.setState({ logo, logo_url: URL.createObjectURL(logo) }, ()=>{
        
      })
    } catch (error) { this.setState({ logo: null, logo_url: '' }) }
  }

  renderImage = (img , img_url) => {
      
    if(!img && img_url !== '') return (<img alt='clicknmeal' src={`${api.root}/${img}`} style={{ width: '100%', height: '100%' }}/>)
    if(img && img_url !== '') return (<img alt='clicknmeal' src={img_url} style={{ width: '100%', height: '100%' }} />)
    return (<img alt='clicknmeal' src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: '100%', height: '100%' }}/>)
  }

  render_Image = (img , img_url) => {

   let background =`${process.env.PUBLIC_URL}/img/no.png`

   if(!img && img_url !== ''){
     background = `${api.root}/${img}`
     return (<div style={{backgroundImage:`url(${background})`,width: '100%', height: '100%',backgroundPosition:'center center',backgroundSize:'cover'}}></div>)
   } 
   if(img && img_url !== ''){
     background = `${img_url}`
     return (<div style={{backgroundImage:`url(${background})`,width: '100%', height: '100%',backgroundPosition:'center center',backgroundSize:'cover'}}></div>)
   } 

   
   return (<div style={{backgroundImage:`url(${background})`, width: '100%', height: '100%',backgroundPosition:'center center',backgroundSize:'cover'}}>

   </div>)
 }
 openNotificationWithIcon = type => {
  const {storageLanguage}=this.state
  notification[type]
  ({
    duration:3,
    message:storageLanguage === "en"? 'Success':'Succès',
    description:storageLanguage === "en"? 'Restaurant successfully added':'Réstaurant ajouté avec succès',
 
  });

};
openNotificationWithIconErreur = type => {
  const {storageLanguage}=this.state
  notification[type]

  ({
    
      duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
    });
};

  submit = () => {


    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })

 
    if (error_frm === "") {
      const { name,email , mobile, capacity, city, state, country,postalCode,type,cuisine ,dites, dressCodes , managers, logo,cover , description } = this.state
      const data = { name, description , email, phone:mobile, capacity, city, state, country,postalCode,cuisine ,dites,type, dressCodes , owner:managers, logo,cover }
      const new_data = JSON.parse(JSON.stringify(data)) 
      new_data["logo"] = JSON.parse(JSON.stringify(logo))
      new_data["cover"] = JSON.parse(JSON.stringify(cover))

      //if(!logo && !cover ) this.props.addRestaurant(new_data)

      const frm = new FormData();
      if (logo ) frm.append("logo",   logo);
      if (cover ) frm.append("cover",   cover);

      frm.append("name",   new_data.name);
      frm.append("description",   new_data.description);
      frm.append("email",   new_data.email);
      frm.append("phone",   new_data.phone);
      frm.append("capacity",   new_data.capacity);
      frm.append("city",   new_data.city);
      frm.append("street",   new_data.state);
      frm.append("country",   new_data.country);
      frm.append("postalCode",   new_data.postalCode);
      frm.append("cuisine",   new_data.cuisine);
      frm.append("dites",new_data.dites);
      frm.append("dressCodes",   new_data.dressCodes);
      frm.append("owner",   new_data.owner);
      frm.append("type",new_data.type)
      let Data =frm
      axios.post(api.restaurants, Data).then(response=>{

        if(response.status===200 ) { 
          this.openNotificationWithIcon('success')  
       let Id=response.data._id
      window.location.href="/app/restaurants/edit/"+Id
        }
        else{
          this.openNotificationWithIconErreur('error')
        } }  
      )  }
  }


  checkFrm = (data) => {
    const { name , description , email, mobile, capacity, city, state, country,postalCode,cuisine } = data
    if (name === "") return "the name are required.";
    if (description === "") return "the description is required.";
    if (email === "") return "email is required.";
    if (mobile === "") return "mobile is required.";
    if (capacity === "") return "capacity is required.";
    if (city === "") return "capacity is required.";
    if (state === "") return "state is required.";
    if (country === "") return "country is required.";
    if (postalCode === "") return "postalCode is required.";
    if (cuisine === "") return "cuisine is required.";

    return ""
  }

  componentDidUpdate(prev) {
    const { loading_add_restaurant, error_add_restaurant, new_restaurant} = this.props
 
    
    if (!loading_add_restaurant && prev.loading_add_restaurant){
      
      if(error_add_restaurant){
        if(error_add_restaurant.message && error_add_restaurant.message !== "")
        this.setState({error_frm: error_add_restaurant.message})
      }

      else this.props.handleSuccess(new_restaurant)
    }
  }

  render() {
    
    const { is_open, users,  kitchen_types , dress_codes,restaurant_types,diet_types} = this.props
    const {cover , cover_url , logo , logo_url , show_resize_img} = this.state
  

    let TypeResto=[];
    restaurant_types.forEach(element => {
      TypeResto.push({
        value:element._id,
        label: element.title,
      })
    });
    let TypeRegime=[];
    diet_types.forEach(element=>{
      TypeRegime.push({
        value:element._id,
        label:element.title,
      })
    })
    let cuisines = [];

    kitchen_types.forEach(element => {
      cuisines.push({
        value:element._id,
        label: element.name,
      })
    });

    let dressCodeList = []
    dress_codes.forEach(element => {
      dressCodeList.push({
        value:element._id,
        label: element.name,
      })
    });

    let managers = []
    {users&&users.filter(user => user.roles.includes('MANAGER')).map((usr, i) =>

    managers.push({
      value:usr._id,
      label:usr.profile.firstName + " " + usr.profile.lastName
      })
          )}

    return (
        <Container>

            <Card>
			
            <CardBody>

        <div className="modal__body" style={{ padding: '0 10px', textAlign: 'left' }}>


          <form className="form" onSubmit={(e) => {
            e.preventDefault();

            this.submit()
          }}>
            <Row>
              <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
                <Alert is_visible={(this.state.error_frm !== "")} color="danger" className="alert--colored" icon >
                  <p><span className="bold-text"><FormattedMessage id="app.warning"/> </span> {this.state.error_frm}</p>
                </Alert>
              </Col>


                <Col md="3">
                  <div className="restaurant_img_wrap" style={{marginBottom:'50px',height:'90px',width:'90px',overflow:'hidden',borderRadius:'5px'}}>
                    {this.renderImage(logo , logo_url)}
        
                    <div className="caption">
                      <label htmlFor="add_logo"><span className="lnr lnr-picture"></span></label>
                      <input onChange={this.changeLogo} id="add_logo" type="file" accept="image/*" hidden />
                    </div>
                  </div>
                </Col>
                <Col md="8">
                  <div className="restaurant_img_wrap" style={{marginBottom:'50px',height:'220px',width:'90%',overflow:'hidden',borderRadius:'5px'}}>
                    {this.render_Image(cover , cover_url)}
                    
                    <div className="caption">
                      <label htmlFor="add_cover"><span className="lnr lnr-picture"></span></label>
                      <input onChange={this.changeCover} id="add_cover" type="file" accept="image/*" hidden />
                    </div>
                  </div>
                </Col>
                <Col md="1"></Col>

              <Col md="12" style={{marginBottom: 20}}><p style={{fontWeight:'bolder'}}><FormattedMessage id="app.informations"/></p></Col>
              


                      <Col md="3">
                        <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.restaurant"/></span>
                          <div className="form__form-group-field">
                            <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                              name="name" component="input" placeholder="Réstaurant"
                              onChange={(e) => this.changeVal('name', e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.email"/></span>
                          <div className="form__form-group-field">
                            <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                              name="email" component="input" placeholder="adress email"
                              onChange={(e) => this.changeVal('email', e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.phone"/></span>
                          <div className="form__form-group-field">
                            <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                              name="mobile" component="input" placeholder="Téléĥone"
                              onChange={(e) => this.changeVal('mobile', e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.restaurant.capacity"/></span>
                          <div className="form__form-group-field">
                            <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                              name="capacity" component="input" type="number" min="0" placeholder="0"
                              onChange={(e) => this.changeVal('capacity', e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.setting.description"/></span>
                          <div className="form__form-group-field">
                            <textarea rows="2" style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                              name="description"
                           
                              placeholder="description"
                              onChange={(e) => this.changeVal('description', e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.restaurant.type"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => this.changeTypeRestoVal(e)}  options={TypeResto} styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                      </Col>
                      <Col md="2">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.cuisine"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => this.changeCuisineVal(e)} 
                                  isMulti options={cuisines} styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                      </Col>
                      <Col md="2">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.regime.type"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => this.changeTypeRegimeVal(e)} 
                                  isMulti
                                  options={TypeRegime} styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                      </Col>

                      <Col md="2">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.stylevestimentaire"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => this.changeDressCodeVal(e)} isMulti options={dressCodeList} styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                      </Col>
                      <Col md="3">
                        <div className="form__form-group">
                          <span className="form__form-group-label"><FormattedMessage id="app.profile.manager"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => this.changeManagersVal(e)}  options={managers} styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                      </Col>

              <Col md="12" style={{marginBottom: 20}}></Col>

              <Col md="12">
                <p style={{marginBottom: 20, fontWeight:'bolder'}}><FormattedMessage id="app.profile.address"/></p>
                <Row>
                <Col md="3">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.city"/></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="city" component="input" type="text" placeholder="ville"
                      onChange={(e) => this.changeVal('city', e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              <Col md="3">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.street"/></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="state" component="input" type="text" placeholder="Rue"
                      onChange={(e) => this.changeVal('state', e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              <Col md="3">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.country"/></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="country" component="input" type="text" placeholder="Pays"
                      onChange={(e) => this.changeVal('country', e.target.value)}
                    />
                  </div>
                </div>
              </Col>



              <Col md="3">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.zipcode"/></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="postalCode" component="input" type="text" placeholder="Code postal"
                      onChange={(e) => this.changeVal('postalCode', e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              </Row>

              </Col>

              <Col md="12">

              </Col>
              <Col md="12">
      
                <Button className="btn btn-small" color="primary" type="submit"><FormattedMessage id="app.btn.submit"/></Button>
                <NavLink className="btn btn-primary btn-small" color="primary"  to={"/app/restaurants"}><FormattedMessage id="app.btn.cancel"/></NavLink>

              </Col>

            </Row>




          </form>
        </div>
          <ResizeImg toggle={() => this.setState((prev) => { return { show_resize_img: !prev.show_resize_img } })}
            src={logo_url}
            image={logo}
            is_open={show_resize_img} accept={() => {
              //this.deleteReq()
            }}
          />
        </CardBody>
        </Card>
        </Container>
    );
  }
}


const mapStateToProps = ({ auth, ktype , dressCode,restauranType,dietReq }) => {
  return {
    form: 'log_in_form',
    loading_login: auth.loading_login,
    restaurant: auth.restaurant,
    loading_add_restaurant: auth.loading_add_restaurant,
    error_add_restaurant: auth.error_add_restaurant,
    new_restaurant: auth.new_restaurant,
    user : auth.user,
    users : auth.users,
    kitchen_types: ktype.kitchen_types,
    dress_codes: dressCode.dress_codes,
    restaurant_types:restauranType.restaurant_types,
    diet_types:dietReq.diet_types,
  }
}
export default connect(mapStateToProps, {
  getRestauranTypes,
  addRestaurant,
  getKitchenTypes,getDressCodes,getUsers,getDiet
})(reduxForm()(AddRestaurantCmp));
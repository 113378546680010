
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_RESERVATION_REQS, getreservationsReqsSuccess, getreservationsReqsError, 
  // ADD_RESERVATION_REQ, addreservationsReqError, addreservationsReqSuccess, 
  // GET_ONE_RESERVATION_REQ, getOnereservationsReqSuccess, getOnereservationsReqError, 
  EDIT_RESERVATION_REQ, editreservationsReqError, editreservationsReqSuccess,

  DELETE_RESERVATION_REQ, deletereservationsReqError, deletereservationsReqSuccess,
} from '../actions/reservationsReqsActions';
import { api } from '../../const';



// *** GET RESERVATION_REQS
export function* watchGetreservationsReqs() { yield takeEvery(GET_RESERVATION_REQS, getreservationsReqs) }
function* getreservationsReqs({ payload }) {

  try {
    const ret = yield call(getreservationsReqsRequest, payload);

    if (ret && ret.status === 200) yield put(getreservationsReqsSuccess({ reservations: ret.data?.reservations || [], count: ret.data?.count || 0}));
    else yield put(getreservationsReqsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getreservationsReqsError({ error, message: (error) ? error.message : '' }));
  }
}
const getreservationsReqsRequest = async (payload) => {  

  if(payload.obj['status']==-1){
    return await axios.get(api.reservations+'/get-all/'+payload.offset+'/'+payload.limit)
  }
  return await axios.get(api.reservations+'/get-all/'+payload.offset+'/'+payload.limit+'?'+'status'+'='+payload.obj['status'])
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE RESERVATION_REQ
// export function* watchGetOnereservationsReq() { yield takeEvery(GET_ONE_RESERVATION_REQ, getOnereservationsReq) }
// function* getOnereservationsReq({ payload }) {
//   try {
//     const ret = yield call(getOnereservationsReqRequest, payload);

//     if (ret && ret.status == 200) yield put(getOnereservationsReqSuccess({ drive_request: ret.data?.drive_request || null }));
//     else yield put(getOnereservationsReqError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(getOnereservationsReqError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const getOnereservationsReqRequest = async (payload) => {  
//   const endpoint = (payload.track) ? '/track-one/' : '/get-one/'
//   return await axios.get(api.reservations+endpoint+payload.drive_request_id)
//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };





// // *** ADD RESERVATION_REQS
// export function* watchAddreservationsReqs() { yield takeEvery(ADD_RESERVATION_REQ, addreservationsReq) }
// function* addreservationsReq({ payload }) {
//   try {
//     const ret = yield call(addreservationsReqsRequest, payload);

//     if (ret && ret.status == 201) yield put(addreservationsReqSuccess({ drive_request: ret.data.drive_request }));
//     else yield put(addreservationsReqError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(addreservationsReqError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const addreservationsReqsRequest = async (payload) => {  
//   return await axios.post(api.reservations+'/create', payload)
//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };









// *** EDIT RESERVATION_REQS
export function* watchEditreservationsReq() { yield takeEvery(EDIT_RESERVATION_REQ, editreservationsReq) }
function* editreservationsReq({ payload }) {
  try {
    const ret = yield call(editreservationsReqsRequest, payload);

    if (ret && ret.status === 200) yield put(editreservationsReqSuccess({ drive_request: ret.data.drive_request }));
    else yield put(editreservationsReqError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editreservationsReqError({ error, message: (error) ? error.message : '' }));
  }
}
const editreservationsReqsRequest = async (payload) => {  
  return await axios.patch(api.reservations+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE RESERVATION_REQS
export function* watchDeletereservationsReq() { yield takeEvery(DELETE_RESERVATION_REQ, deletereservationsReq) }
function* deletereservationsReq({ payload }) {
  try {
    const ret = yield call(deletereservationsReqsRequest, payload);

    if (ret && ret.status === 200) yield put(deletereservationsReqSuccess({ }));
    else yield put(deletereservationsReqError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deletereservationsReqError({ error, message: (error) ? error.message : '' }));
  }
}
const deletereservationsReqsRequest = async (payload) => {  
  return await axios.delete(api.reservations+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetreservationsReqs),
    // fork(watchGetOnereservationsReq),
    // fork(watchAddreservationsReqs),
    fork(watchEditreservationsReq),
    fork(watchDeletereservationsReq),
  ]);
}
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListOrdersPage from './listordersparrestaurants';
import DetailOrderPage from './EditOrder';
import AddOrder from'./AddOrder.jsx';


const OrdersPage = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

        <Route path={`${match.url}/list`}
          render={props => <ListOrdersPage {...props} />}
        />

        <Route path={`${match.url}/detail/:order_id`}
          render={props => <DetailOrderPage {...props} />}
        />
        
   <Route path={`${match.url}/add_New_Order`}
          render={props => <AddOrder {...props} />}
        />
        <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
        <Redirect to="/error" />
      </Switch>
    
  </Suspense>
);
export default OrdersPage;


import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_ORDER_REQS, getOrderReqsSuccess, getOrderReqsError, 
  GET_ONE_ORDER_REQS, getOneOrderReqsSuccess, getOneOrderReqsError, 
  ADD_ORDER_REQ, addOrderReqError, addOrderReqSuccess, 
   GET_ORDER_PAR_RESTO, getOrderParRestoSuccess, getOrderParRestoError, 
   GET_ORDER_BY_USER, getOrderParUserSuccess,getOrderParUserError,
   GET_ORDER_BY_DELIVERYMAN,getOrderParDeliverymanSuccess,getOrderParDeliverymanError,

  EDIT_ORDER_REQ, editOrderReqError, editOrderReqSuccess,

  DELETE_ORDER_REQ, deleteOrderReqError, deleteOrderReqSuccess,
} from '../actions/orderActions';
import { api } from '../../const';
import { Col } from 'reactstrap';


// *** GET ORDER_REQS
export function* watchGetOrderReqs() { yield takeEvery(GET_ORDER_REQS, getOrderReqs) }
function* getOrderReqs({ payload }) {
  try {
    const ret = yield call(getOrderReqsRequest, payload);

    if (ret && ret.status === 200) yield put(getOrderReqsSuccess({ order_requests: ret.data || [], count: ret.data?.counts || 0}));
    else yield put(getOrderReqsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOrderReqsError({ error, message: (error) ? error.message : '' }));

  }
 
}
const getOrderReqsRequest = async (payload) => { 
 

  if (payload.filterGlobal.IdResto)
  return await axios.get(api.orders+'/by-resto/'+payload.filterGlobal.IdResto)
  else
  if(payload.filterGlobal.status['status']==-1 ){
    return await axios.get(api.orders+'/get-all/'+payload.offset+'/'+payload.limit)
  

  }
  if(payload.filterGlobal ){
    return await axios.get(api.orders+'/get-all/'+payload.offset+'/'+payload.limit+'?status='+payload.filterGlobal.status['status']+'&name='+payload.filterGlobal.RestoName)
  }
  else
  return await axios.get(api.orders+'/get-all/'+payload.offset+'/'+payload.limit+'?status='+payload.filterGlobal.status['status']+'&name='+payload.filterGlobal.RestoName)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};



// *** GET ONE_ORDER_REQS
export function* watchGetOneOrderReqs() { yield takeEvery(GET_ONE_ORDER_REQS, getOneOrderReqs) }
function* getOneOrderReqs({ payload }) {
  try {
    const ret = yield call(getOneOrderReqsRequest, payload);

    if (ret && ret.status === 200) yield put(getOneOrderReqsSuccess({ order_requests: ret.data || [], count: ret.data?.counts || 0}));
    else yield put(getOneOrderReqsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneOrderReqsError({ error, message: (error) ? error.message : '' }));

  }
 
}
const getOneOrderReqsRequest = async (payload) => {  

  return await axios.get(api.orders+'/'+payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};






//  *** GET ONE ORDER_By_Resto
 export function* watchGetOrderParResto() { yield takeEvery(GET_ORDER_PAR_RESTO, getOrderParResto) }
 function* getOrderParResto({ payload }) {
   try {
     const ret = yield call(getOrderParRestoRequest, payload);

     if (ret && ret.status === 200) yield put(getOrderParRestoSuccess({ order_request: ret.data }));

   else yield put(getOrderParRestoError({ message: ret.data ? ret.data.msg : "Error" }))


  } catch (error) {
     yield put(getOrderParRestoError({ error, message: (error) ? error.message : '' }));
  }
 }
 const getOrderParRestoRequest = async (payload) => {  
 
   return await axios.get(api.orders+'/by-resto/')
   .then(resp => resp)
 .catch(error => { return (error && error.response) ? error.response : error })
 };


 //  *** GET ONE ORDER_USER
 export function* watchGetOrderParUser() { yield takeEvery(GET_ORDER_BY_USER, getOrderParUser) }
 function* getOrderParUser({ payload }) {
   try {
     const ret = yield call(getOrderParUserRequest, payload);

     if (ret && ret.status === 200) yield put(getOrderParUserSuccess({ order_request: ret.data }));

   else yield put(getOrderParUserError({ message: ret.data ? ret.data.msg : "Error" }))


  } catch (error) {
     yield put(getOrderParUserError({ error, message: (error) ? error.message : '' }));
  }
 }
 const getOrderParUserRequest = async (payload) => {  
 
   return await axios.get(api.orders+'/user/'+payload._id)
   .then(resp => resp)
 .catch(error => { return (error && error.response) ? error.response : error })
 };


  //  *** GET ONE ORDER_DELIVERYMAN
  export function* watchGetOrderParDeliveryman() { yield takeEvery(GET_ORDER_BY_USER, getOrderParDeliveryman) }
  function* getOrderParDeliveryman({ payload }) {
    try {
      const ret = yield call(getOrderParDeliverymanRequest, payload);
 
      if (ret && ret.status === 200) yield put(getOrderParDeliverymanSuccess({ order_request: ret.data }));
 
    else yield put(getOrderParDeliverymanError({ message: ret.data ? ret.data.msg : "Error" }))
 
 
   } catch (error) {
      yield put(getOrderParDeliverymanError({ error, message: (error) ? error.message : '' }));
   }
  }
  const getOrderParDeliverymanRequest = async (payload) => {  
  
    return await axios.get(api.orders+'/deliveryman/'+payload._id)
    .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
  };





// *** ADD ORDER_REQS
export function* watchAddOrderReqs() { yield takeEvery(ADD_ORDER_REQ, addOrderReq) }
function* addOrderReq({ payload }) {
  try {
    const ret = yield call(addOrderReqsRequest, payload);

    if (ret && ret.status == 201) yield put(addOrderReqSuccess({ order_request: ret.data.order_request }));
    else yield put(addOrderReqError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addOrderReqError({ error, message: (error) ? error.message : '' }));
  }
}
const addOrderReqsRequest = async (payload) => {  
  return await axios.post(api.order_requests+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT ORDER_REQS
export function* watchEditOrderReq() { yield takeEvery(EDIT_ORDER_REQ, editOrderReq) }
function* editOrderReq({ payload }) {
  try {
    const ret = yield call(editOrderReqsRequest, payload);

    if (ret && ret.status === 200) yield put(editOrderReqSuccess({ order_request: ret.data.order_request }));
    else yield put(editOrderReqError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editOrderReqError({ error, message: (error) ? error.message : '' }));
  }
}
const editOrderReqsRequest = async (payload) => {  
  return await axios.patch(api.orders+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE ORDER_REQS
export function* watchDeleteOrderReq() { yield takeEvery(DELETE_ORDER_REQ, deleteOrderReq) }
function* deleteOrderReq({ payload }) {
  try {
    const ret = yield call(deleteOrderReqsRequest, payload);

    if (ret && ret.status === 200) yield put(deleteOrderReqSuccess({ }));
    else yield put(deleteOrderReqError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteOrderReqError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteOrderReqsRequest = async (payload) => {  
  return await axios.delete(api.orders+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetOrderReqs),
    fork(watchGetOrderParResto),
    fork(watchGetOneOrderReqs),
   fork(watchGetOrderParUser),
   fork(watchGetOrderParDeliveryman),
    // fork(watchAddOrderReqs),
    fork(watchEditOrderReq),
    fork(watchDeleteOrderReq),
  ]);
}
import React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';

const ConfirmMdl = ({
  is_open, toggle, accept, size
}) => {

  const modalClass = classNames({
    'modal-dialog--colored': false,
    'modal-dialog--header': true,
  });

  return (<Modal
    isOpen={is_open} size={size || "sm"}
    toggle={toggle}
    className={`modal-dialog--danger ${modalClass}`}
  >
    <div className="modal__header">
      <h4 className="text-modal  modal__title" style={{textAlign: 'start'}}>Warning</h4>
    </div>
    
    
    <div className="modal__body" style={{ padding: '0 10px', textAlign: 'left' }}>
      <h3>Are you sure to permanently delete this? </h3>

      <div style={{textAlign: "right", width: '100%', height: 40, marginTop: 50}} >
        <ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
          <Button color="danger" onClick={accept}>OK</Button>
          <Button type="button" onClick={toggle}>Cancel</Button>
        </ButtonToolbar>
      </div>
    </div>
  </Modal>);
};

export default connect(state => ({
  rtl: state.rtl,
}))(ConfirmMdl);

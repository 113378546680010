// ***** GET UserConfig
export const GET_UserConfig            = 'GET_UserConfig';
export const GET_UserConfig_SUCCESS    = 'GET_UserConfig_SUCCESS';
export const GET_UserConfig_ERROR      = 'GET_UserConfig_ERROR';

export function getUserConfig(payload) {

  return {type: GET_UserConfig, payload};
}
export function getUserConfigSuccess(payload) {
  return { type: GET_UserConfig_SUCCESS, payload };
}
export function getUserConfigError(error) {
  return { type: GET_UserConfig_ERROR, error };
}






// ***** ADD UserConfig
export const ADD_UserConfig            = 'ADD_UserConfig';
export const ADD_UserConfig_SUCCESS    = 'ADD_UserConfig_SUCCESS';
export const ADD_UserConfig_ERROR      = 'ADD_UserConfig_ERROR';

export function addUserConfig(payload) {
  return {type: ADD_UserConfig, payload};
}
export function addUserConfigSuccess(payload) {
  return { type: ADD_UserConfig_SUCCESS, payload };
}
export function addUserConfigError(error) {
  return { type: ADD_UserConfig_ERROR, error };
}

// ***** EDIT UserConfig
export const EDIT_UserConfig            = 'EDIT_UserConfig';
export const EDIT_UserConfig_SUCCESS    = 'EDIT_UserConfig_SUCCESS';
export const EDIT_UserConfig_ERROR      = 'EDIT_UserConfig_ERROR';

export function editUserConfig(payload) {
  return {type: EDIT_UserConfig, payload};
}
export function editUserConfigSuccess(payload) {
  return { type: EDIT_UserConfig_SUCCESS, payload };
}
export function editUserConfigError(error) {
  return { type: EDIT_UserConfig_ERROR, error };
}



// ***** DELET UserConfig
export const DELETE_UserConfig            = 'DELETE_UserConfig';
export const DELETE_UserConfig_SUCCESS    = 'DELETE_UserConfig_SUCCESS';
export const DELETE_UserConfig_ERROR      = 'DELETE_UserConfig_ERROR';

export function deleteUserConfig(payload) {
  return {type: DELETE_UserConfig, payload};
}
export function deleteUserConfigSuccess(payload) {
  return { type: DELETE_UserConfig_SUCCESS, payload };
}
export function deleteUserConfigError(error) {
  return { type: DELETE_UserConfig_ERROR, error };
}

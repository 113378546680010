
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_DRESS_CODES, getDressCodesSuccess, getDressCodesError, 
  ADD_DRESS_CODE, addDressCodeError, addDressCodeSuccess, 
  // GET_ONE_DRESS_CODE, getOneDressCodeSuccess, getOneDressCodeError, 
  EDIT_DRESS_CODE, editDressCodeError, editDressCodeSuccess,
  DELETE_DRESS_CODE, deleteDressCodeError, deleteDressCodeSuccess
} from '../actions/dressCodeActions';
import { api } from '../../const';



// *** GET DRESS_CODES
export function* watchGetDressCodes() { yield takeEvery(GET_DRESS_CODES, getDressCodes) }
function* getDressCodes({ payload }) {
  try {
    const ret = yield call(getDressCodesRequest, payload);
    if (ret && ret.status === 200) yield put(getDressCodesSuccess({ dress_codes: ret.data?.dressCodes || [], count: ret.data?.count || 0}));
    else yield put(getDressCodesError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getDressCodesError({ error, message: (error) ? error.message : '' }));
  }
}
const getDressCodesRequest = async (payload) => {  
  return await axios.get(api.dress_codes+'/get-all')
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** GET ONE DRESS_CODE
// export function* watchGetOneDressCode() { yield takeEvery(GET_ONE_DRESS_CODE, getOneDressCode) }
// function* getOneDressCode({ payload }) {
//   try {
//     const ret = yield call(getOneDressCodeRequest, payload);

//     if (ret && ret.status === 200) yield put(getOneDressCodeSuccess({ dress_code: ret.data?.dress_code || null }));
//     else yield put(getOneDressCodeError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(getOneDressCodeError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const getOneDressCodeRequest = async (payload) => {  
//   const endpoint = (payload.track) ? '/track-one/' : '/get-one/'
//   return await axios.get(api.dress_codes+endpoint+payload.dress_code_id)
//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };





// *** ADD DRESS_CODES
export function* watchAddDressCodes() { yield takeEvery(ADD_DRESS_CODE, addDressCode) }
function* addDressCode({ payload }) {
  try {

   
    const ret = yield call(addDressCodesRequest, payload);

    if (ret && ret.status === 200) yield put(addDressCodeSuccess({ dress_code: ret.data }));
    else yield put(addDressCodeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addDressCodeError({ error, message: (error) ? error.message : '' }));
  }
}
const addDressCodesRequest = async (payload) => {  

  return await axios.post(api.dress_codes, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT DRESS_CODES
export function* watchEditDressCode() { yield takeEvery(EDIT_DRESS_CODE, editDressCode) }
function* editDressCode({ payload }) {
  try {
    const ret = yield call(editDressCodesRequest, payload);

    if (ret && ret.status === 200) yield put(editDressCodeSuccess({ dress_code: ret.data }));
    else yield put(editDressCodeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editDressCodeError({ error, message: (error) ? error.message : '' }));
  }
}
const editDressCodesRequest = async (payload) => {  
  return await axios.patch(api.dress_codes+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE DRESS_CODES
export function* watchDeleteDressCode() { yield takeEvery(DELETE_DRESS_CODE, deleteDressCode) }
function* deleteDressCode({ payload }) {
  try {
    const ret = yield call(deleteDressCodesRequest, payload);

    if (ret && ret.status === 204) yield put(deleteDressCodeSuccess({ dress_code: ret.data }));
    else yield put(deleteDressCodeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteDressCodeSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteDressCodesRequest = async (payload) => {  
  return await axios.delete(api.dress_codes+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetDressCodes),
    // fork(watchGetOneDressCode),
    fork(watchAddDressCodes),
    fork(watchDeleteDressCode),
    fork(watchEditDressCode)
  ]);
}
import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import {notification } from 'antd';
import { connect } from 'react-redux';

import {
	addKitchenType,getKitchenTypes
} from "../../../redux/actions/kitchenTypesActions";
import { api } from '../../../const';
import axios from "axios"
import { FormattedMessage } from 'react-intl';
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../helper/utils";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
class AddVTypeCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
      name: "",
      description:"",
      picture: null,
      picture_url: '',
      storageLanguage : localStorage.getItem('language')
		};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }
  checkFrm = (data) => {
 
    const { name } = data
    if (name=== "") return "Type cuisine est obligatoire";

    return ""
  }
  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Type Kitchen add successfully':'Type Cuisine ajouter avec succèes',
   
		});

   
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  addVType = () =>{
    const { name,description, picture, picture_url } = this.state
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
    if(error_frm===''){
      const frm = new FormData()
      if(picture && picture_url!=='') frm.append('picture', picture)
      frm.append('name', name)
      frm.append('description', description)
  
      axios.post(api.kitchen_types, frm).then(response=>{

        if (response.status=200){ 
          this.openNotificationWithIcon('success') 
          this.props.success()
          this.props.getKitchenTypes()
        }
        else{

          this.openNotificationWithIconErreur('error')
 
          this.props.getKitchenTypes()
        }
    })
     }}

  componentDidUpdate(prev){
    if(prev.loading_add_kitchen_type && !this.props.loading_add_kitchen_type && !this.props.error_add_kitchen_type){
      this.props.success()
    } 
  }

  changeImg = (e) => {

    try {
      const picture = e.target.files[0]
      this.setState({ picture, picture_url: URL.createObjectURL(picture) })
    } catch (error) { this.setState({ picture: null, picture_url: '' }) }
  }

  handleChange = (value) => {
    const obj = {};
    obj["description"] = value;
    this.setState(obj);
  };
  render() {
    const { name, picture_url,description } = this.state

    return (<Row>
      <Col md="2">
        <div className="add_img_wrap">
          <img alt={name} src={picture_url === '' ? ` ${process.env.PUBLIC_URL}/img/no.png` : picture_url } />

          <div className="caption">
            <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
            <input onChange={(e)=>this.changeImg(e)} id="add_avatar" type="file" accept="image/*" hidden />
          </div>
        </div>
      </Col>

      <Col md={10}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label"> <FormattedMessage id="app.setting.titre"/></span>

            <div className="form__form-group-field">
              <input value={name} placeholder="Titre" onChange={(e)=>this.changeVal('name', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description"/>
                 </span>
            <div className="form__form-group-field">
            <ReactQuill style={{ width: "100%" }}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={this.handleChange}
        
                />
            </div>

            


          </div>
          <div className="form__form-group-field">
            <div onClick={()=>this.addVType()} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-plus-circle"></span>
              </div>


              <div onClick={(this.props.cancelAdding) ? this.props.cancelAdding : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
            </div>
        </div>
      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ ktype }) => {
  const { loading_add_kitchen_type, error_add_kitchen_type  } = ktype
  return {
    loading_add_kitchen_type, error_add_kitchen_type
  }
}
export default connect(mapStateToProps, {
  addKitchenType,getKitchenTypes
})(AddVTypeCmp);
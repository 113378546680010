
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
    GET_MENUS, getMenusSuccess, getMenusError, 
    ADD_MENU, addMenuSuccess, addMenuError, 
    GET_ONE_MENU, getOneMenuSuccess, getOneMenuError, 
    EDIT_MENU, editMenuSuccess, editMenuError,
    DELETE_MENU, deleteMenuSuccess, deleteMenuError,  
} from '../actions/menuActions';

import { api } from '../../const';



// *** GET MENUS
export function* watchGetMenus() { yield takeEvery(GET_MENUS, getMenus) }
function* getMenus({ payload }) {
  try {
    const ret = yield call(getMenusRequest, payload);

    if (ret && ret.status === 200) yield put(getMenusSuccess({ menus: ret.data?.menus || [], count: ret.data?.counts || 0}));
    else yield put(getMenusError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getMenusError({ error, message: (error) ? error.message : '' }));
  }
}
const getMenusRequest = async (payload) => {  
  return await axios.get(api.menus+'/get-all/'+payload.offset+'/'+payload.limit)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** GET ONE MENU

export function* watchGetOneMenu() { yield takeEvery(GET_ONE_MENU, getOneMenu) }
function* getOneMenu({ payload }) {
  try {
    const ret = yield call(getOneMenuRequest, payload);

    if (ret && ret.status === 200) yield put(getOneMenuSuccess({ menu: ret.data?.menu || null }));
    else yield put(getOneMenuError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneMenuError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneMenuRequest = async (payload) => {  
  return await axios.get(api.menus+'/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};


// *** ADD MENU

export function* watchAddMenu() { yield takeEvery(ADD_MENU, addMenu) }
function* addMenu({ payload }) {
  try {

   
    const ret = yield call(addMenuRequest, payload);

    if (ret && ret.status === 200) yield put(addMenuSuccess({ menu: ret.data }));
    else yield put(addMenuError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addMenuError({ error, message: (error) ? error.message : '' }));
  }
}
const addMenuRequest = async (payload) => {  
  
  return await axios.post(api.menus, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};



// *** EDIT MENU
export function* watchEditMenu() { yield takeEvery(EDIT_MENU, editMenu) }
function* editMenu({ payload }) {
  try {
    const ret = yield call(editMenuRequest, payload);

    if (ret && ret.status === 200) yield put(editMenuSuccess({ menu: ret.data }));
    else yield put(editMenuError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editMenuError({ error, message: (error) ? error.message : '' }));
  }
}
const editMenuRequest = async (payload) => {  
  return await axios.patch(api.menus+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE MENU

export function* watchDeleteMenu() { yield takeEvery(DELETE_MENU, deleteMenu) }
function* deleteMenu({ payload }) {
  try {
    const ret = yield call(deleteMenuRequest, payload);

    if (ret && ret.status === 204) yield put(deleteMenuSuccess({ menu: ret.data }));
    else yield put(deleteMenuError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteMenuError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteMenuRequest = async (payload) => {  
  return await axios.delete(api.menus+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetMenus),
    fork(watchGetOneMenu),
    fork(watchAddMenu),
    fork(watchDeleteMenu),
    fork(watchEditMenu)
  ]);
}
import {
  GET_KITCHEN_TYPES, GET_KITCHEN_TYPES_SUCCESS, GET_KITCHEN_TYPES_ERROR,
  ADD_KITCHEN_TYPE, ADD_KITCHEN_TYPE_SUCCESS, ADD_KITCHEN_TYPE_ERROR,
GET_ONE_KITCHEN_TYPE, GET_ONE_KITCHEN_TYPE_SUCCESS, GET_ONE_KITCHEN_TYPE_ERROR,
  EDIT_KITCHEN_TYPE, EDIT_KITCHEN_TYPE_SUCCESS, EDIT_KITCHEN_TYPE_ERROR
} from '../actions/kitchenTypesActions';

const initialState = {
  loading_get_kitchen_types: false,
  error_get_kitchen_types: null,
  kitchen_types: [],
  // count_all_kitchen_types: 0,

  new_kitchen_type: null, loading_add_kitchen_type: false, error_add_kitchen_type: null,
};

const kitchen_typesReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_KITCHEN_TYPES: return {...state, loading_get_kitchen_types: true, error_get_kitchen_types: null };
    case GET_KITCHEN_TYPES_SUCCESS:
      return {...state, kitchen_types: action.payload.kitchen_types, loading_get_kitchen_types: false, error_get_kitchen_types: null };
    case GET_KITCHEN_TYPES_ERROR:
    
      return {...state, error_get_kitchen_types: action.error, loading_get_kitchen_types: false, kitchen_types: [] };



    case ADD_KITCHEN_TYPE: return {...state, loading_add_kitchen_type: true, error_add_kitchen_type: null };
    case ADD_KITCHEN_TYPE_SUCCESS:
      return {...state, new_kitchen_type: action.payload.kitchen_type, loading_add_kitchen_type: false, error_add_kitchen_type: null };
    case ADD_KITCHEN_TYPE_ERROR:
      return {...state, error_add_kitchen_type: action.error, loading_add_kitchen_type: false, new_kitchen_type: null };


    case GET_ONE_KITCHEN_TYPE: return {...state, loading_one_kitchen_type: true, error_one_kitchen_type: null };
    case GET_ONE_KITCHEN_TYPE_SUCCESS:
      return {...state, selected_kitchen_type: action.payload.kitchen_type, loading_add_kitchen_type: false, error_one_kitchen_type: null };
    case GET_ONE_KITCHEN_TYPE_ERROR:
      return {...state, error_one_kitchen_type: action.error, loading_one_kitchen_type: false, selected_kitchen_type: null };



    case EDIT_KITCHEN_TYPE: return {...state, loading_edit_kitchen_type: true, error_edit_kitchen_type: null };
    case EDIT_KITCHEN_TYPE_SUCCESS:
      return {...state, loading_edit_kitchen_type: false, error_edit_kitchen_type: null };
    case EDIT_KITCHEN_TYPE_ERROR:
      return {...state, error_edit_kitchen_type: action.error, loading_edit_kitchen_type: false}; 


    default: return state;
  }
};

export default kitchen_typesReducer;

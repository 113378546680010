// ***** GET DISHS
export const GET_DISHS            = 'GET_DISHS';
export const GET_DISHS_SUCCESS    = 'GET_DISHS_SUCCESS';
export const GET_DISHS_ERROR      = 'GET_DISHS_ERROR';

export function getDishs(payload) {
  return {type: GET_DISHS, payload};
}
export function getDishsSuccess(payload) {
  return { type: GET_DISHS_SUCCESS, payload };
}
export function getDishsError(error) {
  return { type: GET_DISHS_ERROR, error };
}






// ***** ADD DISH
export const ADD_DISH            = 'ADD_DISH';
export const ADD_DISH_SUCCESS    = 'ADD_DISH_SUCCESS';
export const ADD_DISH_ERROR      = 'ADD_DISH_ERROR';

export function addDish(payload) {
  return {type: ADD_DISH, payload};
}
export function addDishSuccess(payload) {
  return { type: ADD_DISH_SUCCESS, payload };
}
export function addDishError(error) {
  return { type: ADD_DISH_ERROR, error };
}






// ***** GET ONE DISH
export const GET_ONE_DISH            = 'GET_ONE_DISH';
export const GET_ONE_DISH_SUCCESS    = 'GET_ONE_DISH_SUCCESS';
export const GET_ONE_DISH_ERROR      = 'GET_ONE_DISH_ERROR';

export function getOneDish(payload) {
  return {type: GET_ONE_DISH, payload};
}
export function getOneDishSuccess(payload) {
  return { type: GET_ONE_DISH_SUCCESS, payload };
}
export function getOneDishError(error) {
  return { type: GET_ONE_DISH_ERROR, error };
}



// ***** EDIT DISH
export const EDIT_DISH            = 'EDIT_DISH';
export const EDIT_DISH_SUCCESS    = 'EDIT_DISH_SUCCESS';
export const EDIT_DISH_ERROR      = 'EDIT_DISH_ERROR';

export function editDish(payload) {
  return {type: EDIT_DISH, payload};
}
export function editDishSuccess(payload) {
  return { type: EDIT_DISH_SUCCESS, payload };
}
export function editDishError(error) {
  return { type: EDIT_DISH_ERROR, error };
}



// ***** DELETE DISH
export const DELETE_DISH            = 'DELETE_DISH';
export const DELETE_DISH_SUCCESS    = 'DELETE_DISH_SUCCESS';
export const DELETE_DISH_ERROR      = 'DELETE_DISH_ERROR';

export function deleteDish(payload) {
  return {type: DELETE_DISH, payload};
}
export function deleteDishSuccess(payload) {
  return { type: DELETE_DISH_SUCCESS, payload };
}
export function deleteDishError(error) {
  return { type: DELETE_DISH_ERROR, error };
}
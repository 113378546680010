import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';
import { Tag } from "antd"

import {
    getOneOrderReqs, editOrderReq, deleteOrderReq
} from "../../../redux/actions/orderActions";
import { getUsers } from "../../../redux/actions/authActions";



import { api } from '../../../const';
import { NavLink } from 'react-router-dom';

import { getRestaurants } from "../../../redux/actions/restaurantsActions";

import Map from "./map"

import { FormattedMessage } from 'react-intl';

class ListOrdersCmp extends PureComponent {

    constructor() {
        super();
        this.state = {


            show_delete_msg: false,

        };
    }


    changeVal = (key, value) => {
        const obj = {}; obj[key] = value;
        this.setState(obj)
    }


    componentWillMount() {
        const order_id = this.props.order_id
        if (order_id) {
            this.props.getOneOrderReqs(order_id)
        }

    }
    componentDidMount() {
        this.props.getUsers()


    }
    render() {
        const { current_page, selected_page_size } = this.state;
        const { order_requests, count_all_order_requests, restaurants } = this.props
        let restaurant = [];
        restaurants.forEach(element => {
            restaurant.push({
                value: element._id,
                label: element.name,
            })
        });


        return (
            <Card>
                <CardBody>
                    {/* <div className="card__title">
             
                    
                    {localStorage.getItem('language') === "en"? 
                    
                    <h5 className="bold-text "> Detail Order from<span className="text-danger">{order_requests.order?.reference}</span>  </h5>
: <h5 className="bold-text ">  Détail commande de:<span className="text-danger">{order_requests.order?.reference}</span>  </h5>}

                </div> */}
                    <Row>
                        <Col md={12}>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        {/* <th></th> */}
                                        <th><FormattedMessage id="app.profile.client" /></th>
                                        <th></th>
                                        <th> <FormattedMessage id="app.restaurant" /></th>
                                        <th> <FormattedMessage id="app.order.prix" /></th>
                                        <th><FormattedMessage id="app.prix.promo" /></th>
                                        <th><FormattedMessage id="app.order.type" /></th>
                                        <th><FormattedMessage id="app.order.fraislivreur" /></th>
                                        <th><FormattedMessage id="app.profile.status" /></th>
                                        {order_requests.order?.deliveryMan !== "" && order_requests.order?.deliveryMan !== null && order_requests.order?.deliveryMan !== undefined ?
                                            <th><FormattedMessage id="app.profile.livreur" /></th>
                                            : <th></th>}
                                        <th></th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        {/* <td style={{ verticalAlign: 'middle' }}>
                              
                              {
									order_requests.order?.owner?.profile?.picture? <img alt={order_requests.order?.owner?.profile?.firstName  + " " +order_requests.order?.owner?.profile?.lastName} src={`${api.root}/${order_requests.order?.owner?.profile?.picture}`} style={{ width: 40 }} />
									: <img  alt={order_requests.order?.owner?.profile?.firstName  + " " +order_requests.order?.owner?.profile?.lastName} src={`${process.env.PUBLIC_URL}/img/no-user.png`} style={{ width: 40 }} />
								}
                              
                        </td>  */}

                                        <td style={{ verticalAlign: 'middle' }}>
                                            {order_requests.order?.owner !== null ?
                                                <NavLink to={"/app/clients/edit/" + order_requests.order?.owner._id}>
                                                    <span>{order_requests.order?.owner?.profile?.firstName + " " + order_requests.order?.owner?.profile?.lastName}</span>
                                                </NavLink>
                                                : <NavLink to={"/app/clients/edit/" + order_requests.order?.guest._id}>
                                                    <span>{order_requests.order?.guest?.firstName + " " + order_requests.order?.guest?.lastName}</span>
                                                </NavLink>}
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>

                                            {
                                                order_requests.order?.restaurant?.logo ? <img alt={order_requests.order?.restaurant?.name} src={`${api.root}/${order_requests.order?.restaurant?.logo.path}`} style={{ width: 40 }} />
                                                    : <img alt={order_requests.order?.restaurant?.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
                                            }

                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>{order_requests.order?.restaurant?.name}</td>

                                        <td style={{ verticalAlign: 'middle' }}>{order_requests.order?.price}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{order_requests.order?.pricePromo}</td>

                                        {order_requests.order?.orderType?.name === 'PICKUP' ?
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="red"> <FormattedMessage id="app.récupere" /></Tag>  </td>

                                            : <td style={{ verticalAlign: 'middle' }}><Tag color="green"><FormattedMessage id="app.livraison" /></Tag> </td>

                                        }

                                        <td style={{ verticalAlign: 'middle' }}>{order_requests.order?.deliveryFee}</td>

                                        {order_requests.order?.status?.name === 'RECEIVED' &&
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="green"> <FormattedMessage id="app.réçu" /></Tag>  </td>}

                                        {order_requests.order?.status?.name === 'ACCEPTED' &&
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="red"> <FormattedMessage id="app.accepter" /></Tag></td>}


                                        {order_requests.order?.status?.name === 'PREPARING' &&
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="magenta"> <FormattedMessage id="app.preparer" /></Tag>  </td>}

                                        {order_requests.order?.status?.name === 'READY' &&
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="volcano"> <FormattedMessage id="app.prete" /></Tag>  </td>}
                                        {order_requests.order?.status?.name === 'ARRIVNG' &&
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="orange"> <FormattedMessage id="app.arrivant" /></Tag> </td>}

                                        {order_requests.order?.status?.name === 'DELIVERED' &&
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="lime"> <FormattedMessage id="app.livrer" /></Tag></td>}
                                        {order_requests.order?.status?.name === 'CANCELED' &&
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="blue"> <FormattedMessage id="app.annuler" /></Tag> </td>}



                                        {order_requests.order?.deliveryMan !== "" && order_requests.order?.deliveryMan !== null && order_requests.order?.deliveryMan !== undefined ?
                                            <td style={{ verticalAlign: 'middle' }}>{order_requests.order?.deliveryMan}</td>

                                            : null}

                                        <td>
                                            <span onClick={() => {
                                                this.setState({ req_delete_id: order_requests.order?._id, show_delete_msg: true })
                                            }} className="lnr lnr-trash" style={{ color: "#F44336", display: 'block', marginTop: 12 }}></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <br />
                        <Col md={6} style={{ marginTop: 20 }}>
                            <div className="card__title">

                                <h6 className="bold-text text-danger "><FormattedMessage id="app.menus" /> </h6>

                            </div>
                            <Table bordered hover>

                                <thead>
                                    <tr>



                                        <th></th>
                                        <th><FormattedMessage id="app.menu" /></th>
                                        <th><FormattedMessage id="app.menu.prix" /></th>
                                        <th><FormattedMessage id="app.prix.promo" /></th>
                                        <th><FormattedMessage id="app.quantité" /> </th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {order_requests.menus && order_requests.menus.map((menu, i) => (<tr key={'menu' + i}>
                                        <td style={{ verticalAlign: 'middle' }}>

                                            {
                                                menu.picture ? <img alt={menu.name} src={`${api.root}/${menu.picture.path}`} style={{ width: 40 }} />
                                                    : <img alt={menu.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
                                            }


                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>{menu.name}</td>

                                        <td style={{ verticalAlign: 'middle' }}>{menu.price?.price}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{menu.price?.pricePromo}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{menu.quantity}</td>



                                    </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </Col>
                        <Col md={6} style={{ marginTop: 20 }}>
                            <div className="card__title">

                                <h6 className="bold-text text-danger "><FormattedMessage id="app.plats" />  </h6>

                            </div>
                            <Table bordered hover>
                                <thead>
                                    <tr>

                                        <th></th>
                                        <th><FormattedMessage id="app.plats" /></th>
                                        <th><FormattedMessage id="app.menu.prix" /></th>
                                        <th><FormattedMessage id="app.prix.promo" /></th>
                                        <th><FormattedMessage id="app.quantité" /> </th>



                                    </tr>
                                </thead>
                                <tbody>

                                    {order_requests.plates && order_requests.plates.map((plate, i) => (<tr key={'menu' + i}>
                                        <td style={{ verticalAlign: 'middle' }}>

                                            {
                                                plate.picture ? <img alt={plate.name} src={`${api.root}/${plate.picture.path}`} style={{ width: 40 }} />
                                                    : <img alt={plate.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
                                            }

                                        </td>

                                        <td style={{ verticalAlign: 'middle' }}>{plate.name}</td>


                                        <td style={{ verticalAlign: 'middle' }}>{plate.price?.price}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{plate.price?.pricePromo}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{plate.quantity}</td>



                                    </tr>
                                    ))}


                                </tbody>
                            </Table>
                        </Col>
                        <Col md={12} style={{ marginTop: 20 }} >

                            <Map latitude={order_requests?.order?.coordinates?.latitude} longitude={order_requests?.order?.coordinates?.longitude} />
                        </Col>


                    </Row>





                </CardBody>

            </Card>


        );

    }
}

const mapStateToProps = ({ orderReq, restaurant, auth }) => {
    const { order_requests, count_all_order_requests,
        loading_one_order_request, error_get_one_order_requests,
        loading_delete_order_request, error_delete_order_request
    } = orderReq
    const { user, users } = auth
    const { restaurants } = restaurant
    return {
        order_requests, count_all_order_requests, user, users,
        loading_one_order_request, error_get_one_order_requests, restaurants,

        loading_delete_order_request, error_delete_order_request
    }
}
export default connect(mapStateToProps, {
    getOneOrderReqs, editOrderReq, deleteOrderReq, getRestaurants, getUsers,

})(ListOrdersCmp);


import {
  GET_MARQUE_BLANCHE, GET_MARQUE_BLANCHE_SUCCESS, GET_MARQUE_BLANCHE_ERROR,
  GET_MARQUE_BLANCHE_BY_MANAGER,GET_MARQUE_BLANCHE_SUCCESS_BY_MANAGER,GET_MARQUE_BLANCHE_ERROR_BY_MANAGER,
  ADD_MARQUE_BLANCHE, ADD_MARQUE_BLANCHE_SUCCESS, ADD_MARQUE_BLANCHE_ERROR,
  GET_ONE_MARQUE_BLANCHE, GET_ONE_MARQUE_BLANCHE_SUCCESS, GET_ONE_MARQUE_BLANCHE_ERROR,
EDIT_MARQUE_BLANCHE, EDIT_MARQUE_BLANCHE_SUCCESS, EDIT_MARQUE_BLANCHE_ERROR
} from '../actions/marqueblancheActions';
  const initialState = {
  loading_get_marque_blanche: false,
  error_get_marque_blanche: null,
  marque_blanche_by_manager: null,
  loading_get_marque_blanche_by_manager: false,
  error_get_marque_blanche_by_manager: null,

  marque_blanche: [],
   count_all_marque_blanche: 0,

  new_Marque_Blanche: null,selected_marqueblanch:null, loading_add_Marque_Blanche: false, error_add_Marque_Blanche: null,
};

const marque_blancheReducer = (state = initialState, action) => {
 
  switch (action.type) {

    case GET_MARQUE_BLANCHE: return {...state, loading_get_marque_blanche: true, error_get_marque_blanche: null };
    case GET_MARQUE_BLANCHE_SUCCESS:
  
      return {...state, marque_blanche: [ ...action.payload.marque_blanche], loading_get_marque_blanche: false, error_get_marque_blanche: null ,count_all_marque_blanche:action.payload.count};
    case GET_MARQUE_BLANCHE_ERROR:

      return {...state, error_get_marque_blanche: action.error, loading_get_marque_blanche: false, marque_blanche: [] };



      case GET_MARQUE_BLANCHE_BY_MANAGER: return {...state, loading_get_marque_blanche_by_manager: true, error_get_marque_blanche_by_manager: null };
    case GET_MARQUE_BLANCHE_SUCCESS_BY_MANAGER:  return {...state, marque_blanche_by_manager:[action.payload.marque_blanche], loading_get_marque_blanche_by_manager: false, error_get_marque_blanche_by_manager: null };
    case GET_MARQUE_BLANCHE_ERROR_BY_MANAGER:
      return {...state, error_get_marque_blanche: action.error, loading_get_marque_blanche_by_manager: false, marque_blanche_by_manager: [] };




    case ADD_MARQUE_BLANCHE: return {...state, loading_add_Marque_Blanche: true, error_add_Marque_Blanche: null }
   
    case ADD_MARQUE_BLANCHE_SUCCESS:
      return {...state, new_Marque_Blanche: action.payload.Marque_Blanche, loading_add_Marque_Blanche: false, error_add_Marque_Blanche: null };
    case ADD_MARQUE_BLANCHE_ERROR:
      return {...state, error_add_Marque_Blanche: action.error, loading_add_Marque_Blanche: false, new_Marque_Blanche: null };

   

      case EDIT_MARQUE_BLANCHE: return {...state, loading_edit_Marque_Blanche: true, error_edit_Marque_Blanche: null };
      case EDIT_MARQUE_BLANCHE_SUCCESS:
        return {...state, loading_edit_Marque_Blanche: false, error_edit_Marque_Blanche: null };
      case EDIT_MARQUE_BLANCHE_ERROR:
        return {...state, error_edit_Marque_Blanche: action.error, loading_edit_Marque_Blanche: false}; 
        

    case GET_ONE_MARQUE_BLANCHE: return {...state, loading_one_Marque_Blanche: true, error_one_Marque_Blanche: null };
    case GET_ONE_MARQUE_BLANCHE_SUCCESS:
      return {...state, selected_marqueblanch: action.payload.Marque_Blanche, loading_add_Marque_Blanche: false, error_one_Marque_Blanche: null };
    case GET_ONE_MARQUE_BLANCHE_ERROR:
      return {...state, error_one_Marque_Blanche: action.error, loading_one_Marque_Blanche: false, selected_marqueblanch: null };

    default: return state;
  }
};

export default marque_blancheReducer;

// ***** GET DIET
export const GET_DIET            = 'GET_DIET';
export const GET_DIET_SUCCESS    = 'GET_DIET_SUCCESS';
export const GET_DIET_ERROR      = 'GET_DIET_ERROR';

export function getDiet(payload) {

  return {type: GET_DIET, payload};
}
export function getDietSuccess(payload) {
  return { type: GET_DIET_SUCCESS, payload };
}
export function getDietError(error) {
  return { type: GET_DIET_ERROR, error };
}






// ***** ADD DIET
export const ADD_DIET            = 'ADD_DIET';
export const ADD_DIET_SUCCESS    = 'ADD_DIET_SUCCESS';
export const ADD_DIET_ERROR      = 'ADD_DIET_ERROR';

export function addDiet(payload) {
  return {type: ADD_DIET, payload};
}
export function addDietSuccess(payload) {
  return { type: ADD_DIET_SUCCESS, payload };
}
export function addDietError(error) {
  return { type: ADD_DIET_ERROR, error };
}

// ***** EDIT DIET
export const EDIT_DIET            = 'EDIT_DIET';
export const EDIT_DIET_SUCCESS    = 'EDIT_DIET_SUCCESS';
export const EDIT_DIET_ERROR      = 'EDIT_DIET_ERROR';

export function editDiet(payload) {
  return {type: EDIT_DIET, payload};
}
export function editDietSuccess(payload) {
  return { type: EDIT_DIET_SUCCESS, payload };
}
export function editDietError(error) {
  return { type: EDIT_DIET_ERROR, error };
}



// ***** DELET DIET
export const DELETE_DIET            = 'DELETE_DIET';
export const DELETE_DIET_SUCCESS    = 'DELETE_DIET_SUCCESS';
export const DELETE_DIET_ERROR      = 'DELETE_DIET_ERROR';

export function deleteDiet(payload) {
  return {type: DELETE_DIET, payload};
}
export function deleteDietSuccess(payload) {
  return { type: DELETE_DIET_SUCCESS, payload };
}
export function deleteDietError(error) {
  return { type: DELETE_DIET_ERROR, error };
}

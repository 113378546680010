// ***** GET MARQUE_BLANCHE
export const GET_MARQUE_BLANCHE            = 'GET_MARQUE_BLANCHE';
export const GET_MARQUE_BLANCHE_SUCCESS    = 'GET_MARQUE_BLANCHE_SUCCESS';
export const GET_MARQUE_BLANCHE_ERROR      = 'GET_MARQUE_BLANCHE_ERROR';

export function getMarque_Blanche(payload) {

  return {type: GET_MARQUE_BLANCHE, payload};
}
export function getMarque_BlancheSuccess(payload) {
  return { type: GET_MARQUE_BLANCHE_SUCCESS, payload };
}
export function getMarque_BlancheError(error) {
  return { type: GET_MARQUE_BLANCHE_ERROR, error };
}


// ***** GET MARQUE_BLANCHE_BY_MANAGER
export const GET_MARQUE_BLANCHE_BY_MANAGER            = 'GET_MARQUE_BLANCHE_BY_MANAGER';
export const GET_MARQUE_BLANCHE_SUCCESS_BY_MANAGER   = 'GET_MARQUE_BLANCHE_SUCCESS_BY_MANAGER';
export const GET_MARQUE_BLANCHE_ERROR_BY_MANAGER      = 'GET_MARQUE_BLANCHE_ERROR_BY_MANAGER';

export function getMarque_Blanche_By_Manager(payload) {

  return {type: GET_MARQUE_BLANCHE_BY_MANAGER, payload};
}
export function getMarque_Blanche_By_ManagerSuccess(payload) {
  return { type: GET_MARQUE_BLANCHE_SUCCESS_BY_MANAGER, payload };
}
export function getMarque_Blanche_By_ManagerError(error) {
  return { type: GET_MARQUE_BLANCHE_ERROR_BY_MANAGER, error };
}






// ***** ADD MARQUE_BLANCHE
export const ADD_MARQUE_BLANCHE            = 'ADD_MARQUE_BLANCHE';
export const ADD_MARQUE_BLANCHE_SUCCESS    = 'ADD_MARQUE_BLANCHE_SUCCESS';
export const ADD_MARQUE_BLANCHE_ERROR      = 'ADD_MARQUE_BLANCHE_ERROR';

export function addMarque_Blanche(payload) {
  return {type: ADD_MARQUE_BLANCHE, payload};
}
export function addMarque_BlancheSuccess(payload) {
  return { type: ADD_MARQUE_BLANCHE_SUCCESS, payload };
}
export function addMarque_BlancheError(error) {
  return { type: ADD_MARQUE_BLANCHE_ERROR, error };
}

// ***** EDIT MARQUE_BLANCHE
export const EDIT_MARQUE_BLANCHE            = 'EDIT_MARQUE_BLANCHE';
export const EDIT_MARQUE_BLANCHE_SUCCESS    = 'EDIT_MARQUE_BLANCHE_SUCCESS';
export const EDIT_MARQUE_BLANCHE_ERROR      = 'EDIT_MARQUE_BLANCHE_ERROR';

export function editMarque_Blanche(payload) {
  return {type: EDIT_MARQUE_BLANCHE, payload};
}
export function editMarque_BlancheSuccess(payload) {
  return { type: EDIT_MARQUE_BLANCHE_SUCCESS, payload };
}
export function editMarque_BlancheError(error) {
  return { type: EDIT_MARQUE_BLANCHE_ERROR, error };
}

// ***** GET MARQUE_BLANCHE
export const GET_ONE_MARQUE_BLANCHE            = 'GET_ONE_MARQUE_BLANCHE';
export const GET_ONE_MARQUE_BLANCHE_SUCCESS    = 'GET_ONE_MARQUE_BLANCHE_SUCCESS';
export const GET_ONE_MARQUE_BLANCHE_ERROR      = 'GET_ONE_MARQUE_BLANCHE_ERROR';

export function getOneMarque_Blanche(payload) {
  return {type: GET_ONE_MARQUE_BLANCHE, payload};
}
export function getOneMarque_BlancheSuccess(payload) {
  return { type:GET_ONE_MARQUE_BLANCHE_SUCCESS, payload };
}
export function getOneMarque_BlancheError(error) {
  return { type: GET_ONE_MARQUE_BLANCHE_ERROR, error };
}


// ***** DELET MARQUE_BLANCHE
export const DELETE_MARQUE_BLANCHE            = 'DELETE_MARQUE_BLANCHE';
export const DELETE_MARQUE_BLANCHE_SUCCESS    = 'DELETE_MARQUE_BLANCHE_SUCCESS';
export const DELETE_MARQUE_BLANCHE_ERROR      = 'DELETE_MARQUE_BLANCHE_ERROR';

export function deleteMarque_Blanche(payload) {
  return {type: DELETE_MARQUE_BLANCHE, payload};
}
export function deleteMarque_BlancheSuccess(payload) {
  return { type: DELETE_MARQUE_BLANCHE_SUCCESS, payload };
}
export function deleteMarque_BlancheError(error) {
  return { type: DELETE_MARQUE_BLANCHE_ERROR, error };
}

import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListeCarte from './listeCarts'
import Detail from './Detail'


const OrdersPage = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

        <Route path={`${match.url}/list`}
          render={props => <ListeCarte {...props} />}
        />

        <Route path={`${match.url}/detail/:Carte_id`}
          render={props => <Detail {...props} />}
        />

        <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
        <Redirect to="/error" />
      </Switch>
    
  </Suspense>
);
export default OrdersPage;

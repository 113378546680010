import React, { PureComponent } from 'react';
import { Col, Row} from 'reactstrap';
import {notification} from 'antd'

import { connect } from 'react-redux';

import {getFeatures,editFeature} from "../../../redux/actions/feautreActions";
import { api } from '../../../const';
import axios from "axios"
import { FormattedMessage } from 'react-intl';

class EditRegimeCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
        name: "",
        description:'',
        _id: "",
        storageLanguage : localStorage.getItem('language')

	};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }
  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Feature type modified successfully  ':'Caractéristique modifieé avec sucées',
   
		})
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  checkFrm = (data) => {

    const { name } = data
    if (name=== "") return "name require";

    return ""
  }
  editfeature = () =>{
    const { name, description,_id } = this.state
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
    if(error_frm===''){
      const data={name}

      axios.patch(api.Feature+'/'+_id,data).then(response=>{
        
      if (response.status=200){ 
        this.openNotificationWithIcon('success') 
        this.props.success()
        this.props.getFeatures()
      }
      else{

        this.openNotificationWithIconErreur('error')
        this.props.getFeatures()
      
      }
        
    })
  
  }}
  
  
  componentDidMount(){
    if(this.props.Feature) this.setState({
      name: this.props.Feature.name,
      description: this.props.Feature.description,
      _id: this.props.Feature._id,
    })

 
  }

  componentDidUpdate(prev){
    if(prev.loading_edit_Feature && !this.props.loading_edit_Feature && !this.props.error_edit_Feature){
      this.props.success()
    }
  }


  render() {
 
    
    const { name,description} = this.state
    return (<Row>

<Col md={12}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">  <FormattedMessage id="app.setting.titre"/>
                 </span>

            <div className="form__form-group-field">
              <input value={name} placeholder="Titre" onChange={(e)=>this.changeVal('name', e.target.value)} />
            </div>

            {/* <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
                 </span>
            <div className="form__form-group-field">
              <textarea value={description} placeholder="Description" onChange={(e)=>this.changeVal('description', e.target.value)} />
            </div> */}

            <div className="form__form-group-field">
            <div onClick={this.editfeature} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-plus-circle"></span>
              </div>


              <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
            </div>


          </div>
        </div>
      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ dietReq }) => {
  const {loading_edit_feature, error_edit_feature } = dietReq

  return {
    loading_edit_feature, error_edit_feature
  }
}
export default connect(mapStateToProps, {
   getFeatures,editFeature

})(EditRegimeCmp);
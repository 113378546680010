import {
  GET_CARTS, GET_CARTS_SUCCESS, GET_CARTS_ERROR,
    ADD_CART, ADD_CART_SUCCESS, ADD_CART_ERROR,
     GET_ONE_CART, GET_ONE_CART_SUCCESS, GET_ONE_CART_ERROR,
    EDIT_CART, EDIT_CART_SUCCESS, EDIT_CART_ERROR
  } from '../actions/carteActions';
  
  const initialState = {
    loading_get_carts: false,
    error_get_carts: null,
    carts_req:[],
    count_all_cart_requests: 0,
    selected_cart:null,
  
    new_CART: null, loading_add_CART: false, error_add_CART: null,
  };
  
  const Cart_Reducer = (state = initialState, action) => {

    switch (action.type) {
     
      
      case GET_CARTS: return {...state, loading_get_carts: true,  error_get_carts: null };
      case GET_CARTS_SUCCESS:
      
        return {...state, carts_req: [...action.payload.carts_req], loading_get_carts: false,  error_get_carts: null, count_all_cart_requests: action.payload.count };
      case GET_CARTS_ERROR:
       
        return {...state,  error_get_carts: action.error, loading_get_carts: false, carts_req: [] };

     
  
      
  
      case ADD_CART: return {...state, loading_add_CART: true, error_add_CART: null };
      case ADD_CART_SUCCESS:
        return {...state, new_CART: action.payload.CART, loading_add_CART: false, error_add_CART: null };
      case ADD_CART_ERROR:
        return {...state, error_add_CART: action.error, loading_add_CART: false, new_CART: null };
  
  
      // case GET_ONE_CART: return {...state, loading_one_CART: true, error_one_CART: null };
      // case GET_ONE_CART_SUCCESS:
      //   return {...state, selected_cart: action.payload.CART, loading_add_CART: false, error_one_CART: null };
      // case GET_ONE_CART_ERROR:
      //   return {...state, error_one_CART: action.error, loading_one_CART: false, selected_cart: null };
  
  
  
      case EDIT_CART: return {...state, loading_edit_CART: true, error_edit_CART: null };
      case EDIT_CART_SUCCESS:
        return {...state, loading_edit_CART: false, error_edit_CART: null };
      case EDIT_CART_ERROR:
        return {...state, error_edit_CART: action.error, loading_edit_CART: false};
  
  
      default: return state;
    }
  };
  
  export default Cart_Reducer;
  
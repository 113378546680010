import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from "redux-saga";
import { reducer as reduxFormReducer } from 'redux-form';
import { 
  sidebarReducer, themeReducer, rtlReducer, authReducer, kitchenTypeReducer,
   restaurantReducer,  reservationsReqsReducer, dressCodesReducer ,blocsReducer,
   dishReducer , menuReducer,restaurantTypesReducer,orderReducer,cartReducer,dietReducer,
   marqueblancheReducer,veganReducer,UserConfigReducer,FeaturesReducer,demandemanagerReducer,CommadePerduReducer} from './reducers/index';
import sagas from "./sagas";


const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  auth: authReducer,
  reservationsReq: reservationsReqsReducer,
  restaurant: restaurantReducer,
  dressCode: dressCodesReducer,

  bloc: blocsReducer,
  ktype: kitchenTypeReducer,
  dish : dishReducer,
  menu : menuReducer,
  restauranType:restaurantTypesReducer,
  orderReq:orderReducer,
  cartReq:cartReducer,
  dietReq:dietReducer,
  marqueblanche:marqueblancheReducer,
  VEGAN_types:veganReducer,
  UserConfig:UserConfigReducer,
  featureReq:FeaturesReducer,
  Rq_Manager:demandemanagerReducer,
  order_lost:CommadePerduReducer,
 

});

const store = createStore(reducer, applyMiddleware(...middlewares));

sagaMiddleware.run(sagas);

export default store;


import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
    GET_DISHS, getDishsSuccess, getDishsError, 
    ADD_DISH, addDishSuccess, addDishError, 
  GET_ONE_DISH, getOneDishSuccess, getOneDishError, 
  EDIT_DISH, editDishSuccess, editDishError,
  DELETE_DISH, deleteDishSuccess, deleteDishError,  
} from '../actions/dishActions';

import { api } from '../../const';



// *** GET DISHS
export function* watchGetDishs() { yield takeEvery(GET_DISHS, getDishs) }
function* getDishs({ payload }) {
  try {
    const ret = yield call(getDishsRequest, payload);

    if (ret && ret.status === 200) yield put(getDishsSuccess({ dishs: ret.data?.plates || [], count: ret.data?.count || 0}));
    else yield put(getDishsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getDishsError({ error, message: (error) ? error.message : '' }));
  }
}
const getDishsRequest = async (payload) => {  
  return await axios.get(api.dishs+'/get-all/'+payload.offset+'/'+payload.limit)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** GET ONE DISH

export function* watchGetOneDish() { yield takeEvery(GET_ONE_DISH, getOneDish) }
function* getOneDish({ payload }) {
  try {
    const ret = yield call(getOneDishRequest, payload);

    if (ret && ret.status === 200) yield put(getOneDishSuccess({ dish: ret.data?.plat || null }));
    else yield put(getOneDishError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneDishError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneDishRequest = async (payload) => {  
  //const endpoint = (payload.track) ? '/track-one/' : '/get-one/'
  return await axios.get(api.dishs+'/'+payload.dish_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD DISH
export function* watchAddDish() { yield takeEvery(ADD_DISH, addDish) }
function* addDish({ payload }) {
  try {

   
    const ret = yield call(addDishRequest, payload);

    if (ret && ret.status === 200) yield put(addDishSuccess({ dish: ret.data }));
    else yield put(addDishError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addDishError({ error, message: (error) ? error.message : '' }));
  }
}
const addDishRequest = async (payload) => {  
 
  return await axios.post(api.dishs, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};



// *** EDIT DISH
export function* watchEditDish() { yield takeEvery(EDIT_DISH, editDish) }
function* editDish({ payload }) {
  try {
    const ret = yield call(editDishRequest, payload);

    if (ret && ret.status === 200) yield put(editDishSuccess({ dish: ret.data }));
    else yield put(editDishError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editDishError({ error, message: (error) ? error.message : '' }));
  }
}
const editDishRequest = async (payload) => {  
  return await axios.patch(api.dishs+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE DISH
export function* watchDeleteDish() { yield takeEvery(DELETE_DISH, deleteDish) }
function* deleteDish({ payload }) {
  try {
    const ret = yield call(deleteDishRequest, payload);

    if (ret && ret.status === 204) yield put(deleteDishSuccess({ dish: ret.data }));
    else yield put(deleteDishError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteDishError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteDishRequest = async (payload) => {  
  return await axios.delete(api.dishs+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetDishs),
    fork(watchGetOneDish),
    fork(watchAddDish),
    fork(watchDeleteDish),
    fork(watchEditDish)
  ]);
}
import {
    GET_MENUS, GET_MENUS_SUCCESS, GET_MENUS_ERROR,
    ADD_MENU, ADD_MENU_SUCCESS, ADD_MENU_ERROR,
    GET_ONE_MENU, GET_ONE_MENU_SUCCESS, GET_ONE_MENU_ERROR,
    EDIT_MENU, EDIT_MENU_SUCCESS, EDIT_MENU_ERROR
  } from '../actions/menuActions';
  

  const initialState = {
    loading_get_menus: false, error_get_menus: null, menus: [], count_all_menus: 0,
  
  
    menu: null, loading_add_menu: false, error_add_menu: null,
  };
  
  const menuReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_MENUS: return {...state, loading_get_menus: true, error_get_menus: null };
      case GET_MENUS_SUCCESS:
        return {...state, menus: action.payload.menus,count_all_menus:action.payload.count, loading_get_menus: false, error_get_menus: null };
      case GET_MENUS_ERROR:
       
        return {...state, error_get_menus: action.error, loading_get_menus: false, menus: [] };
  
  
  
      case ADD_MENU: return {...state, loading_add_menu: true, error_add_menu: null };
      case ADD_MENU_SUCCESS:
        return {...state, menu: action.payload.menu, loading_add_menu: false, error_add_menu: null };
      case ADD_MENU_ERROR:
        return {...state, error_add_menu: action.error, loading_add_menu: false, menu: null };
  

      case GET_ONE_MENU: return {...state, loading_one_menu: true, error_one_menu: null };
      case GET_ONE_MENU_SUCCESS:
        return {...state, menu: action.payload.menu, loading_one_menu: false, error_one_menu: null };
      case GET_ONE_MENU_ERROR:
        return {...state, error_one_menu: action.error, loading_one_menu: false, menu: null };
  
  
  
      case EDIT_MENU: return {...state, loading_edit_menu: true, error_edit_menu: null };
      case EDIT_MENU_SUCCESS:
        return {...state, loading_edit_menu: false, error_edit_menu: null };
      case EDIT_MENU_ERROR:
        return {...state, error_edit_menu: action.error, loading_edit_menu: false}; 
  
  
      default: return state;
    }
  };
  
  export default menuReducer;
import React,{ PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import {getUserConfig } from "../../../redux/actions/UserConfigActions";
import { api } from '../../../const';
import axios from 'axios'
import { FormattedMessage } from 'react-intl';
class TotalTrips extends PureComponent {

	constructor() {
		super();
    this.state={
      actif:""
    }
  }
    componentDidMount() {
	
this.props.getUserConfig({})

}
    
    render(){

  const {config}=this.props


let allrestaurant=config!==undefined?config?.all:0
let activeresta=config!==undefined?config?.active:0
let encour=config!==undefined?config?.creationInProgress:0

let  restaurantacive=((activeresta*100)/allrestaurant)
let  restaurantencour=((encour*100)/allrestaurant)


      return (
        <Col md={12} xl={3} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__booking-card">
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title dashboard__booking-total-title--red">
             {allrestaurant}
              </h5>
       
            </div>
            <h5 className="dashboard__booking-total-description"> <FormattedMessage id="app.restaurant" /></h5>
            <div className="progress-wrap progress-wrap--small progress-wrap--pink- progress-wrap--rounded">
              <p className="dashboard__booking-card-progress-label progress__label">
                 {restaurantacive!=="" ?restaurantacive.toFixed(2):0}% <FormattedMessage id="app.profile.active" />
                  </p>
              <Progress value={restaurantacive} />

              <p className="dashboard__booking-card-progress-label progress__label">
                 {restaurantencour!=="" ?restaurantencour.toFixed(2):0}% <FormattedMessage id="app.creationInProgress" />
                  </p>
              <Progress  value={restaurantencour} />
            </div>
          </CardBody>
        </Card>
      </Col>
      )
    }
  }
  const mapStateToProps = ({ auth , restaurant,UserConfig }) => {
    const { loading_get_restaurants, user,users } = auth
    const {restaurants, count_all_restaurants , loading_delete_restaurant, error_delete_restaurant} = restaurant

const{config}=UserConfig

    return {
      loading_get_restaurants, user,users, restaurants, count_all_restaurants,config,
     config:UserConfig?.UserConfig.restaurants,
      loading_delete_restaurant, error_delete_restaurant
      
    }

  }
export default connect(mapStateToProps, {
  getUserConfig
})(TotalTrips);

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Card, CardBody, Button, Row, Col,ButtonGroup,ButtonToolbar
} from 'reactstrap';
import{notification,Popconfirm,Tooltip,Table,Tag, Input, Space,} from'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { api } from '../../../const';
import Select from 'react-select';

import {
    getOrderReqs, editOrderReq, deleteOrderReq
} from "../../../redux/actions/orderActions";
import {getUsers} from "../../../redux/actions/authActions";

import { NavLink } from 'react-router-dom';

import {getRestaurants } from "../../../redux/actions/restaurantsActions";
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';
const filterGlobal = {
	status: ' ',
	RestoName: '',
  IdResto:''

}

class ListOrdersCmp extends PureComponent {

    constructor() {
        super();
        this.state = {
           

            filter_all: true,
            

            current_page: 1,
            totalPage: 1,
            selected_page_size: 10,
            page_sizes: [5, 10, 20, 30, 50],

            show_delete_msg: false,
      req_delete_id: null,
      searchResto: '',
      filteredInfo: null,
      visible: false ,
      livreurs:[],
      codedDelevery:"",
      selectdOrder:'',	
     storageLanguage : localStorage.getItem('language'),
     isClearable: true

        };
    }
    

    toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }));
   
    changeVal = (key, value) => {
        const obj = {}; obj[key] = value;
        this.setState(obj)
    }

    componentDidMount(page=1) {
      const { selected_page_size} = this.state;

      const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

      filterGlobal.status = {status:-1}
  this.props.getOrderReqs({ filterGlobal, offset, limit: selected_page_size})
      this.props.getRestaurants({ offset,limit:selected_page_size})
  }

    
    componentDidUpdate(prev) {
        const { 
            loading_get_order_requests,  error_get_order_requests,
            loading_delete_order_request, error_delete_order_request
        } = this.props
     
        if (prev.loading_delete_order_request && !loading_delete_order_request && !error_delete_order_request) {
            
            this.onChangePage(1)
    }
    
    }
    getPage(page = 1) {
        const { selected_page_size} = this.state;

        const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

		this.props.getOrderReqs({ filterGlobal, offset, limit: selected_page_size})
        this.props.getRestaurants({ offset,limit:selected_page_size})
      
     }


    onChangePage = page => {
        this.setState({ current_page: page }, () => this.getPage(page));
    };
    
    deleteReq = ()=>{
        this.props.deleteOrderReq({_id: this.state.req_delete_id})
    this.setState({ show_delete_msg: false })
    }
    resetFilter = (filter, page=1 ) => {
      const { selected_page_size} = this.state;

      const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

                let obj = {}
            
            switch (filter) {
                    case"all":filterGlobal.status = {status:-1}; break;

              case "received": filterGlobal.status = {status: 0}; break;
              case "accepted": filterGlobal.status  = {status: 1}; break;
              case "prepearing": filterGlobal.status  = {status:2}; break;
              case "ready": filterGlobal.status  = {status:3}; break;
              case "arriving": filterGlobal.status  = {status:4}; break;
              case "delivered": filterGlobal.status  = {status:5}; break;
              case "canceled": filterGlobal.status  = {status:6}; break;

          
                default:
                break;
        
              }
              this.props.getOrderReqs({ filterGlobal, offset, limit: selected_page_size })
        }
        getResto=(e, page = 1 )=>{

          const { selected_page_size,restaurant} = this.state;
          const {restaurants}=this.props;
    
          const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
          let Resto=e.target.value
     
          if(Resto!=="")
       {  this.props.getRestaurants({filterName:Resto,offset, limit: selected_page_size})
         
          this.setState({IdResto:restaurants?._id})}
          else{
       
this.getPage()
          }

         
        
        }
        changeRestaurant = (value, page = 1 ) => {
  
          const { selected_page_size,IdResto} = this.state;
    
          const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    
          if (value!==null&& IdResto!==null){
          this.props.restaurants.map(opt => ({ label: opt, value: opt }));
          filterGlobal.RestoName=value.label
          filterGlobal.IdResto=value.value
        this.props.getOrderReqs({ filterGlobal, offset, limit: selected_page_size })
          }else {
            filterGlobal.RestoName=""
            filterGlobal.IdResto=""
            filterGlobal.status = {status:-1}
            this.props.getOrderReqs({ filterGlobal, offset, limit: selected_page_size})
        }
    
      }
        

      openNotificationWithIcon = type => {
        const {storageLanguage}=this.state
        notification[type]
    
        ({
          duration:3,
          message:storageLanguage === "en"? 'Success':'Succès',
          description:storageLanguage === "en"? 'Successful status change':'Changemenet du status avec succès',
		
        })
      };
   
      openNotificationWithIconSuppression = type => {
        const {storageLanguage}=this.state
        notification[type]
    
        ({
          duration:3,
          message:storageLanguage === "en"? 'Success':'Succès',
          description:storageLanguage === "en"? 'Order successful deleted':'Supperssion du commande  avec Succès',
		
          
        });
      };

      openNotificationWithIconErreur = type => {
        const {storageLanguage}=this.state
        notification[type]
    
        ({
          duration:3,
          message:storageLanguage === "en"? 'Error':'Erreur',
          description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
        });
      };
      showModal = () => {
        
      };
      changeLivreur=(value)=>{
    
       
        this.setState({codedDelevery:value.label})

           
axios.patch(api.orders+'/status/'+this.state.selectdOrder,{'code':1,"deliveryMan":value.label}).then(response=>{
    if (response.status=200){ 
   this.openNotificationWithIcon('success') 
   this.setState({
    visible: false,
  });
 
   this.getPage()
         }
 else{
this.openNotificationWithIconErreur('error')
this.getPage()	}  }) 

   
            
      }
  
      handleCancel = e => {
    
        this.setState({
          visible: false,
        });
      };

// changeStatust=(id,value)=>{
// this.setState({selectdOrder:id})
//         switch (value) {


//             case "received": value = {status: 0}; break;
//             case "accepted": value = {status: 1}; break;
//             case "prepearing": value = {status:2}; break;
//             case "ready": value = {status:3}; break;
//             case "arriving": value = {status:4}; break;
//             case "delivered": value = {status:5}; break;
//             case "canceled": value = {status:6}; break;
//             default:
//             break;
    
//         }     
            
//      if(value.value!==1){   
//         axios.patch(api.orders+'/status/'+id,{'code':value.value}).then(response=>{
//         if (response.status=200){ 
//        this.openNotificationWithIcon('success') 
     
//        this.getPage()
//              }
//      else{
//  this.openNotificationWithIconErreur('error')
//  this.getPage()	}  }) }
//  else{
//     this.setState({
//         visible: true,
//       });
//  }
//    }


   suppression=(id)=>{
     console.log('id',id)
    // axios.delete(api.orders+"/"+id).then(response=>{
    //     if (response.status=200){ 
    //       this.openNotificationWithIconSuppression('success') 
    //       this.getPage({})
    //     }
    //     else{
  
    //       this.openNotificationWithIconErreur('error')
  
    //       this.getPage({})
        
    //     }
          
    //   })
    
    }


	handleChange = (pagination, filters) => {

        this.setState({ filteredInfo: filters });
      };
      getColumnSearchProps = dataIndex => ({
       
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
        
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
           <FormattedMessage id="app.btn.chercher"/> 
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              <FormattedMessage id="app.btn.cancel"/> 
              </Button>
         
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',      
  
     
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
 
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
   
    render() {
        const {current_page, selected_page_size, filter_all, filter_status,livreurs,storageLanguage,isClearable} = this.state;
        const {user, order_requests,restaurants,count_all_order_requests} = this.props
   
        let restaurant = [];

        restaurants.forEach(element => {
            restaurant.push({
            value:element._id,
            label: element.name,
          })
          
        }); 
        let deleveryman=[];

        livreurs.forEach(element => {
            deleveryman.push({
            value:element._id,
            label: element.identifier,
          })
        }); 
        const columns = [
          {
            title: <FormattedMessage id="app.restaurant"/>,
            dataIndex: 'restaurant?.name',
            key: 'restaurant?.name',

			  sortDirections: ['descend', 'ascend'],
	
            ...this.getColumnSearchProps('restaurant?.name'),
            render: (value, record, index) => {
            return (<div>{record.restaurant?.name }
              </div>)
            },
            // sorter: (a, b) => a.restaurant?.name.localeCompare(b.restaurant?.name),
         
          },
        {
          title: <FormattedMessage id="app.profile.address"/>,
          dataIndex: 'name',
          key: 'name',
          width:'22%',
          sortDirections: ['descend', 'ascend'],
          render: (value, record, index) => {
          return (<div>
					<Tooltip placement="topLeft" title={record.address !== null ? record.address.street : ""}>
			
            {record.address !== null ?  record.street : ""}
  </Tooltip> </div>)
          }
        
        },
  
			{
			  
			  title: <FormattedMessage id="app.profile.client"/>,
			  key: 'profile?.firstName',
			  width: '20%',
        sortDirections: ['descend', 'ascend'],
			 
			  render: (value, record, index) => {
				return (<div>

				{record!==undefined && record?.owner!==null? 
     <NavLink to={"/app/users/edit/"+ record.owner?._id}>
                                    <span>{record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName}</span>
                                </NavLink>
                                :     <NavLink to={"/app/users/edit/"+ record?.guest?._id}>
                                <span>{record.guest?.firstName  + " " +record.guest?.lastName}</span>
                            </NavLink>}
				  </div>)
			  },      
         sorter: (a, b) => a.owner?.profile?.firstName.localeCompare(b.owner?.profile?.firstName),
  
			},
			{
				title: <FormattedMessage id="app.profile.contact"/> ,
		  
				key: 'email',
				
		 
				render: (value, record, index) => {
				  return (<div>
            				{record!==undefined && record?.owner!==null? 
                     <Tag color="cyan"style={{marginTop:10}}> {record?.owner?.profile?.phone}</Tag>
                   
              :<Tag color="cyan"style={{marginTop:10}}> {record?.guest?.phone}</Tag> 
              }
                   
					</div>)
				}
			   
			  },
       

			{
			  title:<FormattedMessage id="app.order.prix"/>,
			  dataIndex: 'price',
			  key:'price',
	
			  render: (value, record, index) => {
				return (<div>{record.price?.toFixed(2)}</div>)
			  },  sorter: (a, b) => a.price - b.price,
			  
		   
			},
      {
				title:  <FormattedMessage id="app.profile.status" />,
				dataIndex: 'statu',
				key: 'statu',
        
		   
				render: (value, item, index) => {

          if(item.status===undefined){
            return(<div></div>)
          }
					if(item.status?.code===0)
					return(<div>  <Tag color="green"><FormattedMessage id="app.statu.recived"/> </Tag><br/></div>)
			
						if(item.status?.code===1){
						return(<div>  <Tag color="geekblue"><FormattedMessage id="app.statu.accepter"/> </Tag><br/></div>)
					}
			
           if(item.status?.code===2) return(<div>  <Tag color="orange"><FormattedMessage id="app.preparer"/> </Tag><br/></div>)
           if(item.status?.code===3) return(<div>  <Tag color="volcano"><FormattedMessage id="app.status.prete"/> </Tag><br/></div>)
           if(item.status?.code===4) return(<div>  <Tag color="magenta"><FormattedMessage id="app.arrivant"/> </Tag><br/></div>)
           if(item.status?.code===5) return(<div>  <Tag color="cyan"><FormattedMessage id= "app.statu.livrer"/> </Tag><br/></div>)
           if(item.status?.code===6) return(<div>  <Tag color="purple"><FormattedMessage id="app.statu.annuler"/> </Tag><br/></div>)
				}
		   
			  },
			{
			  title:<FormattedMessage id="app.order.type"/>,
			  dataIndex: 'orderType?.name',
			  key: 'orderType?.name',
		
	   
			  render: (value, record, index) => {
                if (record.orderType?.name === 'PICKUP') {
                    return (<Tag color="red"> <FormattedMessage id="app.récupere"/></Tag>) 
      
                 }
                 else 
               {       return ( <Tag color="green"><FormattedMessage id="app.livraison"/></Tag>) }
      
                    }
			  
			 
			},
	
  
			{
			  title: <FormattedMessage id="app.profile.action"/>,
			  width:'15%',
		   
			 
  
			  render: (value, record, index) => {
			return (<span >
           <NavLink to={"/app/order/detail/"+record._id}>
                  <span className="lnr lnr-eye" style={{ color: "#70bbfd", fontSize: 18 }}></span></NavLink>
                  <Popconfirm title={ storageLanguage === "en"? "Do you want to delete this order ?"
                   : "Voulez-vous supprimer cette commande ?"} 
            onConfirm={()=>this.suppression(record._id)}
            onCancel={this.cancel} okText="Oui" cancelText="Non">
               <span  className="lnr lnr-trash" style={{ color: "#dc3545", fontSize: 18 ,marginLeft:25}}></span>
           </Popconfirm> 
                                    </span>)
		  }
			},
		  ];
  
        return (<Card>
            <CardBody>
                <div className="card__title">
             
					<Row  style={{marginBottom: 10 }}>
						<Col md="8">
						</Col>
            {(user && user.roles.includes('ADMIN')) && <Col md="4" style={{ textAlign: 'right' }}>
            <NavLink to={"/app/Order/add_New_Order"}><Button color="success" className="rounded" onClick={(e) =>this.showModal(e)}>
					<FormattedMessage id="app.btn.addneworder"/></Button></NavLink>
						</Col>}
						
					</Row>
                    
                    <Row>
                   
                        <Col md="8" style={{ marginBottom: 10 }}>
						<ButtonToolbar>
                  <ButtonGroup dir="ltr" className="btn-group--icons mb-1">
                    <Button value={filter_all} name="all"   onClick={(e) => this.resetFilter("all",e,filter_status)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.profile.tous"/></Button>
					<Button name="received"  onClick={(e) => this.resetFilter("received",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.réçu"/></Button>
                    <Button name="accepted"  onClick={(e) => this.resetFilter("accepted",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.accepter"/></Button>
                    <Button name="prepearing"  onClick={(e) => this.resetFilter("prepearing",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.preparer"/></Button>
                    <Button name="ready"  onClick={(e) => this.resetFilter("ready",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}> <FormattedMessage id="app.prete"/></Button>
					<Button name="arriving"  onClick={(e) => this.resetFilter("arriving",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}> <FormattedMessage id="app.arrivant"/></Button>
                    
                	<Button name="delivered"  onClick={(e) => this.resetFilter("delivered",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}> <FormattedMessage id="app.livrer"/></Button>
                	<Button name="canceled"  onClick={(e) => this.resetFilter("canceled",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.annuler"/></Button>
				
				  </ButtonGroup>
				 
                </ButtonToolbar>
				
            
                        </Col>
						<Col md="4" style={{marginBottom: 10 }}>
              <div onKeyUp={(e) => this.getResto(e)} >

        
					<Select  isClearable={isClearable}
    
           onChange={(e) => this.changeRestaurant(e)} 
           placeholder={<FormattedMessage id="app.restaurant"/>}
                    options={restaurant} styles={{background: '#e7e2e2' , width: '250px', border: 'none', borderBottom: '1px solid #ddd' }} />
				      </div>
         </Col> 
                    
       
                    </Row>
                </div>
             
   {/* <Modal
        title="Selectionner  un Livreur"
        visible={this.state.visible}
        footer={null}
        onCancel={this.handleCancel}
      >

  <Select onChange={(e) => this.changeLivreur(e)} 
                                placeholder= "Livreur" options={deleveryman} 
                                styles={{background: '#e7e2e2' , width:'250px', border: 'none', borderBottom: '1px solid #ddd' }} />
      </Modal> */}

      <div>        <Table rowKey={(record, index) => index} scroll={{ x: 1000 }} 
        dataSource={order_requests.orders} columns={columns} bordered pagination={false}
  style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} /></div>  
<Pagination currentPage={current_page}
					totalPage={getCountPages(count_all_order_requests, selected_page_size)}
          onChangePage={i => this.onChangePage(i)} />
                
            </CardBody>
           
        </Card>);
    }
}

const mapStateToProps = ({ orderReq,restaurant,auth}) => {
    const { loading_get_order_requests, order_requests, count_all_order_requests, 
        loading_one_order_request, error_one_order_request,
        loading_delete_order_request, error_delete_order_request 
    } = orderReq
	const { user,users } = auth
    const {restaurants} = restaurant
    return {
        loading_get_order_requests, order_requests, count_all_order_requests, user,users,
        loading_one_order_request, error_one_order_request,restaurants,

        loading_delete_order_request, error_delete_order_request
    }
}
export default connect(mapStateToProps, {
    getOrderReqs, editOrderReq, deleteOrderReq,getRestaurants,getUsers
})(ListOrdersCmp);

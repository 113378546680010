import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Recomandation from '../../shared/components//DemandeManager/recommandations'
import { FormattedMessage } from 'react-intl';

const ListRecommandation = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title"> <FormattedMessage id="app.recomandation" /></h3>
      </Col>

      <Col md={12}>
<Recomandation/>
      </Col>
    </Row>
  </Container>
);

export default ListRecommandation;
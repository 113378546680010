
import React, { Component } from 'react';
import {
  Card, Col
} from 'reactstrap';
import{Spin,Tabs,Table,Tag}from 'antd'

import { connect } from 'react-redux';

import axios from 'axios';
import {api} from '../../../../const'
import { FormattedMessage } from 'react-intl';
// import ProfileTimeLine from './ProfileTimeLine';
// import ProfileActivities from './ProfileActivities';
// import showResults from './Show';
// import ProfileSettings from './ProfileSettings';
const { TabPane } = Tabs;
function callback(key) {

}
 class ProfileTabs extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
     Orders:[],
     Carts:[],
      activeTab: '1',
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  componentDidMount(){
    if(!this.props.is_driver) this.setState({activeTab: "2"})
  
    axios.get(api.orders+'/user/'+this.props.id)
    .then(resp => {
      const Orders = resp.data.orders;
      this.setState({ Orders });
    })

    axios.get(api.cart+'/user/'+this.props.id)
    .then(resp => {
      const Carts = resp.data.cart;
      this.setState({ Carts });
    })
    .catch(error => { return (error && error.response) ? error.response : error })

  }

  showDetailPanier=(id)=>{
    if(id){
      window.location.href="/app/order/detail/"+id
     }else
     return(<Spin/>)
    

    }
    showDetailOdrer=(id)=>{
     if(id){
      window.location.href="/app/order/detail/"+id
     }else
     return(<Spin/>)
    
      }
    
  

      
  render() {
  

    const { activeTab,Orders,Carts } = this.state;

    let plateLength=0;
    let menuLength=0;
    

    for(var j=0;j<Carts.length;j++){

    for (var i=0;i<Carts[j].items.length;i++){

       if(Carts[j].items[i].type==="Plate") plateLength++;  
       if(Carts[j].items[i].type==="Menu") menuLength++;         
    }
    Carts[j]['plateCount']=plateLength;
    Carts[j]['menuCount']=menuLength;
  }
    const columnsOrder= [
  
      {  
    
        
        title:<FormattedMessage id="app.order.reference" />,
        key: 'name',
        width: '20%',

       
        render: (value, record, index) => {

          return (<div> 
           
            {record.reference} </div>)
        }

      },
      {  
      
  
        
        title:<FormattedMessage id="app.restaurant" />,
        key: 'name',
        width: '20%',

       
        render: (value, record, index) => {

          return (<div> 
            {record.restaurant?.name} </div>)
        }

      },
      {
       
      
          title: <FormattedMessage id="app.order.type"/>,
    
          key: 'email',
          
   
          render: (value, record, index) => {
            if (record.orderType?.name === 'PICKUP') {
              return (<Tag color="red"> <FormattedMessage id="app.récupere"/></Tag>) 

           }
           else 
         {       return ( <Tag color="green"><FormattedMessage id="app.livraison"/></Tag>) }

              }
          
          
         
        },
    
   
      {
       
        title:<FormattedMessage id="app.order.prix"/>,
        dataIndex: 'plateCount',
        key: 'plateCount',

        render: (value, record, index) => {
          return (<div>  {record.deliveryFee}
            </div>)
        }
   
      },
      {
      
        title:<FormattedMessage id="app.order.prix"/>,
        dataIndex: 'totalPrice',
        key:'totalPrice',

        render: (value, record, index) => {
          return (<div>{record.price?.toFixed(2)}</div>)
        }
        
     
      },
     
      {
          title:<FormattedMessage id="app.profile.status"/>,
          dataIndex: 'totalQty',
          key: 'totalQty',

   
          render: (value, record, index) => {

            
             if (record.status?.name=== 'RECEIVED') {return (<Tag color="green"> <FormattedMessage id="app.réçu"/></Tag>)  } 
             if (record.status?.name=== 'ACCEPTED') {return (<Tag color="red"> <FormattedMessage id="app.accepter"/></Tag>) }
             if(record.status?.name=== 'PREPARING') { return (<Tag color="magenta"> <FormattedMessage id="app.preparer"/></Tag>)}
             if(record.status?.name=== 'READY') { return (<Tag color="volcano"> <FormattedMessage id="app.prete"/></Tag>) }
             if(record.status?.name=== 'ARRIVNG') { return (<Tag color="orange"> <FormattedMessage id="app.arrivant"/></Tag>) }
             if(record.status?.name=== 'DELIVERED') { return (<Tag color="lime"> <FormattedMessage id="app.livrer"/></Tag>) }
             if(record.status?.name=== 'CANCELED') { return (<Tag color="blue"> <FormattedMessage id="app.annuler"/></Tag>) } 
          
        }
      },

      {
        title: <FormattedMessage id="app.profile.action"/>,
        width:'15%',
     
       

        render: (value, record, index) => {
          return(  <div> 
       <span onClick={()=>this.showDetailOdrer(record._id)} className="lnr lnr-eye" style={{ color: "#70bbfd", fontSize: 18 }}></span>

                   
                    </div>
                         
            )  },
              }
    ];
    const columnsCart= [
  
      {  
      
  
        
        title:<FormattedMessage id="app.restaurant" />,
        key: 'name',
        width: '20%',

       
        render: (value, record, index) => {

          return (<div> 
            {record.restaurant?.name} </div>)
        }

      },
      {
       
           
     
     
          title:<FormattedMessage id="app.profile.address" />,
    
          key: 'email',
          
   
          render: (value, record, index) => {
            return (<div>{record.restaurant?.address?.fullAddress}</div>)
          }
         
        },
    
   
      {
      
       
    
        title:<FormattedMessage id="app.restaurant.commande" />,
        dataIndex: 'plateCount',
        key: 'plateCount',

        render: (value, record, index) => {
          return (<div> {record.plateCount} Plats<br/>{record.menuCount} Menus
            </div>)
        }
   
      },
      {
      
        title:<FormattedMessage id="app.quantité" />,
        dataIndex: 'totalPrice',
        key:'totalPrice',

        render: (value, record, index) => {
          return (<div>{record.restaurant?.deliveryFee}</div>)
        }
        
     
      },
     
      {
          title:    <FormattedMessage id="app.restaurant.fraislivraison" />,
          dataIndex: 'totalQty',
          key: 'totalQty',

   
          render: (value, record, index) => {
            return (<div>{record.totalQty}</div>)
         
              }
        },


     
    ];

    return (
      <Col md={12} lg={12} xl={8}>
        <Card>

       
          <div className="profile__card tabs tabs--bordered-bottom">
          <div className="card-container">
            <Tabs defaultActiveKey="1" onChange={callback} type="card">
    <TabPane tab={ <FormattedMessage id="app.order" />} key="1">
    <Table rowKey={(record, index) => index} scroll={{ x: 1000 }} 
        dataSource={Orders.map((Order,i)=>Order)} columns={columnsOrder} bordered pagination={true}
  style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} />
    </TabPane>
    <TabPane tab={ <FormattedMessage id="app.paniers" />}key="2">
    <Table rowKey={(record, index) => index} scroll={{ x: 1000 }} 
        dataSource={Carts.map((Cart,i)=>Cart)} columns={columnsCart} bordered pagination={true}
  style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} />
    </TabPane>
  
  </Tabs>
          
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}
const mapStateToProps = ({ orderReq }) => {
	const {Orderbyadmin} = orderReq
	return {
	
	}
}
export default connect(mapStateToProps, {

})(ProfileTabs);

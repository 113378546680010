import React from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ListReservationsCmp from '../../shared/components/reservations/list-reservations-cmp';
import { FormattedMessage } from 'react-intl';
const ListReservationsPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title"> <FormattedMessage id="app.titre.Reservation" /></h3>
      </Col>
      <Col md={12}>
        <ListReservationsCmp />
      </Col>
    </Row>
    <Row>
      
    </Row>
  </Container>
);

export default ListReservationsPage;

import React, { PureComponent } from 'react';
import { Col, Row, Button } from 'reactstrap';

import { connect } from 'react-redux';

import { getDressCodes, deleteDressCode } from "../../../redux/actions/dressCodeActions";
import { api } from '../../../const';
import axios from "axios"
import { notification } from 'antd';
import ConfirmMdl from '../confirm-mdl';
import { FormattedMessage } from 'react-intl';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';

import { quillFormats, quillModules } from "../../helper/utils";
class EditDressCodeCmp extends PureComponent {
  constructor() {
    super();
    this.state = {
      name: "",
      description: '',
      _id: "",
      show_delete_msg: false,
      storageLanguage: localStorage.getItem('language')
    };
  }
  openNotificationWithIcon = type => {

    const { storageLanguage } = this.state
    notification[type]
      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Edit block successfully  ' : 'Bloc modifier avec sucées',

      })
  }; enNotificationWithIconErreur = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Error' : 'Erreur',
        description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
      });
  };
  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  editDressCode = () => {
    const { name, description, _id } = this.state

    if (name !== '') {
      const data = { name, description }
      axios.patch(api.dress_codes + '/' + _id, data).then(response => {

        if (response.status = 200) {
          this.openNotificationWithIcon('success')
          this.props.success()
          this.props.getDressCodes()
        }
        else {

          this.openNotificationWithIconErreur('error')

          this.props.getDressCodes()
        }
      })
    }
  }




  componentDidMount() {
    if (this.props.dressCode) this.setState({
      name: this.props.dressCode.name,
      description: this.props.dressCode.description,
      _id: this.props.dressCode._id,
    })

  }

  componentDidUpdate(prev) {
    if (prev.loading_edit_dressCode && !this.props.loading_edit_dressCode && !this.props.error_edit_dressCode) {
      this.props.success()
    }
  }

  handleChange = (value) => {
    const obj = {}; obj['description'] = value;
    this.setState(obj)
  }
  render() {
    const { name, description, show_delete_msg } = this.state
    return (<Row>

      <Col md={10}>
        <div className="form">
          <div className="form__form-group">
            <span className="form__form-group-label"> <FormattedMessage id="app.setting.titre" /></span>

            <div className="form__form-group-field">
              <input value={name} placeholder="Titre" onChange={(e) => this.changeVal('name', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
            </span>
            <div className="form__form-group-field">
              <ReactQuill style={{ width: "100%" }} value={description}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                onChange={this.handleChange} />
              {/* <textarea value={description} placeholder="Description"  /> */}
            </div>


          </div>




        </div>
        <div className="form__form-group-field" >
          <div onClick={this.editDressCode} className="form__form-group-icon" style={{ background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer' }}>
            <span className="lnr lnr-pencil"></span>
          </div>


          <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : () => { }} className="form__form-group-icon" style={{ background: "#F44336", color: "#fff", border: "red", cursor: 'pointer' }}>
            <span className="lnr lnr-cross-circle"></span>
          </div>
        </div>
        {/* <Button color="danger" size="sm" className="rounded" onClick={() => this.setState({ 
          show_delete_msg: true })}
        >
          <span className="lnr lnr-trash"></span> Delete
        </Button> */}


        <ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
          is_open={show_delete_msg} accept={() => {
            this.props.deleteDressCode(this.props.dressCode)
            this.setState({ show_delete_msg: false })
          }}
        />

      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ dressCode }) => {
  const { loading_edit_dress_code, error_edit_dress_code } = dressCode

  return {
    loading_edit_dress_code, error_edit_dress_code
  }
}
export default connect(mapStateToProps, {
  getDressCodes,
  deleteDressCode,
})(EditDressCodeCmp);
import React, { PureComponent } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';

import { quillFormats, quillModules } from "../../helper/utils";

import {
	editBloc,deleteBloc
} from "../../../redux/actions/blocsActions";
import { api } from '../../../const';
import ConfirmMdl from '../confirm-mdl';
import { FormattedMessage } from 'react-intl';


class EditBlocCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
      name: "",
      description:"",
      picture: null,
      picture_url: '',
      _id: "",
      show_delete_msg: false
		};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }


  handleChange = (value) =>  {
    const obj = {}; obj['description'] = value;
    this.setState(obj)
  }
  editBloc = () =>{
    const { name,description, picture, picture_url, _id } = this.state

    if(name!==''){
      const frm = new FormData()
      frm.append('title', name);
      frm.append('description', description);

      if(picture && picture_url!=='') {
        frm.append('picture', picture);
      }
      
      this.props.editBloc({data: frm, _id}) 
    }
  }
  
  
  componentDidMount(){
    if(this.props.bloc) this.setState({
      name: this.props.bloc.title,
      description: this.props.bloc.description,
      picture: this.props.bloc.picture,
      _id: this.props.bloc._id,
    })
  }

  changeImg = (e) => {
    try {
      const picture = e.target.files[0]
      this.setState({ picture, picture_url: URL.createObjectURL(picture) })
    } catch (error) { this.setState({ picture: null, picture_url: '' }) }
  }

  componentDidUpdate(prev){
   
    if(prev.loading_edit_bloc && !this.props.loading_edit_bloc && !this.props.error_edit_bloc){
      this.props.success()
    }
  }

  handleChange = (value) =>  {
    const obj = {}; obj['description'] = value;
    this.setState(obj)
  }

  render() {
    const { name,description, picture, picture_url, show_delete_msg, _id } = this.state
    return (<Row>
      <Col md="2">
        <div className="add_img_wrap">
          {
            (picture && picture_url==='') ? <img alt='clicknmeal' src={`${api.root}/${picture.path}`} />
            : <img alt='clicknmeal' src={picture_url === '' ? ` ${process.env.PUBLIC_URL}/img/no.png` : picture_url } />
          }

          <div className="caption">
            <label htmlFor={"edit_avatar_"+_id}><span className="lnr lnr-picture"></span></label>
            <input onChange={(e)=>this.changeImg(e)} htmlFor={"edit_avatar_"+_id} type="file" accept="image/*" hidden />
          </div>
        </div>
      </Col>
      <Col md={9}>
        <div className="form">
          <div className="form__form-group">
            <span className="form__form-group-label"><FormattedMessage id="app.setting.titre" /></span>

            <div className="form__form-group-field">
              <input value={name} placeholder="titre" onChange={(e)=>this.changeVal('name', e.target.value)} />
            </div>
            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
                 </span>
            <div className="form__form-group-field">

              <ReactQuill style={{ width: "100%" }} value={description} 
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                onChange={this.handleChange}  />
  {/* <textarea value={description} placeholder="Description" onChange={(e)=>this.changeVal('description', e.target.value)} />  */}
           
            </div>

          </div>
        </div>

        <div style={{ marginTop:30 }} className="form__form-group-field">

          <div onClick={this.editBloc} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
            <span className="lnr lnr-pencil"></span>
          </div>


          <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
            <span className="lnr lnr-cross-circle"></span>
          </div>

        </div>



        <ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
          is_open={show_delete_msg} accept={() => {
            this.props.deleteBloc(this.props.bloc)
            this.setState({show_delete_msg: false})
          }}
        />

      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ bloc }) => {
  const { loading_edit_bloc, error_edit_bloc  } = bloc
  return {
    loading_edit_bloc, error_edit_bloc
  }
}
export default connect(mapStateToProps, {
  editBloc,
  deleteBloc,
})(EditBlocCmp);
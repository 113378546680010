import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';

import { connect } from 'react-redux';
import ReactQuill from "react-quill";
import {getVEGAN} from "../../../redux/actions/VEGANActions"
import {notification } from 'antd';
import { api } from '../../../const';
import axios from "axios"
import { FormattedMessage } from 'react-intl';
import { quillFormats, quillModules } from "../../helper/utils";

import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';

class AddvegancategoryTypeCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
      title:"",
      description:"",
      storageLanguage : localStorage.getItem('language')
		};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }
  checkFrm = (data) => {

    const { title } = data
    if (title=== "") return "Type vegancategory est require";

    return ""
  } 
   openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Diet type add successfully  ':'Type Régime ajouter avec sucées',
   
		})
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  addrestauType = () =>{
    const { title, description } = this.state
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
    if(error_frm===''){
      const data={title,description}
      axios.post(api.vegan, data).then(response=>{

        if (response.status=200){ 
          this.openNotificationWithIcon('success') 
          this.props.success()
          this.props.getVEGAN()
        }
        else{

          this.openNotificationWithIconErreur('error')
 
          this.props.getVEGAN()
        } 
    })
    }}
  

  componentDidUpdate(prev){
    if(prev.loading_add_Diet && !this.props.loading_add_Diet && !this.props.error_add_Diet){
      this.props.success()
    } 
  }


  handleChange = (value) => {
    const obj = {};
    obj["description"] = value;
    this.setState(obj);
  };
  render() {
    const { title,description } = this.state

    return (<Row>
     

      <Col md={10}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">  <FormattedMessage id="app.setting.titre"/>
                 </span>

            <div className="form__form-group-field">
              <input value={title} placeholder="Titre" onChange={(e)=>this.changeVal('title', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
                 </span>
            <div className="form__form-group-field">
            <ReactQuill style={{ width: "100%" }}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={this.handleChange}
        
                />
            </div>

            <div className="form__form-group-field">
            <div onClick={this.addrestauType} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-plus-circle"></span>
              </div>


              <div onClick={(this.props.cancelAdding) ? this.props.cancelAdding : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
            </div>


          </div>
        </div>
      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ VEGAN_types }) => {
  
  return {
   
    veganReq:VEGAN_types.VEGAN_types,
  }
}
export default connect(mapStateToProps, {
getVEGAN
})(AddvegancategoryTypeCmp);
import React, { PureComponent } from 'react';
import { Col, Row , Button } from 'reactstrap';
 
import { connect } from 'react-redux';

import {
	addMenu
} from "../../../redux/actions/menuActions";
import AddMenusMdl from './add-list-menu-mdl';

import AddDishCmp from "./add-dish";
import CollapseCmp from "../Collapse";
import { api } from '../../../const';
import Select from 'react-select'
import { FormattedMessage } from 'react-intl';
class AddMenuCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
            show_add_dish:false,
            selected_dish:null,
            show_edit_dish:false,
            name: "",
            description:"",
            type :"",
            price:0,
            picture: null,
            picture_url: '',
            plates : [],
            ListPlats:[],
            show_select_menus : false
		};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }

  addMenu = () =>{
    const { name, description , type, price, picture, picture_url , plates} = this.state

    if(name!==''){
      const frm = new FormData()
      if(plates.length > 0){
        let i =0;
        for (i=0; i< plates.length; i++){
            frm.append('plates[]', plates[i]);
          }
      }
      if(picture && picture_url!=='') frm.append('picture', picture)
      frm.append('name', name)
      frm.append('description', description)
      frm.append('type', type)
      frm.append('price', price)
      this.props.addMenu(frm)
    }
  }
  changePlat=(value)=>{
    let SelectedPlat= [];
    if (value!==null){
      value.forEach(element => {
        SelectedPlat.push(element.value)
      });
  
      this.setState({ 
        plates: SelectedPlat
      },()=>{
        
      })
    }else{
      SelectedPlat=''
    }
 
  }



  componentDidUpdate(prev){
    const {new_menu} = this.props
    if(prev.loading_add_menu && !this.props.loading_add_menu && !this.props.error_add_menu){
    
      this.props.success(new_menu)
    } 


  }

  changeImg = (e) => {
    try {
      const picture = e.target.files[0]
      this.setState({ picture, picture_url: URL.createObjectURL(picture) })
    } catch (error) { this.setState({ picture: null, picture_url: '' }) }
  }

  render() {
    const { name, picture_url,description, type , price  , show_add_dish , selected_dish ,  show_edit_dish , show_select_menus} = this.state
    const {selected_restaurant}=this.props
  let ListPlats=[]

  selected_restaurant.plates.forEach(element => {
    ListPlats.push({
      value:element._id,
      label: element.name,
    })
  });


 



    return (<Row>
      <Col md="2">
        <div className="add_img_wrap">
          <img alt={name} src={picture_url === '' ? ` ${process.env.PUBLIC_URL}/img/no.png` : picture_url } />

          <div className="caption">
            <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
            <input onChange={(e)=>this.changeImg(e)} id="add_avatar" type="file" accept="image/*" hidden />
          </div>
        </div>
      </Col>

      <Col md={8}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
              <Row>
              <Col md={7}> 
                <span className="form__form-group-label">  <FormattedMessage id="app.setting.titre"/> </span>

                <div className="form__form-group-field">
                    <input value={name} placeholder="Name" onChange={(e)=>this.changeVal('name', e.target.value)}  style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}/>
                </div>
                <span className="form__form-group-label"> <FormattedMessage id="app.type"/></span>

                <div className="form__form-group-field">
                    <input value={type} placeholder="type" onChange={(e)=>this.changeVal('type', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                </div>
                <span className="form__form-group-label"><FormattedMessage id="app.order.prix"/></span>

                <div className="form__form-group-field">
                    <input value={price}  type="number" min="0" placeholder="0" onChange={(e)=>this.changeVal('price', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                </div>

                
              </Col>
              <Col md={5}> 
              <div className="form__form-group-field">
                    <textarea rows={3} value={description} placeholder="Description" onChange={(e)=>this.changeVal('description', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}/>
                </div>
              </Col>
              </Row>
              
            

            <div className="form__form-group-field" style={{marginTop:'10px'}}>
                
                
                    <Col md="12">
                    <div className="form__form-group">
                    <span className="form__form-group-label" style={{marginTop:'8px',fontWeight:'bolder'}}><FormattedMessage id="app.plats"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select onChange={(e) => this.changePlat(e)} isMulti options={ListPlats} 
                                
                                  styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                        {/* <Button color="primary" style={{ float: 'right', marginTop:'5px' , marginRight:'10px' }} 
                        onClick={() => this.setState({ show_add_dish: true })}>
                          <FormattedMessage id="app.btn.addnewplats"/></Button>
                         
                          {show_add_dish && <Col md="12">
                            <AddDishCmp
                              cancelAdding={() => this.setState({ show_add_dish: false })}
                              success={(plat) => {
                                this.addPlat(plat)
                                this.setState({ show_add_dish: false })
                              }}
                              addPlat={this.addPlat}
                            />
                        </Col>}
                        {this.state.plates?.map((dish, i) => <CollapseCmp key={"dish_" + i} title={dish.name} 
                            handleClick={()=>this.setState({selected_dish: dish})}
                            is_open={(dish._id===selected_dish?._id)}
                            className="with-shadow"
                          >
                            <div>
                              <Row>
                                <Col md="3">
                                  {(dish && !show_edit_dish && dish.picture) && <img alt='clicknmeal' src={ dish.picture.path ? `${api.root}/${dish.picture.path}` : ` ${process.env.PUBLIC_URL}/img/no.png` } />}
                                </Col>
                                <Col md="6">
                                  <p>{dish.price}</p>
                                </Col>
                                <Col md="3" style={{textAlign: 'right'}} >
                                  <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.setState({ 
                                    show_edit_dish: true, selected_dish: dish,
                                  })}>
                                    <span className="lnr lnr-pencil mr-1" style={{color: "green"}}></span>
                                    <FormattedMessage id="app.btn.edit"/>
                                  </Button>
                                </Col>

                              </Row>

                            

                            </div>
                          </CollapseCmp>)} */}

                    </Col>
            </div>



          </div>
        </div>
      </Col>
      <Col md={2}>
        <div className="form__form-group-field" style={{marginTop:'10px'}}>
            <div onClick={this.addMenu} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-plus-circle"></span>
            </div>

            <div onClick={(this.props.cancelAdding) ? this.props.cancelAdding : ()=>{}} className="form__form-group-icon" style={{background: "#F44336",marginLeft:'10px', color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
            </div>
            <div onClick={() => this.setState((prev) => { return { show_select_menus: !prev.show_select_menus } })} 
                className="form__form-group-icon" style={{background: "#289BE1",marginLeft:'10px', color: "#fff", border: "#289BE1", cursor: 'pointer'}}>
                <span className="lnr lnr-list"></span>
            </div> 
        </div>
     
      </Col>

      <AddMenusMdl toggle={() => this.setState((prev) => { return { show_select_menus: !prev.show_select_menus } })}
            is_open={show_select_menus} 
          
            addListMenu={(menus) => {
                this.props.addListMenu(menus)
            }}
	    />
    </Row>)
  }
}


const mapStateToProps = ({ menu ,restaurant}) => {
  const { loading_add_menu, error_add_menu  } = menu
  const new_menu = menu.menu
  const {selected_restaurant} = restaurant


  return {
    loading_add_menu, error_add_menu , new_menu,
    selected_restaurant,
  }
}
export default connect(mapStateToProps, {
    addMenu
})(AddMenuCmp);
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Button} from 'reactstrap';
import{Table, Input, Space,Tag,Popconfirm,notification} from'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {  getCarts} from "../../../redux/actions/carteActions";
import {getUsers} from "../../../redux/actions/authActions";

import { NavLink } from 'react-router-dom';
import { api } from '../../../const';

import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';

class ListCartsCmp extends PureComponent {

    constructor() {
        super();
        this.state = {
            filter_ARRIVING: "",
            filter_RECEIVED:"",
            filter_ACCEPTED:"",
            filter_ready:"",
            filter_CANCELED:"",

            cart_requests: [],

            current_page: 1,
            totalPage: 1,
            selected_page_size: 10,
            page_sizes: [5, 10, 20, 30, 50],

            show_delete_msg: false,
      req_delete_id: null,
      searchResto: '',
      filteredInfo: null,
      searchText: '',
	  searchedColumn: '',
    storageLanguage : localStorage.getItem('language')
		};
	}


	handleChange = (pagination, filters) => {

        this.setState({ filteredInfo: filters });
      };
      getColumnSearchProps = dataIndex => ({
       
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
      
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
             <FormattedMessage id="app.btn.chercher"/> 
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              <FormattedMessage id="app.btn.cancel"/> 
              </Button>
         
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',      
  
     
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
 
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };

    changeVal = (key, value) => {
        const obj = {}; obj[key] = value;
        this.setState(obj)
    }

    componentWillMount() {
      this.getPage()
       
    }
    componentDidMount() {
      this.getPage()
		
		
    }
    componentDidUpdate(prev) {
        const { 
            loading_get_cart_requests,  error_get_cart_requests,
            loading_delete_cart_request, error_delete_cart_request
        } = this.props
     
        if (prev.loading_delete_cart_request && !loading_delete_cart_request && !error_delete_cart_request) {
            
            this.onChangePage(1)
    }
    
    }
    getPage(page = 1) {
		// let obj = {status:-1}
    const { selected_page_size,filter_status,filter_roles
		} = this.state;

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

    this.props.getCarts({offset, limit: selected_page_size} )
     }


    onChangePage = page => {
        this.setState({ current_page: page }, () => this.getPage(page));
    };
    
  
    openNotificationWithIconDesactiver= type => {
      const {storageLanguage}=this.state
        notification[type]
    
        ({
          duration:3,
          message:storageLanguage === "en"? 'Success':'Succès',
          description:storageLanguage === "en"? 'Cart archived successfully':'Panier archivé avec succès',
       
        });
      };
            
          
        openNotificationWithIcon = type => {
          const {storageLanguage}=this.state
            notification[type]
        
            ({
              duration:3,
              message:storageLanguage === "en"? 'Success':'Succès',
              description:storageLanguage === "en"? 'Cart  successfully restored':'Panier restauré avec succès',
		  
          
            });
          };
          openNotificationWithIconErreur = type => {
            const {storageLanguage}=this.state
            notification[type]
          
            ({
              duration:3,
              message:storageLanguage === "en"? 'Error':'Erreur',
              description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
            });
            };
      activer=(isDeleted,id)=>{

    axios.patch(api.cart+'/update/'+id,{"isDeleted":!isDeleted}).then(response=>{
			if (response.status=200){ 
			  this.openNotificationWithIcon('success') 
	
              this.getPage()
			}
			else{
	  
			  this.openNotificationWithIconErreur('error')
              this.getPage()	}  }) }
    
    desactiver=(isDeleted,id)=>{
        axios.patch(api.cart+'/update/'+id,{"isDeleted":!isDeleted}).then(response=>{
             if (response.status=200){ 
            this.openNotificationWithIconDesactiver('success') 
          
            this.getPage()
                  }
          else{
      this.openNotificationWithIconErreur('error')
      this.getPage()	}  }) }

    render() {
        const {count_all_cart_requests, carts_req , card_style, body_style} = this.props
        const {current_page, selected_page_size ,storageLanguage}= this.state
    
        let plateLength=0;
        let menuLength=0;
        

        for(var j=0;j<carts_req.length;j++){

        for (var i=0;i<carts_req[j].items.length;i++){

           if(carts_req[j].items[i].type==="Plate") plateLength=+carts_req[j].items[i].quantity;  
           if(carts_req[j].items[i].type==="Menu") menuLength=+carts_req[j].items[i].quantity;         
        }
        carts_req[j]['plateCount']=plateLength;
        carts_req[j]['menuCount']=menuLength;
   
    }
    

    const columns = [
  
        {
          
          title: <FormattedMessage id="app.profile.client"/>,
          key: 'name',
          width: '20%',

          sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'ascend',
         
          render: (value, record, index) => {

            return (<div> 
              {record?.owner?.profile?.picture? <img alt={record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName}
               src={`${api.root}/${record.owner?.profile?.picture?.path}`} style={{ width: 40,marginRight:15 }} />
: <img alt={record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName}  src={`${process.env.PUBLIC_URL}/img/no-user.png`} style={{ width: 40,marginRight:15 }} />
}
       
				{record!==undefined && record?.owner!==null? 
     <NavLink to={"/app/users/edit/"+ record.owner?._id}>
                                    <span>{record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName}</span>
                                </NavLink>
                                :  <Tag color="gold"><FormattedMessage id="app.invité"/></Tag> }
               
  
              </div>)
          }, 
          sorter: (a, b) => a.owner?.profile?.firstName.localeCompare(b.owner?.profile?.firstName),

        },
        {
            title: <FormattedMessage id="app.profile.contact"/>,
      
            key: 'email',
            
     
            render: (value, record, index) => {
              return (<div>{record.owner!==null?record.owner?.profile?.email:record.guest?.email} <br></br>
                {record.owner!==null?record.owner?.profile?.phone:record.guest?.phone }
                </div>)
            }
           
          },
      
        {
          title: <FormattedMessage id="app.restaurant"/>,
          dataIndex: 'name',
          key: 'name',

          sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'ascend',
          render: (value, record, index) => {
            return (<div>{record.restaurant?.name}
              </div>)
          },
           sorter: (a, b) => a.restaurant?.name.localeCompare(b.restaurant?.name),
        
        },
        {
          title:<FormattedMessage id="app.order.quantitécommande" />,
          dataIndex: 'plateCount',
          key: 'plateCount',

          render: (value, record, index) => {
       
            return (<div>  {record.totalQty} 
              </div>
            
            )}
     
        },
        {
          title:<FormattedMessage id="app.order.prix"/>,
          dataIndex: 'totalPrice',
          key:'totalPrice',
          sortDirections: ['descend', 'ascend'],
         
          render: (value, record, index) => {
            return (<div>{record.totalPrice?.toFixed(2)}</div>)
          },
          sorter: (a, b) => a.price - b.price,
        },
       
        {
            title: <FormattedMessage id="app.profile.status"/>,
            dataIndex: 'totalQty',
            key: 'totalQty',
  
     
            render: (value, record, index) => {
                if (record.isVerified===true) {
                    return (<Tag color="green"><FormattedMessage id="app.profile.verifier" /></Tag>) 
      
                    }else  {
                      return ( <Tag color="red"><FormattedMessage id="app.profile.nonverifier" /></Tag>) 
      
                    }
                }
          },
  

        {
          title: <FormattedMessage id="app.profile.action" />,
          width:'15%',
       
         

          render: (value, record, index) => {
            return(  <div> 
           <NavLink to={"/app/Carts/detail/"+record._id} >
            <span className="lnr lnr-eye" style={{ color: "#70bbfd", fontSize: 18 }}></span></NavLink>
          
{record.isDeleted===true? 
       
        <Popconfirm
        title={ storageLanguage === "en"?  "Do you want to restore the cart of "+record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName+' ?': "Voulez-vous réstaurer le panier de "+record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName+' ?'}
               onConfirm={()=>this.activer(record.isDeleted,record._id)} okText={<FormattedMessage id="app.btn.oui" />} 	cancelText={<FormattedMessage id="app.btn.non" />} >
                <span className="lnr lnr-trash" style={{color: "#F44336",marginLeft:25}}></span>
                  </Popconfirm>
              :<Popconfirm title={ storageLanguage === "en"? " Do you want to archive the cart of "+record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName+' ?': "Voulez-vous archiver le panier de "+record.owner?.profile?.firstName  + " " +record.owner?.profile?.lastName+' ?'}
                  onConfirm={()=>this.desactiver(record.isDeleted,record._id)} okText={<FormattedMessage id="app.btn.oui" />} 	cancelText={<FormattedMessage id="app.btn.non" />} > 
                  <span className="lnr lnr-trash" style={{ color: '#28a745',marginLeft:25}}></span>
                      </Popconfirm> }

                     
                      </div>
                           
              )  },
                }
      ];


        return (

          <Card>
    
          <CardBody >
          <div className="card__title">

          </div>
               
                
                <div>       
                   <Table rowKey={(record, index) => index} scroll={{ x: 1000 }}  
                    dataSource={carts_req} columns={columns} bordered pagination={false}
                    style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} />
               </div>  
            <Pagination currentPage={current_page}
          totalPage={getCountPages(count_all_cart_requests, selected_page_size)}
          onChangePage={i => this.onChangePage(i)}/>

                
            </CardBody>
        </Card>);
    }
}

const mapStateToProps = ({cartReq,auth}) => {
    const {loading_get_carts,carts_req, count_all_cart_requests, 
        loading_one_cart_request,error_get_carts,
        loading_delete_cart_request, error_delete_cart_request 
    } = cartReq

	const {  user,users } = auth

    return {
        loading_get_carts,carts_req, count_all_cart_requests, user,users,
        loading_one_cart_request, error_get_carts,

        loading_delete_cart_request, error_delete_cart_request
    }
}
export default connect(mapStateToProps, {
    getCarts,getUsers
})(ListCartsCmp);

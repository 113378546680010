import React , { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
   
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
   
    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');
   
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg', 1);
    });
}


class ResizeImg extends PureComponent {
  constructor() {
		super();
		this.state = {
            crop: {
                unit: '%',
                width: 50,
                height: 50,
                x: 25,
                y: 25
              }
		};
  }

  setCrop (newCrop){
    this.setState({
        crop: newCrop,
    },()=>{
        console.log("//// crop : ",newCrop);
        
    });
  }

  onImageLoaded = image => {
    this.setState({ crop: { width: image.width, height: image.height } });
    return false; // Return false when setting crop state in here.
  };

  async testCroppedImg() {
    const {image} = this.props
    const {crop } = this.state
    let fileName = 'testCroppedImg'
    const croppedImg = await getCroppedImg(image, crop, fileName);
 
  }

  accept = ()=>{
    this.testCroppedImg()
  }

render() {
    const {src , is_open, toggle, size } = this.props;
    const {crop } = this.state;

    const modalClass = classNames({
        'modal-dialog--colored': false,
        'modal-dialog--header': true,
     });
  return (<Modal
    isOpen={is_open} size={size || "md"}
    toggle={toggle}
    className={`modal-dialog--primary ${modalClass}`}
  >
    <div className="modal__header">
      <h4 className="text-modal  modal__title" style={{textAlign: 'start'}}>Sélectionner Image</h4>
    </div>
    
    
    <div className="modal__body" style={{ padding: '10px', textAlign: 'left' }}>

        <ReactCrop src={src} crop={crop} onChange={(newCrop) => this.setCrop(newCrop)} />
        <div>
            <canvas
                //ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                    width: Math.round(crop?.width ?? 0),
                    height: Math.round(crop?.height ?? 0)
                }}
            />
        </div>

        <div style={{textAlign: "right", width: '100%', height: 40, marginTop: 50}} >
            <ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
            <Button color="danger" onClick={this.accept}>OK</Button>
            <Button type="button" onClick={toggle}>Cancel</Button>
            </ButtonToolbar>
        </div>

    </div>
  </Modal>);
}};

export default connect(state => ({
  rtl: state.rtl,
}))(ResizeImg);
// ***** GET COMMANDE_PERDU
export const GET_COMMANDE_PERDU            = 'GET_COMMANDE_PERDU';
export const GET_COMMANDE_PERDU_SUCCESS    = 'GET_COMMANDE_PERDU_SUCCESS';
export const GET_COMMANDE_PERDU_ERROR      = 'GET_COMMANDE_PERDU_ERROR';

export function getCommande_Perdu(payload) {

  return {type: GET_COMMANDE_PERDU, payload};
}
export function getCommande_PerduSuccess(payload) {
  return { type: GET_COMMANDE_PERDU_SUCCESS, payload };
}
export function getCommande_PerduError(error) {
  return { type: GET_COMMANDE_PERDU_ERROR, error };
}


// ***** GET COMMANDE_PERDU
export const GET_ONE_COMMANDE_PERDU            = 'GET_ONE_COMMANDE_PERDU';
export const GET_ONE_COMMANDE_PERDU_SUCCESS    = 'GET_ONE_COMMANDE_PERDU_SUCCESS';
export const GET_ONE_COMMANDE_PERDU_ERROR      = 'GET_ONE_COMMANDE_PERDU_ERROR';

export function getOneCommande_Perdu(payload) {
  return {type: GET_ONE_COMMANDE_PERDU, payload};
}
export function getOneCommande_PerduSuccess(payload) {
  return { type:GET_ONE_COMMANDE_PERDU_SUCCESS, payload };
}
export function getOneCommande_PerduError(error) {
  return { type: GET_ONE_COMMANDE_PERDU_ERROR, error };
}


// ***** DELET COMMANDE_PERDU
export const DELETE_COMMANDE_PERDU            = 'DELETE_COMMANDE_PERDU';
export const DELETE_COMMANDE_PERDU_SUCCESS    = 'DELETE_COMMANDE_PERDU_SUCCESS';
export const DELETE_COMMANDE_PERDU_ERROR      = 'DELETE_COMMANDE_PERDU_ERROR';

export function deleteCommande_Perdu(payload) {
  return {type: DELETE_COMMANDE_PERDU, payload};
}
export function deleteCommande_PerduSuccess(payload) {
  return { type: DELETE_COMMANDE_PERDU_SUCCESS, payload };
}
export function deleteCommande_PerduError(error) {
  return { type: DELETE_COMMANDE_PERDU_ERROR, error };
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Card, CardBody, Button,Row,Col,Badge,ButtonToolbar,ButtonGroup
} from 'reactstrap';

import{Popconfirm,notification,Switch,Table,Tag, Input, Space,} from'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import {
	getreservationsReqs, editreservationsReq, deletereservationsReq
} from "../../../redux/actions/reservationsReqsActions";
import { api } from '../../../const';
import { NavLink } from 'react-router-dom';
import ConfirmMdl from '../confirm-mdl';
import moment from 'moment'
import 'moment/locale/fr'  
import axios from 'axios'
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';
import { FormattedMessage } from 'react-intl';


class ListReservationsCmp extends PureComponent {

	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			reservations: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,
			page_sizes: [5, 10, 20, 30, 50],


			show_delete_msg: false,
      req_delete_id: null,
	  searchText: '',
	  searchedColumn: '',
	  storageLanguage : localStorage.getItem('language')
		};
	}


	handleChange = (pagination, filters) => {

        this.setState({ filteredInfo: filters });
      };
      getColumnSearchProps = dataIndex => ({
       
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
      
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              > <FormattedMessage id="app.btn.chercher"/> 
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  <FormattedMessage id="app.btn.cancel"/> 
              </Button>
         
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : '',      
  
     
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
 
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };

	changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
	}

	componentDidMount() {
		this.getPage()
	}

	componentDidUpdate(prev) {
	
		const { 
			loading_one_reservationuest, error_one_reservationuest,
			loading_delete_reservationuest, error_delete_reservationuest
		} = this.props

		
		if (prev.loading_delete_reservationuest && !loading_delete_reservationuest && !error_delete_reservationuest) {
			this.onChangePage(1)
    }
	}
	getPage(page = 1) {
		const { selected_page_size,filter_status,filter_roles} = this.state

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
	
		let obj = {status:-1}
		
		if (obj!==undefined) {
			this.props.getreservationsReqs({ obj, offset, limit: selected_page_size })
			  
			  }
		
	}

	

	onChangePage = page => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};
	openNotificationWithIconSuppression = type => {
const {storageLanguage}=this.state

		notification[type]
	
		({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
		  description:storageLanguage === "en"? 'Reservation deleted successfully':'Suppression du réservation avec succés',
			
		});
	  };
	  openNotificationWithIconErreur = type => {
		const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
		  message:storageLanguage === "en"? 'Error':'Erreur',
		  description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		
		});
	  };

	suppression = (id)=>{


		axios.delete(api.reservations+'/'+id).then(response=>{
			if (response.status=200){ 
			  this.openNotificationWithIconSuppression('success') 
			  this.getPage({})
			}
			else{
	  
			  this.openNotificationWithIconErreur('error')
	  
			  this.getPage({})
			
			}
			  
		  })
		
		

    this.setState({ show_delete_msg: false })
	}


	resetFilter = (filter,page = 1)=> {
		const { selected_page_size} = this.state;

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

		let obj = {}
            
		switch (filter) {
			case"all":obj= {status:-1}; break;
			case "draft": obj = {status: 0}; break;
			case "pending": obj = {status:1}; break;
			case "accepted": obj = {status: 2}; break;	
			case "confirmed": obj = {status:3}; break;
			case "archived": obj = {status:4}; break;
			case "refused": obj = {status:5}; break;
			case "canceled": obj = {status:6}; break;
			default:
			break;
	
		  }		  if(filter!=='ALL')
		  {this.setState({ obj: filter })
  
		  this.props.getreservationsReqs({ obj,offset, limit: selected_page_size })
	  }
		  else{
		    this.props.getRestaurants({offset, limit: selected_page_size })

		}
		
		}
		cancel=(e)=> {
			console.log(e);
		
		  }

	
	render() {
		const { current_page, selected_page_size,filter_all, filter_status, show_delete_msg,storageLanguage } = this.state;
		const { count_all_reservations,reservations } = this.props
		
        const columns = [
  
			{
			  
			  title:  <FormattedMessage id="app.profile.client"/>,
			  key: 'restaurant?.name',
			  width: '20%',
			  sortDirections: ['descend', 'ascend'],
			  defaultSortOrder: 'ascend',
			 
			  render: (value, record, index) => {
				return (<div>

{record!==undefined && record?.user!==null? 
     <NavLink to={"/app/users/edit/"+ record.user?._id}>
                                    <span>{record.user?.profile?.firstName  + " " +record.user?.profile?.lastName}</span>
                                </NavLink>
                                :     <NavLink to={"/app/users/edit/"+ record?.guest?._id}>
                                <span>{record.guest?.firstName  + " " +record.guest?.lastName}</span>
                            </NavLink>}

				  </div>)
			  },
			  sorter: (a, b) => a.user?.profile?.firstName.localeCompare(b.user?.profile?.firstName),
        
			},
			{
				title: <FormattedMessage id="app.profile.contact"/>,
		  
				key: 'noteCustomer',
				
		 
				render: (value, record, index) => {
				  return (<div>{record.user===null ?
					<span>{record.guest?.phone}</span>
				
				:<span>{record.user?.profile?.phone }</span>
				}
					</div>)
				}
			   
			  },
		  
			{
			  title: <FormattedMessage id="app.restaurant"/>,
			  dataIndex: 'name',
			  key: 'name',
			  sortDirections: ['descend', 'ascend'],
			  render: (value, record, index) => {
				return (<div>{record.restaurant?.name}
				  </div>)
			  },
			  sorter: (a, b) => a.restaurant?.name - b.restaurant?.name,
			
			},
			{
			  title: <FormattedMessage id="app.reservation.nbrdeplace"/>,
			  dataIndex: 'nbrePerson',
			  key: 'nbrePerson',
		
			  sortDirections: ['descend', 'ascend'],
			  ...this.getColumnSearchProps('nbrePerson'),
			  render: (value, record, index) => {
				return (<div>{record.nbrePerson}
				  </div>)
			  },
			 
		 
			},
			{
			  title: <FormattedMessage id="app.reservation.Date"/>,
			  dataIndex: 'availableDate',
			  key:'availableDate',

			  sortDirections: ['descend', 'ascend'],
			  ...this.getColumnSearchProps('availableDate'),
			  render: (value, record, index) => {
				return (<div>{moment(record.availableDate).format('ll')}</div>)
			  },
			  sorter: (a, b) =>new Date(a.availableDate) - new Date(b.availableDate),
		   
			},
			{
				title:  <FormattedMessage id="app.profile.status" />,
				dataIndex: 'statu',
				key: 'statu',
			 
				render: (value, item, index) => {
					
					if(item.status===null){
					  return(<div></div>)
					}
							  if(item.status.code===0)
							  return(<div>  <Tag color="pink"><FormattedMessage id="app.statu.brouillon"/> </Tag><br/></div>)
					  
								  if(item.status.code===1){
								  return(<div>  <Tag color=""><FormattedMessage id= "app.enattente"/> </Tag><br/></div>)
							  }
							  if(item.status.code==2) return(<div>  <Tag color="green"><FormattedMessage id="app.statu.accepter"/> </Tag><br/></div>)
							  if(item.status.code==3) return(<div>  <Tag color="cyan"><FormattedMessage id="app.statu.confirmer"/> </Tag><br/></div>)
							  if(item.status.code==4) return(<div>  <Tag color="orange"><FormattedMessage id="app.statu.archiver"/> </Tag><br/></div>)
							  if(item.status.code==5) return(<div>  <Tag color="red"><FormattedMessage id="app.statu.refuser"/> </Tag><br/></div>)
							   if(item.status.code==6) return(<div>  <Tag color="orange"><FormattedMessage id="app.statu.annuler"/> </Tag><br/></div>)
			  
							  
						  }
					 
						},
			// {
			//   title:  <FormattedMessage id="app.reservation.note"/>,
			//   dataIndex: 'noteCustomer',
			//   key: 'noteCustomer',
			//   ...this.getColumnSearchProps('noteCustomer'),
	   
			//   render: (value, record, index) => {
			// 	return (<div > <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{record.noteCustomer}</span>
			// 	  </div>)
			//   }
			 
			// },
	
  
			{
			  title:  <FormattedMessage id="app.profile.action"/>,
			  width:'15%',
		   
			 
  
			  render: (value, item, index) => {
				
			return (
	 
				<Popconfirm title={storageLanguage === "en"? "Do you want to delete this reservation ?": "Voulez-vous supprimer cette réservation ?"}
				 onConfirm={()=>this.suppression(item._id)}
				onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />}  cancelText={<FormattedMessage id="app.btn.non" />} >
				   <span  className="lnr lnr-trash" style={{ color: "#dc3545", fontSize: 18,marginLeft:25 }}></span>
			   </Popconfirm>

		)
		  }
			},
		  ];
  
		return (<Card>
			<CardBody>
				<div className="card__title">
	
					<Row>
						<Col md="12" style={{ marginBottom: 10 }}>
						<ButtonToolbar>
                  <ButtonGroup dir="ltr" className="btn-group--icons mb-1">
                    <Button value={filter_all} name="all"   onClick={(e) => this.resetFilter("all",e,filter_status)} outline style={filter_status=='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.profile.tous"/></Button>
					<Button name="draft"  onClick={(e) => this.resetFilter("draft",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.brouillon"/></Button>
					<Button name="pending"  onClick={(e) => this.resetFilter("pending",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}> <FormattedMessage id="app.enattente"/></Button>
					<Button name="accepted"  onClick={(e) => this.resetFilter("accepted",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.accepter"/></Button>
					<Button name="confirmed"  onClick={(e) => this.resetFilter("confirmed",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}> <FormattedMessage id="app.confirmer"/></Button>
					<Button name="archived"  onClick={(e) => this.resetFilter("archived",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}><FormattedMessage id="app.archiver"/></Button>
					<Button name="refused"  onClick={(e) => this.resetFilter("refused",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}> <FormattedMessage id="app.refuser"/></Button>
					<Button name="canceled"  onClick={(e) => this.resetFilter("canceled",e)} outline style={filter_status==='All' ? { background: '#e7e2e2' } : {}}> <FormattedMessage id="app.annuler"/></Button>
				
				  </ButtonGroup>
				 
                </ButtonToolbar>
						</Col>
					</Row>
				</div>
				<div>        <Table rowKey={(record, index) => index} scroll={{ x: 1000 }} 
        dataSource={reservations} columns={columns} bordered pagination={false}
  style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} /></div>  

		
<Pagination currentPage={current_page}
					totalPage={getCountPages(count_all_reservations, selected_page_size)}
					onChangePage={i => this.onChangePage(i)}/>

				<ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
					is_open={show_delete_msg} accept={() => {
						this.deleteReq()
					}}
				/>
			</CardBody>
		</Card>);
	}
}

const mapStateToProps = ({ reservationsReq }) => {
	const { loading_get_reservations, reservations, count_all_reservations, 
		loading_one_reservationuest, error_one_reservationuest,
		loading_delete_reservationuest, error_delete_reservationuest 
	} = reservationsReq
	return {
		loading_get_reservations, reservations, count_all_reservations,
		loading_one_reservationuest, error_one_reservationuest,

		loading_delete_reservationuest, error_delete_reservationuest
	}
}
export default connect(mapStateToProps, {
	getreservationsReqs, editreservationsReq, deletereservationsReq
})(ListReservationsCmp);
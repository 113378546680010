
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_MARQUE_BLANCHE, getMarque_BlancheSuccess, getMarque_BlancheError, 
  GET_MARQUE_BLANCHE_BY_MANAGER,getMarque_Blanche_By_ManagerSuccess,getMarque_Blanche_By_ManagerError,
  // GET_ONE_DIET, getOneDietuccess, getOneDietError, 

} from '../actions/marqueblancheActions';
import { api } from '../../const';

// *** GET MARQUE BLANCHE
export function* watchGetMarqueBlanche() { yield takeEvery(GET_MARQUE_BLANCHE, getMarque_Blanche) }
function* getMarque_Blanche({ payload }) {
  try {
    const ret = yield call(getMarque_BlancheRequest, payload);
    if (ret && ret.status === 200) yield put(getMarque_BlancheSuccess({ marque_blanche: ret.data.whiteLabel|| [], count: ret.data?.counts || 0}));
    else yield put(getMarque_BlancheError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getMarque_BlancheError({ error, message: (error) ? error.message : '' }));
  }
}
const getMarque_BlancheRequest = async (payload) => {  
  return await axios.get(api.marque_blanche+'/'+payload.offset+'/'+payload.limit)

  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};
// *** GET MARQUE BLANCHE BY MANAGER 
export function* watchGetMarqueBlancheByanager() { yield takeEvery(GET_MARQUE_BLANCHE_BY_MANAGER, getMarque_Blanche_By_Manager) }
function* getMarque_Blanche_By_Manager({ payload }) {
  try {
    const ret = yield call(getMarque_Blanche_By_ManagerRequest, payload);
    if (ret && ret.status === 200) yield put(getMarque_Blanche_By_ManagerSuccess({ marque_blanche: ret.data?.requestWhiteLabel|| [], count: ret.data?.counts || 0}));
    else yield put(getMarque_Blanche_By_ManagerError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getMarque_BlancheError({ error, message: (error) ? error.message : '' }));
  }
}
const getMarque_Blanche_By_ManagerRequest = async (payload) => {  
  return await axios.get(api.marque_blanche+'/manager')

  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};







// // *** ADD DIETS
// export function* watchaddDiet() { yield takeEvery(ADD_DIET, addDiet) }
// function* addDiet({ payload }) {
//   try {
//     const ret = yield call(addDietRequest, payload);
//     if (ret && ret.status === 200) yield put(addDietSuccess({ DIET: ret.data }));
//     else yield put(addDietError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(addDietError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const addDietRequest = async (payload) => {  
//   return await axios.post(api.diet_types, payload)
//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
  
// };


// // *** EDIT DIETS
// export function* watchEditDiet() { yield takeEvery(EDIT_DIET, editDiet) }
// function* editDiet({ payload }) {
//  try {
//     const ret = yield call(editDietRequest, payload);

//     if (ret && ret.status === 200) yield put(editDietSuccess({ DIET: ret.data }));
//    else yield put(editDietError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//    yield put(editDietError({ error, message: (error) ? error.message : '' }));
//  }
// }
// const editDietRequest = async (payload) => {  

//  return await axios.patch(api.diet_types+'/'+payload._id,payload)
// .then(resp => resp)
//  .catch(error => { return (error && error.response) ? error.response : error })
// };




// // *** DELETE DIETS
// export function* watchDeleteDiet() { yield takeEvery(DELETE_DIET,deleteDiet) }
// function* deleteDiet({ payload }) {
//  try {
//    const ret = yield call(deleteDietRequest, payload);

//    if (ret && ret.status === 204) yield put(deleteDietSuccess({ DIET: ret.data }));
//     else yield put(deleteDietError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(deleteDietSuccess({ error, message: (error) ? error.message : '' }));
//   }
// }
// const deleteDietRequest = async (payload) => {  
//   return await axios.delete(api.diet_types+'/'+payload._id, payload.data)
//  .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };

export default function* rootSaga() {
    yield all([
      fork(watchGetMarqueBlanche),
      fork(watchGetMarqueBlancheByanager),
    //  fork(watchDeleteDiet),
    //  fork(watchEditDiet)
    ]);
  }
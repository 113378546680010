import React, { PureComponent } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';





class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };


  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            {/* <Link className="topbar__logo" to="/" /> */}
            <a className="topbar__logo" style={{background: 'none'}} href="/">
            <img  src={`${process.env.PUBLIC_URL}/img/Logo.svg`}  />
              {/* <h3 className="account__title">
                
                <img src="logo.png"/>
                <span className="account__logo"> Tuni
                  <span className="account__logo-accent">Table</span>
                </span>
              </h3> */}
            </a>
          </div>
          <div className="topbar__right">
          
            <TopbarProfile />
         
<div>

</div>
                   
               
        </div>
         
          </div>
          
        </div>
    
    );
  }
}

export default Topbar;


import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_DEMANDE_MANAGER, getDemande_ManagerSuccess, getDemande_ManagerError, 
  GET_DEMANDE_MANAGER_BY_MANAGER,getDemande_Manager_By_ManagerSuccess,getDemande_Manager_By_ManagerError,
  // GET_ONE_DIET, getOneDietuccess, getOneDietError, 

} from '../actions/demandemanagerAction';
import { api } from '../../const';

// *** GET DEMANA
export function* watchGetDemandeManager() { yield takeEvery(GET_DEMANDE_MANAGER, getDemande_Manager) }
function* getDemande_Manager({ payload }) {
  try {
    const ret = yield call(getDemande_ManagerRequest, payload);
    if (ret && ret.status === 200) yield put(getDemande_ManagerSuccess({ demande_manager: ret.data?.requestManager|| [], count: ret.data?.counts || 0}));
    else yield put(getDemande_ManagerError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getDemande_ManagerError({ error, message: (error) ? error.message : '' }));
  }
}
const getDemande_ManagerRequest = async (payload) => {  

    return await axios.get(api.demande_manager+'/'+payload.offset+'/'+payload.limit+'?isAccepted='+payload.isAccepted)
 
  
  
 
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};
// *** GET DEMANDE MANAGER BY MANAGER 
// export function* watchGetDemandeManagerByanager() { yield takeEvery(GET_DEMANDE_MANAGER_BY_MANAGER, getDemande_Manager_By_Manager) }
// function* getDemande_Manager_By_Manager({ payload }) {
//   try {
//     const ret = yield call(getDemande_Manager_By_ManagerRequest, payload);
//     if (ret && ret.status === 200) yield put(getDemande_Manager_By_ManagerSuccess({ demande_manager: ret.data?.requestManager|| [], count: ret.data?.count || 0}));
//     else yield put(getDemande_Manager_By_ManagerError({ message: ret.data ? ret.data.msg : "Error" }))
//   } catch (error) {
//     yield put(getDemande_ManagerError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const getDemande_Manager_By_ManagerRequest = async (payload) => {  
//   return await axios.get(api.demande_manager+'/manager')

//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };







// // *** ADD DIETS
// export function* watchaddDiet() { yield takeEvery(ADD_DIET, addDiet) }
// function* addDiet({ payload }) {
//   try {
//     const ret = yield call(addDietRequest, payload);
//     if (ret && ret.status === 200) yield put(addDietSuccess({ DIET: ret.data }));
//     else yield put(addDietError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(addDietError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const addDietRequest = async (payload) => {  
//   return await axios.post(api.diet_types, payload)
//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
  
// };


// // *** EDIT DIETS
// export function* watchEditDiet() { yield takeEvery(EDIT_DIET, editDiet) }
// function* editDiet({ payload }) {
//  try {
//     const ret = yield call(editDietRequest, payload);

//     if (ret && ret.status === 200) yield put(editDietSuccess({ DIET: ret.data }));
//    else yield put(editDietError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//    yield put(editDietError({ error, message: (error) ? error.message : '' }));
//  }
// }
// const editDietRequest = async (payload) => {  

//  return await axios.patch(api.diet_types+'/'+payload._id,payload)
// .then(resp => resp)
//  .catch(error => { return (error && error.response) ? error.response : error })
// };




// // *** DELETE DIETS
// export function* watchDeleteDiet() { yield takeEvery(DELETE_DIET,deleteDiet) }
// function* deleteDiet({ payload }) {
//  try {
//    const ret = yield call(deleteDietRequest, payload);

//    if (ret && ret.status === 204) yield put(deleteDietSuccess({ DIET: ret.data }));
//     else yield put(deleteDietError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(deleteDietSuccess({ error, message: (error) ? error.message : '' }));
//   }
// }
// const deleteDietRequest = async (payload) => {  
//   return await axios.delete(api.diet_types+'/'+payload._id, payload.data)
//  .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };

export default function* rootSaga() {
    yield all([
      fork(watchGetDemandeManager),
      // fork(watchGetDemandeManagerByanager),
    //  fork(watchDeleteDiet),
    //  fork(watchEditDiet)
    ]);
  }
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

import {
  logout,
  getProfile,
} from "../../../redux/actions/authActions";
import { FormattedMessage } from 'react-intl';
const Ava = `${process.env.PUBLIC_URL}/img/no-user.png`;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }
componentDidMount=()=>{
  this.props.getProfile()
}

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;

    const user = this.props.user
   
    if(!user) return null;


    return (
      <div className="topbar__profile">
       
<button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
         <p className="topbar__avatar-name">{user.email}</p> 
          <DownIcon className="topbar__icon" />
        </button>
        

        

        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}

        
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu"> 
            <TopbarMenuLink title={ <FormattedMessage id="app.profile" />} icon="list" path={"/app/users/edit/"+user._id} />
            <TopbarMenuLink title={ <FormattedMessage id="app.tableaubord" />}  icon="inbox" path="/app/dashboard" />
            <div className="topbar__menu-divider" />

            <a className="topbar__link" onClick={()=>this.props.logout()}>
              <span className={`topbar__link-icon lnr lnr-exit`} />
              <p className="topbar__link-title"><FormattedMessage id="app.logout" /> </p>
            </a>
          </div>

        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = ({auth})=>{
  return {
    user: auth.user
  }
}
export default connect(mapStateToProps, {
  logout,
  getProfile,
})(TopbarProfile);

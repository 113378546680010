import {
    GET_DISHS, GET_DISHS_SUCCESS, GET_DISHS_ERROR,
    ADD_DISH, ADD_DISH_SUCCESS, ADD_DISH_ERROR,
    GET_ONE_DISH, GET_ONE_DISH_SUCCESS, GET_ONE_DISH_ERROR,
    EDIT_DISH, EDIT_DISH_SUCCESS, EDIT_DISH_ERROR
  } from '../actions/dishActions';
  

  const initialState = {
    loading_get_dishs: false, error_get_dishs: null, dishs: [],count_all_dishs: 0,
    // count_all_kitchen_types: 0,
  
    dish: null, loading_add_dish: false, error_add_dish: null,
  };
  
  const dishReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_DISHS: return {...state, loading_get_dishs: true, error_get_dishs: null };
      case GET_DISHS_SUCCESS:
        return {...state, dishs: action.payload.dishs,count_all_dishs:action.payload.count, loading_get_dishs: false, error_get_dishs: null };
      case GET_DISHS_ERROR:
       
        return {...state, error_get_dishs: action.error, loading_get_dishs: false, dishs: [] };
  
  
  
      case ADD_DISH: return {...state, loading_add_dish: true, error_add_dish: null };
      case ADD_DISH_SUCCESS:
        return {...state, dish: action.payload.dish, loading_add_dish: false, error_add_dish: null };
      case ADD_DISH_ERROR:
        return {...state, error_add_dish: action.error, loading_add_dish: false, dish: null };
  

      case GET_ONE_DISH: return {...state, loading_one_dish: true, error_one_dish: null };
      case GET_ONE_DISH_SUCCESS:
        return {...state, dish: action.payload.dish, loading_one_dish: false, error_one_dish: null };
      case GET_ONE_DISH_ERROR:
        return {...state, error_one_dish: action.error, loading_one_dish: false, dish: null };
  
  
  
      case EDIT_DISH: return {...state, loading_edit_dish: true, error_edit_dish: null };
      case EDIT_DISH_SUCCESS:
        return {...state, loading_edit_dish: false, error_edit_dish: null };
      case EDIT_DISH_ERROR:
        return {...state, error_edit_dish: action.error, loading_edit_dish: false}; 
  
  
      default: return state;
    }
  };
  
  export default dishReducer;
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const CollapseCmp = ({ className, title, children, is_open, handelOpen, handleClick,icons }) => {
  const [collapse, setCollapse] = useState(false);
  const [view, setView] = useState({ styleClass: 'closed', icon: <PlusIcon /> });

  const onEntering = () => {
    setView({ styleClass: 'opening', icon: <MinusIcon /> });
  };

  const onEntered = () => {
    setView({ styleClass: 'opened', icon: <MinusIcon /> });
  };

  const onExiting = () => {
    setView({ styleClass: 'closing', icon: <PlusIcon /> });
  };

  const onExited = () => {
    setView({ styleClass: 'closed', icon: <PlusIcon /> });
  };

  const toggle = () => {
    setCollapse(prevState => !prevState);
  };

  return (
    <div className={`collapse__wrapper ${view.styleClass} ${className}`} dir="ltr">
      <button onClick={()=>{
        (handelOpen && !collapse && !is_open) && handelOpen();
        handleClick && handleClick()
        if(is_open===null || is_open===undefined) toggle();
      }} className="collapse__title" type="button">
        {view.icon}
        {icons==true?
         <p>{title}</p>
        : <p>{title}<ChevronDownIcon /></p>}
       
      </button>
      <Collapse
        isOpen={ (is_open==null || is_open==undefined) ? collapse : is_open}
        className="collapse__content"
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        <div>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

CollapseCmp.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

CollapseCmp.defaultProps = {
  title: '',
  className: '',
};

export default CollapseCmp;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { FormattedMessage } from 'react-intl';
class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { //changeToDark, changeToLight, is_admin, is_tech, 
      user 
    } = this.props;
  
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title={ <FormattedMessage id="app.tableaubord"/> }icon="apartment" route="/app" onClick={this.hideSidebar} />

          

       
            <SidebarLink title={<FormattedMessage id= "app.restaurant"/>} icon="dinner" route="/app/restaurants" onClick={this.hideSidebar} />
           
          {/* <SidebarLink title="Clients" icon="users" route="/app/clients" onClick={this.hideSidebar} /> */}
          <SidebarLink  title={ <FormattedMessage id= "app.order"/> } icon="store" route="/app/Order/liste" onClick={this.hideSidebar} />
          <SidebarLink title={ <FormattedMessage id="app.profile.livreur"/> } icon='users' route="/app/livreur" onClick={this.hideSidebar} />
          <SidebarLink  title={ <FormattedMessage id= "app.panier"/> } icon="cart" route="/app/Carts/liste" onClick={this.hideSidebar} />
          <SidebarLink  title={ <FormattedMessage id= "app.reservation"/> }icon="calendar-full" route="/app/reservations" onClick={this.hideSidebar} />

          {/* <SidebarCategory title="Layout" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory> */}
        </ul>
        

        {/* {user && user.roles.includes('ADMIN') && <ul className="sidebar__block"> */}
        {user && <ul className="sidebar__block">
          <SidebarCategory title={ <FormattedMessage id=  "app.administrateur"/> }  icon="construction">
            <SidebarLink  title={ <FormattedMessage id=  "app.profile.users"/> }  icon="users" route="/app/users" onClick={this.hideSidebar} />
            <SidebarLink title={ <FormattedMessage id=  "app.setting"/> }   icon="cog" route="/app/settings" onClick={this.hideSidebar} />
            <SidebarCategory icon="layers" title={ <FormattedMessage id= "app.marque.blanche"/> } >
            <SidebarLink title={ <FormattedMessage id= "app.list.marque"/> } icon='license' route="/app/marque-blanche" onClick={this.hideSidebar} />
            <SidebarLink title={ <FormattedMessage id="app.demande.marque"/> } icon='enter-down' route="/app/marque-blanche/demande" onClick={this.hideSidebar} />
           
            </SidebarCategory>

            <SidebarCategory icon="enter-down" title={ <FormattedMessage id="app.demandeManager"/> } >
            <SidebarLink title={ <FormattedMessage id= "app.list.marque"/>  } icon='layers' route="/app/demande-manager" onClick={this.hideSidebar} />
            <SidebarLink title={ <FormattedMessage id= "app.recomandation"/>  } icon='store' route="/app/demande-manager/commandes-perdus" onClick={this.hideSidebar} />
              </SidebarCategory>

           
          </SidebarCategory>
        </ul>}
      </div>
    );
  }
}

// export default SidebarContent;

const mapStateToProps = ({auth})=>{
  const { user, is_admin, is_tech } = auth
  return {
    user, is_admin, is_tech
  }
}
export default connect(mapStateToProps, {
  
})(SidebarContent);

// ***** GET FEATURES
export const GET_FEATURES            = 'GET_FEATURES';
export const GET_FEATURES_SUCCESS    = 'GET_FEATURES_SUCCESS';
export const GET_FEATURES_ERROR      = 'GET_FEATURES_ERROR';

export function getFeatures(payload) {
  return {type:GET_FEATURES, payload}; 
}
export function getFeaturesSuccess(payload) {
  return { type:GET_FEATURES_SUCCESS, payload};
}
export function getFeaturesError(error) {
  return { type:GET_FEATURES_ERROR, error };
}






// ***** ADD FEATUR
export const ADD_FEATURE           = 'ADD_FEATURE';
export const ADD_FEATURE_SUCCESS    = 'ADD_FEATURE_SUCCESS';
export const ADD_FEATURE_ERROR      = 'ADD_FEATURE_ERROR';

export function addFeature(payload) {
  return {type: ADD_FEATURE, payload};
}
export function addFeatureSuccess(payload) {
  return { type: ADD_FEATURE_SUCCESS, payload };
}
export function addFeatureError(error) {
  return { type: ADD_FEATURE_ERROR, error };
}






// ***** GET ONE FEATUR
export const GET_ONE_FEATURE           = 'GET_ONE_FEATUR';
export const GET_ONE_FEATURE_SUCCESS    = 'GET_ONE_FEATURE_SUCCESS';
export const GET_ONE_FEATURE_ERROR      = 'GET_ONE_FEATURE_ERROR';

export function getOneFeature(payload) {
  return {type: GET_ONE_FEATURE, payload};
}
export function getOneFeatureSuccess(payload) {
  return { type: GET_ONE_FEATURE_SUCCESS, payload };
}
export function getOneFeatureError(error) {
  return { type: GET_ONE_FEATURE_ERROR, error };
}



// ***** EDIT FEATUR
export const EDIT_FEATURE           = 'EDIT_FEATUR';
export const EDIT_FEATURE_SUCCESS    = 'EDIT_FEATURE_SUCCESS';
export const EDIT_FEATURE_ERROR      = 'EDIT_FEATURE_ERROR';

export function editFeature(payload) {
  return {type: EDIT_FEATURE, payload};
}
export function editFeatureSuccess(payload) {
  return { type: EDIT_FEATURE_SUCCESS, payload };
}
export function editFeatureError(error) {
  return { type: EDIT_FEATURE_ERROR, error };
}



// ***** EDIT FEATUR
export const DELETE_FEATURE           = 'DELETE_FEATUR';
export const DELETE_FEATURE_SUCCESS    = 'DELETE_FEATURE_SUCCESS';
export const DELETE_FEATURE_ERROR      = 'DELETE_FEATURE_ERROR';

export function deleteFeature(payload) {
  return {type: DELETE_FEATURE, payload};
}
export function deleteFeatureSuccess(payload) {
  return { type: DELETE_FEATURE_SUCCESS, payload };
}
export function deleteFeatureError(error) {
  return { type: DELETE_FEATURE_ERROR, error };
}
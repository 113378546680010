import {
	socketConnect
} from "../../redux/actions/authActions";

/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
// import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import Panel from '../../../../shared/components/Panel';



import {  getTooltipStyles } from '../../shared/helper/utils';

import { Card, CardBody, Container, Row, Col } from 'reactstrap';

import Axios from "axios";
import { api } from '../../const';


// import socketIOClient from "socket.io-client";

 import { Line } from 'react-chartjs-2';
 import{Bar}from 'react-chartjs-2';
import {getUsers} from "../../redux/actions/authActions";
import TotalProfitEarned from "./components/TotalProfitEarned";
import TotalTrips from "./components/TotalTrips";
import TotalCustomers from "./components/TotalCustomers";
import TotalCancels from "./components/TotalCancels";
import {  getCarts} from "../../redux/actions/carteActions";
import { FormattedMessage } from 'react-intl';
import moment from 'moment'
import 'moment/locale/fr'  
const style = {
  left: 0,
  width: 150,
  lineHeight: '24px',
  position: 'relative',
  bottom: 125
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    vslue: PropTypes.string,
  })).isRequired,
};

const line_options = {
  legend: {
    position: 'bottom',
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
  },
};



class DashPage extends Component {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    // dir: PropTypes.string.isRequired,
    // themeName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,

      count_admin: 0,

      count_manager:0,
      count_client: 0,
      count_deliveryman: 0,

     

      filter_roles: [],
	
			filter_all: true,
      socket: null,
      configcount:'',
      storageLanguage : localStorage.getItem('language')

    };
  }

  onMouseMove = (e) => {
    const { dir } = this.props;
    if (e.tooltipPosition) {
      this.setState({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };
  componentDidMount(){
    this.getPage()
  }
  getPage() {
    const {storageLanguage}=this.state
    storageLanguage === "en"? moment.locale('en'):moment.locale('fr')
    this.props.getCarts()
// this.props.getUsers({ obj:-1})
//     Axios.get(api.users+'/get-all'+'?'+'roles='+'MANAGER').then(resp => {
//       this.setState({ count_manager: resp.data?.users  ? resp.data?.counts: 0 })
//     })

//     Axios.get(api.users+'/get-all'+'?'+'roles='+'ADMIN').then(resp => {
//       this.setState({ count_admin: resp.data?.users  ? resp.data?.counts: 0 })
//     })

//     Axios.get(api.users+'/get-all'+'?'+'roles='+'CLIENT').then(resp => {
//       this.setState({ count_client: resp.data?.users  ? resp.data?.counts: 0})
//     })

//     Axios.get(api.users+'/get-all'+'?'+'roles='+'DELIVERYMAN').then(resp => {
//       this.setState({ count_deliveryman: resp.data?.users  ? resp.data?.counts: 0})
//     })    
  
    Axios.get(api.cart+ "/count").then(resp => {
      try {
        const commandeparsemaine = resp.data?.carts
        
        const data = [];
        const dates=[]
       

        for (let i = 0; i < commandeparsemaine.length; i++) {

          if(commandeparsemaine[i]._id!==undefined){
    
            dates[i] =moment(commandeparsemaine[i]._id?.date).format('dddd')
            
        
          }
          else{
            dates[i] =moment(commandeparsemaine[i].date).format('dddd')
          
          }
          for (let j = 0; j < commandeparsemaine.length; j++) {
            data[j]= commandeparsemaine[j].count

          }
        }
       

        this.setState({commande_parsemaine: {
          labels:[dates[5] ,dates[4],dates[3], dates[2],dates[1],dates[0]],
         
          datasets: [
            {
              label: storageLanguage === "en"? 'Order per week':'Comande par semaine',
              fill: false,
              lineTension: 0.3,
              backgroundColor: '#4BC0C0',
              borderColor: '#4BC0C0',
              borderWidth: 1,
              pointBackgroundColor: '#4BC0C0',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 1,
              
              pointRadius: 5,
              pointHitRadius: 10,
              data:[data[5],data[4],data[3],data[2],data[1],data[0]]
            },
          ],
        }},
        )
      } catch
       (error) {}
    }


    )

    Axios.get(api.orders+ "/count").then(resp => {
      try {
        const orderparsemaine = resp.data?.orders
        
        const data = [];
        const dates=[]
       

        for (let i = 0; i < orderparsemaine.length; i++) {

          if(orderparsemaine[i]._id!==undefined){
            
            dates[i] =moment(orderparsemaine[i]._id?.date).format('dddd')
        
          }
          else{
            dates[i] =moment(orderparsemaine[i].date).format('dddd')
          
          }
          for (let j = 0; j < orderparsemaine.length; j++) {
            data[j]= orderparsemaine[j].count

          }
        }
       

        this.setState({panier_semaine: {
          labels:[dates[5] ,dates[4],dates[3], dates[2],dates[1],dates[0]],
         
          datasets: [
            {
   label: storageLanguage === "en"? 'Cart per week':'Panier par semaine',
              fill: false,
              lineTension: 0.3,
            
              backgroundColor: '#ff7e9a',
              borderColor: '#ff4861',
              borderWidth: 1,
              pointBackgroundColor: '#ff4861',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 1,
              pointRadius: 5,
              pointHitRadius: 10,
              data:[data[5],data[4],data[3],data[2],data[1],data[0]]
            },
          ],
        }},
        )
      } catch
       (error) {}
    })

    Axios.get(api.User_Config+'/counts').then(resp => {
      this.setState({ configcount: resp.data})
    // })   
    try {
    
        const dates2=[]

    let restaurantactive=this.state.configcount.restaurants?.active
    let encours=this.state.configcount.restaurants?.creationInProgress
    let partenaire=this.state.configcount.restaurants?.partner
    let valider=this.state.configcount.restaurants?.validated

this.setState({detailrestau: {
  
  labels:['En Cours','Partenaire','Validé',"Active"],
 
  datasets: [
    {
label: storageLanguage === "en"? 'Restaurant status':'Status restaurant',
    
      backgroundColor: [
        // 'rgba(255, 99, 132, 0.2)',
         'rgba(255, 159, 64, 0.2)',
         'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        // 'rgba(153, 102, 255, 0.2)',
       
      ],
      borderColor: [
        // 'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
         'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        // 'rgb(153, 102, 255)',
       
      ],
      borderWidth: 1,
     
      data:[  dates2[3]=encours,dates2[2]=partenaire,dates2[1]=valider,dates2[0]=restaurantactive,]
    },
  ],
}},
)
} catch
(error) {}
})

  }
  

  getNearbyTrips = () =>{
    Axios.post(api.orders+"/nearby", { lat: 9.022997299999999, lng: 38.7520662}).then(resp=>{
      this.generateSocket()
    })
  }





  generateSocket = () =>{
  

    const socket = this.props.socket


    if(socket){
     
      socket.on("NEARBY_TRIPS_"+this.props.user._id, data => {
     
      });
    }
    else console.log("SOCKET not EXIST");
  }
  componentWillUnmount(){
    // if(this.state.socket) this.state.socket.disconnect()
    // if(this.props.socket) this.props.socket.disconnect()
  }

  emitting = () => {
    const socket = this.props.socket
    // const socket = this.state.socket

    if(socket){
      socket.emit('TRACK_NEARBY_TRIPS_'+this.props.user._id, {
        lat: 9.022997299999999, lng: 38.7520662
      })

    }
    else{
      this.props.socketConnect()
    }
  }

  render() {
    const { x, y, count_admin, count_manager, count_client, count_deliveryman,
      panier_semaine,commande_parsemaine,detailrestau
    } = this.state;
    const { theme_mode,count_all_users } = this.props;

    return (<Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">
          <FormattedMessage id="app.tableaubord" /></h3>
        </Col>
      </Row>
      <Row>
       
        <TotalTrips   />
        <TotalCustomers />
        <TotalCancels  />
        <TotalProfitEarned/>
      </Row>

      <Row>
        {/* <Col md="3">
          <Card>
            <CardBody>
           
              <h3><FormattedMessage id="app.profile.users" /> : <strong>{count_all_users}</strong> </h3>
            
              <div className="dashboard__visitors-chart">
               
                <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={300}>
                  <PieChart className="dashboard__chart-pie-container">
                    <Tooltip position={{ x, y }} {...getTooltipStyles(theme_mode)} />
                    <Pie
                      data={[
                        { name:<FormattedMessage id="app.profile.admin" />, value: count_admin, fill: '#b635ba' },
                        {  name:<FormattedMessage id="app.profile.manager" />, value: count_manager, fill: '#4ce1b6' },
                        { name:<FormattedMessage id="app.profile.client" />, value: count_client, fill: '#f6da6e' },
                        { name:<FormattedMessage id="app.profile.livreur" />, value: count_deliveryman, fill: '#70bbfd' },
                   
                      ]}
                      dataKey="value"
                      cy={110}
                      innerRadius={70}
                      outerRadius={100}
                      onMouseMove={this.onMouseMove}
                    />
                    <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style} content={renderLegend} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col> */}
        <Col md="4">
          <Card>
            <CardBody>
              <div className="mb-3">
              <h3><FormattedMessage id="app.detailrestaurant" /></h3>
               
              </div>
              {detailrestau!==undefined?
               <Bar data={detailrestau} options={line_options} />
              :null}
            
            </CardBody>
          </Card>
        </Col>
        
        <Col md="4">
        <Card>
            <CardBody>
              <div className="mb-3">
                <h3><FormattedMessage id="app.commandeparsemanie" /></h3>
              
              </div>
              {commande_parsemaine!==undefined?
              <Line data={commande_parsemaine} options={line_options} />
              :null}
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardBody>
              <div className="mb-3">
              <h3><FormattedMessage id="app.panierparsemaine" /></h3>
               
              </div>
              {panier_semaine!==undefined?
               <Line data={panier_semaine} options={line_options} />
              :null}
            
            </CardBody>
          </Card>
        </Col>
      

       

  
      </Row>
    </Container>);
  }
}

const mapStateToProps = ({ auth, theme ,cartReq}) => {
  const { loading_get_users, user, users, count_all_users, is_admin, } = auth

  const{carts_req}=cartReq

	return {

		loading_get_users, user, users, count_all_users, is_admin,
    theme_mode: theme.className,
    user: auth.users,
    socket: auth.socket,
    carts_req,

	}
}
export default connect(mapStateToProps, {
	socketConnect,getUsers,getCarts,
})(DashPage);


import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import {Card, CardBody,ButtonGroup, ButtonToolbar, Button, Row, Col} from 'reactstrap';
import{notification,Popconfirm,Table,Tag, Input, Space,} from'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import Pagination from '../pagination/Pagination';
import {getUsers} from "../../../redux/actions/authActions";

import { getCountPages } from '../../helper/utils';

// import AddUserMdl from './add-user-mdl';

import { FormattedMessage } from 'react-intl';

import { NavLink } from 'react-router-dom';
import { api } from '../../../const';
import axios from 'axios';

class ListUsersCmp extends PureComponent {

	constructor() {
		super();
		this.state = {
			filter_by_driver: false,
			filter_by_user: false,
			filter_by_tech_support: false,

			filter_roles: [],
			filter_role_admin:[],

			filter_all: true,
			users: [],
	        current_page: 1,
			totalPage: 1,
			selected_page_size: 10,
			page_sizes: [5, 10, 20, 30, 50],


			show_add_user_mdl: false,
			
			
			selected_user 	: null,
			selected_users 	: [],
			storageLanguage : localStorage.getItem('language')
		};
	}


	changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
	}

	componentWillMount(page=1) {
		const { selected_page_size, 
		} = this.state;
 

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		this.props.getUsers({ obj:'DELIVERYMAN',offset, limit: selected_page_size})

	
	}

	componentDidUpdate(prev) {

	}

	getPage(page = 1) {
		const { selected_page_size,	filter_all, filter_roles} = this.state;

 

		const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
		const filter = 'DELIVERYMAN'	
		if(this.props.only_clients) filter['roles'] = ['USER']
		else if(this.props.only_drivers) filter['roles'] = ['DELIVERYMAN']
		else if (!filter_all) filter['roles'] = filter_roles
	
		
	this.props.getUsers({ obj:'DELIVERYMAN',offset, limit: selected_page_size})



}

	onChangePage = page => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};
	



	toggleAddUserMdl = () => this.setState((prev) => { return { show_add_user_mdl: !prev.show_add_user_mdl } })

	changeSelectedUser = (usr, checked= true)=>{
		if(usr){
			if(this.props.uni) this.props.changeSelectedUser(usr)
	
		}
	}
	openNotificationWithIconSuppression = type => {

		const {storageLanguage}=this.state
    
		notification[type]
		({
			  duration:3,
			  message:storageLanguage === "en"? 'Success':'Succès',
		  description:storageLanguage === "en"? 'User successfully deleted  ':'Utilisateur supprimer avec succès ',
	   
			})
	
	  
	  };
	
	openNotificationWithIconDésactivation= type => {
		const {storageLanguage}=this.state
    
		notification[type]
	
		({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
		  description:storageLanguage === "en"? 'Account deactivation  successfully':'Désactivation du compte avec succès',
		 
		});
	  };
	openNotificationWithIcon = type => {
		const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
		  description:storageLanguage === "en"? 'Account Activation Successfully':'Activation du compte avec succès',
		 
		});
	  };
	  openNotificationWithIconErreur = type => {
		const {storageLanguage}=this.state
			notification[type]
		
			({
			  duration:3,
		  message:storageLanguage === "en"? 'Error':'Erreur',
		  description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
			});
	  };
	suppression=(id)=>{
		axios.delete(api.users+"/"+id).then(response=>{
			if (response.status=200){ 
			  this.openNotificationWithIconSuppression('success') 
	
			  this.props.getUsers({ obj:-1})
			}
			else{
	  
			  this.openNotificationWithIconErreur('error')
			  this.props.getUsers({ obj:-1})
			
			}
			  
		  })
		
		}
	desactivercompte=(isactive,id)=>{
		axios.patch(api.users+'/activate/'+id,{isActive:!isactive}).then(response=>{
			if (response.status=200){ 
			  this.openNotificationWithIconDésactivation('success') 
			  this.props.getUsers({ obj:-1})
			}
			else{
	  
			  this.openNotificationWithIconErreur('error')
			  this.props.getUsers({ obj:-1})
			
			}
			  
		  })
		
		}

	 confirm=(isactive,role,id)=> {
		
		 if(role==='MANAGER'){
			axios.post(api.users+'/manager/activate/'+id).then(response=>{
				if (response.status=200){ 
				  this.openNotificationWithIcon('success') 
				  this.props.getUsers({ obj:-1})
				}
				else{
		  
				  this.openNotificationWithIconErreur('error')
				  this.props.getUsers({ obj:-1})
				
				}
				  
			  })
			
			}else
		 {	
			axios.patch(api.users+'/activate/'+id,{isActive:isactive}).then(response=>{
				if (response.status=200){ 
				  this.openNotificationWithIcon('success') 
				  this.props.getUsers({ obj:-1})
				}
				else{
		  
				  this.openNotificationWithIconErreur('error')
				  this.props.getUsers({ obj:-1})
				
				}
				  
			  })
			
			}


	  }
	  
	   cancel=(e)=> {
		console.log(e);

	  }
	  handleChange = (pagination, filters) => {

        this.setState({ filteredInfo: filters });
      };
      getColumnSearchProps = dataIndex => ({
       
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
               <FormattedMessage id="app.btn.chercher"/> 
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
			  <FormattedMessage id="app.btn.cancel"/> 
              </Button>
         
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
    record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
	:'',
   

     
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
 
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
	render() {
		
		
		const { 
			current_page, selected_page_size, show_add_user_mdl,
			selected_user, selected_users,storageLanguage
		} = this.state;

		const { count_all_users,user, users, uni, show_footer, onSuccess, onCancel, only_clients, only_drivers, card_style, body_style } = this.props
		const columns = [
  
			{
			  
			  title:  <FormattedMessage id="app.profile.livreur"/>,
			  key: 'firstName',
			  width: '20%',
			  dataIndex: 'firstName',
	
			  sortDirections: ['descend', 'ascend'],
			  defaultSortOrder: 'ascend',
			 
			  render: (value, record, index) => {
				  let usr=record.profile
		
				return (<div>
				 { usr.picture ? <img src={`${api.root}/${usr.picture?.path}`} alt={usr?.firstName + " " + usr?.lastName} style={{ width: 40 }} />
                 : <img src={`${process.env.PUBLIC_URL}/img/no-user.png`} alt={usr?.firstName + " " + usr?.lastName} style={{ width: 40 }} />
                            }
				 <span style={{marginLeft:25 }}>{usr.firstName + " " + usr?.lastName}</span>
				  </div>)
			  },
			  sorter: (a, b) => a.profile?.firstName.localeCompare(b.profile?.firstName),
  
			},
		  
			{
			  title: <FormattedMessage id="app.profile.contact" />,
			  dataIndex: 'email',
			  key: 'email',
			  sortDirections: ['descend', 'ascend'],
			  ...this.getColumnSearchProps('email'),
			  render: (value, record, index) => {
				return (<div>{record.email}<span>{record.mobile}</span>
				  </div>)
			  },
			  sorter: (a, b) => a.email.localeCompare(b.email),
			
			},
			{
			  title:<FormattedMessage id="app.profile.status" />,
			  dataIndex: 'isActive',
			  key: 'isActive',
			  ...this.getColumnSearchProps('isActive'),
			  render: (value, record, index) => {
			if(record.isActive===true) return(<Tag color="green" >
				 <FormattedMessage id="app.profile.active" /></Tag>) 
			else
			return(<Tag color="red" > <FormattedMessage id="app.profile.inactive" /></Tag>)
		
			  }
		 
			},
			{
			  title:<FormattedMessage id="app.profile.address" />,
			  dataIndex: 'Address',
	  
			  render: (value, record, index) => {
				return (<div>{record.profile?.address?.fullAddress}
				  </div>)
			  }
			  
		   
			},
		
  
			{
			  title: <FormattedMessage id="app.profile.action" />,
			  width:'15%',
		   
			 
  
			  render: (value, record, index) => {
			return (<div>   <NavLink to={"/app/livreur/detail/"+record._id}><span className="lnr lnr-eye" style={{ color: "#f31d1d", fontSize: 18 ,marginLeft:25,cursor:'pointer' }}></span></NavLink>

           
			  </div>)
		  }
			},
		  ];
	return (<Card style={card_style ? card_style : {}}>
		
			<CardBody style={body_style ? body_style : {}}>
				<div className="card__title">
				
					<Row>
						{/* <Col md="8">
							{(!only_clients && !only_drivers) && <ButtonToolbar>
								<h5>    <FormattedMessage id="app.profile.role"/></h5>
								<ButtonGroup dir="ltr">
									<Button name='all' onClick={() => this.resetFilter('all', filter_roles)} outline style={filter_all ? { background: '#e7e2e2' } : {}} ><FormattedMessage id="app.profile.tous"/></Button>
									<Button name='admin' onClick={() => this.resetFilter('admin', filter_roles)} style={filter_roles.includes("ADMIN") ? { background: '#e7e2e2' } : {}} outline><FormattedMessage id="app.profile.admin"/></Button>
									<Button name='manager' onClick={() => this.resetFilter('manager', filter_role_admin)} style={filter_roles.includes("MANAGER") ? { background: '#e7e2e2' } : {}} outline><FormattedMessage id="app.profile.manager"/></Button>
									<Button name='client' onClick={() => this.resetFilter('client', filter_roles)} style={filter_roles.includes("CLIENTS") ? { background: '#e7e2e2' } : {}} outline><FormattedMessage id="app.profile.client"/></Button>
									<Button name='deliveryman' onClick={() => this.resetFilter('deliveryman', filter_roles)} style={filter_roles.includes("DELIVERYMAN") ? { background: '#e7e2e2' } : {}} outline><FormattedMessage id="app.profile.livreur"/></Button>
									
								</ButtonGroup>
							</ButtonToolbar>}
						</Col> */}


					
					</Row>
				
					{/* <Col md="8" style={{marginTop:30}}>
						
						{(!only_clients && !only_drivers) && <ButtonToolbar>
							<h5>    <FormattedMessage id="app.profile.status" /></h5>
							<ButtonGroup dir="ltr">
						
								<Button name='actif' onClick={(e) => this.resetFilter('actif', e)} style={filter_roles.includes("actif") ? { background: '#e7e2e2' } : {}} ><FormattedMessage id="app.profile.active"/></Button>
								<Button name='inactif' onClick={(e) => this.resetFilter('inactif', e)} style={filter_roles.includes("inactif") ? { background: '#e7e2e2' } : {}} outline><FormattedMessage id="app.profile.inactive"/></Button>
								
								
							</ButtonGroup>
						</ButtonToolbar>}
					</Col> */}
					
				</div>

				<div>        <Table rowKey={(record, index) => index} scroll={{ x: 1000 }} 
        dataSource={users} columns={columns} bordered pagination={true}
  style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} /></div>  
  <Pagination currentPage={current_page}
					totalPage={getCountPages(count_all_users, selected_page_size)}
          onChangePage={i => this.onChangePage(i)} />
			</CardBody>

			{show_footer && <div style={{padding: 5}}>
				<ButtonToolbar className="modal__footer form__button-toolbar">
					<Button color="primary" onClick={()=>onSuccess(uni ? selected_user : selected_users)}><FormattedMessage id="app.btn.select" /></Button>
					<Button type="button" onClick={()=>onCancel()}><FormattedMessage id="app.btn.cancel" /></Button>
				</ButtonToolbar>
			</div>}
		</Card>);
	}
}

const mapStateToProps = ({ auth }) => {
	const { loading_get_users, user, users, count_all_users, is_admin } = auth
	return {
		loading_get_users, user, users, count_all_users, is_admin
	}
}
export default connect(mapStateToProps, {
	getUsers
})(ListUsersCmp);
import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import ReactQuill from "react-quill";
import { connect } from 'react-redux';
import { api } from '../../../const';
import axios from "axios"
import {notification } from 'antd';
import {getDressCodes,addDressCode} from "../../../redux/actions/dressCodeActions";
import { FormattedMessage } from 'react-intl';
import { quillFormats, quillModules } from "../../helper/utils";

import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';

class AddDressCodeCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
            name: "",
            description:"",
            storageLanguage : localStorage.getItem('language')
		};
  }
  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Dress style add successfully  ':'Style vestimentaire ajouter avec sucées',
   
		});
    
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }

  addDressCode = () =>{
    const { name, description } = this.state
    if(name!==''){
      const data={name,description}
      axios.post(api.dress_codes,data).then(response=>{

        if (response.status=200){ 
          this.openNotificationWithIcon('success') 
          this.props.success()
          this.props.getDressCodes()
        }
        else{

          this.openNotificationWithIconErreur('error')
 
          this.props.getDressCodes()
        } 
    })
    }}

  

  componentDidUpdate(prev){
    if(prev.loading_add_dress_code && !this.props.loading_add_dress_code && !this.props.error_add_dress_code){
      this.props.success()
    } 
  }
  handleChange = (value) => {
    const obj = {};
    obj["description"] = value;
    this.setState(obj);
  };

  render() {
    const { name,description } = this.state

    return (<Row>

      <Col md={10}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">   <FormattedMessage id = "app.setting.titre"/></span>

            <div className="form__form-group-field">
              <input value={name} placeholder="Titre" onChange={(e)=>this.changeVal('name', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
                 </span>
                 <ReactQuill style={{ width: "100%" }}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={this.handleChange}
        
                />

            <div className="form__form-group-field">
            <div onClick={this.addDressCode} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-plus-circle"></span>
              </div>


              <div onClick={(this.props.cancelAdding) ? this.props.cancelAdding : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
            </div>


          </div>
        </div>
      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ dressCode }) => {
  const { loading_add_dress_code, error_add_dress_code  } = dressCode
  return {
    loading_add_dress_code, error_add_dress_code
  }
}
export default connect(mapStateToProps, {getDressCodes,
  addDressCode
})(AddDressCodeCmp);
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AddRestaurantCmp from '../../shared/components/restaurants/add-restaurant-cmp';
import { FormattedMessage } from 'react-intl';
class RestaurantsAddPage extends PureComponent {
  render() {
    return (<Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title"><FormattedMessage id="app.titre.addRestaurant" /></h3>
        </Col>

        <Col md={12}>
          <AddRestaurantCmp history={this.props.history} />
        </Col>
      </Row>
      <Row>

      </Row>
    </Container>)
  }
}

export default RestaurantsAddPage;
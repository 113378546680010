import {
    GET_COMMANDE_PERDU, GET_COMMANDE_PERDU_SUCCESS, GET_COMMANDE_PERDU_ERROR,

  
    DELETE_COMMANDE_PERDU, DELETE_COMMANDE_PERDU_SUCCESS, DELETE_COMMANDE_PERDU_ERROR

  } from '../actions/commandeperduActions';
    const initialState = {
    loading_get_commande_perdu: false,
    error_get_commande_perdu: null,
    commande_perdu_by_manager: null,
    loading_get_commande_perdu_by_manager: false,
    error_get_commande_perdu_by_manager: null,
    loading_commande_perdu: false, error_commande_perdu: null,
    commande_perdu: [],
  count_all_commande_perdu: 0,
  
    new_Demande_Manager: null,selected_commandeperdu:null, loading_add_Demande_Manager: false, error_add_Demande_Manager: null,
  };
  
  const commande_perduReducer = (state = initialState, action) => {
   
    switch (action.type) {
  
      case GET_COMMANDE_PERDU: return {...state, loading_get_commande_perdu: true, error_get_commande_perdu: null };
      case GET_COMMANDE_PERDU_SUCCESS:
    
        return {...state, commande_perdu: [ ...action.payload.commande_perdu],count_all_commande_perdu:action.payload.count, loading_get_commande_perdu: false, error_get_commande_perdu: null };
      case GET_COMMANDE_PERDU_ERROR:
  
        return {...state, error_get_commande_perdu: action.error, loading_get_commande_perdu: false, commande_perdu: [] };
  
  
 
  
        case DELETE_COMMANDE_PERDU: return {...state, loading_delete_commande_perdu: true, error_delete_commande_perdu: null };
        case DELETE_COMMANDE_PERDU_SUCCESS:
          return {...state, loading_delete_commande_perdu: false, error_delete_commande_perdu: null };
        case DELETE_COMMANDE_PERDU_ERROR:
          return {...state, error_delete_commande_perdu: action.error, loading_delete_commande_perdu: false};
      default: return state;
    }
  };
  
  export default commande_perduReducer;
  
import { all } from 'redux-saga/effects';
import authSaga from './sagas/authSaga';
import reservationsReqSaga from './sagas/reservationsReqSaga';
import kitchenTypeSaga from './sagas/kitchenTypesSaga';
import restaurantSaga from './sagas/restaurantsSaga';
import dressCodeSaga from './sagas/dressCodeSaga';
import blocsSaga from './sagas/blocsSaga';
import dishSaga from './sagas/dishSaga';
import menuSaga from './sagas/menuSaga';
import Restaurant_types from'./sagas/restauranTypesSaga';
import orderReqSaga from './sagas/orderSaga';
import carteReqSaga from './sagas/cartSaga';
import dietSaga from './sagas/dietSaga';
import marqueBlancheSaga from'./sagas/marqueblancheSaga';
import veganSaga from './sagas/veganSaga';
import UserConfigSaga from './sagas/UserConfigSaga'
import FeaturesSaga from "./sagas/feautreSaga"
import Demande_Manager from "./sagas/demandemanagerSaga"
import Commade_Perdu from "./sagas/commandeperduSaga"
 
export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    reservationsReqSaga(),
    kitchenTypeSaga(),
    restaurantSaga(),
    dressCodeSaga(),
    blocsSaga(),
    dishSaga(),
    menuSaga(),
    Restaurant_types(),
    orderReqSaga(),
    carteReqSaga(),
    dietSaga(),
    marqueBlancheSaga(),
    veganSaga(),
    UserConfigSaga(),
    FeaturesSaga(),
    Demande_Manager(),
    Commade_Perdu(),
    ]);
}


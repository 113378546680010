import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Row, Col, ButtonGroup, ButtonToolbar, Alert
} from 'reactstrap';

import { notification, Popconfirm, Tooltip, Table, Tag, Input, Space, Modal, Steps, InputNumber } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { api } from '../../../const';
import Select from 'react-select';

import {
  addCart, getOneCart,
} from "../../../redux/actions/carteActions";
import { getUsers } from "../../../redux/actions/authActions";
import { addOrderReq } from "../../../redux/actions/orderActions"

import { getOneRestaurant } from "../../../redux/actions/restaurantsActions";

import { NavLink } from 'react-router-dom';

import { getRestaurants } from "../../../redux/actions/restaurantsActions";
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';
import TextArea from 'antd/lib/input/TextArea';

const { Step } = Steps;

class AddOreder extends PureComponent {

  constructor() {
    super();
    this.state = {

      order_requests: [],

      current_page: 1,
      totalPage: 1,
      selected_page_size: 10,
      page_sizes: [5, 10, 20, 30, 50],

      show_delete_msg: false,

      storageLanguage: localStorage.getItem('language'),
      isClearable: true,
      visible: false,
      current: 0,
      somme: 0,
      panier: "",
      qtsItem: 0,
      itemName: "",
      itemPrice: "",
      itemQuantité: '',
      itemPicture: '',

      price: "",
      deliveryFee: "",
      orderType: "",
      restaurant: "",
      cart: "",
      street: "",
      city: "",
      country: "",
      note: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: ""

    };
  }

  toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }));
  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  componentDidMount() {

    this.getPage({})

  }
  getPage(page = 1) {
    const { selected_page_size, qtsItem,
      itemName,
      itemPrice,
      itemQuantité,
      itemPicture, } = this.state;


    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    this.props.getRestaurants({ offset, limit: selected_page_size })

    let obj = { status: -1 }




    axios.get(api.cart + '/user').then(resp => {
      if (resp.data != undefined)

        this.setState({ detail: resp.data?.cart[0] })
    }
    )

  }


  onChangePage = page => {
    this.setState({ current_page: page }, () => this.getPage(page));
  };


  getResto = (e, page = 1) => {

    const { selected_page_size, restaurant } = this.state;
    const { restaurants } = this.props;

    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    let Resto = e.target.value
    this.setState({ RestoName: Resto })

    if (e.target.value !== "") {
      this.props.getRestaurants({ filterName: Resto, offset, limit: selected_page_size })

      this.setState({ IdResto: restaurants?._id })
    }
    else {

      this.getPage(page = 1)

    }



  }
  changeRestaurant = (value, page = 1) => {

    const { selected_page_size, IdResto } = this.state;

    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

    if (value !== null && IdResto !== null) {
      this.props.restaurants.map(opt => ({ label: opt, value: opt }));
      let IdResto = value.value

      this.props.getOrderReqs({ IdResto, offset, limit: selected_page_size })
    } else {
      this.getPage(page = 1)
    }

  }

  CreateOrder = (value, page = 1) => {
    const { selected_page_size, IdResto } = this.state;
    let restaurant_id = value.value
    this.setState({ Id_rest: value.value })
    if (restaurant_id !== null) {
      this.props.getOneRestaurant({ restaurant_id })
    } else {
      this.getPage(page = 1)
    }


  }
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }



  openNotificationWithIconSuppression = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Order successful deleted' : 'Supperssion du commande  avec Succès',


      });
  };

  openNotificationWithIconErreur = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Error' : 'Erreur',
        description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
      });
  };
  showModal = () => {

    this.setState({
      visible: true,
    });
  };

  handleOk = e => {

    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {

    this.setState({
      visible: false,
    });
  };
  addPanier = (e, value, type) => {

    let restaurant = this.props.selected_restaurant._id
    let item = value._id

    let quantity = "1"
    axios.post(api.cart, { restaurant, item, type, quantity }).then(resp => {
      if (resp.status === 200) {
        axios.get(api.cart + '/user').then(resp => {
          if (resp.data != undefined)
            this.setState({ detail: resp.data?.cart[0] })
        }
        )
      }
    })
    this.setState({ somme: this.state.somme + 1 })
  }
  deletpanier = (item) => {
    let id = this.state.detail._id

    axios.post(api.cart + '/remove', { id, item }).then(resp => {
      if (resp.data != undefined) { this.openNotificationWithIconSuppression('success') }
      else {
        this.openNotificationWithIconErreur('error')
      }
    })
  }
  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  submit = () => {
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })


    if (error_frm === "") {

      const { firstName, lastName, email, phone, price,
        deliveryFee, orderType, restaurant, cart, street, city, country, note } = this.state

      const data = {
        firstName, lastName, email, phone, price: Number(this.state.detail?.totalPrice.toFixed(2)) + Number(this.state.deliveryFee),
        deliveryFee, orderType, restaurant: this.state.Id_rest, cart: this.state.detail._id, street, city, country, note
      }
      console.log('data', data)
      this.props.addOrderReq({ data })
    }
  }
  checkFrm = (data) => {
    const { phone, price, street, city, } = data

    if (phone === "") return "mobile is required.";
    if (price === " ") return "price is required";

    if (city === "") return "capacity is required.";
    if (street === "") return "street is required.";


    return ""
  }
  render() {
    const { storageLanguage, isClearable, current, panier } = this.state;
    const { restaurants, selected_restaurant, selected_cart } = this.props

    let type = '';
    let restaurant = [];


    restaurants.forEach(element => {
      restaurant.push({
        value: element._id,
        label: element.name,
      })
    })

    const steps = [
      {
        title: 'First',
        content:
          <Row>

            <Col md="12" style={{ marginBottom: 50, marginTop: 50 }}>
              <div onKeyUp={(e) => this.getResto(e)} >


                <Select isClearable={isClearable}

                  onChange={(e) => this.CreateOrder(e)}
                  placeholder={<FormattedMessage id="app.restaurant" />}
                  options={restaurant} styles={{ background: '#e7e2e2', width: '250px', border: 'none', borderBottom: '1px solid #ddd' }} />
              </div>
            </Col>
          </Row>,
      },
      {
        title: <FormattedMessage id="app.menus" />,
        content: <Card>
          <CardBody>
            <Row style={{ marginTop: 50 }}>
              <Col md='9'>


                <Row>

                  <Col md="6" style={{ marginBottom: 50, marginTop: 50 }}>

                    <h4><FormattedMessage id="app.menus" /></h4>
                    {selected_restaurant && selected_restaurant?.menus.map((menu, i) =>
                      <div>
                        <Card>
                          <Row>

                            <Col md="4" >

                              <div className="overlay"></div>
                              {menu.picture ?
                                <CardImg alt={menu.name} src={`${api.root}/${menu.picture.path}`} style={{ cursor: 'pointer' }} />
                                : <CardImg alt={menu.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ cursor: 'pointer' }} />}

                            </Col>
                            <Col md="5" >
                              {/* <CardBody> */}
                              <CardTitle tag="h4">{menu.name}</CardTitle>
                              {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
                              <CardText>{menu.price?.price} €</CardText>


                              {/* </CardBody> */}
                            </Col>
                            <Col md="3">
                              <span style={{ fontSize: 25, cursor: 'pointer', color: 'crimson' }} onClick={(e) => this.addPanier(e, menu, type = 'Menu')} className="lnr lnr-plus-circle"></span>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    )}
                  </Col>
                  <Col md="6" style={{ marginBottom: 50, marginTop: 50 }}>
                    <h4><FormattedMessage id="app.plats" /></h4>
                    {selected_restaurant && selected_restaurant?.plates.map((plat, i) =>
                      <div>
                        <Card>
                          <Row>

                            <Col md="4" >

                              <div className="overlay"></div>
                              {plat.picture ?
                                <CardImg alt={plat.name} src={`${api.root}/${plat.picture.path}`} style={{ cursor: 'pointer' }} />
                                : <CardImg alt={plat.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ cursor: 'pointer' }} />}

                            </Col>
                            <Col md="5" >

                              <CardTitle tag="h4">{plat.name}</CardTitle>

                              <CardText>{plat.price?.price} €</CardText>



                            </Col>
                            <Col md="3">
                              <span style={{ fontSize: 25, cursor: 'pointer', color: 'crimson' }} onClick={(e) => this.addPanier(e, plat, type = 'Plate')} className="lnr lnr-plus-circle"></span>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>


              <Col md='3' style={{ marginBottom: 10, marginTop: 10 }}>
                <Row>
                  <Col md="7" >
                    {this.state.detail?.totalQty}
                    <span style={{ fontSize: 25, cursor: 'pointer', color: 'crimson' }} className="lnr lnr-cart" />
                  </Col>
                  <Col md="5">

                    <Button outline color="danger" onClick={() => this.next()}>
                      CheckOut
          </Button>
                  </Col>
                </Row>




                <Col md='12'>
                  <Card style={{ backgroundColor: '#f8f7f7', padding: 25, borderRadius: 10 }}>


                    {this.state.detail?.items !== undefined ? this.state.detail?.items?.map((item, i) =>
                      <div>
                        <Card>
                          <Row>

                            <Col md="3" >


                              {item.item.picture ?
                                <CardImg alt={item.item.name} src={`${api.root}/${item.item.picture.path}`} style={{ width: 50, cursor: 'pointer' }} />
                                : <CardImg alt={item.item.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ cursor: 'pointer' }} />}

                            </Col>
                            <Col md="7" >

                              <CardTitle tag="h5">{item.item.name}</CardTitle>
                              <CardText><FormattedMessage id="app.quantité" /> :{item.quantity} {item.type} </CardText>


                              <CardText><FormattedMessage id="app.order.prix" />: {item.itemsPrice} €</CardText>




                            </Col>
                            <Col md="1">
                              <span style={{ fontSize: 23, cursor: 'pointer', color: 'crimson' }}
                                onClick={(e) => this.deletpanier(item.item._id)} className="lnr lnr-trash"></span>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    ) : null}




                  </Card>


                </Col>

              </Col>
            </Row>


          </CardBody>
        </Card>
      },
      {
        title: 'Last',
        content: <Card>

          <CardBody>
            <Row>
              <Col md='8'>
                <Col md="12">
                  <h4 style={{ marginBottom: 20, fontWeight: 'bolder' }}>Examiner et passer la commande</h4>
                  <Row>

                    <p style={{ marginBottom: 20, fontWeight: 'bolder' }}>
                      Vos informations</p>
                  </Row>
                  <Row>

                    <Col md="6">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.firstname" /></span>
                        <div className="form__form-group-Input">
                          <Input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="firtname" component="Input" type="text" placeholder="Nom" onChange={(e) => this.changeVal('firstName', e.target.value)}

                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.lastname" /></span>
                        <div className="form__form-group-Input">
                          <Input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="lastname" component="Input" type="text" placeholder="Prenom"
                            onChange={(e) => this.changeVal('lastName', e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="12">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.phone" /></span>
                        <div className="form__form-group-Input">
                          <Input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="phone" component="Input" type="text" placeholder="Téléphone"
                            onChange={(e) => this.changeVal('phone', e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="12">

                      <p style={{ marginBottom: 20, fontWeight: 'bolder' }}>
                        Adresse de livraison
</p>
                    </Col>
                    <Col md="6">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.city" /></span>
                        <div className="form__form-group-Input">
                          <Input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="city" component="Input" type="text" placeholder="ville" onChange={(e) => this.changeVal('city', e.target.value)}

                          />
                        </div>
                      </div>
                    </Col>



                    <Col md="6">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.country" /></span>
                        <div className="form__form-group-Input">
                          <Input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="country" component="Input" type="text" placeholder="Pays"
                            onChange={(e) => this.changeVal('country', e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.profile.street" /></span>
                        <div className="form__form-group-Input">
                          <Input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="street" component="Input" type="text" placeholder="Rue"
                            onChange={(e) => this.changeVal('street', e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>



                    <Col md="12">

                      <p style={{ marginBottom: 20, fontWeight: 'bolder' }}>Instruction de livraison</p>
                    </Col> <Col md="12">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Détails de livraison</span>
                        <div className="form__form-group-Input">
                          <TextArea style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="Detail" component="Input" type="text" placeholder="Détails de livraison"
                            onChange={(e) => this.changeVal('note', e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="12">

                      <p style={{ marginBottom: 20, fontWeight: 'bolder' }}>Payment information</p>
                    </Col>
                    <Col md="6">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.order.fraislivreur" /></span>
                        <div className="form__form-group-Input">
                          <Input type="number" style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="deliveryFee" component="Input" type="text" placeholder="deliveryFee"
                            onChange={(e) => this.changeVal('deliveryFee', e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form__form-group">
                        <span className="form__form-group-label"><FormattedMessage id="app.order.prix" /></span>
                        <div className="form__form-group-Input">
                          <Input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                            name="deliveryFee" component="Input" type="text" placeholder="prix"
                            value={Number(this.state.detail?.totalPrice.toFixed(2)) + Number(this.state.deliveryFee)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>

              </Col>
              <Col md='3'>
                <p style={{ marginBottom: 20, fontWeight: 'bolder' }}> Your order</p>
                <Row>
                  {this.state.detail?.items !== undefined ? this.state.detail?.items?.map((item, i) =>
                    <div>
                      <Card>
                        <Row>


                          <Col md="10" >
                            <p className="text-muted"> {item.quantity} <span className='text-danger'>{item.item.name}</span>  {item.itemsPrice} €
               </p>

                          </Col>
                          <Col md="2">
                            <span style={{ fontSize: 23, cursor: 'pointer', color: 'muted' }}
                              onClick={(e) => this.deletpanier(item.item._id)} className="lnr lnr-trash"></span>
                          </Col>
                        </Row>

                      </Card>
                    </div>
                  )
                    : null}
                </Row>
                <div>

                  <p style={{ fontWeight: 'bolder' }}> Prix totale: <span className='text-danger'>{this.state.detail?.totalPrice.toFixed(2)} €</span></p>
                </div>
              </Col>

            </Row>
          </CardBody>
        </Card>,
      },
    ];
    return (<Card>


      <CardBody>

        <Steps current={current}>

          <Step title="Selectionner un restaurant">

          </Step>
          <Step title="Ajouter commande" />
          <Step title="Passer lar commande" />

        </Steps>
        <div className="steps-content">

          {steps[current].content}</div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => this.submit()}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
              Previous
            </Button>
          )}
        </div>

      </CardBody>
    </Card>


    )
  }
}

const mapStateToProps = ({ cartReq, restaurant, auth, orderReq }) => {
  const { loading_get_carts, carts_req, count_all_cart_requests,
    loading_one_cart_request, error_get_carts,
    loading_delete_cart_request, error_delete_cart_request, selected_cart
  } = cartReq
  const { user, users } = auth
  const { restaurants, selected_restaurant } = restaurant
  const { order_requests } = orderReq

  return {
    loading_get_carts, carts_req, count_all_cart_requests, user, users, restaurants, selected_restaurant, selected_cart,
    loading_one_cart_request, error_get_carts,

    loading_delete_cart_request, error_delete_cart_request
  }

}
export default connect(mapStateToProps, {
  getOneRestaurant, addCart, getOneCart,
  getRestaurants, getUsers, addOrderReq
})(AddOreder);


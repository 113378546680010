import React, { PureComponent } from 'react';
import { Col, Row} from 'reactstrap';
import {notification} from 'antd'

import { connect } from 'react-redux';

import {getDiet,editDiet} from "../../../redux/actions/dietActions";
import { api } from '../../../const';
import axios from "axios"
import { FormattedMessage } from 'react-intl';
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../helper/utils";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';

class EditRegimeCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
        title: "",
        description:'',
        _id: "",
        storageLanguage : localStorage.getItem('language')

	};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }
  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Diet type modified successfully  ':'Type Régime modifieé avec sucées',
   
		})
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  checkFrm = (data) => {

    const { title } = data
    if (title=== "") return "Type regime est require";

    return ""
  }
  editRegime = () =>{
    const { title, description,_id } = this.state
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
    if(error_frm===''){
      const data={title,description}

      axios.patch(api.diet_types+'/'+_id,data).then(response=>{
        
      if (response.status=200){ 
        this.openNotificationWithIcon('success') 
        this.props.success()
        this.props.getDiet()
      }
      else{

        this.openNotificationWithIconErreur('error')
        this.props.getDiet()
      
      }
        
    })
  
  }}
  
  
  componentDidMount(){
    if(this.props.regimeType) this.setState({
      title: this.props.regimeType.title,
      description: this.props.regimeType.description,
      _id: this.props.regimeType._id,
    })

 
  }

  componentDidUpdate(prev){
    if(prev.loading_edit_regimeType && !this.props.loading_edit_regimeType && !this.props.error_edit_regimeType){
      this.props.success()
    }
  }

  handleChange = (value) => {
    const obj = {};
    obj["description"] = value;
    this.setState(obj);
  };

  render() {
 
    
    const { title,description} = this.state
    return (<Row>

      <Col md={10}>
        <div className="form">
          <div className="form__form-group">
            <span className="form__form-group-label"><FormattedMessage id="app.setting.titre" /></span>

            <div className="form__form-group-field">
              <input value={title} placeholder="Titre" onChange={(e)=>this.changeVal('title', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
                 </span>
            <div className="form__form-group-field">
            <ReactQuill style={{ width: "100%" }}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={this.handleChange}
        
                />
            </div>


           
          </div>

          <div className="form__form-group-field">
            <div onClick={this.editRegime} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-pencil"></span>
              </div>


              <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
              </div>

        </div>



      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ dietReq }) => {
  const {loading_edit_Diet, error_edit_Diet } = dietReq

  return {
    loading_edit_Diet, error_edit_Diet
  }
}
export default connect(mapStateToProps, {
    editDiet,getDiet

})(EditRegimeCmp);
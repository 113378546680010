import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';


import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import renderCheckBoxField from '../../../shared/components/form/CheckBox';

import {
  login
} from "../../../redux/actions/authActions";
import { FormattedMessage } from 'react-intl';
class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      email: '',
      password: ''
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  changeVal = (key, value)=>{
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  componentDidUpdate(prev){
   
  }

  render() {
    // const { handleSubmit } = this.props;
    const { showPassword, email, password } = this.state;
    
    if(this.props.user) return (<Redirect to={{ pathname: '/app', state: { from: this.props.location } }} />)

    
    else
    return (
      <form className="form" onSubmit={async (e)=>{
        e.preventDefault()
        if(email !=='' && password !== '' ) this.props.login({email, password})
      }}>
        <div className="form__form-group">
          <span className="form__form-group-label"> <FormattedMessage id="app.profile.email" />/<FormattedMessage id="app.profile.phone" /></span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email" value={email} onChange={(e)=>this.changeVal('email', e.target.value)}
              component="input"
              type="text"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label"><FormattedMessage id="app.profile.password" /></span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password" value={password} onChange={(e)=>this.changeVal('password', e.target.value)}
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/"><FormattedMessage id="app.profile.forgetpassword" /></a>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label={<FormattedMessage id="app.login.rememberme" />}
            />
          </div>
        </div>
        <Button className="account__btn" type="submit" color="primary"><FormattedMessage id="app.login.SignIn" /></Button>
        {/* <Link className="btn btn-outline-primary account__btn account__btn--small" to="/log_in">Create Account</Link> */}
      </form>
    );
  }
}


// LogInForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired
// };


// export default connect(state => ({
//   // errorMsg: state.user.error,
//   form: 'log_in_form',
// }))(reduxForm()(LogInForm));

const mapStateToProps = ({auth})=>{
  return {
    form: 'log_in_form',
    loading_login: auth.loading_login,
    user: auth.user
  }
}
export default connect(mapStateToProps, {
  login
})(reduxForm()(LogInForm));
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListRestaurantsCmp from '../../shared/components/restaurants/list-restaurants-cmp';
import { FormattedMessage } from 'react-intl';

const ListRestaurantsPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title"><FormattedMessage id="app.titre.Restaurant" /></h3>
      </Col>

      <Col md={12}>
        <ListRestaurantsCmp />
      </Col>
    </Row>
  </Container>
);

export default ListRestaurantsPage;
import React from 'react';
// import { Link } from 'react-router-dom';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import { FormattedMessage } from 'react-intl';

const LogIn = () => (
  <div className="account account--photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
        <span className="account__logo"> <img style={{width:170}} src={`${process.env.PUBLIC_URL}/img/Logo.svg`}  />   </span>
          {/* <h3 className="account__title"> <FormattedMessage id="app.login.welcome" /> </h3> */}

        </div>

        <LogInForm />
        
        {/* <div className="account__or">
          <p>Or Easily Using</p>
        </div>
        <div className="account__social">
          <Link
            className="account__social-btn account__social-btn--facebook"
            to="/app/one"
          ><FacebookIcon />
          </Link>
          <Link
            className="account__social-btn account__social-btn--google"
            to="/app/one"
          ><GooglePlusIcon />
          </Link>
        </div> */}
      </div>
    </div>
  </div>
);

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles

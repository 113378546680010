import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Button, Row, Col
} from 'reactstrap';
import { notification, Popconfirm, Modal, Switch, Table, Tag, Input, Space, } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getUsers } from "../../../redux/actions/authActions";
import { api } from '../../../const';
import { NavLink } from 'react-router-dom';
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';
import { getMarque_Blanche } from "../../../redux/actions/marqueblancheActions";
import axios from 'axios';
import AddMarqueBlanche from './addMarqueBlanch';
import EditMarqueBlanche from './editMarqueBlanche'
import { FormattedMessage } from 'react-intl';

class ListMarqueBlancheCmp extends PureComponent {

  state = {
    searchText: '',
    searchedColumn: '',
    current_page: 1,
    totalPage: 1,
    selected_page_size: 10,
    page_sizes: [5, 10, 20, 30, 50],
    storageLanguage: localStorage.getItem('language')

  };
  handleChange = (pagination, filters) => {

    this.setState({ filteredInfo: filters });
  };
  getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>

        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          > <FormattedMessage id="app.btn.chercher" />
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="app.btn.cancel" />
          </Button>

        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',


    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  componentDidMount() {

    this.getPage()

  }
  changerStatus = (id, isActive) => {
    if (isActive === false) {
      axios.patch(api.marque_blanche + '/activate/' + id, { "isActive": !isActive }).then(response => {
        if (response.status = 200) {
          this.openNotificationWithIcon('success')
          this.getPage()


        }
        else {

          this.openNotificationWithIconErreur('error')
          this.getPage()

        }

      })
    }
    else {
      axios.patch(api.marque_blanche + '/activate/' + id, { "isActive": !isActive }).then(response => {
        if (response.status = 200) {
          this.openNotificationWithIcon('success')
          this.getPage()


        }
        else {

          this.openNotificationWithIconErreur('error')
          this.getPage()

        }

      })
    }

  }
  suppression = (id) => {
    axios.delete(api.marque_blanche + '/' + id).then(response => {
      if (response.status = 200) {
        this.openNotificationWithIconSuppression('success')
        this.getPage()


      }
      else {

        this.openNotificationWithIconErreur('error')
        this.getPage()

      }

    })

  }

  getPage(page = 1) {
    const { selected_page_size,
    } = this.state;
    
    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    this.props.getMarque_Blanche( {offset, limit: selected_page_size});


  }
  onChangePage = page => {
    this.setState({ current_page: page }, () => this.getPage(page));
};
  openNotificationWithIcon = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'White label Successfully activated ' : 'La marque blanche est activé avec succès',

      });
  };

  openNotificationWithIconSuppression = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'White label Successfully deleted ' : 'La marque blanche est supprimer avec succès',

      });
  };
  openNotificationWithIcon = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Successful status change' : 'Changemenet du status avec succès',

      });
  };
  openNotificationWithIconErreur = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({

        duration: 3,
        message: storageLanguage === "en" ? 'Error' : 'Erreur',
        description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
      });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
 
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {

    this.setState({
      visible: false,
    });
  };





  showModalEdit = (marqueblanch) => {

    this.setState({
      visibledite: true,
      visible: false,
      selected_marqueblanch: marqueblanch
    });
  };

  handleOkEdit = e => {

    this.setState({
      visibledite: false,
    });
  };

  handleCancelEdit = e => {

    this.setState({
      visibledite: false,
    });
  };
  render() {
    const { user, marque_blanche ,count_all_marque_blanche} = this.props;
    const { storageLanguage,current_page,selected_page_size } = this.state

    const columns = [


      {
        title: <FormattedMessage id="app.marque" />,
        dataIndex: 'name',
        key: 'name',
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
        ...this.getColumnSearchProps('name'),
        render: (value, record, index) => {
          return (<div>{record.name}
          </div>)
        },
        sorter: (a, b) => a.name.localeCompare(b.name),

      },

      {

        title: <FormattedMessage id="app.restaurant" />,
        key: 'restaurant?.name',
        width: '20%',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>
            {  record.restaurant?.logo ? <img alt={record.restaurant?.name} src={`${api.root}/${record.restaurant?.logo?.path}`} style={{ width: 40 }} />
              : <img alt={record.restaurant?.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
            }
            <span> {record.restaurant?.name}</span>
          </div>)
        },
        sorter: (a, b) => a.restaurant?.name.localeCompare(b.restaurant?.name),
      },
      {
        title: <FormattedMessage id="app.marque.domaine" />,
        dataIndex: 'domaine',
        key: 'domaine',
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('domaine'),
        render: (value, record, index) => {
          return (<div>{record.domaine}
          </div>)
        },
        sorter: (a, b) => a.domaine.localeCompare(b.domaine),

      },
      {
        title: <FormattedMessage id="app.marque.propritaire" />,
        dataIndex: 'firstName',
        sortDirections: ['descend', 'ascend'],
        render: (value, record, index) => {
          return (<div>{record.owner && <NavLink to={"/app/users/edit/" + record.owner._id}>
            <span>{record.owner?.profile?.firstName + " " + record.owner?.profile?.lastName}</span>
          </NavLink>}
          </div>)
        },
        sorter: (a, b) => a.owner?.profile?.firstName.localeCompare(b.owner?.profile?.firstName),


      },
      {
        title: <FormattedMessage id="app.profile.contact" />,

        key: 'owner?.email',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>{record.owner?.email}
          </div>)
        },
        sorter: (a, b) => a.owner?.email.localeCompare(b.owner?.email),

      },
      {
        title: <FormattedMessage id="app.marque.logo" />,

        render: (value, record, index) => {
          return (<div>{record.charts?.logo ? <img alt={record.name} src={`${api.root}/${record.charts?.logo?.path}`} style={{ width: 40 }} />
            : <img alt={record.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />}
          </div>)
        }

      },
      {
        title: <FormattedMessage id="app.profile.status" />,

        key: 'status.name',


        render: (value, record, index) => {
          if (record.status.name === 'REFUSED') {
            return (<Tag color="red"><FormattedMessage id="app.refuser" /></Tag>)

          } else if (record.status.name === "ACCEPTED") {
            return (<Tag color="green"><FormattedMessage id="app.accepter" /></Tag>)

          }
          else {
            return (<Tag color="volcano"><FormattedMessage id="app.enattente" /></Tag>)

          }
        }
      },

      {
        title: <FormattedMessage id="app.profile.action" />,
        width: '15%',



        render: (value, record, index) => {
          return (<div>{record.isActive === false ?


            <Switch size='small' onChange={() => this.changerStatus(record._id, record.isActive)} style={{ fontSize: 15, fontWeight: 'bolder', color: '#dc3545' }} />
            : <Popconfirm title={storageLanguage === "en" ? "Do you want to deactivate the white mark of " + record.owner?.profile?.firstName + " " + record.owner?.profile?.lastName + ' ?' : "Voulez-vous désactiver la marque blanche de " + record.owner?.profile?.firstName + " " + record.owner?.profile?.lastName + ' ?'}
              onConfirm={() => this.changerStatus(record._id, record.isActive)} onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >

              <Switch defaultChecked size='small' color='red' />
            </Popconfirm>}
            <Popconfirm title={storageLanguage === "en" ? "Do you want to delete the white label of " + record.owner?.profile?.firstName + " " + record.owner?.profile?.lastName + ' ?' : "Voulez-vous supprimer la marque blanche de  " + record.owner?.profile?.firstName + " " + record.owner?.profile?.lastName + ' ?'} onConfirm={() => this.suppression(record._id)}
              onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
              <span className="lnr lnr-trash" style={{ color: "#dc3545", fontSize: 18, marginLeft: 25 }}></span>
            </Popconfirm>

            <NavLink to={"/app/marque-blanche/edit/" + record._id}>
              <span className="lnr lnr-pencil" style={{ color: "#70bbfd", fontSize: 18, marginLeft: 25 }}></span>

            </NavLink>
          </div>)
        }
      },
    ];

    return (<Card>
      <CardBody>
        <Row style={{ marginBottom: 10 }}>
          <Col md="8"></Col>


          {(user && user.roles.includes('ADMIN')) && <Col md="4" style={{ textAlign: 'right' }}>
            <Button color="success" className="rounded" onClick={this.showModal}><FormattedMessage id="app.btn.addmarqueblanch" /></Button>
          </Col>}
        </Row>
        <div>        <Table rowKey={(record, index) => index} scroll={{ x: 1000 }}
          dataSource={marque_blanche} columns={columns} bordered pagination={true}
          style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} /></div>
             <Pagination currentPage={current_page}
          totalPage={getCountPages(count_all_marque_blanche, selected_page_size)}
          onChangePage={i => this.onChangePage(i)} />
        <Modal

          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1000}
          footer={null}

        >
          <AddMarqueBlanche Edit={this.showModalEdit} />

        </Modal>

        <Modal

          visible={this.state.visibledite}
          onOk={this.handleOkEdit}
          onCancel={this.handleCancelEdit}
          width={1000}
          footer={null}

        >
          <EditMarqueBlanche cancel={this.handleCancelEdit} selected_marqueblanch={this.state.selected_marqueblanch} />

        </Modal>  ,

            </CardBody>


    </Card>)

      ;
  }
}

const mapStateToProps = ({ auth, marqueblanche }) => {

  const { user, users } = auth
  const { loading_get_marqueblanche, error_get_marqueblanche, marque_blanche ,count_all_marque_blanche} = marqueblanche



  return {
    user, users,
    loading_get_marqueblanche, error_get_marqueblanche, marque_blanche,count_all_marque_blanche
  }
}
export default connect(mapStateToProps, {
  getUsers, getMarque_Blanche

})(ListMarqueBlancheCmp);

import {
    GET_VEGAN, GET_VEGAN_SUCCESS, GET_VEGAN_ERROR,
    ADD_VEGAN, ADD_VEGAN_SUCCESS, ADD_VEGAN_ERROR,
    // GET_ONE_VEGAN, GET_ONE_VEGAN_SUCCESS, GET_ONE_VEGAN_ERROR,
  EDIT_VEGAN, EDIT_VEGAN_SUCCESS, EDIT_VEGAN_ERROR
  } from '../actions/VEGANActions';
    const initialState = {
    loading_get_VEGAN_types: false,
    error_get_VEGAN_types: null,
    VEGAN_types: [],
    // count_all_VEGAN_types: 0,
  
    new_VEGAN: null, loading_add_VEGAN: false, error_add_VEGAN: null,
  };
  
  const VEGAN_typesReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_VEGAN: return {...state, loading_get_VEGAN_types: true, error_get_VEGAN_types: null };
      case GET_VEGAN_SUCCESS:

       
          return {...state, VEGAN_types: [ ...action.payload.vegan], loading_get_VEGAN_types: false, error_get_VEGAN_types: null };
      
        case GET_VEGAN_ERROR:

        return {...state, error_get_VEGAN_types: action.error, loading_get_VEGAN_types: false, VEGAN_types: [] };
  
      case ADD_VEGAN: return {...state, loading_add_VEGAN: true, error_add_VEGAN: null }
     
      case ADD_VEGAN_SUCCESS:
        return {...state, new_VEGAN: action.payload.VEGAN, loading_add_VEGAN: false, error_add_VEGAN: null };
      case ADD_VEGAN_ERROR:
        return {...state, error_add_VEGAN: action.error, loading_add_VEGAN: false, new_VEGAN: null };

     

        case EDIT_VEGAN: return {...state, loading_edit_VEGAN: true, error_edit_VEGAN: null };
        case EDIT_VEGAN_SUCCESS:
          return {...state, loading_edit_VEGAN: false, error_edit_VEGAN: null };
        case EDIT_VEGAN_ERROR:
          return {...state, error_edit_VEGAN: action.error, loading_edit_VEGAN: false}; 
          
  
      // case GET_ONE_VEGAN: return {...state, loading_one_VEGAN: true, error_one_VEGAN: null };
      // case GET_ONE_VEGAN_SUCCESS:
      //   return {...state, selected_VEGAN: action.payload.VEGAN, loading_add_VEGAN: false, error_one_VEGAN: null };
      // case GET_ONE_VEGAN_ERROR:
      //   return {...state, error_one_VEGAN: action.error, loading_one_VEGAN: false, selected_VEGAN: null };
  
      default: return state;
    }
  };
  
  export default VEGAN_typesReducer;
  
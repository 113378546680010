
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_FEATURES, getFeaturesSuccess , getFeaturesError , 
  ADD_FEATURE, addFeatureSuccess,addFeatureError,

  EDIT_FEATURE, editFeatureError, editFeatureSuccess,
  DELETE_FEATURE, deleteFeatureError, deleteFeatureSuccess
} from '../actions/feautreActions';
import { api } from '../../const';



// *** GET FEATURES
export function* watchGetFeatures() { yield takeEvery(GET_FEATURES, getFeatures) }
function* getFeatures({ payload }) {
  try {
    const ret = yield call(getFeaturesRequest, payload);
    if (ret && ret.status === 200) yield put(getFeaturesSuccess ({ features: ret.data?.feature|| [], count: ret.data?.count || 0}));
    else yield put(getFeaturesError ({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getFeaturesError ({ error, message: (error) ? error.message : '' }));
  }
}
const getFeaturesRequest = async (payload) => {  
  return await axios.get(api.Feature)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD FEATURES
export function* watchAddFeature() { yield takeEvery(ADD_FEATURE, addFeature) }
function* addFeature({ payload }) {
  try {

   
    const ret = yield call(addFeaturesRequest, payload);

    if (ret && ret.status === 200) yield put(addFeatureSuccess({ features: ret.data }));
    else yield put(addFeatureError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addFeatureError({ error, message: (error) ? error.message : '' }));
  }
}
const addFeaturesRequest = async (payload) => {  

  return await axios.post(api.Feature, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** EDIT FEATURES
export function* watchEditFeature() { yield takeEvery(EDIT_FEATURE, editFeature) }
function* editFeature({ payload }) {
  try {
    const ret = yield call(editFeatureRequest, payload);

    if (ret && ret.status === 200) yield put(editFeatureSuccess({ bloc: ret.data }));
    else yield put(editFeatureError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editFeatureError({ error, message: (error) ? error.message : '' }));
  }
}
const editFeatureRequest = async (payload) => {  
  return await axios.patch(api.Feature+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};



// *** DELETE FEATURES
export function* watchDeleteFeature() { yield takeEvery(DELETE_FEATURE, deleteFeature) }
function* deleteFeature({ payload }) {
  try {
    const ret = yield call(deleteFeatureRequest, payload);

    if (ret && ret.status === 204) yield put(deleteFeatureSuccess({ bloc: ret.data }));
    else yield put(deleteFeatureError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteFeatureSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteFeatureRequest = async (payload) => {  
  return await axios.delete(api.Feature+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};



export default function* rootSaga() {
    yield all([
      fork(watchGetFeatures),
      fork(watchAddFeature),
      fork(watchEditFeature),
     fork(watchDeleteFeature),
    //   fork(watchEditBloc)
    ]);
  }
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';

import { getCarts } from "../../../redux/actions/carteActions";
import { getUsers } from "../../../redux/actions/authActions";
import { FormattedMessage } from 'react-intl';
import { api } from '../../../const';
import axios from 'axios'


class DetailCmp extends PureComponent {

    constructor() {
        super();
        this.state = {
            commande: [],

            current_page: 1,
            totalPage: 1,
            selected_page_size: 10,
            page_sizes: [5, 10, 20, 30, 50],

            show_delete_msg: false,
            req_delete_id: null,
            searchResto: '',
            filteredInfo: null,
        };
    }
    componentDidMount = () => {
        let Id = this.props.Carte_id
        axios.get(api.cart + '/' + Id).then(resp => {
            this.setState({ commande: resp.data?.cart })

        })

    }
    render() {

        const { commande } = this.state

        let plats = commande?.items?.filter(item => item.type === "Plate")
        let listplats = []
        let Qts = ""
        let TypePlat=''
      
        for (var i = 0; i < plats?.length; i++) {
            TypePlat=<FormattedMessage id="app.plats" /> 
            listplats.push(plats[i]?.item)
            Qts = plats.length
           

        }
   
        let menus = commande?.items?.filter(item => item.type === "Menu")
        let listmenus = []
        let typeMenu=''
        let QtsMenu = ""
        for (var i = 0; i < menus?.length; i++) {
            listmenus.push(menus[i]?.item)
            QtsMenu =listmenus.length
          typeMenu=<FormattedMessage id="app.menus" />
         
        }

        return (<Card>
            <CardBody>
                <div className="card__title">
                </div>
                <Row>
                    <Col md={12} style={{ marginTop: 30 }}>
                        <Table bordered hover><thead><tr><th></th>
                                      <th><FormattedMessage id="app.restaurant" /></th>
                                    <th><FormattedMessage id="app.profile.address" /></th>
                                    <th><FormattedMessage id="app.order" /></th>
                                    <th><FormattedMessage id="app.order.quantitécommande" /></th>
                                    <th><FormattedMessage id="app.order.prixcommande" /></th>
                                    <th><FormattedMessage id="app.order.fraislivreur" /></th>
                                    <th><FormattedMessage id="app.order.fraislivreurSupp" /></th>

                                </tr>
                            </thead>
                            <tbody><tr><td style={{ verticalAlign: 'middle' }}> {commande?.restaurant?.logo ? <img src={`${api.root}/${commande?.restaurant?.logo?.path}`} style={{ width: 40 }} />
                                                : <img src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} /> }</td>
                                    <td style={{ verticalAlign: 'middle' }}> {commande?.restaurant?.name}</td>
                                    <td style={{ verticalAlign: 'middle', marginLeft: 25 }}>{commande?.restaurant?.address?.fullAddress !== null ? commande?.restaurant?.address?.fullAddress : commande?.restaurant?.address?.street}</td>
                          
                                    <td style={{ verticalAlign: 'middle' }}>{TypePlat}{typeMenu}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{commande?.totalQty}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{commande?.items?.map((item, i) => item.itemsPrice?.toFixed(2))}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{commande?.restaurant?.deliveryFee}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{commande?.restaurant?.deliverySupplyFee}</td> </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <br />


                    <Col md={6} style={{ marginTop: 30 }}>
                        <h5 className="bold-text text-danger ">{QtsMenu}  <FormattedMessage id="app.menus" /> </h5>
                        <Table bordered hover><thead><tr><th></th>
                                    <th><FormattedMessage id="app.menu" /> </th>
                                    <th><FormattedMessage id="app.setting.description" /> </th>
                                    <th><FormattedMessage id="app.type" /> </th>
                                    <th><FormattedMessage id="app.menu.prix" /></th>
                                    <th><FormattedMessage id="app.prix.promo" /> </th> </tr>
                            </thead>
                            <tbody>{listmenus.map((menu, i) => (<tr key={'item' + i}>
                                <td style={{ verticalAlign: 'middle' }}> {menu?.picture ? <img src={`${api.root}/${menu?.picture.path}`} style={{ width: 40 }} />
                                                : <img src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} /> }</td>
                                    <td style={{ verticalAlign: 'middle' }}>{menu?.name}</td>
                                    <td style={{ verticalAlign: 'middle', width: '200px' }}>{menu?.description}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{menu?.type}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{menu?.price?.price}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{menu?.price?.pricePromo}</td>
                                </tr>))}</tbody>
                        </Table>
                    </Col>
                    <Col md={6} style={{ marginTop: 30 }}>
                        <h5 className="bold-text text-danger ">{Qts} <FormattedMessage id="app.plats" /> </h5>


                        <Table bordered hover><thead><tr><th></th>
                                    <th></th>
                                    <th><FormattedMessage id="Plat" /></th>
                                    <th> <FormattedMessage id="app.setting.description" /></th>
                                    <th><FormattedMessage id="app.type" /> </th>
                                    <th><FormattedMessage id="app.prix.plat" /></th>
                                    <th><FormattedMessage id="app.prix.promo" /> </th></tr>
                            </thead>
                            <tbody>{listplats?.map((plats, i) => (<tr key={'plats' + i}>
                                    <td style={{ verticalAlign: 'middle' }}> { plats?.picture ? <img src={`${api.root}/${plats?.picture.path}`} style={{ width: 40 }} />
                                                : <img src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} /> }</td>
                                    <td style={{ verticalAlign: 'middle' }}>{plats?.name}</td>
                                    <td style={{ verticalAlign: 'middle', width: '200px' }}>{plats?.description}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{plats?.type}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{plats?.price?.price}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{plats?.price?.pricePromo}</td>
                                </tr>))}</tbody>

                        </Table>
                    </Col>

                </Row>


            </CardBody>

        </Card>);
    }
}

const mapStateToProps = ({ cartReq, restaurant, auth }) => {
    const { loading_get_carts, carts_req, count_all_cart_requests,
        loading_one_cart_request, error_get_carts,
        loading_delete_cart_request, error_delete_cart_request
    } = cartReq

    const { user, users } = auth
    const { restaurants } = restaurant
    return {
        loading_get_carts, carts_req, count_all_cart_requests, user, users,
        loading_one_cart_request, error_get_carts, restaurants,

        loading_delete_cart_request, error_delete_cart_request
    }
}
export default connect(mapStateToProps, {
    getCarts, getUsers
})(DetailCmp);

import React, { PureComponent } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import { api } from '../../../const';
import axios from "axios"
import {notification } from 'antd';
import { getBlocs,addBloc } from "../../../redux/actions/blocsActions";
import { FormattedMessage } from 'react-intl';
import { quillFormats, quillModules } from "../../helper/utils";

import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';

class AddBlocCmp extends PureComponent {
  constructor() {
    super();
    this.state = {
      title: "",
      description: "",
      picture: null,
      picture_url: "",
      storageLanguage : localStorage.getItem('language')
    };
  }

  changeVal = (key, value) => {
    const obj = {};
    obj[key] = value;
    this.setState(obj);
  };

  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Block add successfully  ':'Bloc ajouter avec sucées',
   
		})
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  addBloc = () => {
    const { title, description, picture, picture_url } = this.state;

    if (title !== "") {
      const frm = new FormData()
      if(picture && picture_url!=='') frm.append('picture', picture)
      frm.append('title', title)
      frm.append('description', description)
  
      axios.post(api.blocs,frm).then(response=>{

        if (response.status=200){ 
          this.openNotificationWithIcon('success') 
          this.props.success()
          this.props.getBlocs()
        }
        else{

          this.openNotificationWithIconErreur('error')
 
          this.props.getBlocs()
        } 
    })
    }
  };

  componentDidUpdate(prev) {
    if (
      prev.loading_add_bloc &&
      !this.props.loading_add_bloc &&
      !this.props.error_add_bloc
    ) {
      this.props.success();
    }
  }

  changeImg = (e) => {
    try {
      const picture = e.target.files[0];
      this.setState({ picture, picture_url: URL.createObjectURL(picture) });
    } catch (error) {
      this.setState({ picture: null, picture_url: "" });
    }
  };
  handleChange = (value) => {
    const obj = {};
    obj["description"] = value;
    this.setState(obj);
  };

  render() {
    const { title, picture_url, description } = this.state;

    return (
      <Row>

{/* <Col md="2">
      <div className="add_img_wrap">
      <img
            alt={title}
              src={
                picture_url === ""
                  ? ` ${process.env.PUBLIC_URL}/img/no.png`
                  : picture_url
              }
            />

        <div className="caption">
          <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
          <input onChange={(e)=>this.changeImg(e)} id="add_avatar" type="file" accept="image/*" hidden />
        </div>
      </div>
    </Col> */}
        <Col md={10}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label"> <FormattedMessage id="app.setting.titre"/></span>

            <div className="form__form-group-field">
              <input value={title} placeholder="Titre" onChange={(e)=>this.changeVal('title', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description"/>
                 </span>
            <div className="form__form-group-field">


            <ReactQuill style={{ width: "100%" }}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={this.handleChange}
        
                />
            
             {/* <textarea value={description} placeholder="Description" onChange={(e)=>this.changeVal('description', e.target.value)} />  */}
            </div>


          </div>
          <div className="form__form-group-field" style={{marginTop:30}}>
            <div onClick={()=>this.addBloc()} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-plus-circle"></span>
              </div>


              <div onClick={(this.props.cancelAdding) ? this.props.cancelAdding : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
            </div>
        </div>
      </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ bloc }) => {
  const { loading_add_bloc, error_add_bloc } = bloc;
  return {
    loading_add_bloc,
    error_add_bloc,
  };
};
export default connect(mapStateToProps, {getBlocs,
  addBloc,
})(AddBlocCmp);

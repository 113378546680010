import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import {notification} from 'antd'

import { connect } from 'react-redux';

import {editRestaurantType,getRestauranTypes} from "../../../redux/actions/RestauranTypesActions";
import { api } from '../../../const';
import axios from "axios"
import { FormattedMessage } from 'react-intl';

class EditRestautTypeCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
        title: "",
        description:'',
        _id: "",
        storageLanguage : localStorage.getItem('language')

	};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }
  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'Type Restaurant modified successfully  ':'Type Réstaurant modifier avec sucées',
   
		})

  
  };
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  checkFrm = (data) => {

    const { title } = data
    if (title=== "") return "Type restaurant est require";

    return ""
  }
  editRestaurant = () =>{
    const { title, description,_id } = this.state
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
    if(error_frm===''){
      const data={title,description}
      axios.patch(api.restaurant_types+'/'+_id,data).then(response=>{
      if (response.status=200){ 
        this.openNotificationWithIcon('success') 
        this.props.success()
        this.props.getRestauranTypes()
      }
      else{

        this.openNotificationWithIconErreur('error')

      
      }
        
    })
  
  }}
  
  
  componentDidMount(){
    if(this.props.restauType) this.setState({
      title: this.props.restauType.title,
      description: this.props.restauType.description,
      _id: this.props.restauType._id,
    })

 
  }

  componentDidUpdate(prev){
    if(prev.loading_edit_restauType && !this.props.loading_edit_restauType && !this.props.error_edit_restauType){
      this.props.success()
    }
  }


  render() {
 
    
    const { title,description } = this.state
    return (<Row>

      <Col md={10}>
        <div className="form">
          <div className="form__form-group">
            <span className="form__form-group-label"><FormattedMessage id="app.setting.titre" /></span>

            <div className="form__form-group-field">
              <input value={title} placeholder="Titre" onChange={(e)=>this.changeVal('title', e.target.value)} />
            </div>

            <span className="form__form-group-label">  <FormattedMessage id="app.setting.description" />
                 </span>
            <div className="form__form-group-field">
              <textarea value={description} placeholder="Description" onChange={(e)=>this.changeVal('description', e.target.value)} />
            </div>


            <div className="form__form-group-field">
            <div onClick={this.editRestaurant} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                <span className="lnr lnr-pencil"></span>
              </div>


              <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : ()=>{}} className="form__form-group-icon" style={{background: "#F44336", color: "#fff", border: "red", cursor: 'pointer'}}>
                <span className="lnr lnr-cross-circle"></span>
              </div>
              </div>
          </div>



        </div>



      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ restauranType }) => {
  const { loading_edit_reservation_request, error_edit_reservation_request } = restauranType

  return {
    loading_edit_reservation_request, error_edit_reservation_request
  }
}
export default connect(mapStateToProps, {
    editRestaurantType,getRestauranTypes

})(EditRestautTypeCmp);
/* eslint-disable one-var */
export const AUTHENTICATE = "AUTHENTICATE";
export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR";
export const LOGOUT = "LOGOUT";

// ***** AUTH
export const login = (payload) => ({
    payload,
    "type": AUTHENTICATE
});

export const loginSuccess = (payload) => ({
    payload,
    "type": AUTHENTICATE_SUCCESS
});


export const loginError = (error) => ({
    error,
    "type": AUTHENTICATE_ERROR
});

export const logout = () => ({"type": LOGOUT});

// ***** GET USERS
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const getUsers = (payload) => ({
    payload,
    "type": GET_USERS
});

export const getUsersSuccess = (payload) => ({
    payload,
    "type": GET_USERS_SUCCESS
});

export const getUsersError = (error) => ({
    error,
    "type": GET_USERS_ERROR
});

// ***** ADD USER
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const addUser = (payload) => ({
    payload,
    "type": ADD_USER
});

export const addUserSuccess = (payload) => ({
    payload,
    "type": ADD_USER_SUCCESS
});

export const addUserError = (error) => ({
    error,
    "type": ADD_USER_ERROR
});

// ***** EDIT USER
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const editUser = (payload) => ({
    payload,
    "type": EDIT_USER

});


export const editUserSuccess = (payload) => ({
    payload,
    "type": EDIT_USER_SUCCESS
});

export const editUserError = (error) => ({
    error,
    "type": EDIT_USER_ERROR
});

// ***** EDIT USER BY USERS
export const EDIT_USER_BY_ADMIN = "EDIT_USER_BY_ADMIN";
export const EDIT_USER_BY_ADMIN_SUCCESS = "EDIT_USER_BY_ADMIN_SUCCESS";
export const EDIT_USER_BY_ADMIN_ERROR= "EDIT_USER_BY_ADMIN_ERROR";

export const editUserByAdmin = (payload) => ({
    payload,
    "type": EDIT_USER_BY_ADMIN 

});


export const editUserByAdminSuccess = (payload) => ({
    payload,
    "type": EDIT_USER_BY_ADMIN_SUCCESS
});

export const editUserByAdminError = (error) => ({
    error,
    "type": EDIT_USER_BY_ADMIN_ERROR
});


// ***** GET PROFILE
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const getProfile = (payload) => ({
    payload,
    "type": GET_PROFILE
});


export const getProfileSuccess = (payload) => ({
    payload,
    "type": GET_PROFILE_SUCCESS
});


export const getProfileError = (error) => ({
    error,
    "type": GET_PROFILE_ERROR
});

export const SOCKET_CONNECT = "SOCKET_CONNECT";


export const socketConnect = (payload = null) => ({
    payload,
    "type": SOCKET_CONNECT
});


import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_RESTAURANT_TYPES, getRestauranTypesSuccess, getRestauranTypesError, 
  ADD_RESTAURANT_TYPE, addRestauranTypeError, addRestauranTypeSuccess, 
  // GET_ONE_Restaurant_TYPE, getOneRestauranTypeSuccess, getOneRestauranTypeError, 
 EDIT_RESTAURANT_TYPE, editRestaurantTypeError, editRestaurantTypeSuccess,
 
 DELETE_RESTAURANT_TYPE, deleteRestautTypeError, deleteRestautTypeSuccess
} from '../actions/RestauranTypesActions';
import { api } from '../../const';

// *** GET RESTAURANT_TYPES
export function* watchGetRestauranTypes() { yield takeEvery(GET_RESTAURANT_TYPES, getRestauranTypes) }
function* getRestauranTypes({ payload }) {
  try {
    const ret = yield call(getRestauranTypesRequest, payload);

    if (ret && ret.status === 200) yield put(getRestauranTypesSuccess({ restaurant_types: ret.data.typeResto || [], count: ret.data?.count || 0}));
    else yield put(getRestauranTypesError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getRestauranTypesError({ error, message: (error) ? error.message : '' }));
  }
}
const getRestauranTypesRequest = async (payload) => {  
  return await axios.get(api.restaurant_types)

  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};


// *** ADD RESTAURANT_TYPES
export function* watchaddRestauranType() { yield takeEvery(ADD_RESTAURANT_TYPE, addRestauranType) }
function* addRestauranType({ payload }) {
  try {
    const ret = yield call(addRestauranTypesRequest, payload);
    if (ret && ret.status === 200) yield put(addRestauranTypeSuccess({ restaurant_type: ret.data }));
    else yield put(addRestauranTypeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addRestauranTypeError({ error, message: (error) ? error.message : '' }));
  }
}
const addRestauranTypesRequest = async (payload) => {  
  return await axios.post(api.restaurant_types, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
  
};


// *** EDIT RESTAURANT_TYPES
export function* watchEditRestauranType() { yield takeEvery(EDIT_RESTAURANT_TYPE, editRestaurantType) }
function* editRestaurantType({ payload }) {
 try {
    const ret = yield call(editRestaurantTypeRequest, payload);

    if (ret && ret.status === 200) yield put(editRestaurantTypeSuccess({ restaurant_type: ret.data }));
   else yield put(editRestaurantTypeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
   yield put(editRestaurantTypeError({ error, message: (error) ? error.message : '' }));
 }
}
const editRestaurantTypeRequest = async (payload) => {  

 return await axios.patch(api.restaurant_types+'/'+payload._id,payload)
.then(resp => resp)
 .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE RESTAURANT_TYPES
export function* watchDeleteRestauranType() { yield takeEvery(DELETE_RESTAURANT_TYPE,deleteRestautType) }
function* deleteRestautType({ payload }) {
 try {
   const ret = yield call(deleteRestautTypeRequest, payload);

   if (ret && ret.status === 204) yield put(deleteRestautTypeSuccess({ restaurant_type: ret.data }));
    else yield put(deleteRestautTypeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteRestautTypeSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteRestautTypeRequest = async (payload) => {  
  return await axios.delete(api.restaurant_types+'/'+payload._id, payload.data)
 .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};

export default function* rootSaga() {
    yield all([
      fork(watchGetRestauranTypes),
      fork(watchaddRestauranType),
     fork(watchDeleteRestauranType),
     fork(watchEditRestauranType)
    ]);
  }
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListRestaurantsPage from './list';
import EditRestaurantPage from './edit';
import AddRestaurantPage from './add';

const RestaurantsPage = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

        <Route path={`${match.url}/list`}
          render={props => <ListRestaurantsPage {...props} />}
        />

        <Route path={`${match.url}/add`}
          render={props => <AddRestaurantPage {...props} />}
        />

        <Route path={`${match.url}/edit/:restaurant_id`}
          render={props => <EditRestaurantPage {...props} />}
        />

        <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
        <Redirect to="/error" />
      </Switch>
    
  </Suspense>
);
export default RestaurantsPage;

import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Container, Row, Button, Badge, Table } from 'reactstrap';
import { Popconfirm, notification, Tabs } from 'antd';

import AddVTypeCmp from "../../shared/components/settings/add-ktype";
import AddBlocCmp from "../../shared/components/settings/AddBlocCmp";
import EditVTypeCmp from "../../shared/components/settings/edit-ktype";


import AddDressCodeCmp from "../../shared/components/settings/add-dresscode";
import EditDressCodeCmp from "../../shared/components/settings/edit-dresscode";
import EditBlocCmp from "../../shared/components/settings/edit-bloc";

import AddRestauTypeCmp from "../../shared/components/settings/add-Restautype";
import EditRestautTypeCmp from "../../shared/components/settings/editRestautype"

import AddregimeTypeCmp from "../../shared/components/settings/add-Regime";
import EditRegimeCmp from "../../shared/components/settings/edit-regime"


import AddVeganCategoryCmp from "../../shared/components/settings/add_vegancategory";
import EditVeganCategoryCmp from "../../shared/components/settings/edit_vegancategory"
import AddfeatureCmp from "../../shared/components/settings/add_feature";
import EditfeatureCmp from "../../shared/components/settings/edit_feature"

import { connect } from 'react-redux';

import { getKitchenTypes, editKitchenType, deleteKitchenType } from "../../redux/actions/kitchenTypesActions";

import { getDressCodes, editDressCode, deleteDressCode } from "../../redux/actions/dressCodeActions";

import { getBlocs, editBloc, deleteBloc } from "../../redux/actions/blocsActions";
import { getVEGAN } from "../../redux/actions/VEGANActions"

import { getRestauranTypes } from "../../redux/actions/RestauranTypesActions";
import { getDiet } from "../../redux/actions/dietActions";

import { getFeatures, deleteFeature } from "../../redux/actions/feautreActions"
import CollapseCmp from "../../shared/components/Collapse";
import { api } from '../../const';
import axios from "axios";
import { FormattedMessage } from 'react-intl';

const { TabPane } = Tabs;
function callback(key) {

}
const locale = navigator.language;

class SettingsPage extends PureComponent {
  constructor() {
    super();
    this.state = {

      show_add_ktype: false,
      show_edit_ktype: false,

      kType: null,
      selected_kType: null,
      show_delete_msg: false,

      dressCode: null,
      show_add_dressCode: false,
      show_edit_dressCode: false,
      dressCode_delete: null,


      bloc: null,
      show_add_bloc: false,
      show_edit_bloc: false,
      bloc_delete: null,

      restauType: null,
      show_add_restauType: false,
      show_edit_restauType: false,
      selected_restauType: null,


      regimeType: null,
      show_add_regimeType: false,
      show_edit_regimeType: false,
      selected_regimeType: null,

      show_add_vegancategory: false,
      show_edit_vegancategory: false,
      selected_vegancategory: null,
      storageLanguage: localStorage.getItem('language'),

      feature: null,
      show_add_feature: false,
      show_edit_feature: false,
      feature_delete: null,


    };

  }

  componentDidMount() {
    this.getPage({})


  }
  getPage(page = 1) {

    this.props.getKitchenTypes({})
    this.props.getDressCodes()
    this.props.getBlocs()
    this.props.getRestauranTypes()
    this.props.getDiet()
    this.props.getVEGAN()
    this.props.getFeatures()
    this.props.deleteFeature()

  }
  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }




  deleteDressCode = () => {
    const { dressCode_delete } = this.state

    if (dressCode_delete)
      this.props.deleteDressCode({ _id: dressCode_delete._id })

    this.setState({ show_delete_msg: false })
  }
  openNotificationWithIcon = type => {
    const { storageLanguage } = this.state
    notification[type]


      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Successfully deleted' : 'Supprimé avec Succès',

      });;
  };
  openNotificationWithIconErreur = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Error' : 'Erreur',
        description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
      });
  };
  Delete = (ID) => {



    axios.delete(api.kitchen_types + '/' + ID).then(response => {

      if ((response.status = 200) || (response.status = 204)) {
        this.openNotificationWithIcon('success')
        this.getPage()
      }
      else {
        this.openNotificationWithIconErreur('error')
        this.getPage()

      }

    }

    )
  }
  DeleteRestau = (ID) => {

    {
      axios.delete(api.restaurant_types + '/' + ID).then(response => {

        if (response.status = 200) {
          this.openNotificationWithIcon('success')
          this.props.getRestauranTypes()
        }
        else {
          this.openNotificationWithIconErreur('error')
          this.props.getRestauranTypes()
        }
      })
    }
  }
  DeleteRegime = (Id) => {

    {
      axios.delete(api.diet_types + '/' + Id).then(response => {

        if (response.status === 200) {
          this.openNotificationWithIcon('success')
          this.props.getDiet()
        }
        else {
          this.openNotificationWithIconErreur('error')
          this.props.getDiet()
        }
      }
      )
    }
  }

  DeleteVegan = (Id) => {
    axios.delete(api.vegan + '/' + Id).then(response => {

      if (response.status === 200 || response.status === 204) {
        this.openNotificationWithIcon('success')
        this.props.getVEGAN()
      }
      else {
        this.openNotificationWithIconErreur('error')
        this.props.getVEGAN()
      }
    }
    )
  }
  DeleteBloc = (Id) => {
    {
      axios.delete(api.blocs + '/' + Id).then(response => {

        if (response.status === 200) {
          this.openNotificationWithIcon('success')
          this.props.getBlocs()
        }
        else {
          this.openNotificationWithIconErreur('error')
          this.props.getBlocs()
        }
      }
      )
    }

  }

  DeleteDressCode = (Id) => {

    {
      axios.delete(api.dress_codes + '/' + Id).then(response => {

        if (response.status = 200) {
          this.openNotificationWithIcon('success')
          this.props.getDressCodes()
        }
        else {
          this.openNotificationWithIconErreur('error')
          this.props.getDressCodes()
        }
      }
      )
    }
  }
  DeleteFeature = (Id) => {

    {
      axios.delete(api.Feature + '/' + Id).then(response => {

        if (response.status = 200) {
          this.openNotificationWithIcon('success')
          this.props.getFeatures()
        }
        else {
          this.openNotificationWithIconErreur('error')
          this.props.getFeatures()
        }
      }
      )
    }
  }


  componentDidUpdate(prev) {
    if (prev.loading_edit_kitchen_type && !this.props.loading_edit_kitchen_type && !this.props.error_edit_kitchen_type) {
      this.getPage()
    }

    if (prev.loading_edit_dress_code && !this.props.loading_edit_dress_code && !this.props.error_edit_dress_code) {
      this.getPage()
    }

    if (prev.loading_edit_bloc && !this.props.loading_edit_bloc && !this.props.error_edit_bloc) {
      this.getPage()
    }



  }

  render() {
    const {
      show_add_ktype,
      show_edit_ktype,
      kType,
      selected_kType,
      show_delete_msg,

      show_add_dressCode,
      show_edit_dressCode,
      selected_dressCode,
      dressCode,

      show_add_bloc,
      show_edit_bloc,
      selected_bloc,
      bloc,

      show_add_restauType,
      show_edit_restauType,
      selected_restauType,
      restauType,

      show_add_regimeType,
      show_edit_regimeType,
      selected_regimeType,
      storageLanguage,

      show_add_vegancategory,
      show_edit_vegancategory,
      selected_vegancategory,

      show_add_feature,
      show_edit_feature,
      selected_feature,



    } = this.state

    const { kitchen_types, dress_codes, blocs, restaurant_types, diet_types, veganReq, features } = this.props




    return (<Container>
    
      <Row>
        <Col md={12}>
          <h3 className="page-title"><FormattedMessage id="app.settings" /></h3>
        </Col>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <div className="profile__card tabs tabs--bordered-bottom">
              <div className="card-container">
                <Tabs defaultActiveKey="1" onChange={callback} type="card">
                  <TabPane tab={<FormattedMessage id="app.cuisine" />} key="1">
                    <Col md={12} lg={12}>
                   
                      <Card>
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text"><FormattedMessage id="app.cuisine" /></h5>
                            {/* <h5 className="subhead">Make <span className="red-text">prices</span> for each Vehicle Type</h5> */}
                          </div>

                          <Button color="success" style={{ float: 'right', marginTop: -75 }}
                            onClick={() => this.setState({ show_add_ktype: true })}>
                            {/* <FormattedMessage id="app.btn.addnewtypecuisine" /> */}

                            <span className="lnr lnr-plus-circle"></span>   <FormattedMessage id="app.btn.submit" /> </Button>



                          {/* ADD VTYPE */}
                          {show_add_ktype && <Col md="12">
                            <AddVTypeCmp
                              cancelAdding={() => this.setState({ show_add_ktype: false })}
                              success={() => {
                                this.getPage()
                                this.setState({ show_add_ktype: false })
                              }}
                            />
                          </Col>}

                          {kitchen_types && kitchen_types.map((kType, i) => <CollapseCmp key={"ktype_" + i} title={kType.name}
                            icons={true}
                            // is_open={(kType._id!==null)}
                            className="with-shadow">
                            <div>
                              <Row>
                                <Col md="3">
                                  {(kType && !show_edit_ktype && kType.picture) && <img style={{ width: '75%' }} src={kType.picture ? `${api.root}/${kType.picture.path}` : ` ${process.env.PUBLIC_URL}/img/no.png`} />}
                                </Col>
                                <Col md="6">
                                  <p>{kType.description}</p>
                                </Col>
                                <Col md="3" style={{ textAlign: 'right' }} >
                                  <Button color="secondary" outline size="sm" className="rounded ml-1"
                                    onClick={() => this.setState({
                                      show_edit_ktype: true, selected_kType: kType,
                                    })}>
                                    <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                    {/* <FormattedMessage id="app.btn.edit" /> */}
                                  </Button>
                               
                                  <Button color="danger" size="sm" className="rounded">


                                    <Popconfirm title={storageLanguage === "en" ? "Do you want to delete " + kType.name + ' ?' : "Voulez-vous supprimer  " + kType.name + ' ?'} onConfirm={() => this.Delete(kType._id)}
                                      okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
                                      <span className="lnr lnr-trash"></span>
                                      {/* <FormattedMessage id="app.btn.delet" /> */}
                                    </Popconfirm>



                                  </Button>

                                </Col>

                                {show_edit_ktype && <Col md="12">
                                <EditVTypeCmp selected_kType={selected_kType}
                                  cancelEditing={() => this.setState({ show_edit_ktype: false })}
                                  success={() => {
                                    this.getPage({})
                                    this.setState({ show_edit_ktype: false, kType: null })
                                  }}

                                  deleteKType={(kType_delete) => {
                                    this.setState({ kType_delete }, () => this.deleteKType())

                                  }}
                                />
                              </Col>}

                              </Row>


                             

                            </div>
                          </CollapseCmp>)}
                    
                        </CardBody>
                      </Card>
                    </Col>
                  </TabPane>
                  <TabPane tab={<FormattedMessage id="app.restaurant.type" />} key="2">

                    <Col md={12} lg={12}>
                      <Card>
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text"><FormattedMessage id="app.restaurant.type" /></h5>

                          </div>

                          <Button color="success" style={{ float: 'right', marginTop: -75 }}
                            onClick={() => this.setState({ show_add_restauType: true })}>
                            {/* <FormattedMessage id="app.btn.addnewtyperestaurant" /> */}
                            <span className="lnr lnr-plus-circle"></span>   <FormattedMessage id="app.btn.submit" /> </Button>


                          {/* ADD reegimeType */}
                          {show_add_restauType && <Col md="12">
                            <AddRestauTypeCmp
                              cancelAdding={() => this.setState({ show_add_restauType: false })}
                              success={() => {
                                this.props.getRestauranTypes()
                                this.setState({ show_add_restauType: false, restauType: null })
                              }}
                            />
                          </Col>}

                          {restaurant_types && restaurant_types.map((restauType, i) => <CollapseCmp key={"restauType" + i} title={restauType.title}

                            icons={true}
                            //  is_open={(restauType._id!==null)} 
                            className="with-shadow"
                          >

                            <div>
                              <Row>

                                <Col md="9">
                                  <p>{restauType.description}</p>
                                </Col>
                                <Col md="3" style={{ textAlign: 'right' }} >
                                  <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.setState({
                                    show_edit_restauType: true, selected_restauType: restauType,
                                  })}>
                                    <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                    {/* <FormattedMessage id="app.btn.edit" /> */}
                                  </Button>
                                  <Button color="danger" size="sm" className="rounded">
                                    <Popconfirm title={storageLanguage === "en" ? "Do you want to delete " + restauType.title + ' ?' : "Voulez-vous supprimer  " + restauType.title + ' ?'}
                                      onConfirm={() => this.DeleteRestau(restauType._id)}
                                      onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >      <span className="lnr lnr-trash"></span>
                                      {/* <FormattedMessage id="app.btn.delet" /> */}
                                    </Popconfirm>

                                  </Button>
                                </Col>

                              </Row>
                              {(show_edit_restauType && restauType) && <Col md="12">
                                <EditRestautTypeCmp restauType={restauType}
                                  cancelEditing={() => this.setState({ show_edit_restauType: false })}
                                  success={() => {
                                    this.props.getRestauranTypes()
                                    this.setState({ show_edit_restauType: false, restauType: null })
                                  }}


                                />
                              </Col>}




                            </div>
                          </CollapseCmp>)}
                        </CardBody>
                      </Card>
                    </Col>
                  </TabPane>
                  <TabPane tab={<FormattedMessage id="app.regime.type" />} key="3">
                    <Col md={12} lg={12}>
                      <Card>
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text"><FormattedMessage id="app.regime.type" /></h5>

                          </div>

                          <Button color="success" style={{ float: 'right', marginTop: -75 }}
                            onClick={() => this.setState({ show_add_regimeType: true })}>
                            <span className="lnr lnr-plus-circle"></span>   <FormattedMessage id="app.btn.submit" /> </Button>


                          {show_add_regimeType && <Col md="12">
                            <AddregimeTypeCmp
                              cancelAdding={() => this.setState({ show_add_regimeType: false })}
                              success={() => {
                                this.props.getDiet()
                                this.setState({ show_add_regimeType: false, regimeType: null })
                              }}
                            />
                          </Col>}

                          {diet_types && diet_types.map((regimeType, i) => <CollapseCmp key={"restauType" + i} title={regimeType.title} icons={true}
                            //  is_open={(regimeType._id!==null)}  
                            className="with-shadow"
                          >

                            <div>
                              <Row>
                                <Col md="9">
                                  <p>{regimeType.description}</p>
                                </Col>

                                <Col md="3" style={{ textAlign: 'right' }} >
                                  <Button color="secondary" outline size="sm" className="rounded ml-1"
                                    onClick={() => this.setState({
                                      show_edit_regimeType: true, selected_regimeType: regimeType,
                                    })}>
                                    <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                    {/* <FormattedMessage id="app.btn.edit" /> */}
                                  </Button>
                                  <Button color="danger" size="sm" className="rounded">

                                    <Popconfirm title={storageLanguage === "en" ? "Do you want to delete " + regimeType.title + ' ?' : "Voulez-vous supprimer  " + regimeType.title + ' ?'}
                                      onConfirm={() => this.DeleteRegime(regimeType._id)}
                                      onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >      <span className="lnr lnr-trash"></span>
                                      {/* <FormattedMessage id="app.btn.delet" /> */}
                                    </Popconfirm>

                                  </Button>
                                </Col>

                              </Row>
                              {(show_edit_regimeType && regimeType) && <Col md="12">
                                <EditRegimeCmp regimeType={regimeType}
                                  cancelEditing={() => this.setState({ show_edit_regimeType: false })}
                                  success={() => {
                                    this.props.getDiet()
                                    this.setState({ show_edit_regimeType: false, regimeType: null })
                                  }}


                                />
                              </Col>}



                            </div>
                          </CollapseCmp>)}
                        </CardBody>
                      </Card>
                    </Col>
                  </TabPane>
                  <TabPane tab={<FormattedMessage id="app.vegan.type" />} key="4">

                    <Col md={12} lg={12}>
                      <Card>
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text"><FormattedMessage id="app.vegan.type" /></h5>

                          </div>


                          <Button color="success" style={{ float: 'right', marginTop: -75 }}
                            onClick={() => this.setState({ show_add_vegancategory: true })}>
                            {/* <FormattedMessage id="app.btn.addnewvegancategory" /> */}
                            <span className="lnr lnr-plus-circle"></span>   <FormattedMessage id="app.btn.submit" />

                          </Button>


                          {show_add_vegancategory && <Col md="12">
                            <AddVeganCategoryCmp
                              cancelAdding={() => this.setState({ show_add_vegancategory: false })}
                              success={() => {
                                this.props.getVEGAN()
                                this.setState({ show_add_vegancategory: false, vegancategory: null })
                              }}
                            />
                          </Col>}

                          {veganReq && veganReq.map((vagan, i) => <CollapseCmp key={"vagan" + i} title={vagan.title} icons={true}
                            // is_open={(vagan._id!==null)}   
                            className="with-shadow"
                          >

                            <div>
                              <Row>
                                <Col md="9">
                                  <p>{vagan.description}</p>
                                </Col>

                                <Col md="3" style={{ textAlign: 'right' }} >
                                  <Button color="secondary" outline size="sm" className="rounded ml-1"
                                    onClick={() => this.setState({
                                      show_edit_vegancategory: true, selected_vegancategory: vagan,
                                    })}>
                                    <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                    {/* <FormattedMessage id="app.btn.edit" /> */}
                                  </Button>
                                  <Button color="danger" size="sm" className="rounded">

                                    <Popconfirm title={storageLanguage === "en" ? "Do you want to delete " + vagan.title + ' ?' : "Voulez-vous supprimer  " + vagan.title + ' ?'}
                                      onConfirm={() => this.DeleteVegan(vagan._id)}
                                      onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >      <span className="lnr lnr-trash"></span>
                                      {/* <FormattedMessage id="app.btn.delet" /> */}
                                    </Popconfirm>

                                  </Button>
                                </Col>

                              </Row>
                              {(show_edit_vegancategory && vagan) && <Col md="12">
                                <EditVeganCategoryCmp VEGANType={vagan}
                                  cancelEditing={() => this.setState({ show_edit_vegancategory: false })}
                                  success={() => {
                                    this.props.getVEGAN()
                                    this.setState({ show_edit_vegancategory: false, vagan: null })
                                  }}


                                />
                              </Col>}



                            </div>
                          </CollapseCmp>)}
                        </CardBody>
                      </Card>
                    </Col>

                  </TabPane>
                  <TabPane tab={<FormattedMessage id="app.stylevestimentaire" />} key="5">
                    <Col md={12} lg={12}>
                      <Card>
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text"><FormattedMessage id="app.stylevestimentaire" /></h5>
                          </div>

                          <Button color="success" style={{ float: 'right', marginTop: -75 }}
                            onClick={() => this.setState({ show_add_dressCode: true })}>
                            {/* <FormattedMessage id="app.btn.addnewdresscode" /> */}
                            <span className="lnr lnr-plus-circle"></span>   <FormattedMessage id="app.btn.submit" />

                          </Button>


                          {show_add_dressCode && <Col md="12">
                            <AddDressCodeCmp
                              cancelAdding={() => this.setState({ show_add_dressCode: false })}
                              success={() => {
                                this.props.getDressCodes()
                                this.setState({ show_add_dressCode: false })
                              }}
                            />
                          </Col>}

                          {dress_codes && dress_codes.map((code, i) => <CollapseCmp key={"dressCode_" + i} title={code.name} icons={true}
                            //  is_open={(code._id!==null)}  
                            className="with-shadow" >
                            <div>

                              <Row>
                                <Col md="9">
                                  <p>{code.description}</p>
                                </Col>
                                <Col md="3" style={{ textAlign: 'right' }} >
                                  <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.setState({
                                    show_edit_dressCode: true, selected_dressCode: code,
                                  })}>
                                    <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                    {/* <FormattedMessage id="app.btn.edit" /> */}
                                  </Button>


                                  <Button color="danger" size="sm" className="rounded">
                                    <Popconfirm title={storageLanguage === "en" ? "Do you want to delete " + code.name + ' ?' : "Voulez-vous supprimer  " + code.name + ' ?'}
                                      onConfirm={() => this.DeleteDressCode(code._id)}
                                      onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
                                      <span className="lnr lnr-trash"></span>
                                      {/* <FormattedMessage id="app.btn.delet" /> */}
                                    </Popconfirm>

                                  </Button>
                                </Col>
                              </Row>


                              {(show_edit_dressCode) && <Col md="12">
                                <EditDressCodeCmp dressCode={code}
                                  cancelEditing={() => this.setState({ show_edit_dressCode: false })}
                                  success={() => {
                                    this.setState({ show_edit_dressCode: false, dressCode: null })
                                  }}

                                  deleteDressCode={(dressCode_delete) => {
                                    this.setState({ dressCode_delete }, () => this.deleteDressCode())
                                  }}
                                />
                              </Col>}
                            </div>
                          </CollapseCmp>)}
                        </CardBody>
                      </Card>
                    </Col>
                  </TabPane>
                  <TabPane tab={<FormattedMessage id="app.Blocs" />} key="6">
                    <Col md={12} lg={12}>
                      <Card>
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text">  <FormattedMessage id="app.Blocs" />  </h5>
                          </div>

                          <Button color="success" style={{ float: 'right', marginTop: -75 }}
                            onClick={() => this.setState({ show_add_bloc: true })}>
                            {/* <FormattedMessage id="app.btn.addnewbloc" /> */}
                            <span className="lnr lnr-plus-circle"></span>   <FormattedMessage id="app.btn.submit" /> </Button>



                          {show_add_bloc && <Col md="12">
                            <AddBlocCmp
                              cancelAdding={() => this.setState({ show_add_bloc: false })}
                              success={() => {
                                this.props.getBlocs()
                                this.setState({ show_add_bloc: false })
                              }}
                            />
                          </Col>}

                          {blocs && blocs.map((bloc, i) => <CollapseCmp key={"bloc_" + i} title={bloc.title} icons={true}
                            //  is_open={(bloc._id!==null)}  
                            className="with-shadow"
                          >
                            <div>

                              <Row>

                                <Col md="3">
                                  {(bloc && !show_edit_bloc && bloc.picture) &&
                                    <img src={bloc.picture === " " ? ` ${process.env.PUBLIC_URL}/img/no.png` : `${api.root}/${bloc.picture.path}`} />}
                                </Col>

                                <Col md="6">

                                  <div dangerouslySetInnerHTML={{ __html: bloc.description }} />

                                </Col>
                                <Col md="3" style={{ textAlign: 'right' }} >

                                  <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.setState({
                                    show_edit_bloc: true, selected_bloc: bloc,
                                  })}>
                                    <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                    {/* <FormattedMessage id="app.btn.edit" /> */}
                                  </Button>

                                  <Button color="danger" size="sm" className="rounded" >
                                    <Popconfirm title={storageLanguage === "en" ? "Do you want to delete " + bloc.title + ' ?' : "Voulez-vous supprimer  " + bloc.title + ' ?'}
                                      onConfirm={() => this.DeleteBloc(bloc._id)}
                                      onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
                                      <span className="lnr lnr-trash"></span>
                                      {/* <FormattedMessage id="app.btn.delet" /> */}
                                    </Popconfirm>

                                  </Button>
                                </Col>

                              </Row>


                              {(show_edit_bloc) && <Col md="12">
                                <EditBlocCmp bloc={bloc}
                                  cancelEditing={() => this.setState({ show_edit_bloc: false })}
                                  success={() => {
                                    this.setState({ show_edit_bloc: false, bloc: null })
                                  }}


                                />
                              </Col>}
                            </div>
                          </CollapseCmp>)}
                        </CardBody>
                      </Card>
                    </Col>
                  </TabPane>

                  <TabPane tab={<FormattedMessage id="app.caracteristique" />} key="7">
                    <Col md={12} lg={12}>
                      <Card>
                        <CardBody>
                          <div className="card__title">
                            <h5 className="bold-text">  <FormattedMessage id="app.caracteristique" />  </h5>
                          </div>

                          <Button color="success" style={{ float: 'right', marginTop: -75 }}
                            onClick={() => this.setState({ show_add_feature: true })}>
                            {/* <FormattedMessage id="app..btn.addnewcaracteristique" /> */}
                            <span className="lnr lnr-plus-circle"></span>   <FormattedMessage id="app.btn.submit" /> </Button>


                          {show_add_feature && <Col md="12">
                            <AddfeatureCmp
                              cancelAdding={() => this.setState({ show_add_feature: false })}
                              success={() => {
                                this.props.getFeatures()
                                this.setState({ show_add_feature: false })
                              }}
                            />
                          </Col>}



                          {features && features.map((item, i) => <CollapseCmp key={"feature" + i} title={item.name} icons={true}
                            //  is_open={(item._id!==null)}  
                            className="with-shadow"
                          >

                            <div>
                              <Row>
                                <Col md="9">
                                  {/* <p>{feature.description}</p> */}
                                </Col>

                                <Col md="3" style={{ textAlign: 'right' }} >
                                  <Button color="secondary" outline size="sm" className="rounded ml-1"
                                    onClick={() => this.setState({
                                      show_edit_feature: true, selected_feature: item,
                                    })}>
                                    <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                    {/* <FormattedMessage id="app.btn.edit" /> */}
                                  </Button>
                                  <Button color="danger" size="sm" className="rounded">

                                    <Popconfirm title={storageLanguage === "en" ? "Do you want to delete " + item.name + ' ?' : "Voulez-vous supprimer  " + item.name + ' ?'}
                                      onConfirm={() => this.DeleteFeature(item._id)}
                                      onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />}
                                      cancelText={<FormattedMessage id="app.btn.non" />} >
                                      <span className="lnr lnr-trash"></span>
                                      {/* <FormattedMessage id="app.btn.delet" /> */}
                                    </Popconfirm>

                                  </Button>
                                </Col>

                              </Row>
                              {(show_edit_feature) && <Col md="12">
                                <EditfeatureCmp Feature={item}
                                  cancelEditing={() => this.setState({ show_edit_feature: false })}
                                  success={() => {
                                    this.props.getFeatures()
                                    this.setState({ show_edit_feature: false, feature: null })
                                  }}


                                />
                              </Col>}



                            </div>
                          </CollapseCmp>)}
                        </CardBody>
                      </Card>
                    </Col>
                  </TabPane>
                </Tabs>

              

              </div>
            </div>
          </Card>
        </Col>
      </Row>

      {/* CONFIRM MDLs */}

      {/* <ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
        is_open={show_delete_msg} accept={() => {
       
          // this.deleteKType()
          this.setState({show_delete_msg: false})
        }}
      /> */}
    </Container>)
  }
}


const mapStateToProps = ({ ktype, dressCode, bloc, restauranType, dietReq, VEGAN_types, featureReq }) => {
  const { kitchen_types, loading_edit_kitchen_type, error_edit_kitchen_type } = ktype
  const { dress_codes, loading_edit_dress_code, error_edit_dress_code } = dressCode
  const { blocs, loading_edit_bloc, error_edit_bloc } = bloc
  const { restaurant_types } = restauranType
  const { diet_types } = dietReq
  const { features, loading_edit_feature, error_edit_feature } = featureReq

  return {
    kitchen_types, loading_edit_kitchen_type, error_edit_kitchen_type,
    dress_codes, loading_edit_dress_code, error_edit_dress_code,
    blocs, loading_edit_bloc, error_edit_bloc,
    restaurant_types, diet_types,
    veganReq: VEGAN_types.VEGAN_types,
    features, loading_edit_feature, error_edit_feature
  }
}
export default connect(mapStateToProps, {
  getKitchenTypes, editKitchenType, deleteKitchenType,
  getDressCodes, editDressCode, deleteDressCode,
  getBlocs, editBloc, deleteBloc,
  getRestauranTypes, getDiet, getVEGAN,
  getFeatures, deleteFeature
})(SettingsPage);

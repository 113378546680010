
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_BLOCS, getBlocsSuccess, getBlocsError, 
  ADD_BLOC, addBlocError, addBlocSuccess, 
  // GET_ONE_BLOC, getOneBlocSuccess, getOneBlocError, 
  EDIT_BLOC, editBlocError, editBlocSuccess,
  DELETE_BLOC, deleteBlocError, deleteBlocSuccess
} from '../actions/blocsActions';
import { api } from '../../const';



// *** GET BLOCS
export function* watchGetBlocs() { yield takeEvery(GET_BLOCS, getBlocs) }
function* getBlocs({ payload }) {
  try {
    const ret = yield call(getBlocsRequest, payload);
    if (ret && ret.status === 200) yield put(getBlocsSuccess({ blocs: ret.data?.infoBlock || [], count: ret.data?.count || 0}));
    else yield put(getBlocsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getBlocsError({ error, message: (error) ? error.message : '' }));
  }
}
const getBlocsRequest = async (payload) => {  
  return await axios.get(api.blocs)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** GET ONE BLOC
// export function* watchGetOneBloc() { yield takeEvery(GET_ONE_BLOC, getOneBloc) }
// function* getOneBloc({ payload }) {
//   try {
//     const ret = yield call(getOneBlocRequest, payload);

//     if (ret && ret.status === 200) yield put(getOneBlocSuccess({ bloc: ret.data?.bloc || null }));
//     else yield put(getOneBlocError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(getOneBlocError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const getOneBlocRequest = async (payload) => {  
//   const endpoint = (payload.track) ? '/track-one/' : '/get-one/'
//   return await axios.get(api.blocs+endpoint+payload.bloc_id)
//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };





// *** ADD BLOCS
export function* watchAddBlocs() { yield takeEvery(ADD_BLOC, addBloc) }
function* addBloc({ payload }) {
  try {

   
    const ret = yield call(addBlocsRequest, payload);

    if (ret && ret.status === 200) yield put(addBlocSuccess({ bloc: ret.data }));
    else yield put(addBlocError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addBlocError({ error, message: (error) ? error.message : '' }));
  }
}
const addBlocsRequest = async (payload) => {  

  return await axios.post(api.blocs, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT BLOCS
export function* watchEditBloc() { yield takeEvery(EDIT_BLOC, editBloc) }
function* editBloc({ payload }) {
  try {
    const ret = yield call(editBlocsRequest, payload);

    if (ret && ret.status === 200) yield put(editBlocSuccess({ bloc: ret.data }));
    else yield put(editBlocError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editBlocError({ error, message: (error) ? error.message : '' }));
  }
}
const editBlocsRequest = async (payload) => {  
  return await axios.patch(api.blocs+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE BLOCS
export function* watchDeleteBloc() { yield takeEvery(DELETE_BLOC, deleteBloc) }
function* deleteBloc({ payload }) {
  try {
    const ret = yield call(deleteBlocsRequest, payload);

    if (ret && ret.status === 204) yield put(deleteBlocSuccess({ bloc: ret.data }));
    else yield put(deleteBlocError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteBlocSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteBlocsRequest = async (payload) => {  
  return await axios.delete(api.blocs+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetBlocs),
    // fork(watchGetOneBloc),
    fork(watchAddBlocs),
    fork(watchDeleteBloc),
    fork(watchEditBloc)
  ]);
}
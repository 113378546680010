// ***** GET VEGAN
export const GET_VEGAN            = 'GET_VEGAN';
export const GET_VEGAN_SUCCESS    = 'GET_VEGAN_SUCCESS';
export const GET_VEGAN_ERROR      = 'GET_VEGAN_ERROR';

export function getVEGAN(payload) {

  return {type: GET_VEGAN, payload};
}
export function getVEGANSuccess(payload) {
  return { type: GET_VEGAN_SUCCESS, payload };
}
export function getVEGANError(error) {
  return { type: GET_VEGAN_ERROR, error };
}






// ***** ADD VEGAN
export const ADD_VEGAN            = 'ADD_VEGAN';
export const ADD_VEGAN_SUCCESS    = 'ADD_VEGAN_SUCCESS';
export const ADD_VEGAN_ERROR      = 'ADD_VEGAN_ERROR';

export function addVEGAN(payload) {
  return {type: ADD_VEGAN, payload};
}
export function addVEGANSuccess(payload) {
  return { type: ADD_VEGAN_SUCCESS, payload };
}
export function addVEGANError(error) {
  return { type: ADD_VEGAN_ERROR, error };
}

// ***** EDIT VEGAN
export const EDIT_VEGAN            = 'EDIT_VEGAN';
export const EDIT_VEGAN_SUCCESS    = 'EDIT_VEGAN_SUCCESS';
export const EDIT_VEGAN_ERROR      = 'EDIT_VEGAN_ERROR';

export function editVEGAN(payload) {
  return {type: EDIT_VEGAN, payload};
}
export function editVEGANSuccess(payload) {
  return { type: EDIT_VEGAN_SUCCESS, payload };
}
export function editVEGANError(error) {
  return { type: EDIT_VEGAN_ERROR, error };
}



// ***** DELET VEGAN
export const DELETE_VEGAN            = 'DELETE_VEGAN';
export const DELETE_VEGAN_SUCCESS    = 'DELETE_VEGAN_SUCCESS';
export const DELETE_VEGAN_ERROR      = 'DELETE_VEGAN_ERROR';

export function deleteVEGAN(payload) {
  return {type: DELETE_VEGAN, payload};
}
export function deleteVEGANSuccess(payload) {
  return { type: DELETE_VEGAN_SUCCESS, payload };
}
export function deleteVEGANError(error) {
  return { type: DELETE_VEGAN_ERROR, error };
}

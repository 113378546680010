import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';
import { Tag } from "antd"

import {
    getOrderParDeliveryman, editOrderReq
} from "../../../redux/actions/orderActions";
import { getUsers } from "../../../redux/actions/authActions";

import { getCountPages } from '../../helper/utils';
import Pagination from '../pagination/Pagination';
import { api } from '../../../const';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import { getRestaurants } from "../../../redux/actions/restaurantsActions";



import { FormattedMessage } from 'react-intl';

class ListOrdersCmp extends PureComponent {

    constructor() {
        super();
        this.state = {


            show_delete_msg: false,
            detail_Livraison: '',

        };
    }


    changeVal = (key, value) => {
        const obj = {}; obj[key] = value;
        this.setState(obj)
    }


    componentDidMount() {


        let _id = this.props.deliveryman_id
        axios.get(api.orders + '/deliveryman/' + _id).then(response => {
            this.setState({ detail_Livraison: response.data?.orders })

        })

    }
    render() {
        const { current_page, selected_page_size, detail_Livraison } = this.state;
    
        const {  count_all_detail_Livraison, restaurants } = this.props
        let restaurant = [];
        restaurants.forEach(element => {
            restaurant.push({
                value: element._id,
                label: element.name,
            })
        });
      for(let i=0;i<detail_Livraison.length;i++){
          this.setState({deliveryMan:detail_Livraison[i].deliveryMan,
        
            restaurant:detail_Livraison[i].restaurant?.name,
            reference:detail_Livraison[i].reference,
            orderTypecode:detail_Livraison[i].orderType?.code,
            client:detail_Livraison[i].owner?.profile?.firstName +""+detail_Livraison[i].owner?.profile?.lastName,
            price:detail_Livraison[i].price,
            deliveryFee:detail_Livraison[i].deliveryFee,
        
        })
      }

        return (
            <Card>
                <CardBody>

                    <Row>
                        <Col md={12}>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                    <th><FormattedMessage id="app.profile.livreur"/></th>
                                     
                                        <th> <FormattedMessage id="app.restaurant" /></th>
                                        <th><FormattedMessage id="app.restaurant.commande"/> <FormattedMessage id="app.order.reference" /></th>
                                        <th><FormattedMessage id="app.profile.status"  /> <FormattedMessage id="app.restaurant.commande"/> </th>
                                        <th>
                                            {/* <FormattedMessage id="app.profile.address" /> */}
                                         <FormattedMessage id="app.profile.client" /></th>
                                        <th><FormattedMessage id="app.order.prix" /></th>
                                        <th><FormattedMessage id="app.order.fraislivreur" /></th>
                                        {/* <th><FormattedMessage id="app.order.fraislivreur"/></th>
                            <th><FormattedMessage id="app.profile.status"/></th> */}


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    
                                    <td style={{ verticalAlign: 'middle' }}>{this.state.deliveryMan || ""}</td>

                                        <td style={{ verticalAlign: 'middle' }}>{this.state.restaurant ||""}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{this.state.reference || ""}</td>
                                        { this.state.orderTypecode===0 ?
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="green"> <FormattedMessage id="app.statu.accepter"/> </Tag>  </td>
                                      :null }
                                              { this.state.orderTypecode===1 ?
                                            <td style={{ verticalAlign: 'middle' }}> <Tag color="volcano"><FormattedMessage id="app.enattente"/> </Tag>  </td>
                                      :null }
                                        { this.state.orderTypecode===2 ?
                                            <td style={{ verticalAlign: 'middle' }}><Tag color="cyan"><FormattedMessage id="app.statu.refuser"/> </Tag> </td>
                                      :null }
                                        <td style={{ verticalAlign: 'middle' }}>{this.state.client||""}</td>
 
                         
	
				
                                       

                                        
<td style={{ verticalAlign: 'middle' }}>{this.state.price}</td>

                                        <td style={{ verticalAlign: 'middle' }}>{this.state.deliveryFee}</td>

                                       

                                        {/* <td>
                                            <span onClick={() => {
                                                this.setState({ req_delete_id: detail_Livraison.order?._id, show_delete_msg: true })
                                            }} className="lnr lnr-trash" style={{ color: "#F44336", display: 'block', marginTop: 12 }}></span>
                                        </td> */}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        {/* <br />
                        <Col md={6} style={{ marginTop: 20 }}>
                            <div className="card__title">

                                <h6 className="bold-text text-danger "><FormattedMessage id="app.menus" /> </h6>

                            </div>
                            <Table bordered hover>

                                <thead>
                                    <tr>



                                        <th></th>
                                        <th><FormattedMessage id="app.menu" /></th>
                                        <th><FormattedMessage id="app.menu.prix" /></th>
                                        <th><FormattedMessage id="app.prix.promo" /></th>
                                        <th><FormattedMessage id="app.quantité" /> </th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {detail_Livraison.menus && detail_Livraison.menus.map((menu, i) => (<tr key={'menu' + i}>
                                        <td style={{ verticalAlign: 'middle' }}>

                                            {
                                                menu.picture ? <img alt={menu.name} src={`${api.root}/${menu.picture.path}`} style={{ width: 40 }} />
                                                    : <img alt={menu.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
                                            }


                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>{menu.name}</td>

                                        <td style={{ verticalAlign: 'middle' }}>{menu.price?.price}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{menu.price?.pricePromo}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{menu.quantity}</td>



                                    </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </Col>
                        <Col md={6} style={{ marginTop: 20 }}>
                            <div className="card__title">

                                <h6 className="bold-text text-danger "><FormattedMessage id="app.plats" />  </h6>

                            </div>
                            <Table bordered hover>
                                <thead>
                                    <tr>

                                        <th></th>
                                        <th><FormattedMessage id="app.plats" /></th>
                                        <th><FormattedMessage id="app.menu.prix" /></th>
                                        <th><FormattedMessage id="app.prix.promo" /></th>
                                        <th><FormattedMessage id="app.quantité" /> </th>



                                    </tr>
                                </thead>
                                <tbody>

                                    {detail_Livraison.plates && detail_Livraison.plates.map((plate, i) => (<tr key={'menu' + i}>
                                        <td style={{ verticalAlign: 'middle' }}>

                                            {
                                                plate.picture ? <img alt={plate.name} src={`${api.root}/${plate.picture.path}`} style={{ width: 40 }} />
                                                    : <img alt={plate.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
                                            }

                                        </td>

                                        <td style={{ verticalAlign: 'middle' }}>{plate.name}</td>


                                        <td style={{ verticalAlign: 'middle' }}>{plate.price?.price}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{plate.price?.pricePromo}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{plate.quantity}</td>



                                    </tr>
                                    ))}


                                </tbody>
                            </Table>
                        </Col> */}

                    </Row>



                </CardBody>
                <Row>
                    <Col md={{ span: 6, offset: 2 }} style={{ marginTop: 20 }} >




                    </Col>
                </Row>
            </Card>


        );

    }
}

const mapStateToProps = ({ orderReq, restaurant, auth }) => {
    const { detail_Livraison, count_all_detail_Livraison,
        loading_one_order_request, error_get_one_detail_Livraison,
        loading_delete_order_request, error_delete_order_request,

        selected_order_request,
    } = orderReq

    const { user, users } = auth
    const { restaurants } = restaurant
    return {
        detail_Livraison, count_all_detail_Livraison, user, users,
        loading_one_order_request, error_get_one_detail_Livraison, restaurants,
        selected_order_request,
        loading_delete_order_request, error_delete_order_request
    }
}
export default connect(mapStateToProps, {
    getOrderParDeliveryman, editOrderReq, getRestaurants, getUsers,

})(ListOrdersCmp);


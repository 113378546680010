// ***** GET DEMANDE_MANAGER
export const GET_DEMANDE_MANAGER            = 'GET_DEMANDE_MANAGER';
export const GET_DEMANDE_MANAGER_SUCCESS    = 'GET_DEMANDE_MANAGER_SUCCESS';
export const GET_DEMANDE_MANAGER_ERROR      = 'GET_DEMANDE_MANAGER_ERROR';

export function getDemande_Manager(payload) {

  return {type: GET_DEMANDE_MANAGER, payload};
}
export function getDemande_ManagerSuccess(payload) {
  return { type: GET_DEMANDE_MANAGER_SUCCESS, payload };
}
export function getDemande_ManagerError(error) {
  return { type: GET_DEMANDE_MANAGER_ERROR, error };
}


// ***** GET DEMANDE_MANAGER_BY_MANAGER
export const GET_DEMANDE_MANAGER_BY_MANAGER            = 'GET_DEMANDE_MANAGER_BY_MANAGER';
export const GET_DEMANDE_MANAGER_SUCCESS_BY_MANAGER   = 'GET_DEMANDE_MANAGER_SUCCESS_BY_MANAGER';
export const GET_DEMANDE_MANAGER_ERROR_BY_MANAGER      = 'GET_DEMANDE_MANAGER_ERROR_BY_MANAGER';

export function getDemande_Manager_By_Manager(payload) {

  return {type: GET_DEMANDE_MANAGER_BY_MANAGER, payload};
}
export function getDemande_Manager_By_ManagerSuccess(payload) {
  return { type: GET_DEMANDE_MANAGER_SUCCESS_BY_MANAGER, payload };
}
export function getDemande_Manager_By_ManagerError(error) {
  return { type: GET_DEMANDE_MANAGER_ERROR_BY_MANAGER, error };
}






// ***** ADD DEMANDE_MANAGER
export const ADD_DEMANDE_MANAGER            = 'ADD_DEMANDE_MANAGER';
export const ADD_DEMANDE_MANAGER_SUCCESS    = 'ADD_DEMANDE_MANAGER_SUCCESS';
export const ADD_DEMANDE_MANAGER_ERROR      = 'ADD_DEMANDE_MANAGER_ERROR';

export function addDemande_Manager(payload) {
  return {type: ADD_DEMANDE_MANAGER, payload};
}
export function addDemande_ManagerSuccess(payload) {
  return { type: ADD_DEMANDE_MANAGER_SUCCESS, payload };
}
export function addDemande_ManagerError(error) {
  return { type: ADD_DEMANDE_MANAGER_ERROR, error };
}

// ***** EDIT DEMANDE_MANAGER
export const EDIT_DEMANDE_MANAGER            = 'EDIT_DEMANDE_MANAGER';
export const EDIT_DEMANDE_MANAGER_SUCCESS    = 'EDIT_DEMANDE_MANAGER_SUCCESS';
export const EDIT_DEMANDE_MANAGER_ERROR      = 'EDIT_DEMANDE_MANAGER_ERROR';

export function editDemande_Manager(payload) {
  return {type: EDIT_DEMANDE_MANAGER, payload};
}
export function editDemande_ManagerSuccess(payload) {
  return { type: EDIT_DEMANDE_MANAGER_SUCCESS, payload };
}
export function editDemande_ManagerError(error) {
  return { type: EDIT_DEMANDE_MANAGER_ERROR, error };
}

// ***** GET DEMANDE_MANAGER
export const GET_ONE_DEMANDE_MANAGER            = 'GET_ONE_DEMANDE_MANAGER';
export const GET_ONE_DEMANDE_MANAGER_SUCCESS    = 'GET_ONE_DEMANDE_MANAGER_SUCCESS';
export const GET_ONE_DEMANDE_MANAGER_ERROR      = 'GET_ONE_DEMANDE_MANAGER_ERROR';

export function getOneDemande_Manager(payload) {
  return {type: GET_ONE_DEMANDE_MANAGER, payload};
}
export function getOneDemande_ManagerSuccess(payload) {
  return { type:GET_ONE_DEMANDE_MANAGER_SUCCESS, payload };
}
export function getOneDemande_ManagerError(error) {
  return { type: GET_ONE_DEMANDE_MANAGER_ERROR, error };
}


// ***** DELET DEMANDE_MANAGER
export const DELETE_DEMANDE_MANAGER            = 'DELETE_DEMANDE_MANAGER';
export const DELETE_DEMANDE_MANAGER_SUCCESS    = 'DELETE_DEMANDE_MANAGER_SUCCESS';
export const DELETE_DEMANDE_MANAGER_ERROR      = 'DELETE_DEMANDE_MANAGER_ERROR';

export function deleteDemande_Manager(payload) {
  return {type: DELETE_DEMANDE_MANAGER, payload};
}
export function deleteDemande_ManagerSuccess(payload) {
  return { type: DELETE_DEMANDE_MANAGER_SUCCESS, payload };
}
export function deleteDemande_ManagerError(error) {
  return { type: DELETE_DEMANDE_MANAGER_ERROR, error };
}

import React, {PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { Button, ButtonToolbar, Modal, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";
import { notification} from 'antd';
import {
  addUser
} from "../../../redux/actions/authActions";
import { api } from '../../../const';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';


const modalClass = classNames({
  'modal-dialog--colored': false,
  'modal-dialog--header': true,
});

class AddUserMdl extends PureComponent {

  constructor() {
    super();
    this.state = {
      firstName: '', lastName: '', email: '', mobile: '', password: '',confirmPassword:'', is_manager: false, is_driver: false, is_user: false,
      error_frm: "",  storageLanguage : localStorage.getItem('language')
    };
  }




  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;

    this.setState(obj)
   
  
  }
     
  openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
    
    notification[type]
    ({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'User add successfully  ':'Utilisateur ajouté avec Succès',
   
		})

  
  };

  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };
  submit = () => {
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })

    const { only_drivers, only_clients } = this.props
    if (error_frm === "") {
      const { firstName, lastName, email, mobile, password,confirmPassword,is_manager, is_driver, is_user} = this.state
 

    
     // const indx=0
      const role = [];
      if(only_drivers) role.push("DELIVERYMAN") 
      else if(only_clients) role.push("CLIENT") 
      else{
        if (is_manager) role.push("MANAGER")
        if (is_driver) role.push("DELIVERYMAN")
        if (is_user) role.push("USER")
      }

      let roles= role[0]
      let data={firstName,lastName, email, mobile, password,confirmPassword, roles}
      axios.post(api.users+'/add', data).then(response=>{
        if (response.status===200){ 
          this.openNotificationWithIcon('success') 
    
          this.props.handleSuccess()
        }
        else{
      
          this.openNotificationWithIconErreur('error')
          this.props.handleSuccess()		}  }) } 
        }
  




     
  
  checkFrm = (data) => {
    
    const { firstName, lastName, email, mobile, password,confirmPassword, is_manager, is_driver, is_user } = data
    if (firstName === "" || lastName === "") return "First name and last name are required.";
    if (email === "") return "Email is required."
    if (!new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/).test(email)) return "Invalid Email."
    if (mobile === "") return "Mobile is required."
    const { only_drivers, only_clients } = this.props
    if (!only_drivers && !only_clients && !is_manager && !is_driver && !is_user) return "Please check one of the roles."

    if (password === "") return "Password is required."
    if(password===confirmPassword)return""
    else return"Password inccorecte"

  }

  componentDidUpdate(prev) {
    const { loading_add_user, error_add_user, new_user } = this.props
    
    if (!loading_add_user && prev.loading_add_user){
      
      if(error_add_user){
        if(error_add_user.message && error_add_user.message !== "")
        this.setState({error_frm: error_add_user.message})
      }

      else this.props.handleSuccess(new_user)
    }
  }

  render() {
    const { only_drivers, only_clients, is_open, size, toggle } = this.props
    
    return (
      <Modal
        isOpen={is_open} size={size || "lg"}
        toggle={toggle}
        // modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--primary ${modalClass}`}
      >
        <div className="modal__header">
          {/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
            {Icon} */}
          {only_drivers && <h4 className="text-modal  modal__title"><FormattedMessage id="app.btn.submit"/>  <FormattedMessage id="app.restaurant"/> </h4>}
          {only_clients && <h4 className="text-modal  modal__title"><FormattedMessage id="app.btn.submit"/>  <FormattedMessage id="app.profile.client"/> </h4>}
          {(!only_drivers && !only_clients) && <h4 className="text-modal  modal__title"><FormattedMessage id="app.btn.submit"/> 
            <FormattedMessage id="app.profile.manager"/>/ <FormattedMessage id="app.profile.livreur"/> / <FormattedMessage id="app.profile.client"/> </h4>}
        </div>
        <div className="modal__body" style={{ padding: '0 10px', textAlign: 'left' }}>


          <form className="form" onSubmit={(e) => {
            e.preventDefault();

            this.submit()
          }}>
            <Row>
              <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
                <Alert is_visible={(this.state.error_frm !== "")} color="danger" className="alert--colored" icon >
                  <p><span className="bold-text"><FormattedMessage id= "app.warning" /></span> {this.state.error_frm}</p>
                </Alert>
              </Col>
              <Col md="6">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.firstname" /></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="firstName" component="input" placeholder="Nom"
                      onChange={(e) => this.changeVal('firstName', e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.lastname" /></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="lastName" component="input" placeholder="Prénom"
                      onChange={(e) => this.changeVal('lastName', e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.email" /></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="email" component="input" type="email" placeholder="example@mail.com"
                      onChange={(e) => this.changeVal('email', e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              <Col md="12">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.phone" /></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="mobile" component="input" placeholder="Téléphone"
                      onChange={(e) => this.changeVal('mobile', e.target.value)}
                    />
                  
                  </div>
                </div>
              </Col>



              <Col md="12">
                <p><FormattedMessage id="app.profile.role" /></p>
                {only_clients && <p> <FormattedMessage id="app.profile.client"/></p>}
                {only_drivers && <p><FormattedMessage id="app.profile.livreur" /></p>}

                {(!only_clients && !only_drivers) && <Row>
                  <Col md="3">
                    <Field style={{ marginRight: 5 }}
                      name="is_manager"
                      component={renderCheckBoxField}
                      onChange={(e) => this.changeVal("is_manager", (e && e.target && e.target.checked))}
                      label={<FormattedMessage id="app.profile.manager" />}
                      color="#4ce1b6"
                      className="colored"
                    />
                  </Col>
                  <Col md="3">
                    <Field style={{ marginRight: 5 }}
                      name="is_driver"
                      component={renderCheckBoxField}
                      onChange={(e) => this.changeVal("is_driver", (e && e.target && e.target.checked))}
                      label={<FormattedMessage id="app.profile.livreur" />}
                      color="#4ce1b6"
                      className="colored"
                    />
                  </Col>
                  <Col md="3">
                    <Field
                      name="is_user"
                      component={renderCheckBoxField}
                      onChange={(e) => this.changeVal("is_user", (e && e.target && e.target.checked))}
                      label={<FormattedMessage id="app.profile.client" />}
                      defaultChecked
                      color="#4ce1b6"
                      className="colored"
                    />
                  </Col>
                </Row>}
              </Col>

              <Col md="12">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profile.password" /></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="password" component="input" placeholder="Mot de passe"
                      onChange={(e) => this.changeVal('password', e.target.value)}
                    />
                    <button
                      type="button"
                      className={`form__form-group-button${true ? ' active' : ''}`}
                    // onClick={e => this.showPassword(e)}
                    ><span className="lnr lnr-lock" style={{ color: "#fff" }}></span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form__form-group">
                  <span className="form__form-group-label"><FormattedMessage id="app.profil.cnfrmpassword"/></span>
                  <div className="form__form-group-field">
                    <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      name="confirmPassword" component="input" placeholder="Password"
                      onChange={(e) => this.changeVal('confirmPassword', e.target.value)}
                    />
                    <button
                      type="button"
                      className={`form__form-group-button${true ? ' active' : ''}`}
                    // onClick={e => this.showPassword(e)}
                    ><span className="lnr lnr-lock" style={{ color: "#fff" }}></span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md="12">
              <ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
                <Button color="primary" type="submit"><FormattedMessage id="app.btn.submit" /></Button>
                <Button type="button" onClick={this.props.toggle}>
                <FormattedMessage id="app.btn.cancel" />
                  </Button>
              </ButtonToolbar>
              </Col>

            </Row>




          </form>
        </div>
        {/* <ButtonToolbar className="modal__footer" style={{padding: '0 5px'}} >
            <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
            <Button type="submit" className="modal_ok" outline color={color} onClick={toggle}>Add new</Button>
          </ButtonToolbar> */}
      </Modal>
    );
  }
}

// AddUserMdl.propTypes = {
//   title: PropTypes.string,
//   message: PropTypes.string,
//   color: PropTypes.string.isRequired,
//   colored: PropTypes.bool,
//   header: PropTypes.bool,
//   // rtl: RTLProps.isRequired,
// };

// AddUserMdl.defaultProps = {
//   title: '',
//   message: '',
//   colored: false,
//   header: false,
// };

// export default connect(state => ({
//   rtl: state.rtl,
// }))(AddUserMdl);


const mapStateToProps = ({ auth }) => {
  return {
    form: 'log_in_form',
    loading_login: auth.loading_login,
    user: auth.user,
    loading_add_user: auth.loading_add_user,
    error_add_user: auth.error_add_user,
    new_user: auth.new_user
  }
}
export default connect(mapStateToProps, {
  addUser
})(reduxForm()(AddUserMdl));
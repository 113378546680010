const server = process.env.REACT_APP_API
export const api = {
  gmap_key: process.env.REACT_APP_GMAP_KEY,
  root: server,
  sms_server : process.env.REACT_APP_GTEXT,
  users: `${server}/users`,
  orders: `${server}/orders`,
  reservations: `${server}/reservations`,
  vehicles: `${server}/vehicles`,
  vehicle_types: `${server}/vehicle-types`,
  kitchen_types: `${server}/cuisines`,
  dress_codes: `${server}/dress-codes`,
  blocs: `${server}/block-info`,
  restaurants: `${server}/restaurants`,
  dishs: `${server}/plates`,
  menus: `${server}/menus`,
  fav_places: `${server}/fav-places`,
  restaurant_types: `${server}/types`,
  cart: `${server}/carts`,
  diet_types: `${server}/diets`,
  marque_blanche:`${server}/white-label`,
  requests:`${server}/requests`,
  langue:`${server}/languages`,
  promo:`${server}/promo`,
  vegan:`${server}/vegan-category`,
  User_Config:`${server}/configs`,
  Feature:`${server}/features`,
  demande_manager:`${server}/requests-owner`,  
  commande_perdu:`${server}/recommendations`, 
}

// npm install -g sass
// npm install --save-dev sass
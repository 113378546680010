import {
  GET_DEMANDE_MANAGER, GET_DEMANDE_MANAGER_SUCCESS, GET_DEMANDE_MANAGER_ERROR,
  GET_DEMANDE_MANAGER_BY_MANAGER,GET_DEMANDE_MANAGER_SUCCESS_BY_MANAGER,GET_DEMANDE_MANAGER_ERROR_BY_MANAGER,
  ADD_DEMANDE_MANAGER, ADD_DEMANDE_MANAGER_SUCCESS, ADD_DEMANDE_MANAGER_ERROR,
  GET_ONE_DEMANDE_MANAGER, GET_ONE_DEMANDE_MANAGER_SUCCESS, GET_ONE_DEMANDE_MANAGER_ERROR,
EDIT_DEMANDE_MANAGER, EDIT_DEMANDE_MANAGER_SUCCESS, EDIT_DEMANDE_MANAGER_ERROR
} from '../actions/demandemanagerAction';
  const initialState = {
  loading_get_demande_manager: false,
  error_get_demande_manager: null,
  demande_manager_by_manager: null,
  loading_get_demande_manager_by_manager: false,
  error_get_demande_manager_by_manager: null,

  demande_manager: [],
count_all_demande_manager: 0,

  new_Demande_Manager: null,selected_marqueblanch:null, loading_add_Demande_Manager: false, error_add_Demande_Manager: null,
};

const demande_managerReducer = (state = initialState, action) => {
 
  switch (action.type) {

    case GET_DEMANDE_MANAGER: return {...state, loading_get_demande_manager: true, error_get_demande_manager: null };
    case GET_DEMANDE_MANAGER_SUCCESS:
  
      return {...state, demande_manager: [ ...action.payload.demande_manager],count_all_demande_manager:action.payload.count, loading_get_demande_manager: false, error_get_demande_manager: null };
    case GET_DEMANDE_MANAGER_ERROR:

      return {...state, error_get_demande_manager: action.error, loading_get_demande_manager: false, demande_manager: [] };



      case GET_DEMANDE_MANAGER_BY_MANAGER: return {...state, loading_get_demande_manager_by_manager: true, error_get_demande_manager_by_manager: null };
    case GET_DEMANDE_MANAGER_SUCCESS_BY_MANAGER:  return {...state, demande_manager_by_manager:[action.payload.demande_manager], loading_get_demande_manager_by_manager: false, error_get_demande_manager_by_manager: null };
    case GET_DEMANDE_MANAGER_ERROR_BY_MANAGER:
      return {...state, error_get_demande_manager: action.error, loading_get_demande_manager_by_manager: false, demande_manager_by_manager: [] };




    case ADD_DEMANDE_MANAGER: return {...state, loading_add_Demande_Manager: true, error_add_Demande_Manager: null }
   
    case ADD_DEMANDE_MANAGER_SUCCESS:
      return {...state, new_Demande_Manager: action.payload.Demande_Manager, loading_add_Demande_Manager: false, error_add_Demande_Manager: null };
    case ADD_DEMANDE_MANAGER_ERROR:
      return {...state, error_add_Demande_Manager: action.error, loading_add_Demande_Manager: false, new_Demande_Manager: null };

   

      case EDIT_DEMANDE_MANAGER: return {...state, loading_edit_Demande_Manager: true, error_edit_Demande_Manager: null };
      case EDIT_DEMANDE_MANAGER_SUCCESS:
        return {...state, loading_edit_Demande_Manager: false, error_edit_Demande_Manager: null };
      case EDIT_DEMANDE_MANAGER_ERROR:
        return {...state, error_edit_Demande_Manager: action.error, loading_edit_Demande_Manager: false}; 
        

    case GET_ONE_DEMANDE_MANAGER: return {...state, loading_one_Demande_Manager: true, error_one_Demande_Manager: null };
    case GET_ONE_DEMANDE_MANAGER_SUCCESS:
      return {...state, selected_marqueblanch: action.payload.Demande_Manager, loading_add_Demande_Manager: false, error_one_Demande_Manager: null };
    case GET_ONE_DEMANDE_MANAGER_ERROR:
      return {...state, error_one_Demande_Manager: action.error, loading_one_Demande_Manager: false, selected_marqueblanch: null };

    default: return state;
  }
};

export default demande_managerReducer;

import {
    GET_RESERVATION_REQS, GET_RESERVATION_REQS_SUCCESS, GET_RESERVATION_REQS_ERROR,
    // ADD_RESERVATION_REQ, ADD_RESERVATION_REQ_SUCCESS, ADD_RESERVATION_REQ_ERROR,
    // GET_ONE_RESERVATION_REQ, GET_ONE_RESERVATION_REQ_SUCCESS, GET_ONE_RESERVATION_REQ_ERROR,
    EDIT_RESERVATION_REQ, EDIT_RESERVATION_REQ_SUCCESS, EDIT_RESERVATION_REQ_ERROR,
    DELETE_RESERVATION_REQ, DELETE_RESERVATION_REQ_SUCCESS, DELETE_RESERVATION_REQ_ERROR
  } from '../actions/reservationsReqsActions';
  
  const initialState = {
    loading_get_reservations: false,
    error_get_reservations: null,
    reservations: [],
    count_all_reservations: 0,
  
    new_reservation_request: null, loading_add_reservation_request: false, error_add_reservation_request: null,
  
    selected_reservation_request: null, loading_edit_reservation_request: false, error_edit_reservation_request: null,
  
    loading_delete_reservation_request: false, error_delete_reservation_request: null
  };
  
  const reservationRequestsReducer = (state = initialState, action) => {

    switch (action.type) {
  
      case GET_RESERVATION_REQS: return {...state, loading_get_reservations: true, error_get_reservations: null };
      case GET_RESERVATION_REQS_SUCCESS:
        return {...state, reservations: action.payload.reservations, loading_get_reservations: false, error_get_reservations: null, count_all_reservations: action.payload.count };
      case GET_RESERVATION_REQS_ERROR:
     
        return {...state, error_get_reservations: action.error, loading_get_reservations: false, reservations: [] };
  
  
  
      // case ADD_RESERVATION_REQ: return {...state, loading_add_reservation_request: true, error_add_reservation_request: null };
      // case ADD_RESERVATION_REQ_SUCCESS:
      //   return {...state, new_reservation_request: action.payload.reservation_request, loading_add_reservation_request: false, error_add_reservation_request: null };
      // case ADD_RESERVATION_REQ_ERROR:
      //   return {...state, error_add_reservation_request: action.error, loading_add_reservation_request: false, new_reservation_request: null };
  
  
      // case GET_ONE_RESERVATION_REQ: return {...state, loading_one_reservation_request: true, error_one_reservation_request: null };
      // case GET_ONE_RESERVATION_REQ_SUCCESS:
      //   return {...state, selected_reservation_request: action.payload.reservation_request, loading_add_reservation_request: false, error_one_reservation_request: null };
      // case GET_ONE_RESERVATION_REQ_ERROR:
      //   return {...state, error_one_reservation_request: action.error, loading_one_reservation_request: false, selected_reservation_request: null };
  
  
  
      case EDIT_RESERVATION_REQ: return {...state, loading_edit_reservation_request: true, error_edit_reservation_request: null };
      case EDIT_RESERVATION_REQ_SUCCESS:
        return {...state, selected_reservation_request: action.payload.reservation_request, loading_edit_reservation_request: false, error_edit_reservation_request: null };
      case EDIT_RESERVATION_REQ_ERROR:
        return {...state, error_edit_reservation_request: action.error, loading_edit_reservation_request: false, selected_reservation_request: null };
  
  
  
  
      case DELETE_RESERVATION_REQ: return {...state, loading_delete_reservation_request: true, error_one_reservation_request: null };
      case DELETE_RESERVATION_REQ_SUCCESS:
        return {...state, loading_delete_reservation_request: false, error_delete_reservation_request: null };
      case DELETE_RESERVATION_REQ_ERROR:
        return {...state, error_delete_reservation_request: action.error, loading_delete_reservation_request: false };
  
  
      default: return state;
    }
  };
  
  export default reservationRequestsReducer;
  

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_KITCHEN_TYPES, getKitchenTypesSuccess, getKitchenTypesError, 
  ADD_KITCHEN_TYPE, addKitchenTypeError, addKitchenTypeSuccess, 
   GET_ONE_KITCHEN_TYPE, getOneKitchenTypeSuccess, getOneKitchenTypeError, 
  EDIT_KITCHEN_TYPE, editKitchenTypeError, editKitchenTypeSuccess,
  DELETE_KITCHEN_TYPE, deleteKitchenTypeError, deleteKitchenTypeSuccess
} from '../actions/kitchenTypesActions';
import { api } from '../../const';



// *** GET KITCHEN_TYPES
export function* watchGetKitchenTypes() { yield takeEvery(GET_KITCHEN_TYPES, getKitchenTypes) }
function* getKitchenTypes({ payload }) {
  try {
    const ret = yield call(getKitchenTypesRequest, payload);

    if (ret && ret.status ===200) yield put(getKitchenTypesSuccess({ kitchen_types: ret.data?.cuisines || [], count: ret.data?.count || 0}));
    else yield put(getKitchenTypesError({ message: ret.data ? ret.data.msg : "Error" }))
 
  } catch (error) {
    yield put(getKitchenTypesError({ error, message: (error) ? error.message : '' }));
  }

}
const getKitchenTypesRequest = async (payload) => {  
  return await axios.get(api.kitchen_types+'/get-all')
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





//*** GET ONE KITCHEN_TYPE
export function* watchGetOneKitchenType() { yield takeEvery(GET_ONE_KITCHEN_TYPE, getOneKitchenType) }
function* getOneKitchenType({ payload }) {
  try {
    const ret = yield call(getOneKitchenTypeRequest, payload);

    if (ret && ret.status ===200) yield put(getOneKitchenTypeSuccess({ kitchen_type: ret.data?.cuisine || null }));
    else yield put(getOneKitchenTypeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneKitchenTypeError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneKitchenTypeRequest = async (payload) => {  

  return await axios.get(api.kitchen_types+'/'+payload.kitchen_type_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD KITCHEN_TYPES

export function* watchAddKitchenTypes() { yield takeEvery(ADD_KITCHEN_TYPE, addKitchenType) }
function* addKitchenType({ payload }) {


  try {
    const ret = yield call(addKitchenTypesRequest, payload);

    if (ret && ret.status === 201) yield put(addKitchenTypeSuccess({ kitchen_type: ret.data }));
    else yield put(addKitchenTypeError({ message: ret.data ? ret.data.msg : "Error" }))
   

  } catch (error) {
    yield put(addKitchenTypeError({ error, message: (error) ? error.message : '' }));
  }
}
const addKitchenTypesRequest = async (payload) => {  
  return await axios.post(api.kitchen_types, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};


// *** EDIT KITCHEN_TYPES
export function* watchEditKitchenType() { yield takeEvery(EDIT_KITCHEN_TYPE, editKitchenType) }
function* editKitchenType({ payload }) {
  try {
    const ret = yield call(editKitchenTypesRequest, payload);

    if (ret && ret.status ===200) yield put(editKitchenTypeSuccess({ kitchen_type: ret.data }));
    else yield put(editKitchenTypeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editKitchenTypeError({ error, message: (error) ? error.message : '' }));
  }
}
const editKitchenTypesRequest = async (payload) => {  
  return await axios.patch(api.kitchen_types+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE KITCHEN_TYPES
export function* watchDeleteKitchenType() { yield takeEvery(DELETE_KITCHEN_TYPE, deleteKitchenType) }
function* deleteKitchenType({ payload }) {
  try {
    const ret = yield call(deleteKitchenTypesRequest, payload);

    if (ret && ret.status ===204) yield put(deleteKitchenTypeSuccess({ kitchen_type: ret.data }));
    else yield put(deleteKitchenTypeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteKitchenTypeSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteKitchenTypesRequest = async (payload) => {  

  return await axios.delete(api.kitchen_types+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};


export default function* rootSaga() {
  yield all([
    fork(watchGetKitchenTypes),
  fork(watchGetOneKitchenType),
    fork(watchAddKitchenTypes),
    fork(watchDeleteKitchenType),
    fork(watchEditKitchenType)
  ]);
}
import {
    GET_DIET, GET_DIET_SUCCESS, GET_DIET_ERROR,
    ADD_DIET, ADD_DIET_SUCCESS, ADD_DIET_ERROR,
    // GET_ONE_DIET, GET_ONE_DIET_SUCCESS, GET_ONE_DIET_ERROR,
  EDIT_DIET, EDIT_DIET_SUCCESS, EDIT_DIET_ERROR
  } from '../actions/dietActions';
    const initialState = {
    loading_get_diet_types: false,
    error_get_diet_types: null,
    diet_types: [],
    // count_all_diet_types: 0,
  
    new_Diet: null, loading_add_Diet: false, error_add_Diet: null,
  };
  
  const diet_typesReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_DIET: return {...state, loading_get_diet_types: true, error_get_diet_types: null };
      case GET_DIET_SUCCESS:
    
        return {...state, diet_types: [ ...action.payload.diet_types], loading_get_diet_types: false, error_get_diet_types: null };
      case GET_DIET_ERROR:

        return {...state, error_get_diet_types: action.error, loading_get_diet_types: false, diet_types: [] };
  
      case ADD_DIET: return {...state, loading_add_Diet: true, error_add_Diet: null }
     
      case ADD_DIET_SUCCESS:
        return {...state, new_Diet: action.payload.Diet, loading_add_Diet: false, error_add_Diet: null };
      case ADD_DIET_ERROR:
        return {...state, error_add_Diet: action.error, loading_add_Diet: false, new_Diet: null };

     

        case EDIT_DIET: return {...state, loading_edit_Diet: true, error_edit_Diet: null };
        case EDIT_DIET_SUCCESS:
          return {...state, loading_edit_Diet: false, error_edit_Diet: null };
        case EDIT_DIET_ERROR:
          return {...state, error_edit_Diet: action.error, loading_edit_Diet: false}; 
          
  
      // case GET_ONE_DIET: return {...state, loading_one_Diet: true, error_one_Diet: null };
      // case GET_ONE_DIET_SUCCESS:
      //   return {...state, selected_Diet: action.payload.Diet, loading_add_Diet: false, error_one_Diet: null };
      // case GET_ONE_DIET_ERROR:
      //   return {...state, error_one_Diet: action.error, loading_one_Diet: false, selected_Diet: null };
  
      default: return state;
    }
  };
  
  export default diet_typesReducer;
  
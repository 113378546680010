import {
    GET_ORDER_REQS, GET_ORDER_REQS_SUCCESS, GET_ORDER_REQS_ERROR,
    GET_ONE_ORDER_REQS,GET_ONE_ORDER_REQS_SUCCESS,GET_ONE_ORDER_REQS_ERROR,
    // ADD_ORDER_REQ, ADD_ORDER_REQ_SUCCESS, ADD_ORDER_REQ_ERROR,
    GET_ORDER_PAR_RESTO, GET_ORDER_PAR_RESTO_SUCCESS, GET_ORDER_PAR_RESTO_ERROR,
    GET_ORDER_BY_USER,GET_ORDER_BY_USER_SUCCESS,GET_ORDER_BY_USER_ERROR,
    GET_ORDER_BY_DELIVERYMAN,GET_ORDER_BY_DELIVERYMAN_SUCCESS,GET_ORDER_BY_DELIVERYMAN_ERROR,
    EDIT_ORDER_REQ, EDIT_ORDER_REQ_SUCCESS, EDIT_ORDER_REQ_ERROR,
    DELETE_ORDER_REQ, DELETE_ORDER_REQ_SUCCESS, DELETE_ORDER_REQ_ERROR
  } from '../actions/orderActions';
  
  const initialState = {
    loading_get_order_requests: false,
    error_get_order_requests: null,
    order_requests: [],
    count_all_order_requests: 0,
  
    new_order_request: null, loading_add_order_request: false, error_add_order_request: null,
  
    selected_order_request: null, loading_one_order_request: false, error_one_order_request: null,
    loading_get_one_order_requests:false,error_get_one_order_requests:null,
  
    loading_delete_order_request: false, error_delete_order_request: null,
    loading_order_admin_request:false ,error_order_admin_request:null,
  };
  
  const orderRequestsReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_ORDER_REQS: return {...state, loading_get_order_requests: true, error_get_order_requests: null };
      case GET_ORDER_REQS_SUCCESS:
        return {...state, order_requests: action.payload.order_requests, loading_get_order_requests: false, error_get_order_requests: null, count_all_order_requests: action.payload.count };
      case GET_ORDER_REQS_ERROR:
     
        return {...state, error_get_order_requests: action.error, loading_get_order_requests: false, order_requests: [] };
        
  

        case GET_ONE_ORDER_REQS: return {...state, loading_get_one_order_requests: true, error_get_one_order_requests: null };
        case GET_ONE_ORDER_REQS_SUCCESS:
          return {...state, order_requests: action.payload.order_requests, loading_get_one_order_requests: false, error_get_one_order_requests: null, count_all_order_requests: action.payload.count };
        case GET_ONE_ORDER_REQS_ERROR:
         
          return {...state, error_get_order_requests: action.error, loading_get_one_order_requests: false, order_requests: [] };
  
  
      // case ADD_ORDER_REQ: return {...state, loading_add_order_request: true, error_add_order_request: null };
      // case ADD_ORDER_REQ_SUCCESS:
      //   return {...state, new_order_request: action.payload.order_request, loading_add_order_request: false, error_add_order_request: null };
      // case ADD_ORDER_REQ_ERROR:
      //   return {...state, error_add_order_request: action.error, loading_add_order_request: false, new_order_request: null };
  
  
       case GET_ORDER_PAR_RESTO: return {...state, loading_order_resto_request: true, error_order_resto_request: null };
      case GET_ORDER_PAR_RESTO_SUCCESS:
         return {...state, selected_order_request: action.payload.order_request, loading_add_order_request: false, error_order_resto_request: null };
      case GET_ORDER_PAR_RESTO_ERROR:
       return {...state, error_one_order_request: action.error, loading_order_resto_request: false, selected_order_request: null };


       
       case GET_ORDER_BY_DELIVERYMAN: return {...state, loading_order_admin_request: true, error_order_admin_request: null };
       case GET_ORDER_BY_DELIVERYMAN_SUCCESS:
          return {...state, selected_order_request: action.payload.order_request, loading_order_admin_request: false, error_order_admin_request: null };
       case GET_ORDER_BY_DELIVERYMAN_ERROR:
        return {...state, error_order_admin_request: action.error, loading_order_admin_request: false, selected_order_request: null };
  
  

        case GET_ORDER_BY_USER: return {...state, loading_order_admin_request: true, error_order_admin_request: null };
        case GET_ORDER_BY_USER_SUCCESS:
           return {...state, selected_order_request: action.payload.order_request, loading_order_admin_request: false, error_order_admin_request: null };
        case GET_ORDER_BY_USER_ERROR:
         return {...state, error_order_admin_request: action.error, loading_order_admin_request: false, selected_order_request: null };
   
   
  
      case EDIT_ORDER_REQ: return {...state, loading_one_order_request: true, error_one_order_request: null };
      case EDIT_ORDER_REQ_SUCCESS:
        return {...state, selected_order_request: action.payload.order_request, loading_one_order_request: false, error_one_order_request: null };
      case EDIT_ORDER_REQ_ERROR:
        return {...state, error_one_order_request: action.error, loading_one_order_request: false, selected_order_request: null };
  
  
  
  
      case DELETE_ORDER_REQ: return {...state, loading_delete_order_request: true, error_one_order_request: null };
      case DELETE_ORDER_REQ_SUCCESS:
        return {...state, loading_delete_order_request: false, error_delete_order_request: null };
      case DELETE_ORDER_REQ_ERROR:
        return {...state, error_delete_order_request: action.error, loading_delete_order_request: false };
  
  
      default: return state;
    }
  };
  
  export default orderRequestsReducer;
  
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Button, Row, Col
} from 'reactstrap';
import { notification, Popconfirm, Modal, Switch, Table, Tag, Input, Space, } from 'antd';
import Select from 'react-select';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getUsers } from "../../../redux/actions/authActions";
import { api } from '../../../const';
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils'

import { getRestaurants } from "../../../redux/actions/restaurantsActions";
import { getCommande_Perdu,deleteCommande_Perdu } from "../../../redux/actions/commandeperduActions";
import axios from 'axios';

import { FormattedMessage } from 'react-intl';
import moment from 'moment'
import 'moment/locale/fr'

class ListRecommandationCmp extends PureComponent {

  state = {
    current_page: 1,
    totalPage: 1,
    selected_page_size: 10,
    page_sizes: [5, 10, 20, 30, 50],
    searchText: '',
    searchedColumn: '',
    isClearable: true,
    storageLanguage: localStorage.getItem('language')

  };
  toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }))


   

  handleChange = (pagination, filters) => {

    this.setState({ filteredInfo: filters });
  };
  componentDidMount(page = 1) {
    const { selected_page_size,
    } = this.state;

    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);


    this.props.getRestaurants({ offset, limit: selected_page_size })

this.props.getCommande_Perdu({ offset, limit: selected_page_size })
  }
  getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>

        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          > <FormattedMessage id="app.btn.chercher" />
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="app.btn.cancel" />
          </Button>

        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',


    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getResto = (e, page = 1) => {

    const { selected_page_size, restaurant } = this.state;
    const { restaurants } = this.props;

    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    let Resto = e.target.value
    this.setState({ RestoName: Resto })

    if (e.target.value !== "") {
      this.props.getRestaurants({ filterName: Resto, offset, limit: selected_page_size })

      this.setState({ IdResto: restaurants?._id })
    }
    else {

      this.getPage(page = 1)

    }



  }
  changeRestaurant = (value, page = 1) => {

    const { selected_page_size, IdResto } = this.state;

    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

    if (value !== null && IdResto !== null) {
      this.props.restaurants.map(opt => ({ label: opt, value: opt }));
      let IdResto = value.value

      this.props.getCommande_Perdu({ IdResto, offset, limit: selected_page_size })
    } else {
      this.getPage(page = 1)
    }
  }



  getPage(page = 1) {
    const { selected_page_size,
    } = this.state;
    
    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    this.props.getCommande_Perdu({ offset, limit: selected_page_size })
    this.props.getRestaurants({  offset, limit: selected_page_size })
  }

  onChangePage = page => {
    this.setState({ current_page: page }, () => this.getPage(page));
  };


  openNotificationWithIcon = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Request successfully accepted' : 'Demande accepté avec succès',

      });
  };

  openNotificationWithIconSuppression = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'White label Successfully deleted ' : 'La marque blanche est supprimer avec succès',

      });
  };
  openNotificationWithIcon = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Successful status change' : 'Changemenet du status avec succès',

      });
  };
  openNotificationWithIconErreur = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({

        duration: 3,
        message: storageLanguage === "en" ? 'Error' : 'Erreur',
        description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
      });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {

    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {

    this.setState({
      visible: false,
    });
  };





  showModalEdit = (marqueblanch) => {

    this.setState({
      visibledite: true,
      visible: false,
      selected_marqueblanch: marqueblanch
    });
  };

  handleOkEdit = e => {

    this.setState({
      visibledite: false,
    });
  };

  handleCancelEdit = e => {

    this.setState({
      visibledite: false,
    });
  };
  suppression = (id) => {
    axios.delete(api.commande_perdu + '/' + id).then(response => {
      if (response.status = 200) {
        this.openNotificationWithIconSuppression('success')
        this.getPage()


      }
      else {

        this.openNotificationWithIconErreur('error')
        this.getPage()

      }

    })

  }
  render() {
    const { count_all_commande_perdu, commande_perdu, restaurants } = this.props;
    const { storageLanguage, current_page, selected_page_size, isClearable } = this.state
    console.log('commande_perdu',commande_perdu)
    let listResto = [];

    restaurants && restaurants.map((element, i) =>


    listResto.push({
        value:element._id,
        label: element.name,
      })
    )
  
    const columns = [




      {

        title: <FormattedMessage id="app.restaurant" />,
        key: 'restaurant?.name',
        width: '20%',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>
            {  record.restaurant?.logo ? <img alt={record.restaurant?.name} src={`${api.root}/${record.restaurant?.logo?.path}`} style={{ width: 40 }} />
              : <img alt={record.restaurant?.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
            }
            <span> {record.restaurant?.name}</span>
          </div>)
        },

      },


      {
        title: <FormattedMessage id="app.reservation.Date" />,

        key: 'datee',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>{moment(record.createdAt).format('llll')}
          </div>)
        },


      },


        // {
        //   title: <FormattedMessage id="app.profile.action" />,
        //   width: '15%',



        //   render: (value, record, index) => {
        //     return (<div>
        //       <Popconfirm title={storageLanguage === "en" ? "Do you want to delete the order lost ?" : "Voulez-vous supprimer cette commande ?"} onConfirm={() => this.suppression(record._id)}
        //         onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
        //         <span className="lnr lnr-trash" style={{ color: "#dc3545", fontSize: 18, marginLeft: 25,cursor:'pointer' }}></span>
        //       </Popconfirm>


        //     </div>)
        //   }
        // },
    ];

    return (<Card>
      <CardBody>
        <div className="card__title">
          <div onChange={(e) => this.getResto(e)} >


          <Row style={{ marginBottom: 10 }}>
            <Col md="4" style={{ marginBottom: 10 }} >

              <Select isClearable={isClearable}

                onChange={(e) => this.changeRestaurant(e)}
                placeholder={<FormattedMessage id="app.restaurant" />}
                options={listResto} styles={{ background: '#e7e2e2', width: '250px', border: 'none', borderBottom: '1px solid #ddd' }} />

            </Col>
          </Row>
          </div>

         
        </div>

        <div>        <Table rowKey={(record, index) => index} scroll={{ x: 1000 }}
          dataSource={commande_perdu} columns={columns} bordered pagination={false}
          style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} /></div>
        <Pagination currentPage={current_page}
          totalPage={getCountPages(count_all_commande_perdu, selected_page_size)}
          onChangePage={i => this.onChangePage(i)} />

      </CardBody>


    </Card>)

      ;
  }
}

const mapStateToProps = ({ auth, Rq_Manager, restaurant ,order_lost}) => {

  const { user, users } = auth
  const { loading_get_demandemanager, error_get_demandemanager,} = Rq_Manager

  const { restaurants } = restaurant
  const{ count_all_commande_perdu ,commande_perdu}=order_lost


  return {
    user, users, restaurants,commande_perdu,
    loading_get_demandemanager, error_get_demandemanager, commande_perdu, count_all_commande_perdu
  }
}
export default connect(mapStateToProps, {
  getUsers,getRestaurants,getCommande_Perdu,deleteCommande_Perdu

})(ListRecommandationCmp);

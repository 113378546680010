import {
    GET_UserConfig, GET_UserConfig_SUCCESS, GET_UserConfig_ERROR,
    ADD_UserConfig, ADD_UserConfig_SUCCESS, ADD_UserConfig_ERROR,
    // GET_ONE_UserConfig, GET_ONE_UserConfig_SUCCESS, GET_ONE_UserConfig_ERROR,
  EDIT_UserConfig, EDIT_UserConfig_SUCCESS, EDIT_UserConfig_ERROR
  } from '../actions/UserConfigActions';
    const initialState = {
    loading_get_UserConfig: false,
    error_get_UserConfig: null,
    UserConfig: [],
    // count_all_UserConfig: 0,
  
    new_UserConfig: null, loading_add_UserConfig: false, error_add_UserConfig: null,
  };
  
  const UserConfigReducer = (state = initialState, action) => {

    switch (action.type) {
      case GET_UserConfig: return {...state, loading_get_UserConfig: true, error_get_UserConfig: null };
      case GET_UserConfig_SUCCESS:
          return {...state, UserConfig:action.payload?.UserConfig, loading_get_UserConfig: false, error_get_UserConfig: null };
        case GET_UserConfig_ERROR:

        return {...state, error_get_UserConfig: action.error, loading_get_UserConfig: false, UserConfig: [] };
  
      case ADD_UserConfig: return {...state, loading_add_UserConfig: true, error_add_UserConfig: null }
     
      case ADD_UserConfig_SUCCESS:
        return {...state, new_UserConfig: action.payload.UserConfig, loading_add_UserConfig: false, error_add_UserConfig: null };
      case ADD_UserConfig_ERROR:
        return {...state, error_add_UserConfig: action.error, loading_add_UserConfig: false, new_UserConfig: null };

     

        case EDIT_UserConfig: return {...state, loading_edit_UserConfig: true, error_edit_UserConfig: null };
        case EDIT_UserConfig_SUCCESS:
          return {...state, loading_edit_UserConfig: false, error_edit_UserConfig: null };
        case EDIT_UserConfig_ERROR:
          return {...state, error_edit_UserConfig: action.error, loading_edit_UserConfig: false}; 
          
  
      // case GET_ONE_UserConfig: return {...state, loading_one_UserConfig: true, error_one_UserConfig: null };
      // case GET_ONE_UserConfig_SUCCESS:
      //   return {...state, selected_UserConfig: action.payload.UserConfig, loading_add_UserConfig: false, error_one_UserConfig: null };
      // case GET_ONE_UserConfig_ERROR:
      //   return {...state, error_one_UserConfig: action.error, loading_one_UserConfig: false, selected_UserConfig: null };
  
      default: return state;
    }
  };
  
  export default UserConfigReducer;
  
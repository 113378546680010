import React from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AddOrders from '../../shared/components/Orders/addorder';

import { FormattedMessage } from 'react-intl';
const AddOrder = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Ajouter nouvelle commande </h3>
      </Col>
      <Col md={12}>
        <AddOrders/>
      </Col>
    </Row>
    <Row>
      
    </Row>
  </Container>
);

export default AddOrder;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
// import Carousel from '@brainhubeu/react-carousel';
// import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
// import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import '@brainhubeu/react-carousel/lib/style.css';
import {
     Col, Row , Button
  } from 'reactstrap';
  import { api } from '../../const';
  import { FormattedMessage } from 'react-intl';
export default class Gallery extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      _id:PropTypes.string,
    })).isRequired,
    // tags: PropTypes.arrayOf(PropTypes.shape({
    //   tag: PropTypes.string,
    //   title: PropTypes.string,
    // })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      images: props.images,
      currentTag: 'all',
      new_img:null,
      new_img_url:'',
      //tags: props.tags,
      lightboxIsOpen: false,
      currentImage: 0,
      carouselImages: [],
    };
  }

//   onFilter = (tag) => {
//     const { images } = this.props;
//     const image = images;
//     if (tag === 'all') {
//       this.setState({
//         images: image,
//         currentTag: 'all',
//       });
//     } else {
//       this.setState({
//         images: image.filter(t => t.type === tag),
//         currentTag: tag,
//       });
//     }
//   };

  componentDidUpdate(prev){
    const {images } = this.props
    if(prev.images !== images){
     
      this.setState({images})
    }
  }

  openLightbox = () => {
    
    this.setState({
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
      new_img:null,
      new_img_url:'',
    });
  };

  onChange = (value) => {
    this.setState({ currentImage: value });
  };

  carouselImages = () => {
    const { images } = this.state;
    this.setState({
      carouselImages: images.map(item => item.src),
    });
  };

  addImg = () => {
      const {images , new_img , new_img_url }  = this.state

      images.push({
          src:new_img_url,
          alt:'new image'
      })
      this.props.accept(new_img)
  
      this.setState({
        lightboxIsOpen: false,
        new_img:null,
        new_img_url:'',
        images
      });
  }

  deleteImg = (img,idx) =>{
    const {images  }  = this.state

    this.props.delete(img);
    images.splice(idx, 1);

    this.setState({
        images
      });
  }

  changeImage = (e) => {
    try {
      const new_img = e.target.files[0]
      this.setState({ new_img, new_img_url: URL.createObjectURL(new_img) }, ()=>{
        
      })
    } catch (error) { this.setState({ new_img: null, new_img_url: '' }) }
  }


  renderImage = (img , img_url) => {
      
    if(!img && img_url !== '') return (<img src={`${api.root}/${img}`} alt='clicknmeal' />)
    if(img && img_url !== '') return (<img alt='clicknmeal'  src={img_url} style={{ width: 'auto', height: '100%' ,maxHeight:'180px'}} />)
    return (<img alt='clicknmeal'  src={`${process.env.PUBLIC_URL}/img/no.png`} />)
  }
  render() {
    const {lightboxIsOpen,images , new_img , new_img_url  } = this.state;
  

    return (
      <div >
        {/* <div className="gallery__btns">
          <button
            type="button"
            className={`gallery__btn${currentTag === 'all' ? ' gallery__btn--active' : ''}`}
            onClick={e => this.onFilter('all', e)}
          >
            all
          </button>
          {tags.map(btn => (
            <button
              type="button"
              className={`gallery__btn${btn.tag === currentTag ? ' gallery__btn--active' : ''}`}
              key={btn}
              onClick={e => this.onFilter(btn.tag, e)}
            >
              {btn.title}
            </button>
          ))}
        </div> */}
        <Row>
            <Col md="12">
                <Button className="btn btn-small" color="primary" style={{ float: 'right' }}  onClick={() => this.openLightbox()}><FormattedMessage id="app.btn.addimage"/></Button>
            </Col>
            <Col md="12">
                <Row>
                    {images.map((img, index) => (
                        <Col md="2" sm="4" key={'image_' + index+'_'+img._id}>
                            <div    className="gallery__img-wrap"
           
                                style={{padding:'0px',width:'150px',height:'150px',display:'flex'}}
                            >
                                <img src={img.src} alt={img.alt} style={{ width: '80%', height: '80%' }}/>
                                <span onClick={()=>this.deleteImg(img,index)}  className="lnr lnr-trash"  style={{ color: "#dc3545", fontSize: 20 ,margin:'8px'}}></span>
                            </div>
                        </Col>
                    
                    ))}
                </Row>
                
            </Col>
        </Row>
        
        <Modal
          isOpen={lightboxIsOpen}
          toggle={this.closeLightbox}
          className="modal-dialog--primary"
        >
          <div className="modal__body">
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.closeLightbox} />
            </div>
            <div className="restaurant_img_wrap" >
                {this.renderImage(new_img , new_img_url)}
                    
                <div className="caption">
                    <label htmlFor="add_img"><span className="lnr lnr-picture"></span></label>
                    <input onChange={this.changeImage} id="add_img" type="file" accept="image/*" hidden />
                
                </div>
                
            </div>
            <div style={{margin:'8px'}}>
                <Button className="btn btn-small" color="primary"  onClick={this.addImg}><FormattedMessage id="app.btn.submit"/></Button>
            </div>
           
          </div>
        </Modal>
      </div>
    );
  }
}

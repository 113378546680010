
import React from 'react';

import GoogleMapReact from 'google-map-react';


class MapContaner extends React.Component {



  render() {

  
    return (
      <div style={{ height: `350px`, width:"100%"}} >
        {this.props.latitude !== 0 && this.props.longitude!== 0 ?


          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBLgrrbyKrR8ueJbQZNWRQXWT5L3cnlYbM' }}
            defaultCenter={{ lat: 48.84, lng: 2.20 }}
            center={{ lat: this.props.latitude, lng: this.props.longitude }}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals={true}
            title={this.props.OrderPlace?.fullAddress ?"":this.props.OrderPlace?.street}

          >

          </GoogleMapReact>

          : <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBLgrrbyKrR8ueJbQZNWRQXWT5L3cnlYbM' }}
            defaultCenter={{ lat: 48.84, lng: 2.20 }}
            center={{ lat: 48.84, lng: 2.20 }}
            defaultZoom={11}
        

          >
       

          </GoogleMapReact>
        }

      </div>

     
    );
  }
}
export default (MapContaner);
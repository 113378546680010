import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import EditOrderCmp from '../../shared/components/Orders/edit-order';

class OrderEditPage extends PureComponent {
  render() {
    return (<Container>
      <Row>
  
        <Col md={12}>
          <EditOrderCmp order_id={this.props.match?.params?.order_id} history={this.props.history} />
        </Col>
      </Row>
      <Row>

      </Row>
    </Container>)
  }
}

export default OrderEditPage;
// ***** GET DRESS_CODES
export const GET_DRESS_CODES            = 'GET_DRESS_CODES';
export const GET_DRESS_CODES_SUCCESS    = 'GET_DRESS_CODES_SUCCESS';
export const GET_DRESS_CODES_ERROR      = 'GET_DRESS_CODES_ERROR';

export function getDressCodes(payload) {
  return {type: GET_DRESS_CODES, payload};
}
export function getDressCodesSuccess(payload) {
  return { type: GET_DRESS_CODES_SUCCESS, payload };
}
export function getDressCodesError(error) {
  return { type: GET_DRESS_CODES_ERROR, error };
}






// ***** ADD DRESS_CODE
export const ADD_DRESS_CODE            = 'ADD_DRESS_CODE';
export const ADD_DRESS_CODE_SUCCESS    = 'ADD_DRESS_CODE_SUCCESS';
export const ADD_DRESS_CODE_ERROR      = 'ADD_DRESS_CODE_ERROR';

export function addDressCode(payload) {
  return {type: ADD_DRESS_CODE, payload};
}
export function addDressCodeSuccess(payload) {
  return { type: ADD_DRESS_CODE_SUCCESS, payload };
}
export function addDressCodeError(error) {
  return { type: ADD_DRESS_CODE_ERROR, error };
}






// ***** GET ONE DRESS_CODE
// export const GET_ONE_DRESS_CODE            = 'GET_ONE_DRESS_CODE';
// export const GET_ONE_DRESS_CODE_SUCCESS    = 'GET_ONE_DRESS_CODE_SUCCESS';
// export const GET_ONE_DRESS_CODE_ERROR      = 'GET_ONE_DRESS_CODE_ERROR';

// export function getOneDressCode(payload) {
//   return {type: GET_ONE_DRESS_CODE, payload};
// }
// export function getOneDressCodeSuccess(payload) {
//   return { type: GET_ONE_DRESS_CODE_SUCCESS, payload };
// }
// export function getOneDressCodeError(error) {
//   return { type: GET_ONE_DRESS_CODE_ERROR, error };
// }



// ***** EDIT DRESS_CODE
export const EDIT_DRESS_CODE            = 'EDIT_DRESS_CODE';
export const EDIT_DRESS_CODE_SUCCESS    = 'EDIT_DRESS_CODE_SUCCESS';
export const EDIT_DRESS_CODE_ERROR      = 'EDIT_DRESS_CODE_ERROR';

export function editDressCode(payload) {
  return {type: EDIT_DRESS_CODE, payload};
}
export function editDressCodeSuccess(payload) {
  return { type: EDIT_DRESS_CODE_SUCCESS, payload };
}
export function editDressCodeError(error) {
  return { type: EDIT_DRESS_CODE_ERROR, error };
}



// ***** EDIT DRESS_CODE
export const DELETE_DRESS_CODE            = 'DELETE_DRESS_CODE';
export const DELETE_DRESS_CODE_SUCCESS    = 'DELETE_DRESS_CODE_SUCCESS';
export const DELETE_DRESS_CODE_ERROR      = 'DELETE_DRESS_CODE_ERROR';

export function deleteDressCode(payload) {
  return {type: DELETE_DRESS_CODE, payload};
}
export function deleteDressCodeSuccess(payload) {
  return { type: DELETE_DRESS_CODE_SUCCESS, payload };
}
export function deleteDressCodeError(error) {
  return { type: DELETE_DRESS_CODE_ERROR, error };
}
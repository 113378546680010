import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col
} from 'reactstrap';
import{notification} from'antd';
import { api } from '../../../const';
import { NavLink } from 'react-router-dom';

import Alert from "../../helper/Alert";

import {getUsers} from "../../../redux/actions/authActions";
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select'
import {getRestaurants } from "../../../redux/actions/restaurantsActions";
import {getMarque_Blanche} from "../../../redux/actions/marqueblancheActions";
import axios from 'axios';
import { FormattedMessage } from 'react-intl';


const Template = [
    {label: 'template 1', value: 0},
    {label: 'template 2 ', value: 1},
    {label: 'template 3', value: 2}, 


 {label: 'template 4 ', value: 3},
 {label: 'template 5 ', value: 4}] 
class AddMarqueBlanche extends PureComponent {

    constructor() {
       
        super();
        this.state = {
          visible: false,
          logo:null,
          logo_url:'',
          cover:null,
          cover_url:'',
          name:'' , 
          description:'' ,
         domaine:'',
          template:[],
          error_frm:'',
          storageLanguage : localStorage.getItem('language')
        };
    }
    
    
    componentWillMount() {
        this.props.getRestaurants({})

    }
    changeCover = (e) => {
        try {
          const cover = e.target.files[0]
          this.setState({ cover, cover_url: URL.createObjectURL(cover) }, ()=>{
            
          })
        } catch (error) { this.setState({ cover: null, cover_url: '' }) }
      }

    changeLogo = (e) => {
        try {
          const logo = e.target.files[0]
          //this.setState({ logo, logo_url: URL.createObjectURL(logo) ,show_resize_img:true}, ()=>{
          this.setState({ logo, logo_url: URL.createObjectURL(logo) }, ()=>{
            
          })
        } catch (error) { this.setState({ logo: null, logo_url: '' }) }
      }
      renderImage = (img , img_url) => {
        if(!img && img_url !== '') return (<img src={`${api.root}/${img}`} alt='click&meal' style={{ width: '100%', height: '100%' }}/>)
        if(img && img_url !== '') return (<img src={img_url} style={{ width: '100%', height: '100%' }} />)
        return (<img src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: '100%', height: '100%' }}/>)}
      changeVal = (key, value) => {

        const obj = {}; obj[key] = value;
        this.setState(obj)
      }     
      render_Image = (img , img_url) => {

        let background =`${process.env.PUBLIC_URL}/img/no.png`
     
        if(!img && img_url !== ''){
          background = `${api.root}/${img}`
          return (<div style={{backgroundImage:`url(${background})`,width: '100%', height: '100%',backgroundPosition:'center center',backgroundSize:'cover'}}></div>)
        } 
        if(img && img_url !== ''){
          background = `${img_url}`
          return (<div style={{backgroundImage:`url(${background})`,width: '100%', height: '100%',backgroundPosition:'center center',backgroundSize:'cover'}}></div>)
        } 
     
        
        return (<div style={{backgroundImage:`url(${background})`, width: '100%', height: '100%',backgroundPosition:'center center',backgroundSize:'cover'}}>
     
        </div>)
      }
      changeRestoVal = (elem) => {
     
        this.setState({ 
            restaurant : elem.value
        })
     
      }
      changeTemplate= (elem) => {
        this.setState({ 
          template : elem.value
        })
     
      }
  submit = () => {

    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
 
    if (error_frm === "") {
      const { name,description , domaine,restaurant, logo } = this.state
      const data = { name,description , domaine,restaurant, logo }
      const new_data = JSON.parse(JSON.stringify(data)) 
      new_data["logo"] = JSON.parse(JSON.stringify(logo))

      const frm = new FormData();
      if (logo ) frm.append("logo",   logo);


      frm.append("name",   new_data.name);
      frm.append("description",   new_data.description);
      frm.append("domaine",   new_data.domaine);
      
      frm.append("restaurant",new_data.restaurant)
    
      let Data =frm
      axios.post(api.marque_blanche, Data).then(response=>{

        if(response.status=200 ) { 
          this.openNotificationWithIcon('success')  
      
  
        }
        else{
          this.openNotificationWithIconErreur('error')
         
        }
        let Id=response.data._id
        window.location.href="/app/marque-blanche/edit/"+Id
      }
       )
       }
  }


  openNotificationWithIcon= type => {
    const {storageLanguage}=this.state
    notification[type]

    ({
      duration:3,
      message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'White label Successfully created ':'La marque blanche est créer avec succès',
   
    });
  }; 
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
    notification[type]

    ({
      duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
		  description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
    });
  };
  checkFrm = (data) => {
    const {storageLanguage}=this.state
    const { name , description , domaine, template,restaurant} = data
    if (name === "") return storageLanguage === "en"? "the name are required.":'Nom marque blanche est obligatoire';
    if (domaine === "") return storageLanguage === "en"? "Field is required.":'le domaine est obligatoire';
    if (description === "") return storageLanguage === "en"? "Description is required.":'Description est obligatoire';
    if(restaurant===undefined) return storageLanguage === "en"? "Restaurant is required":'Réstaurant est obligatoire';
  

    return ""
  }

      render() {
        const {restaurants}=this.props;
        const {logo,logo_url}=this.state;
        let restaurant = [];

        restaurants.forEach(element => {
            restaurant.push({
            value:element._id,
            label: element.name,
          })
        }); 
      
                return (
                <div className="modal__body">
                <form className="form" onSubmit={(e) => {
                  e.preventDefault();
      
                  this.submit()
                }}>
       <Row>
                    <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
                      <Alert is_visible={(this.state.error_frm !== "")} color="danger" className="alert--colored" icon >
                        <p><span className="bold-text"><FormattedMessage id="app.warning"/></span> {this.state.error_frm}</p>
                      </Alert>
                    </Col>
      
      
                    <Col md={{  offset: 5,size: 7 }}>
                  <div className="restaurant_img_wrap" style={{marginBottom:'50px',height:'90px',width:'90px',overflow:'hidden',borderRadius:'5px'}}>
                    {this.renderImage(logo , logo_url)}
        
                    <div className="caption">
                      <label htmlFor="add_logo"><span className="lnr lnr-picture"></span></label>
                      <input onChange={this.changeLogo} id="add_logo" type="file" accept="image/*" hidden />
                    </div>
                  </div>
                </Col>
             
                      <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.blanche"/></span>
                                <div className="form__form-group-field">
                               
                                  <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                    name="name" component="input" placeholder="marque blanche"
                                    onChange={(e) => this.changeVal('name', e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.domaine"/></span>
                              
                                  <Field style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                    name="domaine" component="input" placeholder="domaine"
                                    onChange={(e) => this.changeVal('domaine', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                        <div className="form__form-group">
                          <span className="form__form-group-label"> <FormattedMessage id="app.restaurant"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select 
                                 onChange={(e) => this.changeRestoVal(e)}
                                   options={restaurant}
                                   styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                      </Col>
                     
                           
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.template"/></span>
                                <div className="form__form-group-field">
                                <div style={{width:'100%'}}>

                                <Select 
                                 onChange={(e) => this.changeTemplate(e)}
                                   options={Template}
                                   styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                               
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.setting.description"/></span>
                                <div className="form__form-group-field">
                                  <Field  component="textarea" style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                    name="description"  placeholder="description"
                                    onChange={(e) => this.changeVal('description', e.target.value)}   />
                                </div>
                              </div>
                            </Col>
                            <Col md={{  offset: 8,size: 4 }}>
      
      <Button className="btn btn-small" color="primary" type="submit"> <FormattedMessage id="app.btn.submit"/></Button>

      <NavLink className="btn btn-primary btn-small" color="primary"  to={"app/Marque-Blanche/list"}><FormattedMessage id="app.btn.cancel"/></NavLink>

    </Col>

                      </Row>
                      </form>
                </div>
                )
            }
        }
        
        const mapStateToProps = ({auth, marqueblanche,restaurant}) => {
           
            const {user,users } = auth
            const {loading_get_marqueblanche, error_get_marqueblanche,marque_blanche}=marqueblanche
            const {restaurants}=restaurant
         
         
        
        
            return {
                form: 'log_in_form',
               user,users,restaurants,
               loading_get_marqueblanche, error_get_marqueblanche,marque_blanche
            }
        }
        export default connect(mapStateToProps, {
            getUsers,getMarque_Blanche,getRestaurants
          
        }) (reduxForm()(AddMarqueBlanche));
        
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Detail from '../../shared/components/Carts/detailcarte'

class DetailCarte extends PureComponent {
  render() {
    return (<Container>
      <Row>
        {/* <Col md={12}>
          <h3 className="page-title">Add Restaurant</h3>
        </Col> */}

        <Col md={12}>
          <Detail Carte_id={this.props.match?.params?.Carte_id} />
        </Col>
      </Row>
      <Row>

      </Row>
    </Container>)
  }
}

export default DetailCarte;
import React, { PureComponent } from 'react';
import { Col, Row , Button } from 'reactstrap';
 
import { connect } from 'react-redux';

import {
	addDish
} from "../../../redux/actions/dishActions";

import AddDishsMdl from './add-list-dish-mdl';
import { FormattedMessage } from 'react-intl';
class AddDishCmp extends PureComponent {
  constructor() {
		super();
		this.state = {
            name: "",
            type: '',
            price:0,
            dish_picture: null,
            dish_picture_url: '',
            show_select_dishs: false
		};
  }

  changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
  }

  addDish = () =>{
    const { name, type, price, dish_picture, dish_picture_url } = this.state

    if(name!==''){
      const frm = new FormData()
      if(dish_picture && dish_picture_url!=='') frm.append('picture', dish_picture)
      frm.append('name', name)
      frm.append('type', type)
      frm.append('price', price)
      this.props.addDish(frm)
    }
  }
  

  componentDidUpdate(prev){
    const {new_dish} = this.props
    if(prev.loading_add_dish && !this.props.loading_add_dish && !this.props.error_add_dish){
     
      this.props.success(new_dish)
    } 
  }

  changeDishImg = (e) => {
    try {
      const dish_picture = e.target.files[0]
      this.setState({ dish_picture, dish_picture_url: URL.createObjectURL(dish_picture) })
    } catch (error) { this.setState({ dish_picture: null, dish_picture_url: '' }) }
  }

  render() {
    const { name, dish_picture_url,type,price, show_select_dishs} = this.state
    return (<Row style={{marginTop:'30px'}}>
      <Col md="2">
        <div className="add_img_wrap">
          <img alt={name} src={dish_picture_url == '' ? ` ${process.env.PUBLIC_URL}/img/no.png` : dish_picture_url } />

          <div className="caption">
            <label htmlFor="add_dish"><span className="lnr lnr-picture"></span></label>
            <input onChange={(e)=>this.changeDishImg(e)} id="add_dish" type="file" accept="image/*" hidden />
          </div>
        </div>
      </Col>

      <Col md={10}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>


            <div className="form__form-group-field" style={{margin:'5px'}}>
                
                    <Col md="4">
                        <span className="form__form-group-label"><FormattedMessage id="app.setting.titre"/></span>
                    </Col>
                    <Col md="8">
                        <input value={name} placeholder="Titre" onChange={(e)=>this.changeVal('name', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                    </Col>
                
                
              
            </div>

            <div  className="form__form-group-field" style={{margin:'5px'}}>
                
                    <Col md="4">
                        <span className="form__form-group-label"><FormattedMessage id="app.type"/></span>
                    </Col>
                    <Col md="8">
                        <input value={type} placeholder="Type" onChange={(e)=>this.changeVal('type', e.target.value)} style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}/>
                    </Col>
                
              
            </div>

            <div className="form__form-group-field" style={{margin:'5px'}}>
                
                    <Col md="4">
                        <span className="form__form-group-label"><FormattedMessage id="app.order.prix"/></span>
                    </Col>
                    <Col md="8">
                        <input value={price}  type="number" min="0" placeholder="0"
                         style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                         onChange={(e)=>this.changeVal('price', e.target.value)} />
                    </Col>
                
               
            </div>



            <div className="form__form-group-field" style={{marginTop:'10px'}}>
                <div onClick={this.addDish} className="form__form-group-icon" style={{background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer'}}>
                    <span className="lnr lnr-plus-circle"></span>
                </div>

                <div onClick={(this.props.cancelAdding) ? this.props.cancelAdding : ()=>{}} className="form__form-group-icon" style={{background: "#F44336",marginLeft:'10px', color: "#fff", border: "red", cursor: 'pointer'}}>
                    <span className="lnr lnr-cross-circle"></span>
                </div>

            </div>

          </div>
        </div>
      </Col>
      
        <AddDishsMdl toggle={() => this.setState((prev) => { return { show_select_dishs: !prev.show_select_dishs } })}
            is_open={show_select_dishs} 
           
            addPlat={this.props.addPlat}
	    />
    </Row>)
  }
}


const mapStateToProps = ({ dish }) => {
  const { loading_add_dish, error_add_dish   } = dish
  const new_dish = dish.dish
  return {
    loading_add_dish, error_add_dish , new_dish
  }
}
export default connect(mapStateToProps, {
    addDish
})(AddDishCmp);
import React from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ListOrders from '../../shared/components/Orders/list-orders';

import { FormattedMessage } from 'react-intl';
const ListOrdersparRestotCmp = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title"><FormattedMessage id="app.liste.commande" /> </h3>
      </Col>
      <Col md={12}>
        <ListOrders/>
      </Col>
    </Row>
    <Row>
      
    </Row>
  </Container>
);

export default ListOrdersparRestotCmp;

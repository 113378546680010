import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Button} from 'reactstrap';
import{notification,Popconfirm,Modal,Table, Input, Space,} from'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { api } from '../../../const';
import {getUsers} from "../../../redux/actions/authActions";

import moment from 'moment'
import 'moment/locale/fr' 
import axios from 'axios';
import { FormattedMessage } from 'react-intl';

class DemandeMarqueBlancheCmp extends PureComponent {

    constructor() {
        super();
        this.state = {
            recordebymanager:[],
            loading: false,
    visible: false,
    searchText: '',
    searchedColumn: '',
    storageLanguage : localStorage.getItem('language')
          
        };
    }

    handleChange = (pagination, filters) => {

      this.setState({ filteredInfo: filters });
    };
    getColumnSearchProps = dataIndex => ({
     
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
   
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              > <FormattedMessage id="app.btn.chercher"/> 
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="app.btn.cancel"/> 
            </Button>
       
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
  record[dataIndex]
  ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
  : '',      

   
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: text =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

  
    handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
  
    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };


    componentWillMount() {
        this.getPage()
    }
    componentDidMount() {

		this.getPage()

   
    
        }
        showModal = () => {
            this.setState({
              visible: true,
            });
          };
        
    
        cancel=(e)=> {
            console.log(e);
    
          }

     openNotificationWithIconSuppression= type => {
      const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
		  message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'The request is successfully deleted ':'La demande est supprimer avec succès',
   
		});
	  };
      openNotificationWithIconIngorer= type => {
        const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'The request is ignored ':	'Demande ignorer',
   
		});
	  };
	openNotificationWithIcon = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'The request is successfully accepted ':'La demande est accepter avec succès',
   
		});
	  };
	  openNotificationWithIconErreur = type => {
      const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  }
    
      accepter=(id,isAuthorized)=>{
        this.setState({ loading: true });

          axios.patch(api.requests+'/'+id,{isAuthorized:!isAuthorized}).then(response=>{
            	if (response.status=200){ 
            	  this.openNotificationWithIcon('success') 
                  this.getPage()
                  this.setState({ loading: false, visible: false });
            	}
            	else{
          
            	  this.openNotificationWithIconErreur('error')
                  this.setState({ loading: false, visible: false });
                  this.getPage()
                }
			  
            })
          
          }
	ignorer=(id,isAuthorized)=>{

            axios.patch(api.requests+'/'+id,{isAuthorized:!isAuthorized}).then(response=>{
                  if (response.status=200){ 
                    this.openNotificationWithIconIngorer('success') 
                    this.getPage()
                    this.setState({ loading: false, visible: false });
                 
                  }
                  else{
            
                    this.openNotificationWithIconErreur('error')
                    this.setState({ loading: false, visible: false });
                    this.getPage()
                  }
                
              })
            
		}
        suppression=(id)=>{
            axios.delete(api.requests+'/'+id).then(response=>{
                if (response.status=200){ 
                  this.openNotificationWithIconSuppression('success') 
                  this.getPage()
                  this.setState({ loading: false, visible: false });
               
                }
                else{
          
                  this.openNotificationWithIconErreur('error')
                  this.getPage()
                  this.setState({ loading: false, visible: false });
                }
              
            })
          
        }
    getPage(page = 1) {
      axios.get(api.requests).then(resp=>
        this.setState({recordebymanager:resp.data.requestWhiteLabel}))
     }

     handleCancel = () => {
      
        this.setState({
          visible: false,
        });
      };
    
   
    render() {
        const{recordebymanager, visible, loading,storageLanguage}=this.state
        
        const columns = [
  
          {
            
            title:  <FormattedMessage id="app.profile.manager" />,
            key: 'restaurant?.name',
            width: '20%',

            sortDirections: ['descend', 'ascend'],
            render: (value, record, index) => {
              return (<div>
              {  record.restaurant?.logo?<img alt={record.manager?.profile?.firstName} src={`${api.root}/${record.restaurant?.logo?.path}`} style={{ width: 40}} />
                : <img alt={record.manager?.profile?.firstName} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
              }
               <span>{record.manager?.profile?.firstName+" "+record.manager?.profile?.lastName}</span>
                </div>)
            },
            sorter: (a, b) => a.manager?.profile?.firstName.localeCompare(b.manager?.profile?.firstName),

          },
        
          {
            title:   <FormattedMessage id="app.restaurant"/>,
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend', 'ascend'],
            render: (value, record, index) => {
              return (<div>{record.restaurant?.name} </div>)
            },
          
          sorter: (a, b) => a.restaurant?.name.localeCompare(b.restaurant?.name),
          },
         
          {
            title: <FormattedMessage id="app.reservation.Date"/>,
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend', 'ascend'],
            render: (value, record, index) => {
              return (<div>{moment(record.createdAt).format('ll')}</div>
              
              )
            },
            sorter: (a, b) =>new Date(a.createdAt) - new Date(b.createdAt),
          
          },
       

          {
            title:   <FormattedMessage id="app.profile.action"/>,
            width:'15%',
         
           

            render: (value, record, index) => {
          return (<div>
  {!record.isAuthorized&&<span className="lnr lnr-lock"  
                          onClick={()=>this.showModal(record._id,record.isAuthorized)}  style={{ fontSize: 15, fontWeight: 'bolder', color: '#dc3545' }}>
                           
        </span>}
        
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" loading={loading} 
            onClick={()=>this.accepter(record._id,record.isAuthorized)}>
               <FormattedMessage id="app.accepter" />
            </Button>,
          
            <Button key="back" onClick={this.handleCancel}>
             <FormattedMessage id="app.annuler" />
          </Button>,
          ]}
        >
         
          <p> 
             <FormattedMessage id="app.demandemarque.msg" />{record.manager?.profile?.firstName+" "+record.manager?.profile?.lastName} </p>
      
        </Modal>
							
							
							<Popconfirm title={storageLanguage === "en"? "Do you want to delete the request of "+ record.manager?.profile?.firstName + " " + record.manager?.profile?.lastName+'?' :  "Voulez-vous supprimer la demmande de " + record.manager?.profile?.firstName + " " + record.manager?.profile?.lastName+' ?'} onConfirm={()=>this.suppression(record._id)}
                                 onCancel={this.cancel} onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />}  cancelText={<FormattedMessage id="app.btn.non" />} >
							<span className="lnr lnr-trash" style={{ color: "#dc3545", fontSize: 18,marginLeft:25 }}></span>
								</Popconfirm>
            </div>)
        }
          },
        ];


                return (<Card>
                   
            <CardBody>
            <div>        <Table rowKey={(record, index) => index} scroll={{ x: 1000 }} 
        dataSource={recordebymanager} columns={columns} bordered pagination={true}
  style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} /></div>  
              
            </CardBody>
           
        </Card>);
    }
}

const mapStateToProps = ({auth}) => {
   
    const {user,users } = auth
    

    return {
       user,users,

    }
}
export default connect(mapStateToProps, {
    getUsers
  
})(DemandeMarqueBlancheCmp);

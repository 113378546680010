
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
// import { api } from "../../constants/defaultValues";
import axios from "axios";


import {
  AUTHENTICATE, loginSuccess, loginError,
  GET_USERS, getUsersSuccess, getUsersError, 
  
  ADD_USER, addUserError, addUserSuccess,
  EDIT_USER, editUserError, editUserSuccess, 
  EDIT_USER_BY_ADMIN,editUserByAdminError, editUserByAdminSuccess, 
  GET_PROFILE, getProfileSuccess, getProfileError,
} from '../actions/authActions';
import { api } from '../../const';




//  *** LOGIN
export function* watchAuthenticate() { yield takeEvery(AUTHENTICATE, authenticate) }
function* authenticate({ payload }) {
  try {
    const ret = yield call(authRequest, {
        email: payload.email,
        password: payload.password
    });

    if (ret && ret.status === 200){
      try {


        yield put(loginSuccess({ user: ret.data}));
        
     // else yield put(loginError({ message: "Unauthorized access" }))
      } catch (error) {
        yield put(loginError({ message: "Unauthorized access" }))
      }
      
    } 
    else yield put(loginError({ message: "Error" }))

  } catch (error) {
    yield put(loginError({ error, message: (error) ? error.message : '' }));
  }
}
const authRequest = async (payload) => {  
  return await axios.post(api.users+'/login', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};







// *** GET USERS
export function* watchGetUsers() { yield takeEvery(GET_USERS, getUsers) }
function* getUsers({ payload }) {
  try {
    const ret = yield call(getUsersRequest, payload);

    if (ret && ret.status === 200) yield put(getUsersSuccess({ users: ret.data?.users || [], count: ret.data?.counts || 0}));
    else yield put(getUsersError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getUsersError({ error, message: (error) ? error.message : '' }));
  }
}
const getUsersRequest = async (payload) => {  


if(payload.obj==='true'){
  return await axios.get(api.users+'/get-all/'+payload.offset+'/'+payload.limit+'?'+'isActive='+payload.obj) 
}
if(payload.obj==='false'){
  return await axios.get(api.users+'/get-all/'+payload.offset+'/'+payload.limit+'?'+'isActive='+payload.obj) 
}
if(payload.obj===-1){
  return await axios.get(api.users+'/get-all/'+payload.offset+'/'+payload.limit)
 
}
if(payload.obj){
 
  return await axios.get(api.users+'/get-all/'+payload.offset+'/'+payload.limit+'?roles='+payload.obj)
}
return await axios.get(api.users+'/get-all/'+payload.offset+'/'+payload.limit)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};






// *** ADD USERS
export function* watchAddUsers() { yield takeEvery(ADD_USER, addUser) }
function* addUser({ payload }) {


  try {
    const ret = yield call(addUsersRequest, payload);

    if (ret && ret.status === 201) yield put(addUserSuccess({ user: ret.data }));
    else yield put(addUserError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addUserError({ error, message: (error) ? error.message : '' }));
  }
}
const addUsersRequest = async (payload) => {  
  return await axios.post(api.users+'/add', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT USERS
export function* watchEditUsers() { yield takeEvery(EDIT_USER, editUser) }
function* editUser({ payload }) {
  try {
    const ret = yield call(editUsersRequest, payload);

    if (ret && ret.status === 200) yield put(editUserSuccess({ user: ret.data }));
    else yield put(editUserError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editUserError({ error, message: (error) ? error.message : '' }));
  }
}
const editUsersRequest = async (payload) => {  
  return await axios.patch(api.users+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};

//*** EDIT USERS BY ADMIN
export function* watchEditUsersByAdmin() { yield takeEvery(EDIT_USER_BY_ADMIN, editUserByAdmin) }
function* editUserByAdmin({ payload }) {
  try {
    const ret = yield call(editUsersByAdminRequest, payload);

    if (ret && ret.status === 200) yield put(editUserByAdminSuccess({ user: ret.data }));
    else yield put(editUserByAdminError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editUserByAdminError({ error, message: (error) ? error.message : '' }));
  }
}
const editUsersByAdminRequest = async (payload) => {  
  
  return await axios.patch(api.users+'/update-profile/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** GET USER PROFILE
export function* watchGetProfile() { yield takeEvery(GET_PROFILE, getProfile) }
function* getProfile({ payload }) {
  try {
    const ret = yield call(getProfileRequest, payload);

    if (ret && ret.status === 200) yield put(getProfileSuccess({ user: ret.data.user }));
    else yield put(getProfileError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getProfileError({ error, message: (error) ? error.message : '' }));
  }
}
const getProfileRequest = async (payload) => {
  return await axios.get(api.users+'/'+payload._id, {withCredentials: true})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





export default function* rootSaga() {
  yield all([
    fork(watchAuthenticate),
    fork(watchGetUsers),
    fork(watchAddUsers),
    fork(watchEditUsers),
    fork(watchEditUsersByAdmin),
    fork(watchGetProfile),
  ]);
}
// ***** GET CARTS
export const GET_CARTS            = 'GET_CARTS';
export const GET_CARTS_SUCCESS    = 'GET_CARTS_SUCCESS';
export const GET_CARTS_ERROR      = 'GET_CARTS_ERROR';

export function getCarts(payload) {
  return {type:GET_CARTS, payload}; 
}
export function getCartsSuccess(payload) {
  return { type:GET_CARTS_SUCCESS, payload};
}
export function getCartsError(error) {
  return { type:GET_CARTS_ERROR, error };
}






// ***** ADD CART
export const ADD_CART            = 'ADD_CART';
export const ADD_CART_SUCCESS    = 'ADD_CART_SUCCESS';
export const ADD_CART_ERROR      = 'ADD_CART_ERROR';

export function addCart(payload) {
  return {type: ADD_CART, payload};
}
export function addCartSuccess(payload) {
  return { type: ADD_CART_SUCCESS, payload };
}
export function addCartError(error) {
  return { type: ADD_CART_ERROR, error };
}






// ***** GET ONE CART
export const GET_ONE_CART            = 'GET_ONE_CART';
export const GET_ONE_CART_SUCCESS    = 'GET_ONE_CART_SUCCESS';
export const GET_ONE_CART_ERROR      = 'GET_ONE_CART_ERROR';

export function getOneCart(payload) {
  return {type: GET_ONE_CART, payload};
}
export function getOneCartSuccess(payload) {
  return { type: GET_ONE_CART_SUCCESS, payload };
}
export function getOneCartError(error) {
  return { type: GET_ONE_CART_ERROR, error };
}



// ***** EDIT CART
export const EDIT_CART            = 'EDIT_CART';
export const EDIT_CART_SUCCESS    = 'EDIT_CART_SUCCESS';
export const EDIT_CART_ERROR      = 'EDIT_CART_ERROR';

export function editCart(payload) {
  return {type: EDIT_CART, payload};
}
export function editCartSuccess(payload) {
  return { type: EDIT_CART_SUCCESS, payload };
}
export function editCartError(error) {
  return { type: EDIT_CART_ERROR, error };
}



// ***** EDIT CART
export const DELETE_CART            = 'DELETE_CART';
export const DELETE_CART_SUCCESS    = 'DELETE_CART_SUCCESS';
export const DELETE_CART_ERROR      = 'DELETE_CART_ERROR';

export function deleteCart(payload) {
  return {type: DELETE_CART, payload};
}
export function deleteCartSuccess(payload) {
  return { type: DELETE_CART_SUCCESS, payload };
}
export function deleteCartError(error) {
  return { type: DELETE_CART_ERROR, error };
}
import {
    GET_BLOCS, GET_BLOCS_SUCCESS, GET_BLOCS_ERROR,
    ADD_BLOC, ADD_BLOC_SUCCESS, ADD_BLOC_ERROR,
    // GET_ONE_BLOC, GET_ONE_BLOC_SUCCESS, GET_ONE_BLOC_ERROR,
    EDIT_BLOC, EDIT_BLOC_SUCCESS, EDIT_BLOC_ERROR
  } from '../actions/blocsActions';
  
  const initialState = {
    loading_get_blocs: false,
    error_get_blocs: null,
    bloc: [],
    // count_all_bloc: 0,
  
    new_bloc: null, loading_add_bloc: false, error_add_bloc: null,
  };
  
  const BlocsReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_BLOCS: return {...state, loading_get_blocs: true, error_get_blocs: null };
      case GET_BLOCS_SUCCESS:
        return {...state, blocs: action.payload.blocs, loading_get_blocs: false, error_get_blocs: null };
      case GET_BLOCS_ERROR:

        return {...state, error_get_blocs: action.error, loading_get_blocs: false, blocs: [] };
  
  
  
      case ADD_BLOC: return {...state, loading_add_bloc: true, error_add_bloc: null };
      case ADD_BLOC_SUCCESS:
        return {...state, new_bloc: action.payload.bloc, loading_add_bloc: false, error_add_bloc: null };
      case ADD_BLOC_ERROR:
        return {...state, error_add_bloc: action.error, loading_add_bloc: false, new_bloc: null };
  
  
      // case GET_ONE_BLOC: return {...state, loading_one_bloc: true, error_one_bloc: null };
      // case GET_ONE_BLOC_SUCCESS:
      //   return {...state, selected_bloc: action.payload.bloc, loading_add_bloc: false, error_one_bloc: null };
      // case GET_ONE_BLOC_ERROR:
      //   return {...state, error_one_bloc: action.error, loading_one_bloc: false, selected_bloc: null };
  
  
  
      case EDIT_BLOC: return {...state, loading_edit_bloc: true, error_edit_bloc: null };
      case EDIT_BLOC_SUCCESS:
        return {...state, loading_edit_bloc: false, error_edit_bloc: null };
      case EDIT_BLOC_ERROR:
        return {...state, error_edit_bloc: action.error, loading_edit_bloc: false};
  
  
      default: return state;
    }
  };
  
  export default BlocsReducer;
  
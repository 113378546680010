// ***** GET RESERVATION_REQS
export const GET_RESERVATION_REQS            = 'GET_RESERVATION_REQS';
export const GET_RESERVATION_REQS_SUCCESS    = 'GET_RESERVATION_REQS_SUCCESS';
export const GET_RESERVATION_REQS_ERROR      = 'GET_RESERVATION_REQS_ERROR';

export function getreservationsReqs(payload) {
  return {type: GET_RESERVATION_REQS, payload};
}
export function getreservationsReqsSuccess(payload) {
  return { type: GET_RESERVATION_REQS_SUCCESS, payload };
}
export function getreservationsReqsError(error) {
  return { type: GET_RESERVATION_REQS_ERROR, error };
}





// ***** GET ONE RESERVATION_REQ
// export const GET_ONE_RESERVATION_REQ            = 'GET_ONE_RESERVATION_REQ';
// export const GET_ONE_RESERVATION_REQ_SUCCESS    = 'GET_ONE_RESERVATION_REQ_SUCCESS';
// export const GET_ONE_RESERVATION_REQ_ERROR      = 'GET_ONE_RESERVATION_REQ_ERROR';

// export function getOnereservationsReq(payload) {
//   return {type: GET_ONE_RESERVATION_REQ, payload};
// }
// export function getOnereservationsReqSuccess(payload) {
//   return { type: GET_ONE_RESERVATION_REQ_SUCCESS, payload };
// }
// export function getOnereservationsReqError(error) {
//   return { type: GET_ONE_RESERVATION_REQ_ERROR, error };
// }



// ***** EDIT RESERVATION_REQ
export const EDIT_RESERVATION_REQ            = 'EDIT_RESERVATION_REQ';
export const EDIT_RESERVATION_REQ_SUCCESS    = 'EDIT_RESERVATION_REQ_SUCCESS';
export const EDIT_RESERVATION_REQ_ERROR      = 'EDIT_RESERVATION_REQ_ERROR';

export function editreservationsReq(payload) {
  return {type: EDIT_RESERVATION_REQ, payload};
}
export function editreservationsReqSuccess(payload) {
  return { type: EDIT_RESERVATION_REQ_SUCCESS, payload };
}
export function editreservationsReqError(error) {
  return { type: EDIT_RESERVATION_REQ_ERROR, error };
}


// ***** DELETE RESERVATION_REQ
export const DELETE_RESERVATION_REQ            = 'DELETE_RESERVATION_REQ';
export const DELETE_RESERVATION_REQ_SUCCESS    = 'DELETE_RESERVATION_REQ_SUCCESS';
export const DELETE_RESERVATION_REQ_ERROR      = 'DELETE_RESERVATION_REQ_ERROR';

export function deletereservationsReq(payload) {
  return {type: DELETE_RESERVATION_REQ, payload};
}
export function deletereservationsReqSuccess(payload) {
  return { type: DELETE_RESERVATION_REQ_SUCCESS, payload };
}
export function deletereservationsReqError(error) {
  return { type: DELETE_RESERVATION_REQ_ERROR, error };
}
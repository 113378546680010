/* eslint-disable one-var */
// ***** GET BLOCS
export const GET_BLOCS = "GET_BLOCS";
export const GET_BLOCS_SUCCESS = "GET_BLOCS_SUCCESS";
export const GET_BLOCS_ERROR = "GET_BLOCS_ERROR";

export const getBlocs = (payload) => ({
    payload,
    "type": GET_BLOCS
});

export const getBlocsSuccess = (payload) => ({
    payload,
    "type": GET_BLOCS_SUCCESS
});


export const getBlocsError = (error) => ({
    error,
    "type": GET_BLOCS_ERROR
});

// ***** ADD BLOC
export const ADD_BLOC = "ADD_BLOC";
export const ADD_BLOC_SUCCESS = "ADD_BLOC_SUCCESS";
export const ADD_BLOC_ERROR = "ADD_BLOC_ERROR";

export const addBloc = (payload) => ({
    payload,
    "type": ADD_BLOC
});

export const addBlocSuccess = (payload) => ({
    payload,
    "type": ADD_BLOC_SUCCESS

});

export function addBlocError (error) {

    return {"type": ADD_BLOC_ERROR,
        error};

}


/*
 * ***** GET ONE BLOC
 * export const GET_ONE_BLOC            = 'GET_ONE_BLOC';
 * export const GET_ONE_BLOC_SUCCESS    = 'GET_ONE_BLOC_SUCCESS';
 * export const GET_ONE_BLOC_ERROR      = 'GET_ONE_BLOC_ERROR';
 */

/*
 * Export function getOneBloc(payload) {
 *   return {type: GET_ONE_BLOC, payload};
 * }
 * export function getOneBlocSuccess(payload) {
 *   return { type: GET_ONE_BLOC_SUCCESS, payload };
 * }
 * export function getOneBlocError(error) {
 *   return { type: GET_ONE_BLOC_ERROR, error };
 * }
 */


// ***** EDIT BLOC
export const EDIT_BLOC = "EDIT_BLOC";
export const EDIT_BLOC_SUCCESS = "EDIT_BLOC_SUCCESS";
export const EDIT_BLOC_ERROR = "EDIT_BLOC_ERROR";

export function editBloc (payload) {

    return {"type": EDIT_BLOC,
        payload};

}
export function editBlocSuccess (payload) {

    return {"type": EDIT_BLOC_SUCCESS,
        payload};

}
export function editBlocError (error) {

    return {"type": EDIT_BLOC_ERROR,
        error};

}


// ***** EDIT BLOC
export const DELETE_BLOC = "DELETE_BLOC";
export const DELETE_BLOC_SUCCESS = "DELETE_BLOC_SUCCESS";
export const DELETE_BLOC_ERROR = "DELETE_BLOC_ERROR";

export function deleteBloc (payload) {

    return {"type": DELETE_BLOC,
        payload};

}
export function deleteBlocSuccess (payload) {

    return {"type": DELETE_BLOC_SUCCESS,
        payload};

}
export function deleteBlocError (error) {

    return {"type": DELETE_BLOC_ERROR,
        error};

}

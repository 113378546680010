import React,{ PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import {getUserConfig } from "../../../redux/actions/UserConfigActions";

import { FormattedMessage } from 'react-intl';
 
class TotalProfitEarned extends PureComponent {

  componentDidMount() {

    	

    }
    render(){
      
      const {config}=this.props

let allcart=config!==undefined?config?.all:0
let cartverified=config!==undefined?config?.verified:0

let  carts=((cartverified*100)/allcart)
      return (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card">
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title dashboard__booking-total-title--red">
          {allcart}
          </h5>
         
        </div>
        <h5 className="dashboard__booking-total-description"><FormattedMessage id="app.panier" /></h5>
        <div className="progress-wrap progress-wrap--small progress-wrap--pink-gradient progress-wrap--rounded">
          <p className="dashboard__booking-card-progress-label progress__label">{cartverified}% <FormattedMessage id="app.profile.verifier"/></p> 
          <Progress  value={cartverified} />
       
        </div>
      </CardBody>
    </Card>
  </Col>
)
}
}


const mapStateToProps = ({UserConfig}) => {
  const{config}=UserConfig


  return {
    config:UserConfig?.UserConfig.carts,
  }
}
export default connect(mapStateToProps, { getUserConfig})
(TotalProfitEarned);

import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ListLivreur from '../../shared/components/Livreur/list-livreur';
import DetailLivreur from "./detail"


const OrdersPage = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

        <Route path={`${match.url}/list`}
          render={props => <ListLivreur/>}
        />
        <Route path={`${match.url}/detail/:deliveryman_id`}
          render={props => <DetailLivreur {...props} />}
        />

       
        <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
        <Redirect to="/error" />
      </Switch>
    
  </Suspense>
);
export default OrdersPage;

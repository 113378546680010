import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from '../../redux/store';
import ScrollToTop from './ScrollToTop';

import Axios from 'axios';
import { IntlProvider } from 'react-intl'
import French from '../../Lang/fr.json';
import English from '../../Lang/en.json';

import { Badge } from 'reactstrap';
// import socketIOClient from "socket.io-client";
// import { api } from '../../const';
Axios.defaults.withCredentials = true

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
      locale: 'fr',
      active: false,

      
    };

  

  this.messages = {
    en: English,
    fr: French
  };

  
  this.setEnLocale = this.setEnLocale.bind(this);
  this.setFRLocale = this.setFRLocale.bind(this);
  }
 
  setEnLocale() {
    const currentState = this.state.active;


  this.setState({locale: 'en',active: !currentState});
  localStorage.setItem('language', 'en');

  }
  
  setFRLocale() {
    const currentState = this.state.active;

  

  this.setState({ locale: 'fr' ,active: !currentState});

  localStorage.setItem('language', 'fr');

  }

  componentDidMount() {
    // IT WILL RENDER 3 TIMES IF WE MAKE LOADING !!!!!!!!!!!

    // window.addEventListener('load', () => {
    //   this.setState({ loading: false });
    //   setTimeout(() => this.setState({ loaded: true }), 500);
    // });
  }

  render() {

    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              {/* {!loaded
              && (
              <div className={`load${loading ? '' : ' loaded'}`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
              )
              }
              <div>
                <Router />
              </div> */}
              <div >
              <IntlProvider locale={this.state.locale} 
                messages={this.messages[this.state.locale]}>
              <Router />
              <div className="languageSelector"> 
       
              <Badge  href="#" className={this.state.active ?  null : 'badge-light'} 
             
               onClick={this.setFRLocale}>FR</Badge>
<Badge  href="#" className={this.state.active ?  'badge-light' : ''} 
 onClick={this.setEnLocale}>EN</Badge>
         
              </div>

              </IntlProvider>
              </div>
             
             
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
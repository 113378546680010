// ***** GET RESTAURANT_TYPES
export const GET_RESTAURANT_TYPES            = 'GET_RESTAURANT_TYPES';
export const GET_RESTAURANT_TYPES_SUCCESS    = 'GET_RESTAURANT_TYPES_SUCCESS';
export const GET_RESTAURANT_TYPES_ERROR      = 'GET_RESTAURANT_TYPES_ERROR';

export function getRestauranTypes(payload) {

  return {type: GET_RESTAURANT_TYPES, payload};
}
export function getRestauranTypesSuccess(payload) {
  return { type: GET_RESTAURANT_TYPES_SUCCESS, payload };
}
export function getRestauranTypesError(error) {
  return { type: GET_RESTAURANT_TYPES_ERROR, error };
}






// ***** ADD RESTAURANT_TYPE
export const ADD_RESTAURANT_TYPE            = 'ADD_RESTAURANT_TYPE';
export const ADD_RESTAURANT_TYPE_SUCCESS    = 'ADD_RESTAURANT_TYPE_SUCCESS';
export const ADD_RESTAURANT_TYPE_ERROR      = 'ADD_RESTAURANT_TYPE_ERROR';

export function addRestauranType(payload) {
  return {type: ADD_RESTAURANT_TYPE, payload};
}
export function addRestauranTypeSuccess(payload) {
  return { type: ADD_RESTAURANT_TYPE_SUCCESS, payload };
}
export function addRestauranTypeError(error) {
  return { type: ADD_RESTAURANT_TYPE_ERROR, error };
}

// ***** EDIT RESTAURANT_TYPE
export const EDIT_RESTAURANT_TYPE            = 'EDIT_RESTAURANT_TYPE';
export const EDIT_RESTAURANT_TYPE_SUCCESS    = 'EDIT_RESTAURANT_TYPE_SUCCESS';
export const EDIT_RESTAURANT_TYPE_ERROR      = 'EDIT_RESTAURANT_TYPE_ERROR';

export function editRestaurantType(payload) {
  return {type: EDIT_RESTAURANT_TYPE, payload};
}
export function editRestaurantTypeSuccess(payload) {
  return { type: EDIT_RESTAURANT_TYPE_SUCCESS, payload };
}
export function editRestaurantTypeError(error) {
  return { type: EDIT_RESTAURANT_TYPE_ERROR, error };
}



// ***** DELET RESTAURANT_TYPE
export const DELETE_RESTAURANT_TYPE            = 'DELETE_RESTAURANT_TYPE';
export const DELETE_RESTAURANT_TYPE_SUCCESS    = 'DELETE_RESTAURANT_TYPE_SUCCESS';
export const DELETE_RESTAURANT_TYPE_ERROR      = 'DELETE_RESTAURANT_TYPE_ERROR';

export function deleteRestautType(payload) {
  return {type: DELETE_RESTAURANT_TYPE, payload};
}
export function deleteRestautTypeSuccess(payload) {
  return { type: DELETE_RESTAURANT_TYPE_SUCCESS, payload };
}
export function deleteRestautTypeError(error) {
  return { type: DELETE_RESTAURANT_TYPE_ERROR, error };
}

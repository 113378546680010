import React, { PureComponent } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { notification } from 'antd'

import { connect } from 'react-redux';

import {
  getKitchenTypes,
  editKitchenType, deleteKitchenType, getOneKitchenType
} from "../../../redux/actions/kitchenTypesActions";
import { api } from '../../../const';
import axios from "axios";
import ReactQuill from "react-quill";
import { quillFormats, quillModules } from "../../helper/utils";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
class EditVTypeCmp extends PureComponent {
  constructor() {
    super();
    this.state = {
      name: "",
      description: "",
      picture: null,
      picture_url: '',
      _id: "",
    };
  }

  componentDidMount() {
    if (this.props.selected_kType !== null) {
      this.setState({
        name: this.props.selected_kType.name,
        description: this.props.selected_kType.description,
        picture: this.props.selected_kType.picture,
        // picture_url:this.props.selected_kType.picture.path,
        _id: this.props.selected_kType._id,
      })
    }
  }



  handleChange = (value) => {
    const obj = {};
    obj["description"] = value;
    this.setState(obj);
  };
  checkFrm = (data) => {

    const { name } = data
    if (name == "") return "Type cuisine est obligatoire";

    return ""
  }
  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }
  openNotificationWithIcon = type => {
    notification[type]

      ({
        duration: 3,
        message: 'Succès',
        description:
          'Type Réstaurant modifier',
      });
  };
  openNotificationWithIconErreur = type => {
    notification[type]

      ({
        duration: 3,
        message: 'Erreur',
        description:
          'un erreur se produit ',
      });
  };

  editVType = () => {

    const { name, description, picture, picture_url, _id } = this.state
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })
    if (error_frm === '') {


      const frm = new FormData()
      frm.append('name', name);
      frm.append('description', description);

      if (picture && picture_url != '') {
        frm.append('picture', picture);
      }

      axios.patch(api.kitchen_types + '/' + _id, frm).then(response => {
        if (response.status = 200) {
          this.openNotificationWithIcon('success')
          this.props.success()
          // this.props.getKitchenTypes()
        }
        else {

          this.openNotificationWithIconErreur('error')


        }

      })


    }
  }


  changeImg = (e) => {
    const { _id} = this.state
    try {
      const picture = e.target.files[0]
      this.setState({ picture, picture_url: URL.createObjectURL(picture) },()=>{
        const frm = new FormData()
        frm.append('picture', picture)
        
        this.props.editKitchenType({data:frm,_id})
        this.props.success()
      })
      this.setState({ picture, picture_url: URL.createObjectURL(picture) })
    } catch (error) { this.setState({ picture: null, picture_url: '' }) }
  }


  componentDidUpdate(prev) {

    if (prev.loading_edit_kitchen_type && !this.props.loading_edit_kitchen_type && !this.props.error_edit_kitchen_type) {
      this.props.success()
    }
  }




  render() {
    const { name, description, picture, picture_url, _id } = this.state

    return (<Row>

<Col md="2">
        <div className="add_img_wrap">
        {
            (picture && picture_url==='') ? <img alt={name} src={`${api.root}/${picture.path}`} />
         : <img alt={name} src={picture_url === '' ? ` ${process.env.PUBLIC_URL}/img/no.png` : picture_url } />
        }
          <div className="caption">
            <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
            <input onChange={(e)=>this.changeImg(e)} id="add_avatar" type="file" accept="image/*" hidden />
          </div>
        </div>
      </Col>
      {/* <Col md="2">
        
        <div className="add_img_wrap">
        {
            (picture && picture_url==='') ? <img alt={name} src={`${api.root}/${picture.path}`} />
            : <img  alt={name} src={picture_url === '' ? ` ${process.env.PUBLIC_URL}/img/no.png` : picture_url } />
          }

          <div className="caption">
        
       
         <label htmlFor="imageUpload"> <span className="lnr lnr-picture"/></label>
         <input type="file"
       id="imageUpload" name="avatar"
       accept="image/png, image/jpeg"
       onChange={(e)=>this.changeImg(e)} hidden />
        
          </div>
        </div>
      </Col> */}

      <Col md={10}>
        <div className="form">
          <div className="form__form-group">
            <span className="form__form-group-label">Name</span>

            <div className="form__form-group-field">
              <input value={name} placeholder="Name" onChange={(e) => this.changeVal('name', e.target.value)} />
            </div>

            <div className="form__form-group-field">
            <ReactQuill style={{ width: "100%" }}
                placeholder="Description"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={this.handleChange}
        
                />
            </div>


            



          </div>
          <div className="form__form-group-field">

              <div onClick={this.editVType} className="form__form-group-icon" style={{ background: "#4ce1b6", color: "#fff", border: "green", cursor: 'pointer' }}>
                <span className="lnr lnr-pencil"></span>
              </div>


              <div onClick={(this.props.cancelEditing) ? this.props.cancelEditing : () => { }} className="form__form-group-icon" style={{ background: "#F44336", color: "#fff", border: "red", cursor: 'pointer' }}>
                <span className="lnr lnr-cross-circle"></span>
              </div>

            </div>
        </div>

      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ ktype }) => {
  const { selected_kitchen_type, loading_edit_kitchen_type, error_edit_kitchen_type } = ktype
  return {
    selected_kitchen_type, loading_edit_kitchen_type, error_edit_kitchen_type
  }
}
export default connect(mapStateToProps, {
  getKitchenTypes,
  getOneKitchenType,
  editKitchenType,
  deleteKitchenType,
})(EditVTypeCmp);

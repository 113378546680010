import {
    GET_RESTAURANT_TYPES, GET_RESTAURANT_TYPES_SUCCESS, GET_RESTAURANT_TYPES_ERROR,
    ADD_RESTAURANT_TYPE, ADD_RESTAURANT_TYPE_SUCCESS, ADD_RESTAURANT_TYPE_ERROR,
    // GET_ONE_RESTAURANT_TYPE, GET_ONE_RESTAURANT_TYPE_SUCCESS, GET_ONE_RESTAURANT_TYPE_ERROR,
  EDIT_RESTAURANT_TYPE, EDIT_RESTAURANT_TYPE_SUCCESS, EDIT_RESTAURANT_TYPE_ERROR
  } from '../actions/RestauranTypesActions';
    const initialState = {
    loading_get_restaurant_types: false,
    error_get_restaurant_types: null,
    restaurant_types: [],
    // count_all_restaurant_types: 0,
  
    new_Restaurant_type: null, loading_add_Restaurant_type: false, error_add_Restaurant_type: null,
  };
  
  const restaurant_typesReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_RESTAURANT_TYPES: return {...state, loading_get_restaurant_types: true, error_get_restaurant_types: null };
      case GET_RESTAURANT_TYPES_SUCCESS:
        return {...state, restaurant_types: action.payload.restaurant_types, loading_get_restaurant_types: false, error_get_restaurant_types: null };
      case GET_RESTAURANT_TYPES_ERROR:
        
        return {...state, error_get_restaurant_types: action.error, loading_get_restaurant_types: false, restaurant_types: [] };
  
      case ADD_RESTAURANT_TYPE: return {...state, loading_add_Restaurant_type: true, error_add_Restaurant_type: null }
      case ADD_RESTAURANT_TYPE_SUCCESS:
        return {...state, new_Restaurant_type: action.payload.Restaurant_type, loading_add_Restaurant_type: false, error_add_Restaurant_type: null };
      case ADD_RESTAURANT_TYPE_ERROR:
        return {...state, error_add_Restaurant_type: action.error, loading_add_Restaurant_type: false, new_Restaurant_type: null };

     

        case EDIT_RESTAURANT_TYPE: return {...state, loading_edit_Restaurant_type: true, error_edit_Restaurant_type: null };
        case EDIT_RESTAURANT_TYPE_SUCCESS:
          return {...state, loading_edit_Restaurant_type: false, error_edit_Restaurant_type: null };
        case EDIT_RESTAURANT_TYPE_ERROR:
          return {...state, error_edit_Restaurant_type: action.error, loading_edit_Restaurant_type: false}; 
          
  
      // case GET_ONE_RESTAURANT_TYPE: return {...state, loading_one_Restaurant_type: true, error_one_Restaurant_type: null };
      // case GET_ONE_RESTAURANT_TYPE_SUCCESS:
      //   return {...state, selected_Restaurant_type: action.payload.Restaurant_type, loading_add_Restaurant_type: false, error_one_Restaurant_type: null };
      // case GET_ONE_RESTAURANT_TYPE_ERROR:
      //   return {...state, error_one_Restaurant_type: action.error, loading_one_Restaurant_type: false, selected_Restaurant_type: null };
  
      default: return state;
    }
  };
  
  export default restaurant_typesReducer;
  

import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import ListeDemanade from '../../shared/components/DemandeManager/listedemande'
import { FormattedMessage } from 'react-intl';

const ListDemandeManager = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title"> <FormattedMessage id="app.liste.demande" /></h3>
      </Col>

      <Col md={12}>
<ListeDemanade/>
      </Col>
    </Row>
  </Container>
);

export default ListDemandeManager;
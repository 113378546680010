import React, {PureComponent} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import DashPage from '../Dash/index';
import ProfilePage from '../Profile/index';

import UsersPage from '../Users/index';
import RestaurantsPage from '../restaurants/';

import ReservationsPage from '../reservations/index';
import SettingsPage from '../Settings/index';
import OrdersPage from '../Orders/index';
 import CartsPage from '../Carts/index';
 import MarqueBlanche from '../MarqueBlanche/index';
 import LivreurPage from "../Livreur/index"
 import DemandeManager from '../DemandeManager/index'


// const Router = (user) => (
//   <MainWrapper>
//     <main>
//       <Switch>
//         <Route exact path="/" component={LogIn} />
//         {user && <Route path="/" component={wrappedRoutes} />}
//       </Switch>
//     </main>
//   </MainWrapper>
// );


const admin_routes = [
  {path: "/app/users", component: UsersPage},
  {path: "/app/settings", component: SettingsPage},
  {path: "/app/marque-blanche", component: MarqueBlanche},
  {path:"/app/demande-manager",component:DemandeManager}

]

const tech_routes = [
  {path: "/app/profile", component: ProfilePage},
  {path: "/app/restaurants", component: RestaurantsPage},
  {path: "/app/reservations", component: ReservationsPage},
  {path:"/app/Order",component:OrdersPage},
  {path:"/app/Carts",component:CartsPage},
  {path:"/app/livreur",component:LivreurPage}
 
 
]

const Pages = (user) => (
  <Switch>
    <Route path="/app/dashboard" component={DashPage} />

    {tech_routes.map((route, i)=><Route key={i} path={route.path} component={route.component} />)}
    {(user && user.roles.includes('ADMIN')) && admin_routes.map((route, i)=><Route key={i} path={route.path} component={route.component} />)}

    <Redirect to="/app/dashboard" />
  </Switch>
);

const wrappedRoutes = (user) => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/app" component={()=>Pages(user)} />
    </div>
  </div>
);

class Router extends PureComponent {

  render(){
    const user = this.props.user

    return(
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" component={LogIn} />

            {(user) && <Route path="/" component={()=>wrappedRoutes(user)} />}
            {(!user)&&
               <Route exact path="/" component={LogIn} />}

            <Redirect to="/" />
          </Switch>
        </main>
      </MainWrapper>
    )
  }
}



const mapStateToProps = ({auth})=>{
  return {
    user: auth.user
  }
}
export default connect(mapStateToProps, {
  
})(Router);

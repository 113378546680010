
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_CARTS, getCartsSuccess, getCartsError, 
  ADD_CART, addCartError, addCartSuccess, 
 GET_ONE_CART, getOneCartSuccess, getOneCartError, 
  EDIT_CART, editCartError, editCartSuccess,
  DELETE_CART, deleteCartError, deleteCartSuccess
} from '../actions/carteActions';
import { api } from '../../const';



// *** GET CARTS
export function* watchGetCarts() { yield takeEvery(GET_CARTS,getCarts) }
function* getCarts({ payload }) {
  try {
    const ret = yield call(getCartsRequest, payload);

    if (ret && ret.status ===200) yield put(getCartsSuccess({ carts_req: ret.data.cart || [], count: ret.data?.counts	|| 0}));
    else yield put(getCartsError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getCartsError({ error, message: (error) ? error.message : '' }));
  }
}
const getCartsRequest = async (payload) => {  
  return await axios.get(api.cart+'/get-all/'+payload.offset+'/'+payload.limit)

  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};

// *** GET ONE CART
export function* watchGetOneCart() { yield takeEvery(GET_ONE_CART, getOneCart) }
function* getOneCart({ payload }) {
  try {
    const ret = yield call(getOneCartRequest, payload);

    if (ret && ret.status ===200) yield put(getOneCartSuccess({ CART: ret.data?.CART || null }));
    else yield put(getOneCartError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneCartError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneCartRequest = async (payload) => {  
  // const endpoint = (payload.track) ? '/track-one/' : '/get-one/'
  return await axios.get(api.cart+'/user')
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD CARTS
export function* watchAddCarts() { yield takeEvery(ADD_CART, addCart) }
function* addCart({ payload }) {
  try {

   
    const ret = yield call(addCartsRequest, payload);

    if (ret && ret.status ===200) yield put(addCartSuccess({ CART: ret.data }));
    else yield put(addCartError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addCartError({ error, message: (error) ? error.message : '' }));
  }
}
const addCartsRequest = async (payload) => {  

  return await axios.post(api.cart, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT CARTS
export function* watchEditCart() { yield takeEvery(EDIT_CART, editCart) }
function* editCart({ payload }) {
  try {
    const ret = yield call(editCartsRequest, payload);

    if (ret && ret.status ===200) yield put(editCartSuccess({ CART: ret.data }));
    else yield put(editCartError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editCartError({ error, message: (error) ? error.message : '' }));
  }
}
const editCartsRequest = async (payload) => {  
  return await axios.patch(api.cart+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE CARTS
export function* watchDeleteCart() { yield takeEvery(DELETE_CART, deleteCart) }
function* deleteCart({ payload }) {
  try {
    const ret = yield call(deleteCartsRequest, payload);

    if (ret && ret.status ===204) yield put(deleteCartSuccess({ CART: ret.data }));
    else yield put(deleteCartError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteCartSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteCartsRequest = async (payload) => {  
  return await axios.delete(api.cart+'/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetCarts),
    // fork(watchGetOneCart),
    fork(watchAddCarts),
    fork(watchDeleteCart),
    fork(watchEditCart)
  ]);
}
// ***** GET MENUS
export const GET_MENUS            = 'GET_MENUS';
export const GET_MENUS_SUCCESS    = 'GET_MENUS_SUCCESS';
export const GET_MENUS_ERROR      = 'GET_MENUS_ERROR';

export function getMenus(payload) {
  return {type: GET_MENUS, payload};
}
export function getMenusSuccess(payload) {
  return { type: GET_MENUS_SUCCESS, payload };
}
export function getMenusError(error) {
  return { type: GET_MENUS_ERROR, error };
}






// ***** ADD MENU
export const ADD_MENU           = 'ADD_MENU';
export const ADD_MENU_SUCCESS    = 'ADD_MENU_SUCCESS';
export const ADD_MENU_ERROR      = 'ADD_MENU_ERROR';

export function addMenu(payload) {
  return {type: ADD_MENU, payload};
}
export function addMenuSuccess(payload) {
  return { type: ADD_MENU_SUCCESS, payload };
}
export function addMenuError(error) {
  return { type: ADD_MENU_ERROR, error };
}




// ***** GET ONE MENU
export const GET_ONE_MENU            = 'GET_ONE_MENU';
export const GET_ONE_MENU_SUCCESS    = 'GET_ONE_MENU_SUCCESS';
export const GET_ONE_MENU_ERROR      = 'GET_ONE_MENU_ERROR';

export function getOneMenu(payload) {
  return {type: GET_ONE_MENU, payload};
}
export function getOneMenuSuccess(payload) {
  return { type: GET_ONE_MENU_SUCCESS, payload };
}
export function getOneMenuError(error) {
  return { type: GET_ONE_MENU_ERROR, error };
}



// ***** EDIT MENU
export const EDIT_MENU            = 'EDIT_MENU';
export const EDIT_MENU_SUCCESS    = 'EDIT_MENU_SUCCESS';
export const EDIT_MENU_ERROR      = 'EDIT_MENU_ERROR';

export function editMenu(payload) {
  return {type: EDIT_MENU, payload};
}
export function editMenuSuccess(payload) {
  return { type: EDIT_MENU_SUCCESS, payload };
}
export function editMenuError(error) {
  return { type: EDIT_MENU_ERROR, error };
}



// ***** DELETE MENU
export const DELETE_MENU            = 'DELETE_MENU';
export const DELETE_MENU_SUCCESS    = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_ERROR      = 'DELETE_MENU_ERROR';

export function deleteMenu(payload) {
  return {type: DELETE_MENU, payload};
}
export function deleteMenuSuccess(payload) {
  return { type: DELETE_MENU_SUCCESS, payload };
}
export function deleteMenuError(error) {
  return { type: DELETE_MENU_ERROR, error };
}
import axios from "axios"

// const axiosApi = axios.create({
//   baseURL: process.env.REACT_APP_API,
// })

// axios.defaults.withCredentials = true
// axiosApi.defaults.withCredentials = true


// axiosApi.interceptors.response.use(
//   response => response,
//   error => {
//     if (error?.response?.status === 401){
//       localStorage.removeItem("authUser")
//       localStorage.removeItem("authToken")

//       window.location.href = '/'
//     }

//     return Promise.reject(error)
//   }
// )

export function axiosGlobalConfigs() {
//   axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.defaults.withCredentials = true
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401){
        localStorage.removeItem("tunitable_user")
  
        window.location.href = '/'
      }
  
      return Promise.reject(error)
    }
  )

  axios.defaults.baseURL = process.env.REACT_APP_API
}
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListRecommandation from './recommandation';
import ListeMArques from'./list';

const DemandeManagerRoutingPages = ({ match }) => (


<Suspense fallback={<div className="loading" />}>
<Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

    <Route path={`${match.url}/list`}
      render={props => <ListeMArques {...props} />}
    />

    <Route path={`${match.url}/commandes-perdus`}
      render={props => <ListRecommandation {...props} />}
    />
   

 
    <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
    <Redirect to="/error" />
  </Switch>

</Suspense>
);
export default DemandeManagerRoutingPages;

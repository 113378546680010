import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import EditRestaurantCmp from '../../shared/components/restaurants/edit-restaurant-cmp';

class RestaurantsEditPage extends PureComponent {
  render() {
    return (<Container>
      <Row>
        {/* <Col md={12}>
          <h3 className="page-title">Add Restaurant</h3>
        </Col> */}

        <Col md={12}>
          <EditRestaurantCmp restaurant_id={this.props.match?.params?.restaurant_id} history={this.props.history} />
        </Col>
      </Row>
      <Row>

      </Row>
    </Container>)
  }
}

export default RestaurantsEditPage;
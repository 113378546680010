// ***** GET ORDER_REQS
export const GET_ORDER_REQS            = 'GET_ORDER_REQS';
export const GET_ORDER_REQS_SUCCESS    = 'GET_ORDER_REQS_SUCCESS';
export const GET_ORDER_REQS_ERROR      = 'GET_ORDER_REQS_ERROR';

export function getOrderReqs(payload) {
  return {type: GET_ORDER_REQS, payload};
}
export function getOrderReqsSuccess(payload) {
  return { type: GET_ORDER_REQS_SUCCESS, payload };
}
export function getOrderReqsError(error) {
  return { type: GET_ORDER_REQS_ERROR, error };
}


// ***** GET ONE ORDER_REQ
export const GET_ONE_ORDER_REQS           = 'GET_ONE_ORDER_REQS';
export const GET_ONE_ORDER_REQS_SUCCESS    = 'GET_ONE_ORDER_REQS_SUCCESS';
 export const GET_ONE_ORDER_REQS_ERROR      = 'GET_ONE_ORDER_REQS_ERROR';

export function getOneOrderReqs(payload) {
  return {type: GET_ONE_ORDER_REQS, payload};
 }
 export function getOneOrderReqsSuccess(payload) {
   return { type: GET_ONE_ORDER_REQS_SUCCESS, payload };
 }
 export function getOneOrderReqsError(error) {
   return { type: GET_ONE_ORDER_REQS_ERROR, error };
 }




// ***** GET ONE ORDER_REQ_PAR_RESTO
 export const GET_ORDER_PAR_RESTO            = 'GET_ORDER_PAR_RESTO';
export const GET_ORDER_PAR_RESTO_SUCCESS    = 'GET_ORDER_PAR_RESTO_SUCCESS';
 export const GET_ORDER_PAR_RESTO_ERROR      = 'GET_ORDER_PAR_RESTO_ERROR';

export function getOrderParResto(payload) {
  return {type: GET_ORDER_PAR_RESTO, payload};
 }
 export function getOrderParRestoSuccess(payload) {
   return { type: GET_ORDER_PAR_RESTO_SUCCESS, payload };
 }
 export function getOrderParRestoError(error) {
   return { type: GET_ORDER_PAR_RESTO_ERROR, error };
 }

 //******* GET ORDER BY USER
 
 export const GET_ORDER_BY_USER= 'GET_ORDER_BY_USER';
 export const GET_ORDER_BY_USER_SUCCESS    = 'GET_ORDER_BY_USER_SUCCESS';
 export const GET_ORDER_BY_USER_ERROR      = 'GET_ORDER_BY_USERN_ERROR';
 export function getOrderParUser(payload) {
  return {type: GET_ORDER_BY_USER, payload};
 }
 export function getOrderParUserSuccess(payload) {
   return { type: GET_ORDER_BY_USER_SUCCESS, payload };
 }
 export function getOrderParUserError(error) {
   return { type: GET_ORDER_BY_USER_ERROR, error };
 }

 //******* GET ORDER BY  DELIVERYMAN
 
 export const GET_ORDER_BY_DELIVERYMAN= 'GET_ORDER_BY_DELIVERYMAN';
 export const GET_ORDER_BY_DELIVERYMAN_SUCCESS    = 'GET_ORDER_BY_DELIVERYMAN_SUCCESS';
 export const GET_ORDER_BY_DELIVERYMAN_ERROR      = 'GET_ORDER_BY_DELIVERYMAN_ERROR';
 
 export function getOrderParDeliveryman(payload) {
  return {type: GET_ORDER_BY_DELIVERYMAN, payload};
 }
 export function getOrderParDeliverymanSuccess(payload) {
   return { type: GET_ORDER_BY_DELIVERYMAN_SUCCESS, payload };
 }
 export function getOrderParDeliverymanError(error) {
   return { type: GET_ORDER_BY_DELIVERYMAN_ERROR, error };
 }

 // ***** ADD ORDER_REQ

export const ADD_ORDER_REQ            = 'ADD_ORDER_REQ';
export const ADD_ORDER_REQ_SUCCESS    = 'ADD_ORDER_REQ_SUCCESS';
export const ADD_ORDER_REQ_ERROR      = 'ADD_ORDER_REQ_ERROR';

export function addOrderReq(payload) {
  return {type: ADD_ORDER_REQ, payload};
}
export function addOrderReqSuccess(payload) {
  return { type: ADD_ORDER_REQ_SUCCESS, payload };
}
export function addOrderReqError(error) {
  return { type: ADD_ORDER_REQ_ERROR, error };
}





// ***** EDIT ORDER_REQ
export const EDIT_ORDER_REQ            = 'EDIT_ORDER_REQ';
export const EDIT_ORDER_REQ_SUCCESS    = 'EDIT_ORDER_REQ_SUCCESS';
export const EDIT_ORDER_REQ_ERROR      = 'EDIT_ORDER_REQ_ERROR';

export function editOrderReq(payload) {
  return {type: EDIT_ORDER_REQ, payload};
}
export function editOrderReqSuccess(payload) {
  return { type: EDIT_ORDER_REQ_SUCCESS, payload };
}
export function editOrderReqError(error) {
  return { type: EDIT_ORDER_REQ_ERROR, error };
}


// ***** DELETE ORDER_REQ
export const DELETE_ORDER_REQ            = 'DELETE_ORDER_REQ';
export const DELETE_ORDER_REQ_SUCCESS    = 'DELETE_ORDER_REQ_SUCCESS';
export const DELETE_ORDER_REQ_ERROR      = 'DELETE_ORDER_REQ_ERROR';

export function deleteOrderReq(payload) {
  return {type: DELETE_ORDER_REQ, payload};
}
export function deleteOrderReqSuccess(payload) {
  return { type: DELETE_ORDER_REQ_SUCCESS, payload };
}
export function deleteOrderReqError(error) {
  return { type: DELETE_ORDER_REQ_ERROR, error };
}
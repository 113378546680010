import {
    GET_RESTAURANTS, GET_RESTAURANTS_SUCCESS, GET_RESTAURANTS_ERROR,
    GET_ONE_RESTAURANT,GET_ONE_RESTAURANT_SUCCESS , GET_ONE_RESTAURANT_ERROR ,
    ADD_RESTAURANT, ADD_RESTAURANT_SUCCESS, ADD_RESTAURANT_ERROR,
    EDIT_RESTAURANT, EDIT_RESTAURANT_SUCCESS, EDIT_RESTAURANT_ERROR,
    DELETE_RESTAURANT, DELETE_RESTAURANT_SUCCESS, DELETE_RESTAURANT_ERROR
  } from '../actions/restaurantsActions';
  
  const initialState = {
    loading_get_restaurants: false,
    error_get_restaurants: null,
    restaurants: [],
    count_all_restaurants: 0,
    loading_one_restaurant:false,
  
    new_restaurant: null,selected_restaurant:null, loading_add_restaurant: false, error_add_restaurant: null,
  
    loading_delete_restaurant: false, error_delete_restaurant: null,
  
    nearby_restaurants: [],
  };
  
  const restaurantsReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_RESTAURANTS: return {...state, loading_get_restaurants: true, error_get_restaurants: null };
      case GET_RESTAURANTS_SUCCESS:
        return {...state, restaurants: action.payload.restaurants,count_all_restaurants:action.payload.count, loading_get_restaurants: false, error_get_restaurants: null };
      case GET_RESTAURANTS_ERROR:
     
        return {...state, error_get_restaurants: action.error, loading_get_restaurants: false, restaurants: [] };
  
      

  
      case ADD_RESTAURANT: return {...state, loading_add_restaurant: true, error_add_restaurant: null };
      case ADD_RESTAURANT_SUCCESS:
        return {...state, new_restaurant: action.payload.restaurant, loading_add_restaurant: false, error_add_restaurant: null };
      case ADD_RESTAURANT_ERROR:
        return {...state, error_add_restaurant: action.error, loading_add_restaurant: false, new_restaurant: null };
  
  
      case GET_ONE_RESTAURANT: return {...state, loading_one_restaurant: true, error_one_restaurant: null };
      case GET_ONE_RESTAURANT_SUCCESS:
        return {...state, selected_restaurant: action.payload.restaurant, loading_add_restaurant: false, error_one_restaurant: null };
      case GET_ONE_RESTAURANT_ERROR:
        return {...state, error_one_restaurant: action.error, loading_one_restaurant: false, selected_restaurant: null };
  
  
  
      case EDIT_RESTAURANT: return {...state, loading_edit_restaurant: true, error_edit_restaurant: null };
      case EDIT_RESTAURANT_SUCCESS:
        return {...state, loading_edit_restaurant: false, error_edit_restaurant: null };
      case EDIT_RESTAURANT_ERROR:
        return {...state, error_edit_restaurant: action.error, loading_edit_restaurant: false};
  
  
  
  
  
      case DELETE_RESTAURANT: return {...state, loading_delete_restaurant: true, error_delete_restaurant: null };
      case DELETE_RESTAURANT_SUCCESS:
        return {...state, loading_delete_restaurant: false, error_delete_restaurant: null };
      case DELETE_RESTAURANT_ERROR:
        return {...state, error_delete_restaurant: action.error, loading_delete_restaurant: false};
  
  
      default: return state;
    }
  };
  
  export default restaurantsReducer;
  
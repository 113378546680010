
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_UserConfig, getUserConfigSuccess, getUserConfigError, 
  ADD_UserConfig, addUserConfigError, addUserConfigSuccess, 
  // GET_ONE_UserConfig, getOneUserConfiguccess, getOneUserConfigError, 
 EDIT_UserConfig, editUserConfigError, editUserConfigSuccess,
 
 DELETE_UserConfig, deleteUserConfigError, deleteUserConfigSuccess
} from '../actions/UserConfigActions';
import { api } from '../../const';

// *** GET UserConfigS
export function* watchGetUserConfig() { yield takeEvery(GET_UserConfig, getUserConfig) }
function* getUserConfig({ payload }) {
  try {
    const ret = yield call(getUserConfigRequest, payload);

    if (ret && ret.status === 200) yield put(getUserConfigSuccess({ UserConfig:ret.data}))
    else yield put(getUserConfigError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getUserConfigError({ error, message: (error) ? error.message : '' }));
  }
}
const getUserConfigRequest = async (payload) => {  
  return await axios.get(api.User_Config +'/counts')

  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};


// *** ADD UserConfigS
export function* watchaddUserConfig() { yield takeEvery(ADD_UserConfig, addUserConfig) }
function* addUserConfig({ payload }) {
  try {
    const ret = yield call(addUserConfigRequest, payload);
    if (ret && ret.status === 200) yield put(addUserConfigSuccess({ UserConfig: ret.data }));
    else yield put(addUserConfigError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addUserConfigError({ error, message: (error) ? error.message : '' }));
  }
}
const addUserConfigRequest = async (payload) => {  
  return await axios.post(api.UserConfig_types, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
  
};


// *** EDIT UserConfigS
export function* watchEditUserConfig() { yield takeEvery(EDIT_UserConfig, editUserConfig) }
function* editUserConfig({ payload }) {
 try {
    const ret = yield call(editUserConfigRequest, payload);

    if (ret && ret.status === 200) yield put(editUserConfigSuccess({ UserConfig: ret.data }));
   else yield put(editUserConfigError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
   yield put(editUserConfigError({ error, message: (error) ? error.message : '' }));
 }
}
const editUserConfigRequest = async (payload) => {  

 return await axios.patch(api.UserConfig_types+'/'+payload._id,payload)
.then(resp => resp)
 .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE UserConfigS
export function* watchDeleteUserConfig() { yield takeEvery(DELETE_UserConfig,deleteUserConfig) }
function* deleteUserConfig({ payload }) {
 try {
   const ret = yield call(deleteUserConfigRequest, payload);

   if (ret && ret.status === 204) yield put(deleteUserConfigSuccess({ UserConfig: ret.data }));
    else yield put(deleteUserConfigError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteUserConfigSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteUserConfigRequest = async (payload) => {  
  return await axios.delete(api.UserConfig_types+'/'+payload._id, payload.data)
 .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};

export default function* rootSaga() {
    yield all([
      fork(watchGetUserConfig),
      fork(watchaddUserConfig),
     fork(watchDeleteUserConfig),
     fork(watchEditUserConfig)
    ]);
  }
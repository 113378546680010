import {
    GET_DRESS_CODES, GET_DRESS_CODES_SUCCESS, GET_DRESS_CODES_ERROR,
    ADD_DRESS_CODE, ADD_DRESS_CODE_SUCCESS, ADD_DRESS_CODE_ERROR,
    // GET_ONE_DRESS_CODE, GET_ONE_DRESS_CODE_SUCCESS, GET_ONE_DRESS_CODE_ERROR,
    EDIT_DRESS_CODE, EDIT_DRESS_CODE_SUCCESS, EDIT_DRESS_CODE_ERROR
  } from '../actions/dressCodeActions';
  
  const initialState = {
    loading_get_dress_codes: false,
    error_get_dress_codes: null,
    dress_codes: [],
    // count_all_dress_codes: 0,
  
    new_dress_code: null, loading_add_dress_code: false, error_add_dress_code: null,
  };
  
  const dressCodesReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case GET_DRESS_CODES: return {...state, loading_get_dress_codes: true, error_get_dress_codes: null };
      case GET_DRESS_CODES_SUCCESS:
        return {...state, dress_codes: action.payload.dress_codes, loading_get_dress_codes: false, error_get_dress_codes: null };
      case GET_DRESS_CODES_ERROR:
        
        return {...state, error_get_dress_codes: action.error, loading_get_dress_codes: false, dress_codes: [] };
  
  
  
      case ADD_DRESS_CODE: return {...state, loading_add_dress_code: true, error_add_dress_code: null };
      case ADD_DRESS_CODE_SUCCESS:
        return {...state, new_dress_code: action.payload.dress_code, loading_add_dress_code: false, error_add_dress_code: null };
      case ADD_DRESS_CODE_ERROR:
        return {...state, error_add_dress_code: action.error, loading_add_dress_code: false, new_dress_code: null };
  
  
      // case GET_ONE_DRESS_CODE: return {...state, loading_one_dress_code: true, error_one_dress_code: null };
      // case GET_ONE_DRESS_CODE_SUCCESS:
      //   return {...state, selected_dress_code: action.payload.dress_code, loading_add_dress_code: false, error_one_dress_code: null };
      // case GET_ONE_DRESS_CODE_ERROR:
      //   return {...state, error_one_dress_code: action.error, loading_one_dress_code: false, selected_dress_code: null };
  
  
  
      case EDIT_DRESS_CODE: return {...state, loading_edit_dress_code: true, error_edit_dress_code: null };
      case EDIT_DRESS_CODE_SUCCESS:
        return {...state, loading_edit_dress_code: false, error_edit_dress_code: null };
      case EDIT_DRESS_CODE_ERROR:
        return {...state, error_edit_dress_code: action.error, loading_edit_dress_code: false};
  
  
      default: return state;
    }
  };
  
  export default dressCodesReducer;
  
import React,{ PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Edit from '../../shared/components/MarqueBlanche/editMarqueBlanche'

class EditMarqueBlanche extends PureComponent {
  render() {
    return (<Container>
      <Row>
        

        <Col md={12}>
        <Edit marqueblanch_id={this.props.match?.params?.marqueblanch_id} history={this.props.history} />
        </Col>
      </Row>
      <Row>

      </Row>
    </Container>)
  }
}

export default EditMarqueBlanche;


import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import {Row, Col, Container} from 'reactstrap';

import {getProfile, editUserByAdmin} from "../../../redux/actions/authActions";

import {
	getreservationsReqs, editreservationsReq, deletereservationsReq
} from "../../../redux/actions/reservationsReqsActions";

import { api } from '../../../const';
import ProfileMain from './profile/ProfileMain';
import ProfileTabs from './profile/ProfileTabs';
import axios from 'axios'


class EditUsersCmp extends PureComponent {

  constructor() {
    super();
    this.state = {
      active_tab: "Vehicles",
      avatar: null,
      avatar_url: "",
      profil:[],

      count_trips: 0, count_finished: 0, count_canceled: 0,

      fav_places: []
    };
  }

  toggle = (active_tab) => this.setState({active_tab})


  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  componentWillMount = () => {
     let Id=this.props.user_id

    this.props.getProfile({ _id: this.props.user_id})
    // axios.get(api.users+"/"+Id)
    // .then(resp => {
    //   const profil = resp.data.user;
    //   this.setState({ profil });

    // })
		// .catch(error => { return (error && error.response) ? error.response : error })



    this.getUserreservationsReq()
  }


  componentDidUpdate(prev) {
    const { 
      loading_edit_user, error_edit_user,
      loading_one_drive_request, error_one_drive_request
    } = this.props



		if (!loading_one_drive_request && prev.loading_one_drive_request && !error_one_drive_request){
      
      this.getUserreservationsReq()
		}
  }

  getUserreservationsReq = () => {
    this.props.getreservationsReqs({
      filter: {
        statuses: ['request'], user: this.props.user_id
      },
      offset: 0, limit: 1
    })
  }

  changeAvatar = (e) => {
    try {
      const avatar = e.target.files[0]
      this.setState({ avatar, avatar_url: URL.createObjectURL(avatar) }, ()=>{
        const frm = new FormData()
        frm.append('picture', avatar)
const data=frm
let  id= this.props.user_id
axios.patch(api.users+'/update-profile/'+id,data)
.then(resp => {
  if(resp.data=200){
    this.props.getProfile({ _id: this.props.user_id })
  }
})
      
        
      })
  
    } catch (error) { this.setState({ avatar: null, avatar_url: '' }) }

  }
  editProfile = (data) => {

    this.props.editUserByAdmin({
      _id: this.props.user_id,
      data
    })
  
  }

  render() {

    const {count_trips, count_finished, count_canceled} = this.state;

    const { profile, reservations } = this.props



    return (<Container>
      <div className="profile">
        {profile &&<Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain 
                profile={profile} editProfile={this.editProfile}
                 changeAvatar={this.changeAvatar} 
                 />

            </Row>
          </Col>
          <ProfileTabs id={this.props.user_id} />
        </Row>}
      </div>
    </Container>);
  }
}

const mapStateToProps = ({ auth, reservationsReq }) => {
  const { loading_profile, error_profile, profile, user, loading_edit_user, error_edit_user } = auth
  const { 
    reservations, loading_one_drive_request, error_one_drive_request} = reservationsReq
  
  return {
    profile, loading_profile, error_profile, user,
    loading_edit_user, error_edit_user,
		reservations, loading_one_drive_request, error_one_drive_request,
  }
}
export default connect(mapStateToProps, {
  getProfile, editUserByAdmin,
  getreservationsReqs, editreservationsReq, deletereservationsReq
})(EditUsersCmp);
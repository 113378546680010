import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';

import French from './Lang/fr.json';
const locale = navigator.language;
let lang;
if (locale === "fr") {
       lang = French;
}
ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,

 
  <App />

, document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Card, CardBody, Container, Button, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import {
  Table, Tag, Switch, notification, Typography
} from 'antd';
import Select from 'react-select'
import classnames from 'classnames';

import Alert from "../../helper/Alert";
//import { NavLink } from 'react-router-dom';

import { addRestaurant, getOneRestaurant, editRestaurant } from "../../../redux/actions/restaurantsActions";
import { getRestauranTypes } from '../../../redux/actions/RestauranTypesActions'
import { getDiet } from "../../../redux/actions/dietActions"
import AddMenuCmp from "../../components/menu/add-menu";
import EditMenuCmp from "../../components/menu/edit-menu";
import EditDishCmp from "../../components/menu/edit-dish";
import AddDishCmp from "../../components/menu/add-dish";
import { getVEGAN } from "../../../redux/actions/VEGANActions"

import CollapseCmp from "../../components/Collapse";

import { getKitchenTypes } from "../../../redux/actions/kitchenTypesActions";
import { getDressCodes } from "../../../redux/actions/dressCodeActions";
import { getUsers } from "../../../redux/actions/authActions"
import { getFeatures, deleteFeature } from "../../../redux/actions/feautreActions"
import Gallery from '../gallery';
import { api } from '../../../const';
import axios from "axios";
import { FormattedMessage } from 'react-intl';
import moment from 'moment'
import 'moment/locale/fr'
const { Text, Link } = Typography;

const payments = [
  { label: 'cb', value: 0 },
  { label: 'ae ', value: 1 },
  { label: 'espace', value: 2 }]

const StatusRestaurany = []
StatusRestaurany.push({ label: <FormattedMessage id="app.creationInProgress" />, code: 0 })
StatusRestaurany.push({ label: <FormattedMessage id="app.profile.verifier" />, code: 1 })
StatusRestaurany.push({ label: <FormattedMessage id="app.Partenaire" />, code: 2 })

class EditRestaurantCmp extends PureComponent {


  constructor() {
    super();
    this.state = {
      name: '', description: '', email: '', phone: '', capacity: '', city: '', state: '', country: '',
      postalCode: '', managers: '',
      cuisine: [], type: [], diets: [], Feautres: [], dressCodes: [], logo: null, logo_url: '', TypeStatus: '',
      cover: null, cover_url: '', gallery: [], images: [], error_frm: "",
      TypeResto: '', vegancategory: '',
      activeTab: '1', menus: [], plates: [], promo: [],
      show_add_Menu: false,
      show_edit_menu: false,
      show_add_new_dish: false,
      show_add_new_promo: false,
      show_edit_dish: false,
      selected_menu: null,
      selected_dish: null,
      storageLanguage: localStorage.getItem('language'),
      parkingDetails: false,
      isChain: false,
      website: '',
      facebook: '',
      clickCollect: false,
      delivery: false,
      hasWifi: false,
      hasTerrace: false,
      allowsPets: false,
      deliveryFee: '', deliverySupplyFee: '',
    };
    //this.changeCuisineVal = this.changeCuisineVal.bind(this);
  }


  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentWillMount() {
    const restaurant_id = this.props.restaurant_id
    if (restaurant_id) {
      this.props.getOneRestaurant({ restaurant_id })
    }

  }

  componentDidMount() {
    this.props.getVEGAN({})
    this.props.getFeatures()
    this.props.getKitchenTypes()
    this.props.getDressCodes()
    this.props.getUsers({})
    this.props.getRestauranTypes({})
    this.props.getDiet()




  }

  changeVal = (key, value) => {

    const obj = {}; obj[key] = value;
    this.setState(obj)
  }
  changeTypeRegimeVal = (value) => {
    if (value !== null) {
      let SelectedRgime = [];
      value.forEach(element => {
        SelectedRgime.push(element.value)
      });

      this.setState({
        diets: SelectedRgime
      })
    }
    else {
      this.setState({
        diets: " "
      })
    }
  }


  changeFeaturesType = (value) => {
    if (value !== null) {
      let SelectedFeature = [];
      value.forEach(element => {
        SelectedFeature.push(element.value)
      });

      this.setState({
        Feautres: SelectedFeature
      })
    }
    else {
      this.setState({
        Feautres: " "
      })
    }


  }





  changeCuisineVal = (value) => {
    if (value !== null) {
      let SelectedCuisine = [];
      value.forEach(element => {
        SelectedCuisine.push(element.value)
      });

      this.setState({
        cuisines: SelectedCuisine
      })
    }
    else {

      this.setState({
        cuisines: ""
      })
    }

  }

  changeTypeRestoVal = (element) => {

    if (element !== null) {
      this.setState({
        TypeResto: element.value
      })
    }
    else {
      this.setState({
        TypeResto: ""
      })
    }

  }

  changeStatusType = (value) => {

    if (value !== null) {


      this.setState({
        TypeStatus: value.value
      })
    }
    else {
      this.setState({
        TypeStatus: ""
      })
    }

  }
  changevegancategory = (elem) => {
    this.setState({
      vegancategory: elem.value
    })
  }
  changeDressCodeVal = (value) => {

    let SelectedDressCode = [];
    value.forEach(element => {
      SelectedDressCode.push(element.value)
    });
    this.setState({
      dressCodes: SelectedDressCode
    })

  }

  changeManagersVal = (elem) => {

    this.setState({
      managers: elem.value
    })

  }
  onChangeChecked(parkingDetails) {
    this.setState({ parkingDetails: parkingDetails })

  }
  onChangeChaine(isChain) {
    this.setState({ isChain: isChain })
  }
  onChangeclickCollect(clickCollect) {
    this.setState({ clickCollect: clickCollect })

  }
  onChangeLivraison(delivery) {

    this.setState({ delivery: delivery })

  }
  onChangeWifi(hasWifi) {

    this.setState({ hasWifi: hasWifi })

  }
  onChangeTerrace(hasTerrace) {
    this.setState({ hasTerrace: hasTerrace })
  }
  onChangeAnimaux(allowsPets) {
    this.setState({ allowsPets: allowsPets })
  }
  changeCover = (e) => {
    const { restaurant_id } = this.props
    try {
      const cover = e.target.files[0]
      this.setState({ cover, cover_url: URL.createObjectURL(cover) }, () => {
        const frm = new FormData();
        frm.append("cover", cover);
        this.props.editRestaurant({ data: frm, history: null, _id: restaurant_id })
      })
    } catch (error) { this.setState({ cover: null, cover_url: '' }) }
  }
  changeLogo = (e) => {
    const { restaurant_id } = this.props

    try {
      const logo = e.target.files[0]
      this.setState({ logo, logo_url: URL.createObjectURL(logo) }, () => {
        const frm = new FormData();
        frm.append("logo", logo);


        this.props.editRestaurant({ data: frm, history: null, _id: restaurant_id })
      })
    } catch (error) { this.setState({ logo: null, logo_url: '' }) }
  }

  addImgToGallery = (img) => {
    const { restaurant_id } = this.props
    const frm = new FormData();
    let newGallery = []
    newGallery.push(img)
    frm.append("newGallery", img);


    this.props.editRestaurant({ data: frm, history: null, _id: restaurant_id })
  }

  deleteImgFromGallery = (img) => {
    const { restaurant_id } = this.props
    const { images } = this.state
    let images_list = []
    images.forEach(i => {
      if (i._id !== img._id) {
        images_list.push(i._id)
      }
    });
    if (images_list.length > 0) {
      const frm = new FormData();
      let j = 0
      for (j = 0; j < images_list.length; j++) {
        frm.append('gallery[]', images_list[j]);
      }


      this.props.editRestaurant({ data: frm, history: null, _id: restaurant_id })
    } else {
      const data = { gallery: [] }
      this.props.editRestaurant({ data, history: null, _id: restaurant_id })
    }

  }

  renderImage = (img, img_url) => {

    if (!img && img_url !== '') return (<img src={`${api.root}/${img}`} alt='clicknmeal' style={{ width: '100%', height: '100%', position: 'center' }} />)
    if (img && img_url !== '') return (<img alt='clicknmeal' src={img_url} style={{ width: '100%', height: '100%', position: 'center' }} />)
    return (<img alt='clicknmeal' src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: '100%', height: '100%', position: 'center' }} />)
  }

  render_Image = (img, img_url) => {

    let background = `${process.env.PUBLIC_URL}/img/no.png`

    if (!img && img_url !== '') {
      background = `${api.root}/${img}`
      return (<div style={{ backgroundImage: `url(${background})`, width: '100%', height: '100%', backgroundPosition: 'center center', backgroundSize: 'cover' }}></div>)
    }
    if (img && img_url !== '') {
      background = `${img_url}`
      return (<div style={{ backgroundImage: `url(${background})`, width: '100%', height: '100%', backgroundPosition: 'center center', backgroundSize: 'cover' }}></div>)
    }


    return (<div style={{ backgroundImage: `url(${background})`, width: '100%', height: '100%', backgroundPosition: 'center center', backgroundSize: 'cover' }}>

    </div>)
  }

  addNewMenu = (menu) => {
    const { menus } = this.state
    const { restaurant_id } = this.props
    menus.push(menu)
    this.setState({ menus }, () => {

      const frm = new FormData();
      let i = 0;
      for (i = 0; i < menus.length; i++) {
        frm.append('menus[]', menus[i]._id);
      }
      this.props.editRestaurant({ data: frm, history: null, _id: restaurant_id })
      this.forceUpdate()
    })
  }

  addListMenu = (menus) => {
    let i = 0;
    for (i = 0; i < menus.length; i++) {
      this.addNewMenu(menus[i])
    }
  }

  deleteMenu = (idx) => {
    const { menus } = this.state
    const { restaurant_id } = this.props

    menus.splice(idx, 1);
    this.setState({ menus, selected_menu: null }, () => {
      if (menus.length > 0) {

        const frm = new FormData();
        let j = 0
        for (j = 0; j < menus.length; j++) {
          frm.append('menus[]', menus[j]._id);
        }
        this.props.editRestaurant({ data: frm, _id: restaurant_id })
      } else {
        const data = { menus: [] }
        this.props.editRestaurant({ data, _id: restaurant_id })
      }

    })
  }
  addPlat = (plat) => {
    const { plates } = this.state
    const { restaurant_id } = this.props
    plates.push(plat)
    this.setState({ plates }, () => {
      const frm = new FormData();
      let i = 0;
      for (i = 0; i < plates.length; i++) {
        frm.append('plates[]', plates[i]._id);
      }
      this.props.editRestaurant({ data: frm, history: null, _id: restaurant_id })
      this.forceUpdate()
    })
  }

  deletePlat = (idx) => {
    const { restaurant_id } = this.props
    const { plates } = this.state
    plates.splice(idx, 1);
    this.setState({ plates, selected_dish: null }, () => {
      if (plates.length > 0) {

        const frm = new FormData();
        let j = 0
        for (j = 0; j < plates.length; j++) {
          frm.append('plates[]', plates[j]._id);
        }
        this.props.editRestaurant({ data: frm, history: null, _id: restaurant_id })
      } else {
        const data = { plates: [] }
        this.props.editRestaurant({ data, history: null, _id: restaurant_id })
      }

    })
  }
  openNotificationWithIcon = type => {

    const { storageLanguage } = this.state
    notification[type]
      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Restaurant successfully modified' : 'Réstaurant modifié avec succès',

      });


  };
  openNotificationWithIconErreur = type => {
    const { storageLanguage } = this.state
    notification[type]
      ({

        duration: 3,
        message: storageLanguage === "en" ? 'Error' : 'Erreur',
        description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
      });
  };
  submit = () => {
    const { restaurant_id } = this.props


    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })


    if (error_frm === "") {
      const { name, email, mobile, capacity, city, state, country, postalCode,
        cuisines, diets, dressCodes, managers, TypeResto, vegancategory, logo, cover, description, website, deliveryFee,
        hasWifi, hasTerrace, allowsPets, deliverySupplyFee, delivery, clickCollect, parkingDetails, facebook, isChain, Feautres, TypeStatus } = this.state
      const data = {
        name, email, description, phone: mobile, capacity, city, state, delivery, clickCollect, parkingDetails,
        isChain, hasTerrace, allowsPets, hasWifi, country, postalCode, cuisines, diets, type: TypeResto, veganCategory: vegancategory,
        dressCodes, owner: managers, logo, cover, website, deliveryFee, deliverySupplyFee, facebook, Feautres, creationStatus: TypeStatus
      }


      const frm = new FormData();

      let i = 0;
      for (i = 0; i < cuisines.length; i++) {
        frm.append('cuisines[]', cuisines[i]);
      }
      for (i = 0; i < diets.length; i++) {
        frm.append('diets[]', diets[i]);

      }
      for (i = 0; i < Feautres.length; i++) {
        frm.append('features[]', Feautres[i]);

      }

      frm.append("name", data.name);
      if(data.description)
{      frm.append("description", data.description);}
      if(data.email)
   {   frm.append("email", data.email);}
      if(data.phone)
    {}  frm.append("phone", data.phone);
      if(capacity!==undefined){
        frm.append("capacity", data.capacity);
      }
 
      if(data.city)
   {   frm.append("city", data.city);}
      if(data.state)
     { frm.append("street", data.state);}
      if(data.country)
      {frm.append("country", data.country);}
      if(data.postalCode)
     { frm.append("postalCode", data.postalCode);}
      if(data.deliveryFee)
  {    frm.append("deliveryFee", data.deliveryFee);}
      if(data.deliverySupplyFee)
     { frm.append("deliverySupplyFee", deliverySupplyFee)}
      if(data.type)
      {frm.append("type", data.type)}
      if(data.dressCodes)
  {    frm.append("dressCodes", data.dressCodes);}
      if(data.website)
      {frm.append("website", data.website);}

      if(data.delivery)
  {    frm.append("delivery", data.delivery)}
if(data.clickCollect){
  frm.append('clickCollect', data.clickCollect)
}
     if(data.facebook)

     { frm.append('facebook', data.facebook)}

      frm.append('veganCategory', data.veganCategory)

      frm.append('creationStatus', data.creationStatus)


      let Id = restaurant_id

      let Data = frm

      axios.patch(api.restaurants + '/' + Id, Data).then(response => {

        if ((response.status = 200) || (response.status = 204)) {
          this.openNotificationWithIcon('success')


        }
        else {
          this.openNotificationWithIconErreur('error')


        }



      }


      )
    }
  }


  checkFrm = (data) => {
    const { name, email, mobile, capacity, city, state, country, postalCode, cuisines, description } = data
    if (name === "") return "the name are required.";
    if (description === "") return "the description is required.";
    if (email === "") return "email is required.";
    if (mobile === "") return "mobile is required.";

    if (city === "") return "city is required.";
    if (state === "") return "state is required.";
    if (country === "") return "country is required.";
    if (postalCode === "") return "postalCode is required.";
    if (cuisines === []) return "cuisinesis required.";

    return ""
  }

  componentDidUpdate(prev) {

    const { selected_restaurant } = this.props


    const { restaurant_id } = this.props

    let Id = restaurant_id

    if (selected_restaurant && (prev.selected_restaurant !== selected_restaurant)) {


      const { name, email, phone, capacity, cuisines, diets, owner, type, dressCodes, logo,
        cover, gallery, description, menus, plates, isActive, website, delivery, parkingDetails, isChain,
        facebook, hasWifi, veganCategory, hasTerrace, allowsPets, features, creationStatus,
        clickCollect, deliveryFee, deliverySupplyFee } = selected_restaurant
      const { city, street, country, postalCode } = selected_restaurant.address


      let managers = owner ? owner._id : ''
      let TypeResto = type ? type._id : ' '
      let TypeStatus = creationStatus ? creationStatus.code : ''

      let vegancategory = veganCategory ? veganCategory._id : ''
      let SelectedDressCode = [];
      dressCodes.forEach(element => {
        SelectedDressCode.push(element?._id)
      });
      let SelectedCuisines = [];
      cuisines.forEach(cu => {
        SelectedCuisines.push(cu?._id)
      });
      let Seecteddiets = [];
      if (diets !== undefined) {
        diets.forEach(diet =>
          Seecteddiets.push(diet?._id));
      }
      let SeectedFeautres = [];
      if (features !== undefined) {
        features.forEach(featu =>
          SeectedFeautres.push(featu?._id));
      }

      let images = [];
      gallery.forEach(img => {
        images.push({
          src: api.root + '/' + img?.path,
          alt: img?.filename,
          _id: img?._id
        })
      });

      axios.get(api.promo + '/by-resto/' + Id + '?' + { isActive: isActive }).then(resp =>
        this.setState({ promo: resp.data?.promotion }))


      this.setState({
        name, email, description, phone, capacity, logo, cover, images, menus, plates,
        website, deliveryFee, deliverySupplyFee, clickCollect, parkingDetails, delivery, facebook, hasWifi, hasTerrace, allowsPets, isChain,
        logo_url: logo ? api.root + '/' + logo.path : '',
        cover_url: cover ? api.root + '/' + cover.path : '',
        city, state: street, country, postalCode, managers, cuisines: SelectedCuisines, diets: Seecteddiets, Feautres: SeectedFeautres,
        dressCodes: SelectedDressCode, TypeResto, vegancategory, TypeStatus
      })

    }



  }

  render() {

    const { is_open, users, kitchen_types, dress_codes, restaurant_types, diet_types, veganReq, features } = this.props
    const { name, email, phone, capacity, city, description, state, country, postalCode, activeTab, cuisines,
      show_add_Menu, show_edit_menu, show_add_new_dish, show_edit_dish, plates, website,
      deliveryFee, deliverySupplyFee, hasTerrace, allowsPets, delivery, clickCollect, parkingDetails, facebook, hasWifi,
      vegancategory, isChain,
      cover, cover_url, logo, logo_url, dressCodes, TypeResto, diets, selected_menu, menus, selected_dish, promo, Feautres,
      TypeStatus
    } = this.state

    let Vegan = []
    veganReq.forEach(element => {
      Vegan.push({
        value: element._id,
        label: element.title,
      })
    }
    )


    let ListResto = [];
    restaurant_types.forEach(element => {
      ListResto.push({
        value: element._id,
        label: element.title,
      })
    });

    let ListCuisine = [];
    kitchen_types.forEach(element => {
      ListCuisine.push({
        value: element._id,
        label: element.name,
      })
    });
    let ListeRegime = []
    diet_types.forEach(element => {
      ListeRegime.push({
        value: element._id,
        label: element.title,
      })
    });

    let ListStatus = []
    StatusRestaurany.forEach(element => {
      ListStatus.push({
        value: element.code,
        label: element.label
      })
    })

    let dressCodeList = []
    dress_codes.forEach(element => {
      dressCodeList.push({
        value: element._id,
        label: element.name,
      })
    });

    let managers = []
    users.forEach(element => {
      managers.push({
        value: element._id,
        label: element.email,
      })
    });

    let ListeFeatures = []
    features.forEach(element => {
      ListeFeatures.push({
        value: element._id,
        label: element.name,
      })
    });

    const columnsMenu = [



      {
        title: <FormattedMessage id="app.menu.prix" />,
        dataIndex: 'price',
        key: 'price',

        render: (value, item, index) => {
          return (<div>{item.price ? item.price?.price : " "}
          </div>)
        }

      },

      {
        title: <FormattedMessage id="app.setting.description" />,
        dataIndex: 'firstName',
        key: 'firstName',

        render: (value, item, index) => {
          return (<div>{item.description ? item.description : " "}
          </div>)
        }


      },
      {
        title: <FormattedMessage id="app.type" />,
        dataIndex: 'firstName',
        key: 'firstName',

        render: (value, item, index) => {
          return (<div>{item.type} </div>)
        }


      },
      {
        title: <FormattedMessage id="app.plat" />,
        dataIndex: 'pat',
        key: 'plat',

        render: (value, item, index) => {
          return (<div>{item.plates.map((plat, i) =>
            <Tag color="red" style={{ marginTop: 10 }}> {plat.name}</Tag>
          )}
          </div>)
        }


      },


      {
        title: <FormattedMessage id="app.profile.action" />,
        width: '15%',



        render: (value, record, index) => {
          return (<span>
            <Row>


              <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.setState({
                show_edit_menu: true, selected_menu: record,
              })}>
                <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>

              </Button>


              <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.deleteMenu(index)}>
                <span className="lnr lnr-trash mr-1" style={{ color: "red" }}></span>

              </Button>


            </Row>

          </span>)
        }
      },
    ];


    const columns = [

      {

        title: <FormattedMessage id="app.promo" />,
        key: 'name',
        width: '20%',


        render: (value, record, index) => {

          return (<div> {record.name}  </div>)
        }

      },
      {
        title: <FormattedMessage id="app.promo.reduction" />,

        key: 'email',


        render: (value, record, index) => {
          return (<div>{record.reduction} </div>)
        }

      },
      {
        title: <FormattedMessage id="app.type" />,

        key: 'email',


        render: (value, record, index) => {
          if (record.type?.name === 'DELIVERY') {
            return (<Tag color="green"><FormattedMessage id="app.livraison" /></Tag>)

          }
          if (record.orderType?.name === 'PICKUP') {
            return (<Tag color="red"> <FormattedMessage id="app.récupere" /></Tag>)

          } else return (<Tag color="gold"> <FormattedMessage id="app.reservation" /></Tag>)
        }



      },
      {
        title: <FormattedMessage id="app.promo.datedebut" />,

        key: 'email',


        render: (value, record, index) => {
          return (<div>{moment(record.schedule?.beginDate).format('ll')}</div>)
        }

      },
      {
        title: <FormattedMessage id="app.promo.datefin" />,

        key: 'email',


        render: (value, record, index) => {
          return (<div>{<div>{moment(record.schedule?.endDate).format('ll')}</div>} </div>)
        }

      },


    ];
    return (
      <Container>

        <Card>

          <CardBody>

            <div className="modal__body" style={{ padding: '0 10px' }}>


              <form className="form" style={{ width: '100%', display: 'block' }} onSubmit={(e) => {
                e.preventDefault();

                this.submit()
              }}>

                <Row>
                  <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
                    <Alert is_visible={(this.state.error_frm !== "")} color="danger" className="alert--colored" icon >
                      <p><span className="bold-text"> <FormattedMessage id="app.warning" /> </span> {this.state.error_frm}</p>
                    </Alert>
                  </Col>
                </Row>
                <Row>

                  <Col md="12">
                    <div className="restaurant_img_wrap" style={{ marginBottom: '50px', height: '250px', width: '100%', overflow: 'hidden', borderRadius: '5px' }}>
                      {this.render_Image(cover, cover_url)}

                      <div className="caption">
                        <label htmlFor="add_cover"><span className="lnr lnr-picture"></span></label>
                        <input onChange={this.changeCover} id="add_cover" type="file" accept="image/*" hidden />
                      </div>
                    </div>
                  </Col>
                  <Col md="3" style={{ top: -100, marginLeft: 50 }}>
                    <div className="restaurant_img_wrap" style={{ marginBottom: '50px', height: '90px', width: '50%', maxWidth: '90px', overflow: 'hidden', borderRadius: '5px' }}>
                      {this.renderImage(logo, logo_url)}

                      <div className="caption">
                        <label htmlFor="add_logo"><span className="lnr lnr-picture"></span></label>
                        <input onChange={this.changeLogo} id="add_logo" type="file" accept="image/*" hidden />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col md="12">
                    <Nav tabs>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '1' })}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <FormattedMessage id="app.informations" />

                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '2' })}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        > <FormattedMessage id="app.gallery" />

                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '3' })}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          <FormattedMessage id="app.menus" />

                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '4' })}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.toggle('4');
                          }}
                        >
                          <FormattedMessage id="app.plats" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '5' })}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.toggle('5');
                          }}
                        >
                          <FormattedMessage id="app.promo" />
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row style={{ padding: '20px' }}>

                          <Col md="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.restaurant" /></span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="name" component="input" placeholder="Réstaurant"
                                  defaultValue={name !== undefined ? name : ""}
                                  onChange={(e) => this.changeVal('name', e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                <FormattedMessage id="app.restaurant.capacity" /></span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="capacity" component="input" type="number" placeholder="0" defaultValue={capacity !== "" ? capacity :null}
                                  onChange={(e) => this.changeVal('capacity', e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md="12">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.setting.description" /></span>
                              <div className="form__form-group-field">
                                <textarea rows="1" style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="description"

                                  defaultValue={description !== undefined ? description : ""}
                                  placeholder="description"
                                  onChange={(e) => this.changeVal('description', e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>


                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.profile.email" /></span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="email" component="input" placeholder="adress email" defaultValue={email !== undefined ? email : ""}
                                  onChange={(e) => this.changeVal('email', e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.profile.phone" /></span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="mobile" component="input" placeholder="Mobile" defaultValue={phone !== "undefined" ? phone : "  "}
                                  onChange={(e) => this.changeVal('mobile', e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>



                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.website" /> </span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="website" component="input" type="text" placeholder="Site Web" defaultValue={website}
                                  onChange={(e) => this.changeVal('website', e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.facebook" /> </span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="facebook" component="input" type="text" placeholder="Facebook"
                                  defaultValue={facebook}
                                  onChange={(e) => this.changeVal('facebook', e.target.value)} />


                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.instgram" /> </span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="facebook" component="input" type="text" placeholder="Instagram"
                                // defaultValue={facebook}
                                // onChange={(e) => this.changeVal('facebook', e.target.value)}
                                />


                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.twitter" /> </span>
                              <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                  name="facebook" component="input" type="text" placeholder="Twitter"
                                // defaultValue={facebook}
                                 onChange={(e) => this.changeVal('facebook', e.target.value)}
                                />


                              </div>
                            </div>
                          </Col>

                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.restaurant.type" /></span>
                              <div className="form__form-group-field">
                                <div style={{ width: '100%' }}>
                                  <Select onChange={(e) => this.changeTypeRestoVal(e)} options={ListResto}

                                    value={ListResto.filter(option => TypeResto !== undefined ? TypeResto.includes(option.value) : " ")}

                                    styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>

                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.regime.type" /></span>
                              <div className="form__form-group-field">
                                <div style={{ width: '100%' }}>
                                  <Select onChange={(e) => this.changeTypeRegimeVal(e)}
                                    isMulti
                                    options={ListeRegime}
                                    value={ListeRegime.filter(option => diets !== undefined ? diets.includes(option.value) : " ")}
                                    styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>

                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.vegan" /></span>
                              <div className="form__form-group-field">
                                <div style={{ width: '100%' }}>
                                  <Select onChange={(e) => this.changevegancategory(e)} options={Vegan}

                                    value={Vegan.filter(option => vegancategory !== undefined ? vegancategory.includes(option.value) : " ")}

                                    styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>

                              </div>
                            </div>
                          </Col>

                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.cuisine" /></span>
                              <div className="form__form-group-field">
                                <div style={{ width: '100%' }}>
                                  <Select onChange={(e) => this.changeCuisineVal(e)} isMulti options={ListCuisine}

                                    value={ListCuisine.filter(option => cuisines !==undefined&& cuisines.includes(option.value))}

                                    styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>

                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.stylevestimentaire" /></span>
                              <div className="form__form-group-field">
                                <div style={{ width: '100%' }}>
                                  <Select onChange={(e) => this.changeDressCodeVal(e)}
                                    value={dressCodeList.filter(option => dressCodes !== undefined && dressCodes.includes(option.value))}
                                    isMulti

                                    options={dressCodeList} styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>

                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.profile.manager" /></span>
                              <div className="form__form-group-field">
                                <div style={{ width: '100%' }}>
                                  <Select onChange={(e) => this.changeManagersVal(e)} options={managers}
                                    value={managers.filter(option => option.value === this.state.managers)}
                                    styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>

                              </div>
                            </div>
                          </Col>

                          <Col md="12" style={{ marginBottom: 20 }}></Col>

                          <Col md="12">
                            <p style={{ marginBottom: 20, fontWeight: 'bolder' }}><FormattedMessage id="app.profile.address" /></p>
                            <Row>
                              <Col md="3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label"><FormattedMessage id="app.profile.city" /></span>
                                  <div className="form__form-group-field">
                                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                      name="city" component="input" type="text" placeholder="city" defaultValue={city}
                                      onChange={(e) => this.changeVal('city', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col md="3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label"> <FormattedMessage id="app.profile.street" /></span>
                                  <div className="form__form-group-field">
                                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                      name="state" component="input" type="text" placeholder="Rue" defaultValue={state}
                                      onChange={(e) => this.changeVal('state', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col md="3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">  <FormattedMessage id="app.profile.country" /></span>
                                  <div className="form__form-group-field">
                                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                      name="country" component="input" type="text" placeholder="country" defaultValue={country}
                                      onChange={(e) => this.changeVal('country', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>



                              <Col md="3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label"> <FormattedMessage id="app.profile.zipcode" /></span>
                                  <div className="form__form-group-field">
                                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                      name="postalCode" component="input" type="text" placeholder="postalCode" defaultValue={postalCode}
                                      onChange={(e) => this.changeVal('postalCode', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>


                              <Col md="3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label"> <FormattedMessage id="app.récupere" /> </span>
                                  <div className="form__form-group-field">
                                    <Switch checked={clickCollect ? clickCollect === true : false} onChange={(e) => this.onChangeclickCollect(e)} />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label"> <FormattedMessage id="app.livraison" /> </span>
                                  <div className="form__form-group-field">
                                    <Switch checked={delivery ? delivery === true : false} onChange={(e) => this.onChangeLivraison(e)} />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    <FormattedMessage id="app.paymentMethods" /></span>
                                  <div className="form__form-group-field">
                                    <div style={{ width: '100%' }}>
                                      <Select onChange={(e) => this.changeManagersVal(e)} options={payments}
                                        // value={ managers.filter(option =>  option.value=== this.state.managers)}
                                        styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col md="2">

                                <div className="form__form-group">
                                  <span className="form__form-group-label">
                                    <FormattedMessage id="app.order.fraislivreur" /></span>
                                  <div className="form__form-group-field">
                                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                      name="deliveryFee" component="input" type="number" min="0" placeholder="0" defaultValue={deliveryFee !== undefined ? deliveryFee : ""}
                                      onChange={(e) => this.changeVal('deliveryFee', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label"><FormattedMessage id="app.caracteristique" /></span>
                                  <div className="form__form-group-field">
                                    <div style={{ width: '100%' }}>
                                      <Select onChange={(e) => this.changeFeaturesType(e)}
                                        isMulti
                                        options={ListeFeatures}
                                        value={ListeFeatures.filter(option => Feautres !== undefined && Feautres.includes(option.value))}
                                        styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                    </div>

                                  </div>
                                </div>
                              </Col>


                              <Col md="6">
                                <div className="form__form-group">
                                  <span className="form__form-group-label"><FormattedMessage id="app.profile.status" /></span>
                                  <div className="form__form-group-field">
                                    <div style={{ width: '100%' }}>
                                      <Select onChange={(e) => this.changeStatusType(e)} options={ListStatus}

                                        value={ListStatus.filter(option => TypeStatus !== null ? TypeStatus === (option.value) : " ")}

                                        styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                    </div>

                                  </div>
                                </div>
                              </Col>



                              {/* 
                      <Col md="2">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                          <FormattedMessage id="app.order.fraislivreurSupp"/></span>
                          <div className="form__form-group-field">
                            <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                              name="deliverySupplyFee" component="input" type="number" min="0" placeholder="0" defaultValue={deliverySupplyFee!==undefined?deliverySupplyFee:""} 
                              onChange={(e) => this.changeVal('deliverySupplyFee', e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                      
                      <Col md="2">
                                    <div className="form__form-group">
                                    <span className="form__form-group-label"> <FormattedMessage id= "app.chaine"/> </span>
                                    <div className="form__form-group-field">
                                    <Switch checked={isChain?isChain===true:false}   onChange={(e) =>this.onChangeChaine(e)}/>
                                    </div>
                                    </div>
                       </Col>
                      <Col md="2">
                                    <div className="form__form-group">
                                    <span className="form__form-group-label"> <FormattedMessage id= "app.parking"/> </span>
                                    <div className="form__form-group-field">
                                    <Switch checked={parkingDetails?parkingDetails===true:false}   onChange={(e) =>this.onChangeChecked(e)}/>
                                    </div>
                                    </div>
                       </Col>
                         <Col md="2">
                                    <div className="form__form-group">
                                    <span className="form__form-group-label"> <FormattedMessage id= "app.wifi"/> </span>
                                    <div className="form__form-group-field">
                                    <Switch checked={hasWifi?hasWifi===true:false}   onChange={(e) =>this.onChangeWifi(e)}/>
                                    </div>
                                    </div>
                          </Col>
                          <Col md="2">
                                    <div className="form__form-group">
                                    <span className="form__form-group-label"> <FormattedMessage id= "app.terrace"/> </span>
                                    <div className="form__form-group-field">
                                    <Switch checked={hasTerrace?hasTerrace===true:false}   onChange={(e) =>this.onChangeTerrace(e)}/>
                                    </div>
                                    </div>
                          </Col>
                          <Col md="2">
                                    <div className="form__form-group">
                                    <span className="form__form-group-label"> <FormattedMessage id= "app.Pets"/> </span>
                                    <div className="form__form-group-field">
                                    <Switch checked={allowsPets?allowsPets===true:false}   onChange={(e) =>this.onChangeanimaux(e)}/>
                                    </div>
                                    </div>
                          </Col> */}



                            </Row>

                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">

                            <Button className="btn btn-small" color="primary" type="submit"> <FormattedMessage id="app.btn.edit" /></Button>

                            <Button className="btn btn-secondary btn-small" color="secondary"  >
                              <a href={"/app/restaurants/list"} className="text-muted"><FormattedMessage id="app.btn.cancel" /></a>

                            </Button>

                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2" >

                        <Row style={{ padding: '20px' }}>
                          <Col md="12">
                            <Gallery images={this.state.images} accept={this.addImgToGallery} delete={this.deleteImgFromGallery} />
                          </Col>

                        </Row>

                      </TabPane>
                      <TabPane tabId="3" >

                        <Row style={{ padding: '20px' }}>
                          <Col md="12">
                            <Button color="success" style={{ float: 'right', marginTop: '-10px' }}
                              onClick={() => this.setState({ show_add_Menu: true })}><FormattedMessage id="app.btn.addnewmenu" /></Button>

                            {show_add_Menu && <Col md="12">
                              <AddMenuCmp
                                cancelAdding={() => this.setState({ show_add_Menu: false })}
                                success={(menu) => {
                                  this.addNewMenu(menu)
                                  this.setState({ show_add_Menu: false })
                                }}
                                addListMenu={(menus) => {
                                  this.addListMenu(menus)
                                }}
                              />
                            </Col>}

                            {menus !== undefined && menus !== null && menus !== "" && menus.map((menu, i) => <CollapseCmp key={"menu_" + i} title={menu?.name}
                              handleClick={() => !selected_menu ? this.setState({ selected_menu: menu }) : this.setState({ selected_menu: null })}
                              is_open={(menu?._id === selected_menu?._id)}
                              className="with-shadow"
                            >

                              <div>

                                <Row>
                                  <Col md="2">
                                    <span> <h5>  <FormattedMessage id="app.setting.description" /></h5></span>

                                    <p>{menu?.description}</p>
                                  </Col>
                                  <Col md="2">
                                    <span> <h5>    <FormattedMessage id="app.menu.prix" /> </h5></span>

                                    <p>{menu?.price?.price}</p>
                                  </Col>
                                  <Col md="2">
                                    <span> <h5>   <FormattedMessage id="app.type" /> </h5></span>

                                    <p>{menu?.type}</p>
                                  </Col>
                                  <Col md="2">
                                    <span> <h5>  <FormattedMessage id="app.plat" /></h5></span>
                                    <p>{menu?.plates.map((plat, i) =>
                                      <Tag color="red" style={{ marginTop: 10 }}> {plat.name}</Tag>
                                    )}
                                    </p>
                                  </Col>
                                  <Col md="2" style={{ textAlign: 'right' }} >
                                    <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.setState({
                                      show_edit_menu: true, selected_menu: menu,
                                    })}>
                                      <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                      <FormattedMessage id="app.btn.edit" />
                                    </Button>
                                  </Col>
                                  <Col md="2" style={{ textAlign: 'right' }} >
                                    <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.deleteMenu(i)}>
                                      <span className="lnr lnr-trash mr-1" style={{ color: "red" }}></span>
                                      <FormattedMessage id="app.btn.delet" />
                                    </Button>
                                  </Col>

                                </Row>

                                {(show_edit_menu && selected_menu) && <Col md="12">
                                  <EditMenuCmp selected_menu={selected_menu}
                                    cancelEditing={() => this.setState({ show_edit_menu: false })}
                                    success={() => {

                                      this.setState({ show_edit_menu: false, selected_menu: null })
                                    }}


                                  />
                                </Col>}



                              </div>
                            </CollapseCmp>)}

                          </Col>

                        </Row>

                      </TabPane>



                      <TabPane tabId="4" >

                        <Row style={{ padding: '20px' }}>
                          <Col md="12">
                            <Button color="primary" style={{ float: 'right', marginTop: '5px', marginRight: '10px' }} onClick={() => this.setState({ show_add_new_dish: true })}> <FormattedMessage id="app.btn.addnewplats" /></Button>
                            {/* ADD dish */}
                            {show_add_new_dish && <Col md="12">
                              <AddDishCmp
                                cancelAdding={() => this.setState({ show_add_new_dish: false })}
                                success={(plat) => {
                                  this.addPlat(plat)
                                  this.setState({ show_add_new_dish: false })
                                }}
                                addPlat={this.addPlat}
                              />
                            </Col>}

                            {plates !== undefined && plates !== null && plates !== "" && plates.map((dish, i) => <CollapseCmp key={"dish_" + i} title={dish?.name}
                              handleClick={() => !selected_dish ? this.setState({ selected_dish: dish }) : this.setState({ selected_dish: null })}
                              is_open={(dish._id === selected_dish?._id)}
                              className="with-shadow"
                            >

                              <div>
                                <Row>
                                  <Col md="2">
                                    <span> <h5>  <FormattedMessage id="app.plat" /></h5></span>
                                    <p>{dish?.type}</p>
                                  </Col>
                                  <Col md="2">
                                    <span> <h5>  <FormattedMessage id="app.setting.description" /></h5></span>
                                    <p>{dish?.description}</p>
                                  </Col>
                                  <Col md="2">
                                    <span> <h5>  <FormattedMessage id="app.prix.plat" /></h5></span>
                                    <p>{dish?.price?.price}</p>
                                  </Col>

                                  <Col md="3" style={{ textAlign: 'right' }} >
                                    <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.setState({
                                      show_edit_dish: true, selected_dish: dish,
                                    })}>
                                      <span className="lnr lnr-pencil mr-1" style={{ color: "green" }}></span>
                                      <FormattedMessage id="app.btn.edit" />
                                    </Button>
                                  </Col>
                                  <Col md="3" style={{ textAlign: 'right' }} >
                                    <Button color="secondary" outline size="sm" className="rounded ml-1" onClick={() => this.deletePlat(i)}>
                                      <span className="lnr lnr-trash mr-1" style={{ color: "red" }}></span>
                                      <FormattedMessage id="app.btn.delet" />
                                    </Button>
                                  </Col>

                                </Row>

                                {(show_edit_dish && selected_dish) && <Col md="12">
                                  <EditDishCmp selected_dish={selected_dish}
                                    cancelEditing={() => this.setState({ show_edit_dish: false })}
                                    success={() => {

                                      this.setState({ show_edit_dish: false, selected_dish: null })
                                    }}


                                  />
                                </Col>}



                              </div>
                            </CollapseCmp>)}

                          </Col>

                        </Row>

                      </TabPane>

                      <TabPane tabId="5" >

                        <Row style={{ padding: '20px' }}>
                          <Col md="12">


                            <Table rowKey={(record, index) => index} scroll={{ x: 1000 }}
                              dataSource={promo.map((promos, i) => promos)} columns={columns} bordered pagination={true}
                              style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} />

                          </Col>

                        </Row>

                      </TabPane>

                    </TabContent>
                  </Col>





                </Row>




              </form>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}


const mapStateToProps = ({ auth, ktype, dressCode, restaurant, restauranType, dietReq, VEGAN_types, featureReq }) => {
  const { selected_restaurant } = restaurant
  const { features, loading_edit_feature, error_edit_feature } = featureReq
  return {
    form: 'log_in_form',
    loading_login: auth.loading_login,
    restaurant: auth.restaurant,
    loading_add_restaurant: auth.loading_add_restaurant,
    error_add_restaurant: auth.error_add_restaurant,
    new_restaurant: auth.new_restaurant,
    user: auth.user,
    users: auth.users,
    selected_restaurant,
    kitchen_types: ktype.kitchen_types,
    dress_codes: dressCode.dress_codes,
    restaurant_types: restauranType.restaurant_types,
    diet_types: dietReq.diet_types,
    veganReq: VEGAN_types.VEGAN_types,
    features, loading_edit_feature, error_edit_feature
  }
}
export default connect(mapStateToProps, {
  addRestaurant, getRestauranTypes,
  getKitchenTypes, getDressCodes, getUsers, getOneRestaurant, editRestaurant, getDiet, getVEGAN, getFeatures, deleteFeature

})(reduxForm()(EditRestaurantCmp));
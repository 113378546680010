import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {  Card, CardBody, Button, Row, Col,CustomInput} from 'reactstrap';
import{notification} from'antd';
import { api } from '../../../const';
import { NavLink } from 'react-router-dom';

import Alert from "../../helper/Alert";

import {getUsers} from "../../../redux/actions/authActions";

import Gallery from '../gallery';
import { reduxForm } from 'redux-form';
import Select from 'react-select'

import {getRestaurants } from "../../../redux/actions/restaurantsActions";

import {getMarque_Blanche} from "../../../redux/actions/marqueblancheActions";
import axios from 'axios';

import { FormattedMessage } from 'react-intl';

const Template = [
    {label: 'template 1', value: 0},
    {label: 'template 2 ', value: 1},
    {label: 'template 3', value: 2}, 


 {label: 'template 4 ', value: 3},
 {label: 'template 5 ', value: 4}] 
class EditMarqueBlanche extends PureComponent {

    constructor() {
       
        super();
        this.state = {
          visible: false,
          logo:null,
          logo_url:'',
      
          name:'' , 
          description:'' ,
         domaine:'',
          template:"",
          error_frm:'',
          marqueblanche:"",
          images:[],
          language:[],
          Resto:'',
          
          aboutH1:'',cartH1:'',color1:'',color2:'',contactH1:'',homH1:'',
          keywords:'',metaDescription:'',policeNameText:'',policeNameTitle:'',
          policeText:'',policeTitle:'',
          police:'',
          fontSize:'',
          storageLanguage : localStorage.getItem('language')
        };
    }
    
    
    componentDidMount=()=>{
        let Id=this.props.marqueblanch_id
      
      axios.get(api.marque_blanche+'/'+Id).then(resp => {
        this.setState({ marqueblanche: resp.data?.whiteLabel })
        
      })
      axios.get(api.langue).then(resp => {
        this.setState({ language: resp.data?.langConfig })
        
      })
      this.props.getRestaurants({})
   

    }
   
    componentDidUpdate(prev) {
    const {marqueblanche}=this.state;

    if (marqueblanche && (prev.marqueblanche!== marqueblanche)){
      const{aboutH1,cartH1,color1,color2,contactH1,homH1,
        keywords,metaDescription,policeNameText,policeNameTitle,
        policeText,policeTitle}=marqueblanche?.extras
     
    this.setState({Resto:marqueblanche?.restaurant?._id,
      aboutH1,
      cartH1,color1,color2,contactH1,homH1,
      keywords,metaDescription,policeNameText,policeNameTitle,
      policeText,policeTitle
    })

 if(marqueblanche?.sliders?.length>0){
     this.state.images=[]
    for(let i=0 ;i< marqueblanche?.sliders?.length; i++){
        this.state.images.push({
            src : api.root+'/'+marqueblanche?.sliders[i].picture?.path ,
            alt : marqueblanche?.sliders[i].picture?.filename,
            _id:marqueblanche?.sliders[i].picture?._id
        })
      }
 
}else{
  this.state.images.map((img,i)=>({ src : api.root+'/'+img.picture?.picture ,
  alt : img.picture?.filename,
  _id:marqueblanche?.sliders[i].picture?._id}))

}


}}

    changeLogo = (e) => {
      let Id=this.props.marqueblanch_id
        try {
          const logo = e.target.files[0]

          this.setState({ logo, logo_url: URL.createObjectURL(logo) }, ()=>{
            const frm = new FormData();
        frm.append("logo",   logo);

        
        let data=frm

        axios.patch(api.marque_blanche+'/'+Id,data).then(response=>{
  
          if((response.status=200 )||(response.status=204)) { 
            this.openNotificationWithIcon('success')  
        
      
          }
          else{
            this.openNotificationWithIconErreur('error')
      
            
          }  })
      })
        } catch (error) { this.setState({ logo: null, logo_url: '' }) }
      }
      renderImage = (img , img_url) => {
    

        if(img && img_url !== null) return (<img src={`${api.root}/${img}`} style={{ width: '100%', height: '100%' }}/>)
        if(img && img_url !== null) return (<img src={img_url} style={{ width: '100%', height: '100%' }} />)
        return (<img src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: '100%', height: '100%' }}/>)
      }
   changeVal = (key, value) => {
        const obj = {};
        obj[key] = value;

        this.setState(obj)
       
     
      }     
    
    
      changeTemplate= (elem) => {
        this.setState({ 
          template : elem.value
        })
     
      }

  openNotificationWithIcon= type => {
    const {storageLanguage}=this.state
    notification[type]

    ({
      duration:3,
      message:storageLanguage === "en"? 'Success':'Succès',
      description:storageLanguage === "en"? 'White label is successfully created ':'La marque blanche est créer avec succès',
 
    });
  }; 
  openNotificationWithIconErreur = type => {
    const {storageLanguage}=this.state
		notification[type]
	
		({
		  duration:3,
      message:storageLanguage === "en"? 'Error':'Erreur',
      description:storageLanguage === "en"? 'An error has occurred':'Un erreur se produit',
		});
  };

  addImgToGallery = (img)=>{
    let Id=this.props.marqueblanch_id
    const frm = new FormData();
    let newSliders = []
    newSliders.push(img)
    frm.append("newSliders",img);
   
    axios.patch(api.marque_blanche+'/'+Id,frm).then(response=>{
        if((response.status=200 )||(response.status=204)) { 
          this.openNotificationWithIcon('success')  
        }
        else{
          this.openNotificationWithIconErreur('error')
       
          
        }  }
    )}

  

  deleteImgFromGallery = (img)=>{

    let Id=this.props.marqueblanch_id
    const {images } = this.state
    let images_list = []
    images.forEach(i => {
      if(i._id !== img._id){
        images_list.push(i._id)
      }
    });
    if(images_list.length>0){
      const frm = new FormData();
      let j = 0
      for (j=0; j< images_list.length; j++){
        frm.append('sliders[]', images_list[j]);
      }

      let data=frm

      axios.patch(api.marque_blanche+'/'+Id,data).then(response=>{

        if((response.status=200 )||(response.status=204)) { 
          this.openNotificationWithIcon('success')  
       }
        else{
          this.openNotificationWithIconErreur('error')
       
        }  })
    }else{
        let data = {sliders : []}
        axios.patch(api.marque_blanche+'/'+Id,data).then(response=>{

            if((response.status=200 )||(response.status=204)) { 
              this.openNotificationWithIcon('success')  
      }
            else{
              this.openNotificationWithIconErreur('error')
           
            }  })       
    }
    
  }




  changeRestoVal = (elem) => {
 
   
    this.setState({ 
      Resto : elem.value
    })

   
  }
 


  submit = () => {
    let Id=this.props.marqueblanch_id
    // const error_frm = this.checkFrm(this.state)
    // this.setState({ error_frm })
    // if (error_frm === "") {
      const { name, domaine,description,Resto,aboutH1,cartH1,color1,color2,contactH1,homH1,
        keywords,metaDescription,policeNameText,policeNameTitle,
        policeText,policeTitle} = this.state
      
        const data = { name, domaine,description,restaurant:Resto,aboutH1,cartH1,color1,color2,contactH1,homH1,
          keywords,metaDescription,policeNameText,policeNameTitle,
          policeText,policeTitle}
       
    
      const frm = new FormData();  
      frm.append("name",   data.name);
      frm.append("description",   data.description);
      frm.append("domaine",   data.domaine);
      frm.append("extras[aboutH1]",data.aboutH1);
      frm.append("extras[cartH1]",data.cartH1);
      frm.append("extras[color1]",data.color1);
      frm.append("extras[color2]",data.color2);
      frm.append("extras[contactH1]",data.contactH1);
      frm.append("extras[homH1]",data.homH1);
      frm.append("extras[keywords]",data.keywords);
      frm.append("extras[metaDescription]",data.metaDescription);
      frm.append("extras[policeNameText]",data.policeNameText);
      frm.append("extras[policeNameTitle]",data.policeNameTitle);
      frm.append("extras[policeText]",data.policeText);
      frm.append("extras[policeTitle]",data.policeTitle);
      frm.append("restaurant",   data.restaurant);
      let Data=frm
      axios.patch(api.marque_blanche+'/'+Id, Data).then(response=>{

        if((response.status=200 )||(response.status=204)) { 
          this.openNotificationWithIcon('success')  
         }
        else{
          this.openNotificationWithIconErreur('error')
       
        }
     
        //  window.location.href="/app/marque-blanche/list"
        }     )
      // }
  }
  // checkFrm = (data) => {
  //   const { name, domaine,description,Resto,aboutH1,cartH1,color1,color2,contactH1,homH1,
  //     keywords,metaDescription,policeNameText,policeNameTitle,
  //     policeText,policeTitle } = data
  //   if (name !== "") return "the name are required.";


  //   return ""
  // }


      render() {
        const {restaurants}=this.props;
        const {logo,logo_url,marqueblanche,language,Resto,aboutH1}=this.state;


        let langue=[];
        language.forEach(element => {
            langue.push({
            value:element._id,
            label: element.language,
          })
        }); 

let Lang=marqueblanche?.language?._id

        let restaurant = [];

        restaurants.forEach(element => {
            restaurant.push({
            value:element._id,
            label: element.name,
          })
        }); 
      
  
                return (
                    <Card>
                    <CardBody>
                        <div className="card__title">
                <div className="modal__body">
                <form className="form" onSubmit={(e) => {
                  e.preventDefault();
      
                  this.submit()
                }}>
       <Row>
                    <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
                      <Alert is_visible={(this.state.error_frm !== "")} color="danger" className="alert--colored" icon >
                        <p><span className="bold-text"><FormattedMessage id="app.warning"/> </span> {this.state.error_frm}</p>
                      </Alert>
                    </Col>
      
      
                    <Col md="3">
                  <div className="restaurant_img_wrap" style={{marginBottom:'50px',height:'90px',width:'90px',overflow:'hidden',borderRadius:'5px'}}>
                    {this.renderImage(logo , this.state.images)}
        
                    <div className="caption">
                      <label htmlFor="add_logo"><span className="lnr lnr-picture"></span></label>
                      <input onChange={this.changeLogo} id="add_logo" type="file" accept="image/*" hidden />
                    </div>
                  </div>
                </Col>
                <Col md="8">
                  <Row>
                      <Col >
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.blanche"/></span>
                                <div className="form__form-group-field">
                                <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                              name="name" component="input" placeholder="marque blanche"
                              defaultValue={marqueblanche?.name!==undefined?marqueblanche.name:""} 
                              onChange={(e) => this.changeVal('name', e.target.value)}
                            />

                                 
                                </div>
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.domaine"/></span>
                              
                                   <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.domaine!==undefined?marqueblanche.domaine:""} 
                                 name="domaine" component="input" placeholder="domaine"
                                    onChange={(e) => this.changeVal('domaine', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                        <div className="form__form-group">
                          <span className="form__form-group-label"> <FormattedMessage id="app.restaurant"/></span>
                            <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                  <Select  onChange={(e) => this.changeRestoVal(e)}
                              value={restaurant.filter(option =>Resto!==undefined &&Resto.includes(option.value))}
                           isDisabled
                                options={restaurant}
                                    styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                </div>
                                 
                            </div>
                          </div>
                     </Col>
                     <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.marque.langue"/></span>
                                <div className="form__form-group-field">
                                <div style={{width:'100%'}}>
                                <Select 
                                 onChange={(e) => this.changeLang(e)}
                                 value={langue.filter(option =>Lang!==undefined &&Lang.includes(option.value))}
                                   options={langue}
                                   styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} />
                                   
                                   </div>
                                 
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                            <h4> <FormattedMessage id="app.marque.seo"/></h4>
                            </Col>
                            <Col md='6'>
                            <div className="form__form-group">
                              <span className="form__form-group-label">  <FormattedMessage id="app.setting.metaDescription"/></span>
                                <div className="form__form-group-field">
                                   <textarea  component="textarea" style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                     defaultValue={marqueblanche?.extras?.metaDescription!==undefined?marqueblanche.extras?.metaDescription:""}   name="metaDescription"  placeholder="description"
                                    onChange={(e) => this.changeVal('metaDescription', e.target.value)}   />
                                </div>
                              </div>
                            </Col>
                            <Col md='6'>
                            <div className="form__form-group">
                              <span className="form__form-group-label">  <FormattedMessage id="app.setting.seokeywords"/></span>
                                <div className="form__form-group-field">
                                   <textarea  component="textarea" style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                     defaultValue={marqueblanche?.extras?.keywords!==undefined?marqueblanche?.extras?.keywords:""}   name="keywords"  placeholder="keywords"
                                    onChange={(e) => this.changeVal('keywords', e.target.value)}   />
                                </div>
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.Hometitle"/></span>
                              
                                   <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.extras?.homH1!==undefined?marqueblanche.extras?.homH1:""} 
                                 name="homH1" component="input" placeholder="domaine"
                                    onChange={(e) => this.changeVal('homH1' , e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.Abouttitle"/></span>
                              
                                   <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={aboutH1!==undefined?aboutH1:''} 
                                 name="aboutH1" component="input" placeholder="A propos du titre"
                                    onChange={(e) => this.changeVal('aboutH1', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.Cardtitle"/></span>
                              
                                   <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.extras?.cartH1!==undefined?marqueblanche.extras?.cartH1:""} 
                                 name="cartH1" component="input" placeholder="domaine"
                                    onChange={(e) => this.changeVal('cartH1', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.Contacttitle"/></span>
                              
                                   <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.extras?.contactH1!==undefined?marqueblanche.extras?.contactH1:""} 
                                 name="contactH1" component="input" placeholder="domaine"
                                    onChange={(e) => this.changeVal('contactH1', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="12">
                            <h4> <FormattedMessage id="app.marque.color"/></h4>
                            </Col>
                            <Col md="6">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.color"/> 1</span>
                              <br/>
                              
                                   <input  type="color"style={{ width: '50%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   value={marqueblanche?.extras?.color1!==undefined?marqueblanche.extras?.color1:""} 
                                 name="color1" component="input" placeholder="domaine"
                                    onChange={(e) => this.changeVal('color1', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.color"/> 2</span><br/>
                              
                                   <input  type="color"style={{ width: '50%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   value={marqueblanche?.extras?.color2!==undefined?marqueblanche.extras?.color2:""} 
                                 name="color2" component="input" placeholder="domaine"
                                    onChange={(e) => this.changeVal('color2', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="12">
                            <h4> <FormattedMessage id="app.marque.fontsize"/></h4>
                            </Col>
                            <Col md="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.marque.fontsizeName"/></span>
                              
                                   <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.extras?.policeNameTitle!==undefined?marqueblanche.extras?.policeNameTitle:""} 
                                 name="policeNameTitle" component="input" placeholder="fontSize name"
                                    onChange={(e) => this.changeVal('policeNameTitle', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.marque.font"/></span>
                              
                                   <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.extras?.policeTitle!==undefined?marqueblanche.extras?.policeTitle:""} 
                                 name="policeTitle" component="input" placeholder="fontSize"
                                    onChange={(e) => this.changeVal('policeTitle', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="12">
                            <h4> <FormattedMessage id="app.marque.textFont"/></h4>
                            </Col>
                            <Col md="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.marque.fontsizeName"/></span>
                              
                              <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.extras?.policeNameText!==undefined?marqueblanche.extras?.policeNameText:""} 
                                 name="policeNameText" component="input" placeholder="fontSize text"
                                    onChange={(e) => this.changeVal('policeNameText', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <Col md="6">
                            <div className="form__form-group">
                              <span className="form__form-group-label"> <FormattedMessage id="app.marque.font"/></span>
                              
                              <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                   defaultValue={marqueblanche?.extras?.policeText!==undefined?marqueblanche.extras?.policeText:""} 
                                 name="policeText" component="input" placeholder="fontSize"
                                    onChange={(e) => this.changeVal('policeText', e.target.value)}
                                  />
                             
                              </div>
                            </Col>
                            <br/>
                        
                      
                        
                     
                           
                            <Col md="12">
                              <div className="form__form-group">
                              <span className="form__form-group-label"><FormattedMessage id="app.marque.template"/></span>
                                <div className="form__form-group-field">
                                <Col md="6"> <CustomInput type="radio" id="exampleCustomRadio" name="customRadio" >
          <img alt="Template1"  src={`${process.env.PUBLIC_URL}/img/template-one.jpg`} style={{ width: 250,marginRight:15 }} /></CustomInput></Col> 
                                <Col md="6">  <CustomInput type="radio" id="exampleCustomRadio2" name="customRadio"  >
          <img alt="Template1"  src={`${process.env.PUBLIC_URL}/img/template-two.jpg`}   style={{ width: 250,marginRight:15, marginTop:15 }}/></CustomInput>
        </Col>           
                               
         
        
        </div>
                         
                             {/* <Select 
                                 onChange={(e) => this.changeTemplate(e)}
                                   options={Template}
                                  //  value="template 1"
                                   styles={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }} /> */}
                                  
                            
                               
                              
                              </div>
                            </Col>
                            
                           
                        
                            

                            <Col md="12">
                              <div className="form__form-group">
                              <span className="form__form-group-label">  <FormattedMessage id="app.setting.description"/></span>
                                <div className="form__form-group-field">
                                   <textarea  component="textarea" style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                                     defaultValue={marqueblanche?.description!==undefined?marqueblanche.description:""}   name="description"  placeholder="description"
                                    onChange={(e) => this.changeVal('description', e.target.value)}   />
                                </div>
                              </div>
                            </Col>
                            <Col >
                            </Col>
                            </Row>
                            </Col>
                            <Col >
      
                            <Gallery images={this.state.images}  accept={this.addImgToGallery} delete={this.deleteImgFromGallery} />
                            </Col>
                            <Col  md="12" style={{marginTop:15}}>
      
      <Button className="btn btn-small" color="primary" type="submit"> <FormattedMessage id="app.btn.edit"/></Button>

      <Button className="btn btn-small" color="secondary"  to={"/app/marque-blanche/list"}> <FormattedMessage id="app.btn.cancel"/></Button>

    </Col>

                      </Row>
                      </form>
                </div>
               
               </div>
               </CardBody>
               </Card> )
            }
        }
        
        const mapStateToProps = ({auth, marqueblanche,restaurant}) => {
           
            const {user,users } = auth
            const {loading_get_marqueblanche, error_get_marqueblanche,marque_blanche}=marqueblanche
            const {restaurants}=restaurant
         
         
        
        
            return {
                form: 'log_in_form',
               user,users,restaurants,
               loading_get_marqueblanche, error_get_marqueblanche,marque_blanche
            }
        }
        export default connect(mapStateToProps, {
            getUsers,getMarque_Blanche,getRestaurants
          
        }) (reduxForm()(EditMarqueBlanche));
        
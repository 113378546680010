import React, { PureComponent } from 'react';
//import { Col, Row , Button } from 'reactstrap';
import { Button, ButtonToolbar, Modal , Table } from 'reactstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
	getDishs
} from "../../../redux/actions/dishActions";
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';
class AddDishsMdl extends PureComponent {
  constructor() {
		super();
		this.state = {
            // name: "",
            // type: '',
            // price:0,
            // dish_picture: null,
            // dish_picture_url: '',
            selected_items : [],
            current_page: 1,
			totalPage: 1,
			selected_page_size: 5,
			page_sizes: [5, 10, 20, 30, 50],
		};
}

//   changeVal = (key, value) => {
// 		const obj = {}; obj[key] = value;
// 		this.setState(obj)
//   }

  addDishs = () =>{
    // const { name, type, price, dish_picture, dish_picture_url } = this.state

    // if(name!=''){
    //   const frm = new FormData()
    //   if(dish_picture && dish_picture_url!='') frm.append('picture', dish_picture)
    //   frm.append('name', name)
    //   frm.append('type', type)
    //   frm.append('price', price)
    //   this.props.addDish(frm)
    // }
  }
  componentWillMount() {
    this.getPage()
  }

  getPage(page = 1) {
    const { selected_page_size,
    } = this.state;

    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);

    //const filter = {}

    // if (filter_by_query != '') filter['name'] = filter_by_query

    
    this.props.getDishs({ offset, limit: selected_page_size })
  }

  onChangePage = page => {
    this.setState({ current_page: page }, () => this.getPage(page));
  };
  componentDidUpdate(prev){
    // const {new_dish} = this.props
    // if(prev.loading_add_dish && !this.props.loading_add_dish && !this.props.error_add_dish){
    //   console.log('new dish : ',new_dish);
    //   this.props.success(new_dish)
    // } 
  }

//   changeDishImg = (e) => {
//     try {
//       const dish_picture = e.target.files[0]
//       this.setState({ dish_picture, dish_picture_url: URL.createObjectURL(dish_picture) })
//     } catch (error) { this.setState({ dish_picture: null, dish_picture_url: '' }) }
//   }

    changeSelected = (item , e)=>{
      const {selected_items } = this.state
      
      
      if(e.target.checked){
   
        selected_items.push(item)
        this.setState({selected_items})
      }
      if(!e.target.checked){
      
        let i =0;
        let new_selected_items = []
        for (i=0; i< selected_items.length; i++){
            if(selected_items[i]._id !== item._id){
                new_selected_items.push(selected_items[i])
            } 
        }
        this.setState({selected_items : new_selected_items})
        
      }
    }

    // isSelected = (item)=>{
    //     const {selected_items } = this.state
    //     let i =0;
    //     for (i=0; i< selected_items.length; i++){
    //         if(selected_items[i]==item._id) return true
    //     }
    //     return false
    // }

    accept = ()=>{
        const {selected_items } = this.state
        
        let i =0;
        for (i=0; i< selected_items.length; i++){
            this.props.addPlat(selected_items[i])
        }
        this.props.toggle()
        this.setState({selected_items:[]})
    }

  render() {
 
    const {current_page , selected_page_size } = this.state
    const {is_open, toggle, size , dishs  ,count_all_dishs } = this.props
 
    const modalClass = classNames({
        'modal-dialog--colored': false,
        'modal-dialog--header': true,
      });
    return (<Modal
        isOpen={is_open} size={size || "md"}
        toggle={toggle}
        className={`modal-dialog--primary ${modalClass}`}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title" style={{textAlign: 'start'}}>Ajouter Plats</h4>
        </div>
        
        
        <div className="modal__body" style={{ padding: '0 10px', textAlign: 'left' }}>
        <Table responsive hover>
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Type</th>
							<th>Price</th>
						</tr>
					</thead>
					<tbody>
						{dishs && dishs.map((item, i) => (<tr  key={'dish_' + i+'_'+item._id}>
							<td style={{ verticalAlign: 'middle' }}>
                                <input name="selected_dish" type="checkbox" 
                                    onChange={(e)=>this.changeSelected(item,e)}
									//checked={this.isSelected(item)}

									style={{height: 18, width: 18, cursor: 'pointer'}}
								/>
							</td>
							{/* {!uni && <td style={{ verticalAlign: 'middle' }}></td>} */}
							

							<td style={{ verticalAlign: 'middle' }}>{item.name}</td>
                            <td style={{ verticalAlign: 'middle' }}>{item.type}</td>
                            <td style={{ verticalAlign: 'middle' }}>{item.price}</td>

							
						</tr>))}
					</tbody>
				</Table>


				<Pagination currentPage={current_page}
					totalPage={getCountPages(count_all_dishs, selected_page_size)}
					onChangePage={i => this.onChangePage(i)}
				/>
    
          <div style={{textAlign: "right", width: '100%', height: 40, marginTop: 50}} >
            <ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
              <Button color="primary" onClick={()=>this.accept()}>OK</Button>
              <Button type="button" onClick={toggle}>Cancel</Button>
            </ButtonToolbar>
          </div>
        </div>
      </Modal>);
  }
}


const mapStateToProps = ({ dish }) => {
  const { loading_get_dishs, error_get_dishs  , dishs  ,count_all_dishs } = dish
  
  return {
    loading_get_dishs, error_get_dishs  , dishs , count_all_dishs
  } 
}
export default connect(mapStateToProps, {
    getDishs
})(AddDishsMdl);
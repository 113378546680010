import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card, CardBody, Button, Row, Col
} from 'reactstrap';
import { notification, Popconfirm, Modal, Switch, Table, Tag, Input, Space, } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getUsers } from "../../../redux/actions/authActions";
import { api } from '../../../const';
import Pagination from '../pagination/Pagination';
import { getCountPages } from '../../helper/utils';

import { getDemande_Manager } from "../../../redux/actions/demandemanagerAction";
import axios from 'axios';

import { FormattedMessage } from 'react-intl';
import moment from 'moment'
import 'moment/locale/fr'

class ListDemandeManagerCmp extends PureComponent {

  state = {
    current_page: 1,
    totalPage: 1,
    selected_page_size: 10,
    page_sizes: [5, 10, 20, 30, 50],
    searchText: '',
    searchedColumn: '',
    storageLanguage: localStorage.getItem('language')

  };
  handleChange = (pagination, filters) => {

    this.setState({ filteredInfo: filters });
  };
  getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>

        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          > <FormattedMessage id="app.btn.chercher" />
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="app.btn.cancel" />
          </Button>

        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',


    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  changerStatus = (id) => {

    axios.patch(api.demande_manager + '/' + id, { "isAccepted": true }).then(response => {
      if (response.status = 200) {
        this.openNotificationWithIcon('success')
        this.getPage()


      }
      else {

        this.openNotificationWithIconErreur('error')
        this.getPage()

      }

    })



  }
  suppression = (id) => {
    axios.delete(api.demande_manager + '/' + id).then(response => {
      if (response.status = 200) {
        this.openNotificationWithIconSuppression('success')
        this.getPage({})


      }
      else {

        this.openNotificationWithIconErreur('error')
        this.getPage({})

      }

    })

  }

  componentDidMount(page = 1) {
    this.getPage({})
  }


  getPage(page = 1) {
    const { selected_page_size,
    } = this.state;
    let isAccepted = false
    const offset = (page === 1 || page === 0) ? 0 : selected_page_size * (page - 1);
    this.props.getDemande_Manager({ isAccepted, offset, limit: selected_page_size })
  }

  onChangePage = page => {
    this.setState({ current_page: page }, () => this.getPage(page));
  };


  openNotificationWithIcon = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Request successfully accepted' : 'Demande accepté avec succès',

      });
  };

  openNotificationWithIconSuppression = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'White label Successfully deleted ' : 'La marque blanche est supprimer avec succès',

      });
  };
  openNotificationWithIcon = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({
        duration: 3,
        message: storageLanguage === "en" ? 'Success' : 'Succès',
        description: storageLanguage === "en" ? 'Successful status change' : 'Changemenet du status avec succès',

      });
  };
  openNotificationWithIconErreur = type => {
    const { storageLanguage } = this.state
    notification[type]

      ({

        duration: 3,
        message: storageLanguage === "en" ? 'Error' : 'Erreur',
        description: storageLanguage === "en" ? 'An error has occurred' : 'Un erreur se produit',
      });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {

    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {

    this.setState({
      visible: false,
    });
  };





  showModalEdit = (marqueblanch) => {

    this.setState({
      visibledite: true,
      visible: false,
      selected_marqueblanch: marqueblanch
    });
  };

  handleOkEdit = e => {

    this.setState({
      visibledite: false,
    });
  };

  handleCancelEdit = e => {

    this.setState({
      visibledite: false,
    });
  };
  render() {
    const { count_all_demande_manager, demande_manager } = this.props;
    const { storageLanguage, current_page, selected_page_size, } = this.state

    const columns = [




      {

        title: <FormattedMessage id="app.restaurant" />,
        key: 'restaurant?.name',
        width: '20%',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>
            {  record.restaurant?.logo ? <img alt={record.restaurant?.name} src={`${api.root}/${record.restaurant?.logo?.path}`} style={{ width: 40 }} />
              : <img alt={record.restaurant?.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />
            }
            <span> {record.restaurant?.name}</span>
          </div>)
        },

      },
      {
        title: <FormattedMessage id="app.profile.manager" />,
        dataIndex: 'domaine',
        key: 'domaine',
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('domaine'),
        render: (value, record, index) => {
          return (<div>{record.firstName + " " + record.lastName}
          </div>)
        },


      },

      {
        title: <FormattedMessage id="app.reservation.Date" />,

        key: 'datee',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>{moment(record.createdAt).format('llll')}
          </div>)
        },


      },
      {
        title: "SIRET",

        key: 'datee',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>{record.SIRET}
          </div>)
        },


      },
      {
        title: "APE",

        key: 'datee',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>{record.APE}
          </div>)
        },


      },
      {
        title: <FormattedMessage id="app.informations" />,

        key: 'datee',
        sortDirections: ['descend', 'ascend'],

        render: (value, record, index) => {
          return (<div>
            {record.isOwner ? <div><FormattedMessage id="app.propritair.restaurant" /> <span className="lnr lnr-checkmark-circle" style={{ color: "green" }}></span> </div>
              : <div><FormattedMessage id="app.propritair.restaurant" /> <span className="lnr lnr-checkmark-circle" style={{ color: "red" }}></span> </div>}
            {record.acceptUserCondition ?
              <div><FormattedMessage id="app.condition.utilisation" /> <span className="lnr lnr-checkmark-circle" style={{ color: "green" }}></span> </div>
              : <div><FormattedMessage id="app.condition.utilisation" /> <span className="lnr lnr-checkmark-circle" style={{ color: "red" }}></span> </div>}
            {record.acceptAccord ?
              <div><FormattedMessage id="app.accord" /> <span className="lnr lnr-checkmark-circle" style={{ color: "green" }}></span></div>

              : <div><FormattedMessage id="app.accord" /> <span className="lnr lnr-checkmark-circle" style={{ color: "redd" }}></span></div>}
          </div>)
        },


      },
      // {
      //   title: <FormattedMessage id="app.marque.logo" />,

      //   render: (value, record, index) => {
      //     return (<div>{record.charts?.logo ? <img alt={record.name} src={`${api.root}/${record.charts?.logo?.path}`} style={{ width: 40 }} />
      //       : <img alt={record.name} src={`${process.env.PUBLIC_URL}/img/no.png`} style={{ width: 40 }} />}
      //     </div>)
      //   }

      // },

      {
        title: <FormattedMessage id="app.profile.action" />,
        width: '15%',



        render: (value, record, index) => {
          return (<div>{record.isAccepted === false ?


            <Switch size='small' onChange={() => this.changerStatus(record._id)} style={{ fontSize: 15, fontWeight: 'bolder', color: '#dc3545', cursor: 'pointer' }} />
            : <Popconfirm title={storageLanguage === "en" ? "Do you want to accept the request for " + record.restaurant?.name + ' ?' : "Voulez-vous accepté la demmande de" + record.restaurant?.name + ' ?'}
              onConfirm={() => this.changerStatus(record._id, record.isAccepted)} onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >

              <Switch defaultChecked size='small' color='red' />
            </Popconfirm>}
            <Popconfirm title={storageLanguage === "en" ? "Do you want to delete the request  of " + record.restaurant?.name + ' ?' : "Voulez-vous supprimer la demande de  " + record.restaurant?.name + ' ?'} onConfirm={() => this.suppression(record._id)}
              onCancel={this.cancel} okText={<FormattedMessage id="app.btn.oui" />} cancelText={<FormattedMessage id="app.btn.non" />} >
              <span className="lnr lnr-trash" style={{ color: "#dc3545", fontSize: 18, marginLeft: 25, cursor: 'pointer' }}></span>
            </Popconfirm>


          </div>)
        }
      },
    ];

    return (<Card>
      <CardBody>
        {/* <Row style={{ marginBottom: 10 }}>
          <Col md="8"></Col>


          {(user && user.roles.includes('ADMIN')) && <Col md="4" style={{ textAlign: 'right' }}>
            <Button color="success" className="rounded" onClick={this.showModal}><FormattedMessage id="app.btn.addmarqueblanch" /></Button>
          </Col>}
        </Row> */}
        <div>        <Table rowKey={(record, index) => index} scroll={{ x: 1000 }}
          dataSource={demande_manager} columns={columns} bordered pagination={false}
          style={{ marginLeft: '1%', marginRight: '1%', marginTop: '3%' }} /></div>
        <Pagination currentPage={current_page}
          totalPage={getCountPages(count_all_demande_manager, selected_page_size)}
          onChangePage={i => this.onChangePage(i)} />

      </CardBody>


    </Card>)

      ;
  }
}

const mapStateToProps = ({ auth, Rq_Manager }) => {

  const { user, users } = auth
  const { loading_get_demandemanager, error_get_demandemanager, demande_manager, count_all_demande_manager } = Rq_Manager



  return {
    user, users,
    loading_get_demandemanager, error_get_demandemanager, demande_manager, count_all_demande_manager
  }
}
export default connect(mapStateToProps, {
  getUsers, getDemande_Manager

})(ListDemandeManagerCmp);


import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_DIET, getDietSuccess, getDietError, 
  ADD_DIET, addDietError, addDietSuccess, 
  // GET_ONE_DIET, getOneDietuccess, getOneDietError, 
 EDIT_DIET, editDietError, editDietSuccess,
 
 DELETE_DIET, deleteDietError, deleteDietSuccess
} from '../actions/dietActions';
import { api } from '../../const';

// *** GET DIETS
export function* watchGetDiet() { yield takeEvery(GET_DIET, getDiet) }
function* getDiet({ payload }) {
  try {
    const ret = yield call(getDietRequest, payload);

    if (ret && ret.status === 200) yield put(getDietSuccess({ diet_types: ret.data.diets || [], count: ret.data?.count || 0}));
    else yield put(getDietError({ message: ret.data ? ret.data.msg : "Error" }))
  } catch (error) {
    yield put(getDietError({ error, message: (error) ? error.message : '' }));
  }
}
const getDietRequest = async (payload) => {  
  return await axios.get(api.diet_types +'/get-all')

  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};


// *** ADD DIETS
export function* watchaddDiet() { yield takeEvery(ADD_DIET, addDiet) }
function* addDiet({ payload }) {
  try {
    const ret = yield call(addDietRequest, payload);
    if (ret && ret.status === 200) yield put(addDietSuccess({ DIET: ret.data }));
    else yield put(addDietError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addDietError({ error, message: (error) ? error.message : '' }));
  }
}
const addDietRequest = async (payload) => {  
  return await axios.post(api.diet_types, payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
  
};


// *** EDIT DIETS
export function* watchEditDiet() { yield takeEvery(EDIT_DIET, editDiet) }
function* editDiet({ payload }) {
 try {
    const ret = yield call(editDietRequest, payload);

    if (ret && ret.status === 200) yield put(editDietSuccess({ DIET: ret.data }));
   else yield put(editDietError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
   yield put(editDietError({ error, message: (error) ? error.message : '' }));
 }
}
const editDietRequest = async (payload) => {  

 return await axios.patch(api.diet_types+'/'+payload._id,payload)
.then(resp => resp)
 .catch(error => { return (error && error.response) ? error.response : error })
};




// *** DELETE DIETS
export function* watchDeleteDiet() { yield takeEvery(DELETE_DIET,deleteDiet) }
function* deleteDiet({ payload }) {
 try {
   const ret = yield call(deleteDietRequest, payload);

   if (ret && ret.status === 204) yield put(deleteDietSuccess({ DIET: ret.data }));
    else yield put(deleteDietError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteDietSuccess({ error, message: (error) ? error.message : '' }));
  }
}
const deleteDietRequest = async (payload) => {  
  return await axios.delete(api.diet_types+'/'+payload._id, payload.data)
 .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};

export default function* rootSaga() {
    yield all([
      fork(watchGetDiet),
      fork(watchaddDiet),
     fork(watchDeleteDiet),
     fork(watchEditDiet)
    ]);
  }